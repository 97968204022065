<template>
  <div id="dealer-bike-loan">
    <v-stepper v-model="currentStep" alt-labels class="stepper-wrapper elevation-0">
      <v-stepper-header class="header-stepper-wrapper elevation-0">
        <v-divider class="first_divider_stepper mt-13" :style="dividerStyle(0)" />
        <v-stepper-step :complete="currentStep > 1" class="loan-step-customer-data" step="1">
          <span class="stepper-header">{{ $t('dealer-bike.loan.customer-data-title') }}</span>
        </v-stepper-step>
        <v-divider class="divider_stepper mt-13" :style="dividerStyle(1)" />
        <v-stepper-step :complete="currentStep > 2" class="loan-step-loan-data" step="2">
          <span class="stepper-header">{{ $t('dealer-bike.loan.loan-data-title') }}</span>
        </v-stepper-step>
        <v-divider class="last_divider_stepper mt-13" :style="dividerStyle(2)" />
      </v-stepper-header>
    </v-stepper>
    <v-card elevation="5" class="mx-0 mx-md-12 px-0 px-md-6 py-6">
      <v-expansion-panels v-model="panels" multiple flat>
        <v-form
          id="dealer-bike-loan-form-customer-data"
          ref="DealerBikeLoanFormCustomerData"
          v-model="isValidFormCustomerData"
          :class="`full-width ${currentStep !== 1 ? 'd-none' : ''}`"
          v-focusNextOnEnter
        >
          <contact-lookup
            v-model="contact"
            :text="$t('dealer-bike.loan.lbl-search-contact')"
            :message-not-found="$t('dealer-bike.loan.contact.lbl-search-not-found')"
            :message-error="$t('dealer-bike.loan.contact.lbl-search-error')"
            @search="onContactSearched" v-if="!contact"
            :country="country"
          />

          <expansion-panel :class="{'d-hidden': contact === null}"
                           uid="expansion-panel-loan-contact-data"
                           :title="$t('dealer-bike.loan.contact-data-title')"
                           :hide-divider="true"
                           expanded
          >
            <template v-slot:panel-content>
              <contact-form v-model="contact"
                            first-name-required
                            last-name-required
                            email-required
                            phone-readonly
                            country-disabled
                            :country="country"
                            :invalid-emails="invalidEmails"
              />
            </template>
          </expansion-panel>
        </v-form>
        <v-form
          id="dealer-bike-loan-form-loan-data"
          ref="DealerBikeLoanFormLoanData"
          :class="`full-width ${currentStep !== 2 ? 'd-none' : ''}`"
          v-focusNextOnEnter
          v-if="currentStep === 2"
        >
          <vehicle-data @validateForm="validateFormStepLoanData" :readonly="true" :reduced="true" show-labels />
          <expansion-panel
            :title="$t('dealer-bike.detail.observations-title')"
            data-panel="damage-log"
          >
            <template v-slot:panel-content>
              <m-form-row>
                <m-note-log
                  v-model="damageLog"
                  :placeholder="$t('dealer-bike.detail.observations-placeholder')"
                  :add-button-text="$t('dealer-bike.detail.observations-btn-add')"
                  :cancel-text="$t('buttons.cancel')"
                  :remove-dialog-title="$t('dealer-bike.detail.observations-remove-dialog-title')"
                  :remove-dialog-text="$t('dealer-bike.detail.observations-remove-dialog-text')"
                  allow-remove
                />
              </m-form-row>
            </template>
          </expansion-panel>
          <loan-data :showValidations="showValidations" @validateForm="validateFormStepLoanData"/>
        </v-form>
      </v-expansion-panels>
    </v-card>
    <v-card-actions class="px-12 pt-10 pb-6">
      <v-btn
        v-if="contact"
        class="btn-gray"
        data-cy="btnBackLoanDealerBike"
        @click="backStep"
        :loading="loading"
        :disabled="loading"
        tabindex="-1"
      >
        {{ $t('buttons.back') }}
      </v-btn>
      <div class="my-spacer" />
      <v-btn
        v-if="currentStep === 2"
        class="btn-primary"
        data-cy="btnSaveLoanDealerBike"
        @click.once="saveLoanDealerBike"
        :key="buttonKey"
        :loading="loading"
        :disabled="!isValidFormCustomerData || !isValidFormLoanData || loading"
      >
        {{ $t('buttons.confirm-data') }}
      </v-btn>
      <v-btn
        ref="nextBtn"
        class="btn-primary"
        data-cy="btnNextStep"
        @click="nextStep"
        :loading="loading"
        v-else-if="phoneSearched"
        :disabled="!isValidFormCustomerData"
      >
        {{ $t('buttons.next') }}
      </v-btn>
    </v-card-actions>
    <mapit-dialog :dialog="isOpenDialog" :hideCloseButton="true" @close="closeDialog()" v-if="contact">
      <template v-slot:header-icon>
        <v-icon color="#DBDBDB">fa-solid fa-signature</v-icon>
      </template>
      <template v-slot:header-text>
        <span>{{ $t(digitalSignatureTextsPopup.title) }}</span>
      </template>
      <template v-slot:body>
        <result-info-box
          class="mt-5"
          :type="digitalSignatureTextsPopup[getDigitalSignatureStatus].color"
          :text="$t(digitalSignatureTextsPopup[getDigitalSignatureStatus].description)"
        />
        <div
          class="dialog-body mt-5"
          v-html="$t(digitalSignatureTextsPopup[getDigitalSignatureStatus].subDescription, { email: contact.email })"
        />
        <div
          style="text-align: -webkit-center"
          class="mt-5"
          v-if="getDigitalSignatureStatus == digitalSignatureStatus.waiting"
        >
          <v-progress-circular :size="50" color="gray" indeterminate />
          <div class="dialog-body mt-5" v-html="$t(digitalSignatureTextsPopup[getDigitalSignatureStatus].status)" />
        </div>
      </template>
      <template v-slot:footer-center>
        <v-btn data-cy="btnDialogSignature" class="btn-gray" @click="closeDialog()">{{
          $t(digitalSignatureTextsPopup[getDigitalSignatureStatus].action)
        }}</v-btn>
      </template>
    </mapit-dialog>
  </div>
</template>

<script>
import DealerBikesApi from '@/api/dealerBikes.api'
import VehicleData from './sections/VehicleData'
import LoanData from './sections/LoanData'
import MapitDialog from '@/components/dialog/MapitDialog'
import { digitalSignatureStatus } from '@/utils/constants'
import { isEqual } from 'lodash'
import ResultInfoBox from '@/components/general/ResultInfoBox.vue'
import { UsageType } from '@mapit/dealer-bikes-dynamodb/dist/types'
import { showAlert } from '@/vuex/modules/ui'
import ExpansionPanel from "@/components/general/ExpansionPanel"
import {mapMutations, mapGetters} from "vuex"
import ContactLookup from "@/components/contact/ContactLookup"
import ContactForm from "@/components/contact/ContactForm"

export default {
  name: 'DealerBikesLoan',
  components: {
    ContactForm,
    ContactLookup, ExpansionPanel, VehicleData, LoanData, MapitDialog, ResultInfoBox },
  props: ['dealerBikeId'],
  data() {
    return {
      showValidations: false,
      loading: false,
      isValidFormCustomerData: false,
      isValidFormLoanData: false,
      buttonKey: 1,
      currentStep: 1,
      isOpenDialog: false,
      interval: null,
      timeoutInterval: null,
      digitalSignatureStatus,
      digitalSignatureTextsPopup: {
        title: 'dealer-bike.loans.popups.digital-signature.title',
        [digitalSignatureStatus.waiting]: {
          color: 'WARNING',
          description: 'dealer-bike.loans.popups.digital-signature.waiting-popup.body-top',
          subDescription: 'dealer-bike.loans.popups.digital-signature.waiting-popup.body-bottom',
          status: 'dealer-bike.loans.popups.digital-signature.waiting-popup.status',
          action: 'buttons.close',
        },
        [digitalSignatureStatus.success]: {
          color: 'SUCCESS',
          description: 'dealer-bike.loans.popups.digital-signature.success-popup.body-top',
          subDescription: 'dealer-bike.loans.popups.digital-signature.success-popup.body-bottom',
          action: 'buttons.close',
        },
        [digitalSignatureStatus.error]: {
          color: 'ERROR',
          description: 'dealer-bike.loans.popups.digital-signature.error-popup.body-top',
          subDescription: 'dealer-bike.loans.popups.digital-signature.error-popup.body-bottom',
          action: 'buttons.close',
        },
      },
      phoneSearched: null,
    }
  },
  methods: {
    async onContactSearched(phone, contact) {
      this.phoneSearched = phone

      if (contact) {
        await this.$store.dispatch('showAlertSuccess', 'dealer-bike.loan.contact.lbl-search-found')
      } else {
        this.$nextTick(() => this.$refs.nextBtn.$el.focus())
      }
    },
    dividerStyle(step) {
      return this.currentStep > step
        ? 'border: 1px solid var(--v-primary-base) !important; background-color:var(--v-primary-base);'
        : 'border: 1px solid var(--v-white-base) !important;'
    },
    validateFormStepLoanData() {
      if (this.$refs.DealerBikeLoanFormLoanData) {
        this.isValidFormLoanData = this.$refs.DealerBikeLoanFormLoanData.validate()
      }
    },
    async backStep() {
      if (this.currentStep === 1) {
        await this.$store.dispatch('resetDealerBikeLoanContact')
        this.phoneSearched = null
        this.isValidFormCustomerData = true
      } else {
        this.currentStep = 1
      }
    },
    async nextStep() {
      if (!this.contact && this.phoneSearched) {
        await this.$store.dispatch("prepareDealerBikeLoanContact",{phone:this.phoneSearched})
      } else if (this.$refs.DealerBikeLoanFormCustomerData.validate()) {
        this.currentStep = 2
        this.buttonKey++
        this.validateFormStepLoanData()
      }
    },
    checkStatusLoan() {
      this.$store
        .dispatch('loadLoanById', this.getCurrentLoanId)
        .then((data) => {
          if (data.handover.signed) {
            this.$store.commit('SET_DIGITAL_SIGNATURE_STATUS', digitalSignatureStatus.success)
            this.clearIntervals()
          }
        })
        .catch((error) => {
          console.error(error)
          this.clearIntervals()
          return showAlert(this.$store.dispatch, 'error', 'dealer-bike.loans.popups.error')
        })
    },
    saveLoanDealerBike() {
      this.loading = true
      if (this.$refs.DealerBikeLoanFormCustomerData.validate() && this.$refs.DealerBikeLoanFormLoanData.validate()) {
        DealerBikesApi.createLoan(this.$route.params.dealerBikeId, this.getRequestLoanData)
          .then((res) => {
            this.$store.commit('SET_LOAN_ID', res.headers.location)
            this.isOpenDialog = true
            this.interval = setInterval(this.checkStatusLoan, 5000)
            this.timeoutInterval = setInterval(() => {
              this.$store.commit('SET_DIGITAL_SIGNATURE_STATUS', digitalSignatureStatus.error)
              this.clearIntervals()
            }, 300000)
          })
          .catch((err) => {
            this.$store.dispatch('setAlertResult', {
              type: 'error',
              stringId: 'dealer-bike.loan.alert-create-error',
              open: true,
            })
            this.showValidations = true
          })
          .finally(() => {
            this.loading = false
            this.buttonKey++
          })
      } else {
        this.showValidations = true
        this.loading = false
        this.buttonKey++
      }
    },
    closeDialog() {
      if (isEqual(this.getDigitalSignatureStatus, digitalSignatureStatus.waiting)) {
        this.clearIntervals()
        this.$store.commit('SET_DIGITAL_SIGNATURE_STATUS', digitalSignatureStatus.error)
      } else {
        this.isOpenDialog = false
        this.$router.push({ name: 'LoansList' })
      }
    },
    clearIntervals() {
      clearInterval(this.interval)
      clearInterval(this.timeoutInterval)
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      country: 'getCountry',
      getRequestLoanData: 'getRequestLoanData',
      getDigitalSignatureStatus: 'getDigitalSignatureStatus',
      getCurrentLoanId: 'getLoanId'
    }),
    invalidEmails(){
      return [this.user.login]
    },
    panels: {
      get() {
        return this.$store.getters.getDealerBikeLoanPanels
      },
      set(panels) {
        this.$store.dispatch('setDealerBikeLoanPanels', panels)
      },
    },
    contact: {
      get() {
        return this.$store.getters.getDealerBikeLoanContact
      },
      set(contact) {
        this.$store.commit('SET_DEALER_BIKE_LOAN_CONTACT', contact)
      }
    },
    isCourtesyBike() {
      return this.$store.getters.getDealerBikeUsageType === UsageType.Courtesy
    },
    damageLog: {
      ...mapGetters({get: 'getDealerBikeLoanHandoverDamageLog' }),
      ...mapMutations({set: 'SET_DEALER_BIKE_LOAN_HANDOVER_DAMAGE_LOG'})
    }
  },
  async created() {
    if (this.$route.params.dealerBikeId) {
      await this.$store.dispatch('startLoanHandoverProcess', this.$route.params.dealerBikeId)
    } else {
      await this.$store.dispatch('cleanDealerBike')
    }
  },
  destroyed() {
    this.clearIntervals()
  },
}
</script>
<style scoped>
.stepper-header {
  line-height: 1.5;
}
</style>
