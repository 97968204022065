import { hondaCatalogApi } from '@/api/honda-catalog.api';
import { MaterialWithDescription, SortOrder } from '@/types';
import { Branch } from '@/utils/constants';
import { createMaterialDescription } from '@/utils/utils';
import { Material, Model } from "@mapit/honda-catalog-dynamodb/dist/types";
import { ref, Ref, watch } from "@vue/composition-api";

interface Props {
  branch: Branch
  model: Ref<Model | undefined>
}

export const useMaterials = ({ branch, model }: Props) => {

  const materials = ref([]) as Ref<Array<MaterialWithDescription>>
  const isLoading = ref(false)
  const abortController = ref(new AbortController()) as Ref<AbortController>

  watch(model, fetchMaterials)

  function formatMaterialDescription(material: Material) {
    return {
      ...material,
      description: createMaterialDescription(material)
    } as MaterialWithDescription
  }

  async function fetchMaterials() {
    try {
      if (isLoading.value) {
        abortController.value.abort()
        abortController.value = new AbortController()
      }
      isLoading.value = true
      const modelName = model.value?.name

      if (modelName) {
        const response = await hondaCatalogApi.getMaterialsByModel(branch, modelName, abortController.value.signal)
        materials.value = response
          .map(formatMaterialDescription)
          .sort(sortMaterials)
      }
    } catch (e) {
      console.error(`Error fetching materials`, e)
      return []
    } finally {
      isLoading.value = false
    }
  }

  function sortMaterials(a: MaterialWithDescription, b: MaterialWithDescription) {
    return compareMaterialYear(a, b, SortOrder.DESC) || compareMaterialCode(a, b, SortOrder.ASC)
  }

  function compareMaterialYear(a: MaterialWithDescription, b: MaterialWithDescription, order: SortOrder) {
    if (order === SortOrder.ASC) {
      return a.model.y - b.model.y
    } else {
      return b.model.y - a.model.y
    }
  }

  function compareMaterialCode(a: MaterialWithDescription, b: MaterialWithDescription, order: SortOrder) {
    if (order === SortOrder.ASC) {
      return parseInt(a.idMat as string) - parseInt(b.idMat as string)
    } else {
      return parseInt(b.idMat as string) - parseInt(a.idMat as string)
    }
  }

  return {
    materials,
    loading: isLoading,
    fetch: fetchMaterials,
  }
}
