
import { defineComponent, PropType, computed } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'

import { Variant } from './types'
import { themes } from './theme'

export default defineComponent({
  name: 'MChip',
  props: {
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String as PropType<string | TranslateResult>,
      required: true,
    },
    tooltip: {
      type: String as PropType<string | TranslateResult>,
      required: false,
    },
    variant: {
      type: String as PropType<Variant>,
      required: false,
    },
  },
  setup(props: any) {
    const theme = computed(() => {
      return themes[props.variant as Variant]
    })

    return { theme }
  },
})
