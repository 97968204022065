<template>
<v-app class="bg-main" :style="login_bg_style">
  <div class="login" style="height: 100%;">
    <m-snackbar
      id="login-snackbar"
      v-model="alertResultState"
      :type="alertResult.type"
      :timeout="5000"
      :text="alertResult.stringId"
    />
    <app-header :hideUserMenu="true"></app-header>

    <amplify-authenticator username-alias="email" :federated.prop="{}" handle-auth-state-change="">
      <amplify-sign-up
        slot="sign-up"
        username-alias="email"
        :form-fields.prop="formFields"
      ></amplify-sign-up>
      <amplify-sign-in
        :federated.prop="{}"
        slot="sign-in"
        username-alias="email"
        hide-sign-up
      />
      <amplify-forgot-password
        slot="forgot-password"
        show-resend
      />
    </amplify-authenticator>

    <v-card class="form-card my-16" style="width: 160px; height: 100px;" :loading="true">
      <div class="form-card d-flex justify-center align-center">
        {{ $t('common.loading') }}
      </div>
    </v-card>

    <!--    </v-card>-->
    <v-footer fixed bottom dark color="transparent">
      <div class="full-width text-center">
      Copyright&copy; {{ new Date().getFullYear() }} MAPIT IoT S.L.
      </div>
    </v-footer>

  </div>
</v-app>
</template>

<script>
import '../styles/login.less'
import AppHeader from '@/components/AppHeader'
import {Hub, I18n} from 'aws-amplify'
import {onAuthUIStateChange} from "@aws-amplify/ui-components"
import awsAmplifyLocales from '@/utils/aws-amplify-locales'

I18n.putVocabularies(awsAmplifyLocales)

export default {
  components: { AppHeader },
  name: 'Login',
  data () {
    return {
      err: null,
      authState: null,
      authData: null,
      formFields: [
        {
          type: 'email',
          label: 'Correo electrónico',
          placeholder: '',
          required: true,
        },
        {
          type: 'password',
          label: 'Contraseña',
          placeholder: '',
          required: true,
        },
      ]
    }
  },
  mounted() {
    window.document.body.className = "styled_forms"
  },
  computed: {
    filled() {
      return (this.email && this.email.length && this.password && this.password.length);
    },
    loading() {
      return this.$store.getters.getLoading
    },
    user() {
      return this.$store.getters.getUser;
    },
    login_bg_style() {
      return {
        "background": `url('/images/login-bg-${Math.ceil(Math.random()*3)}.png')`
      }
    },
    alertResult() {
      return this.$store.getters.getAlertResult
    },
    alertResultState: {
      get() {
        return this.alertResult.open
      },
      set(value) {
        this.$store.dispatch('setAlertResult', { ...this.alertResult, open: value })
      },
    },
  },
    beforeCreate() {
      let locale = navigator.language.substring(0,2).toLowerCase() || 'es'
      if(!(locale in awsAmplifyLocales)) locale = 'es'
      I18n.setLanguage(locale)
      this.$i18n.locale = locale
    },
    created(){
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState
      this.authData = authData
    })
    Hub.listen('UI Auth', ({payload}) => {
      if (payload.event === 'ToastAuthError') {
        this.$store.dispatch('setAlertResult', {
          type: 'error',
          stringId: `${payload.message}`,
          open: true,
        })
        // Hide Amplify's default Toast since we're showing our own
        const target = document.getElementsByTagName("amplify-authenticator")[0];
        if (target?.shadowRoot?.children) {
          setTimeout(() => {
            target.shadowRoot.children[0].style.display = "none";
          }, 50); // needed because node isn't injected straight away
        }
      }
    })
  },
  watch: {
    authState(newVal) {
      if (newVal === 'signedin') {
        return this.$router.push({name: 'Dashboard'})
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
.custom-outlined {
  border-radius: 0;
  input {
    font-family: 'ProximaNovaRegular' !important;
    font-size: 16px !important;
  }
  fieldset {
    border-radius: 0 !important;
    height: 60px !important;
  }
}

amplify-authenticator {
  top: 80px
}
.toast {
  position: relative !important;
}
</style>
<style lang="scss" scoped>
#login-snackbar {
  top: 70px;
  z-index: 0;
}
.card-title{
  text-align: left;
  color: black;
  font-size: 32px !important;
  font-family: 'ProximaNovaExCnSbold', sans-serif !important;
  text-transform: uppercase;
}

h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
