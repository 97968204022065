<template>
  <th class="align-left" v-if="isHeader">
      {{title}}
  </th>
  <td class='container-column' v-else>
		<div class="col-md-6 col-sm-12 p-r-10 align-left" v-if="rowData">
			<span class="f-s-14"><b>{{getContainerDetailParam(rowData.container, 'Model')}}</b></span><br/>
		</div>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
      rowData: {
        type: Object,
        default: () => {
          return {
              container:{
                detail:{
                  Model: '-',
                },
              },
          }
        }
      }
  },
  methods: {
    getContainerDetailParam(container, key){
      const detail = JSON.parse(container.detail);
      return detail[key];
    }
  },
  name: 'Model'

}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>

