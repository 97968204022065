














import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import Packs from '@/components/offer-manager/sections/Packs.vue'
import Complements from '@/components/offer-manager/sections/Complements.vue'
import {defineComponent, inject, ref} from '@vue/composition-api'

export default defineComponent({
  name: 'Accessories',
  components: { Complements, Packs, ExpansionPanel },
  props: {
    panelReadonly: {
      type: Boolean,
      default: false
    },
    panelHideDivider: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const isEditing = inject('isEditing', ref(false))

    return {isEditing}
  }
})
