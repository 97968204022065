<template>
  <th class='b-r-1 align-left' v-if="isHeader">
      {{title}}
  </th>
  <td class='b-r-1 align-left column-500' v-else>
		<div v-if="rowData">
			<div class="col-12 p-l-15 p-r-15 ">
				<span class="f-s-14">
          {{getTime(rowData.request)}}
        </span><br/>
			</div>
		</div>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
      rowData: {
        type: Object,
        default: () => {
          return { request: { pickup: { hour: '-'} } };
        }
      }
  },
  methods:{
    getTime : (request) => {
      return  (request && request.pickup)? request.pickup.hour:'';
    }
  },
  name: 'Time'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>

