
























import MapitDialog from '@/components/dialog/MapitDialog.vue'
import {defineComponent} from "@vue/composition-api";

export default defineComponent({
  name: "DialogExitProcess",
  components: {
    MapitDialog
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    }
  },
  setup() {
    return {}
  }
})
