







import {ResultStatus} from '@/types'
import {computed, defineComponent, PropType, ref} from '@vue/composition-api'
import {TypeResultStylesData} from '@/types'

export default defineComponent({
  name: 'ResultInfoBox',
  props: {
    type: {
      type: String as PropType<ResultStatus | undefined>,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  setup(props){
    const cancelDialog = ref<boolean>(false)

    const typeData = computed((): TypeResultStylesData => {
      switch (props.type) {
        case ResultStatus.ERROR:
          return {
            icon: 'T',
            class: 'result-info-box-error',
          }
        case ResultStatus.WARNING:
          return {
            icon: 'T',
            class: 'result-info-box-warning',
          }
        case ResultStatus.SUCCESS:
          return {
            icon: 'R',
            class: 'result-info-box-success',
          }
        default:
          return {
            icon: 'R',
            class: 'result-info-box-transp',
          }
      }
    })

    return {
      cancelDialog,
      typeData
    }
  }
})
