<template>
  <v-row no-gutters>
    <v-col>
      <select data-cy="opening-hours-from" name="open" v-validate="'required'" class='form-select' v-model="open" :disabled='disabled' >
        <option v-for="time in times">
          {{ time }}
        </option>
      </select>
      <small v-if="errors.collect('open').length>0" class="field-text text-error">{{  errors.first('open') }}</small>
    </v-col>
    <v-col>
      <select data-cy="opening-hours-to" name="close" v-validate="`required|date_format:HH:mm|after:${open}`" class='form-select' v-model='close'  :disabled='disabled'>
        <option v-for="time in times">
          {{ time }}
        </option>
      </select>
      <small v-if="errors.collect('close').length>0" class="field-text text-error">{{  errors.first('close') }}</small>
    </v-col>
  </v-row>
</template>
<script>
    import {generateArrayOfHours, parseSchedule} from './HourGenerator';

    export default {
      props: {
        value:{
          type: String
        },
        disabled:{
          type: Boolean,
          default: false
        }
      },
      computed: {
        result() {
          return this.open + " - " + this.close;
        }
      },
      data() {
        const times = generateArrayOfHours();
        let open="", close="";
        if(this.value && this.value.length>0) {
          ([open, close] = parseSchedule(this.value));
        }
        return {open, close, times};
      },
      watch: {
        $data: {
          handler: function (val, oldVal) {
            this.$emit('input', this.result);
          },
          deep: true
        },
        value(val) {
          if(val && val.length>0) {
            this.open = parseSchedule(this.value)[0];
            this.close = parseSchedule(this.value)[1];
          }
        }
      }
    }
</script>
<style scoped>
    .form-select{
      font-family: 'Roboto', sans-serif;
      color: #888;
    }
</style>

