<template>
  <v-data-table
    id="users-table-list"
    :loading="loading"
    :loading-text="$t('honda.users.list.loading')"
    :headers="headers"
    :items="filteredUsers"
    :item-class="getRowClass"
    :items-per-page="10"
    :sort-by="['created']"
    :sort-desc="[true]"
    class="elevation-0"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'fas fa-angle-double-left',
      lastIcon: 'fas fa-angle-double-right',
      prevIcon: 'fas fa-angle-left',
      nextIcon: 'fas fa-angle-right',
      'items-per-page-text': $t('honda.registration.list.footer_num_x_page_client'),
      'items-per-page-options': [10, 25, 50, -1],
    }"
    :no-data-text="
      $t(
        usersList && usersList.length > 0
          ? 'honda.users.list.no_data_search'
          : 'honda.users.list.no_data',
        { search },
      )
    "
    :no-results-text="$t('honda.users.list.no_data_search')"
    data-cy="users-table-list"
    @click:row="selectUser"
  >
    <template #footer.page-text="props">
      {{ props.pageStart }}-{{ props.pageStop }} {{ $t('honda.registration.list.of') }}
      {{ props.itemsLength }}
    </template>

    <template v-slot:item.created="{ item }">
      <span class="text-item">{{ item.created | formatDate }}</span>
    </template>

    <template v-slot:item.ownerLogin="{ item }">
      <div class="customer py-3 lbl-registration-bold" data-cy="customer-name-email">
        {{ item.fullName }}<br />
        <span class="customer-email">{{ item.ownerLogin }}</span>
      </div>
    </template>

    <template v-slot:item.phone="{ item }">
      <span class="text-item" data-cy="customer-phone">{{ item.phone | formatPhone }}</span>
    </template>

    <template v-slot:item.deviceCount="{ item }">
      <span class="text-item">{{ item.deviceCount }}</span>
    </template>

    <template v-slot:item.containerCount="{ item }">
      <span class="text-item">{{ item.containerCount }}</span>
    </template>
  </v-data-table>
</template>

<script>
import api from '@/api'

export default {
  name: 'UsersTable',
  components: {},
  props: ['search', 'forcedGroup'],
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t('honda.users.list.lbl-client'),
          align: 'start',
          value: 'ownerLogin',
          class: 'text-header',
          width: '35%',
          sortable: false,
        },
        {
          text: this.$t('honda.users.list.lbl-phone'),
          align: 'left',
          value: 'phone',
          class: 'text-header',
          width: '20%',
          sortable: false,
        },
        {
          text: this.$t('honda.users.list.lbl-vehicles'),
          align: 'center',
          value: 'containerCount',
          class: 'text-header',
          width: '15%',
          sortable: false,
        },
        {
          text: this.$t('honda.users.list.lbl-mapit'),
          align: 'center',
          value: 'deviceCount',
          class: 'text-header',
          width: '15%',
          sortable: false,
        },
        {
          text: this.$t('honda.users.list.lbl-creation'),
          align: 'left',
          value: 'created',
          class: 'text-header',
          width: '15%',
          sortable: true,
        },
      ],
      usersList: [],
    }
  },
  watch: {
    forcedGroup: function () {
      this.getUsersList()
    },
  },
  computed: {
    filteredUsers() {
      return this.search
        ? this.usersList.filter(
            ({ ownerLogin, fullName, phone, ownerDetail }) =>
              ownerLogin.includes(this.search.toLowerCase()) ||
              fullName.toLowerCase().includes(this.search.toLowerCase()) ||
              `${phone}`.includes(this.search.toLowerCase()) ||
              ownerDetail.Document?.includes(this.search.toUpperCase()),
          )
        : this.usersList
    },
    group() {
      return this.$store.getters.getGroup
    },
  },
  created() {
    this.getUsersList()
  },
  methods: {
    getUsersList() {
      this.loading = true
      api.getSupervisors(
        {
          groupId: `${this.forcedGroup ? this.forcedGroup : this.group.legacy.id}`,
        },
        (err, resp) => {
          if (!err) {
            this.usersList = resp
            this.loading = false
          }
        },
        1,
        5000,
      )
    },
    selectUser(user) {
      this.$router.push({ name: 'User', params: { userId: user.ownerId } })
    },
    getRowClass() {
      return 'click'
    },
  },
}
</script>

<style scoped>
.text-header {
  letter-spacing: 0;
  color: var(--v-gray-lighten5);
  text-align: left;
  font: normal normal 600 16px/20px 'ProximaNovaExCnSBold';
  text-transform: uppercase;
  opacity: 1;
}

.text-item {
  letter-spacing: 0;
  text-align: left;
  font: normal normal normal 16px/26px 'ProximaNovaRegular';
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.customer-phone {
  font-family: 'ProximaNovaRegular', sans-serif;
  color: var(--v-gray-lighten5);
  font-size: 14px !important;
}

.customer-email {
  font-family: 'ProximaNovaRegular', sans-serif;
  color: var(--v-gray-lighten5);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
