









































































import { defineComponent } from '@vue/composition-api'
import CardBars from '@/components/metrics/CardBars.vue'
import CardTrend from '@/components/metrics/CardTrend.vue'

export default defineComponent({
  name: 'StatsOffers',
  components: {
    CardBars,
    CardTrend,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
})
