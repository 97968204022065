<template>

	<div style="height: 100%; width:100%;">
		<div style="height: 150px;" class="p-20 bg-gray-dark">
			<div class="col-12">
				<div class="col-12 columns m-0">
					<div class="col-9 p-r-10">
						<span class="color-white">Session Id:</span>
						<input class="form-input m-b-10" v-model="sessionId" />
					</div>
					<div class="col-3 p-10">
						<button class="btn btn-primary full-width full-height" @click="getData">Buscar Rutas</button>
					</div>
				</div>
				<select v-model="routeId" class="form-input" @change="goLocation(null, true)">
					<option :value="key" v-for="(r, key) in routes">Ruta - {{key /*r[0].timestamp?r[0].timestamp:'Google'*/}}</option>
				</select>
			</div>

		</div>
		<div style="height:100%; background: #333;">
			<div class="google-map" :id="idMap" ></div>
		</div>

	</div>
</template>

<script>
  export default {
    name: 'GoogleMap',
    props: ["name"],
    data() {
      return {
				idMap: this.name + "-map",
	      routes: [],
	      routeId: 0,
	      MAP: null,
	      obj:{
				  poly: [],
		      markers: []
	      },
        sessionId: 861359032350066,
        iconInit: {
          url: 'https://honda.mapit.me/static/images/ic_start_route.png',
          size: new google.maps.Size(57, 69),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 69)
        },
        iconEnd: {
          url: 'https://honda.mapit.me/static/images/ic_end_route.png',
          size: new google.maps.Size(57, 69),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 69)
        }
      };
    },
    mounted() {
      const element = document.getElementById(this.idMap)
      const options = {
        zoom: 12,
        center: new google.maps.LatLng(40.4378698, -3.8196207)
      }


      this.MAP  = new google.maps.Map(element, options);
      this.getData();
    },
    methods: {
      addMarker(location, type) {
        if(type === 1) {
          var marker = new google.maps.Marker({
            position: location,
            map: this.MAP,
	          icon: this.iconInit
          });
          this.obj.markers.push(marker);
        }else{
          var marker = new google.maps.Marker({
            position: location,
            map: this.MAP,
            icon: this.iconEnd
          });
          this.obj.markers.push(marker);
        }
	    },
      goLocation(list, clean){
        if(list === null){
          list = this.routes[this.routeId];
        }

        if(list.length>0) {
          this.MAP.setZoom(14);
          var bounds = new google.maps.LatLngBounds();
          let p1 = {lat: list[0].lat, lng: list[0].lng};
          let p2 = {lat: list[list.length - 1].lat, lng: list[list.length - 1].lng}
          //bounds.extend(p1);
          //bounds.extend(p2);

          list.forEach((point) => {
            bounds.extend({lat: point.lat, lng: point.lng});
          });


          if (clean) {
            this.obj.poly.forEach((item) => {
              item.setMap(null);
            })
            this.obj.markers.forEach((item) => {
              item.setMap(null);
            })
          }

          //create new
          this.addMarker(p1, 0)
          this.addMarker(p2, 1)
          this.createPoly(list)

          this.MAP.fitBounds(bounds);
        }
      },
      createPoly(route){

        var poly = new google.maps.Polyline({
          path: route,
          geodesic: true,
          strokeColor: this.getRandomColor(),
          strokeOpacity: 1.0,
          strokeWeight: 4
        });

        poly.setMap(this.MAP);
        this.obj.poly.push(poly);
      },
	    getRandomColor() {
		    var letters = '0123456789ABCDEF';
		    var color = '#';
		    for (var i = 0; i < 6; i++) {
		      color += letters[Math.floor(Math.random() * 16)];
		    }
		    return color;
		  },
      getData() {
        const http = new XMLHttpRequest();
        http.open('GET', `${VUE_APP_MAPIT_API}container/rawsession?sessionId=${this.sessionId}`, true);
        //http.open('GET', `http://localhost:3000/routes?ndevice=${this.sessionId}`, true);
        http.setRequestHeader('Content-type', 'application/json');
        http.setRequestHeader('Authorization', this.$store.getters.getToken);
        http.onreadystatechange = () => {
          if (http.readyState === 4 && http.status === 200) {

              this.routes = JSON.parse(http.responseText);

              if(this.routes.length>0) {
                this.routes.forEach((item) => {
                  this.goLocation(item, false);
                })
              }

          }
        };
        http.send();
      }
    }
  };
</script>
<style lang="less">
	.google-map {
		width: 1400px;
		height: 800px;
		margin: 0 auto;
		background: #333333;
	}
</style>
