import { render, staticRenderFns } from "./CardAppointments.vue?vue&type=template&id=52ff52b6&scoped=true&"
import script from "./CardAppointments.vue?vue&type=script&lang=ts&"
export * from "./CardAppointments.vue?vue&type=script&lang=ts&"
import style0 from "./CardAppointments.vue?vue&type=style&index=0&lang=css&"
import style1 from "./CardAppointments.vue?vue&type=style&index=1&id=52ff52b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ff52b6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardText,VIcon,VSkeletonLoader})
