<template>
  <th v-if="isHeader">
      {{title}}
  </th>
  <td class='column-500' v-else>
	  <div class="p-20 f-s-14">
		  <b>{{rowData.inspection.prevision}}</b><br/>
	  </div>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
      rowData: {
        type: Object,
        default: () => {
          return { inspection: {prevision: '-'} };
        }
      }
  },
  name: 'Prevision'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
