<template>
  <th class="align-left" v-if="isHeader">
      {{title}}
  </th>
  <td v-else>
		<div class="align-center" v-if="rowData">
<!--      <span :class="getCssClasses(rowData.request)" :title="getTitle(rowData.request)" :data-tooltip="getTitle(rowData.request)"></span>-->
      <span :class="getCssClasses(rowData.request, true)" :data-tooltip="getTitle(rowData.request)"></span>
		</div>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

const getCssClasses = (request, includeTooltip = false) => {
  let ret = 'dot';
  if (request && request.courtesyBike.requested && request.courtesyBike.status == "ACCEPTED")
    ret += ' greenbg';
  else if (request && request.courtesyBike.requested && request.courtesyBike.status == "NOT_AVAILABLE")
    ret += ' redbg';
  else if (request && request.courtesyBike.requested)
    ret += ' warnbg';

  ret = !includeTooltip ? ret : ret + ' tooltip tooltip-left';
  return ret;
};

export default {
  mixins: [VuetableFieldMixin],
  props: {
      rowData: {
        type: Object,
        default: () => {
          return { request:{ courtesyBike: {requested: false} } };
        }
      }
  },
  methods:{
    getCssClasses: getCssClasses,

    getTitle: (request) => {
        let ret = 'No solicitada';
        if (request && request.courtesyBike.requested && request.courtesyBike.status == "ACCEPTED")
          ret = 'Solicitada y disponible';
        else if (request && request.courtesyBike.requested && request.courtesyBike.status == "NOT_AVAILABLE")
          ret = 'Solicitada y no disponible';
        else if (request && request.courtesyBike.requested)
          ret = 'Solicitada';

        return ret;
      }
  },
  name: 'HasContainer'
}
</script>

<style scoped>
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid lightgray;
  display: inline-block;
}
.greenbg{
      background-color: green !important;
}
.warnbg{
  background-color: orange !important;
}

.redbg{
  background-color: red !important;
}

</style>
