



















































































//@ts-nocheck
import {
  FinanceCalculator,
  FinanceConfig,
  FinanceOpts,
  FinanceResult
} from '@mapit/honda-offers-builder/dist/finance-calculator'
import Vue, {PropType} from 'vue'
import {formatNumberToCurrency} from '@mapit/common-utils'
import {Country} from '@mapit/common-utils/dist/types'
import {isMinAmount, isRequired} from "@/helpers/validations";
import PriceField from '@/components/general/PriceField.vue';

interface FinancialCalculatorModel {
  mode?: FinanceConfig
  input?: FinanceOpts
  result?: FinanceResult
}
export default Vue.extend({
  name: 'FinancialCalculatorForm',
  components: { PriceField },
  props: {
    value: {
      type: Object as () => FinancialCalculatorModel,
      required: false,
    },
    modes: {
      type: Array as () => FinanceConfig[]
    },
    mode: Object as () => FinanceConfig,
    priceOfGoodsAmount: Number,
    vehiclePriceAmount: Number,
    additionalResidualValueAmount: Number,
    depositAmount: Number,
    paymentProtectionInsurance: Boolean,
    country: {
      type: String as PropType<Country>,
      default: Country.Spain
    },
    totalPrice: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formatNumberToCurrency,
      isMinAmount,
      isRequired,
      selectedMode: this.mode || this.modes[0],
      input: {
        priceOfGoodsAmount: this.priceOfGoodsAmount,
        vehiclePriceAmount: this.vehiclePriceAmount,
        additionalResidualValueAmount: this.additionalResidualValueAmount,
        depositAmount: this.depositAmount || this.priceOfGoodsAmount * (this.mode || this.modes[0])?.depositRate,
        paymentProtectionInsurance: this.paymentProtectionInsurance || false
      },
      minDepositAmount: this.priceOfGoodsAmount * (this.mode || this.modes[0])?.depositRate,
    }
  },
  computed: {
    result: {
      get(): FinanceResult {
        return new FinanceCalculator().calculate(this.input, this.selectedMode)
      },
      set(): void {
        // ignored
      }
    },
    maxDepositAmount() {
      const removedPrice = this.selectedMode && this.selectedMode.name === 'OPTIONS'
        ? this.result?.finalInstallmentAmount || 0
        : 1000

      return this.priceOfGoodsAmount - removedPrice
    },
    minPriceOfGoods() {
      const removedPrice = this.selectedMode && this.selectedMode.name === 'OPTIONS'
        ? this.result?.finalInstallmentAmount || 0
        : 1000

      return removedPrice / 0.85
    },
    isDepositInfoEqual() {
      const epsilon = 0.001;
      const isEq = (a: number, b: number) => Math.abs(a - b) < epsilon;

      return isEq(this.minDepositAmount, this.maxDepositAmount)
    },
    depositInfo() {
      return this.isDepositInfoEqual
        ? this.$t('offer-manager.create.step-financial-services.lbl-same-deposit-error', {
          deposit: formatNumberToCurrency(this.minDepositAmount, this.country),
        })
        : this.$t('offer-manager.create.step-financial-services.lbl-deposit-range-error', {
          minDeposit: formatNumberToCurrency(this.minDepositAmount, this.country),
          maxDeposit: formatNumberToCurrency(this.maxDepositAmount, this.country)
        })
    }
  },
  watch: {
    totalPrice(newVal) {
      this.input.priceOfGoodsAmount = newVal
      this.input.depositAmount = newVal * this.selectedMode?.depositRate
      this.minDepositAmount = newVal * this.selectedMode?.depositRate
      this.$emit('input', {
        mode: this.selectedMode,
        input: this.input,
        result: this.result
      })
    },
    'input.priceOfGoodsAmount'(newVal) {
      this.input.depositAmount = newVal * this.selectedMode?.depositRate
      this.minDepositAmount = newVal * this.selectedMode?.depositRate
    },
    priceOfGoodsAmount(newVal) {
      this.input.priceOfGoodsAmount = newVal
      this.minDepositAmount = newVal * this.selectedMode?.depositRate
    },
    selectedMode(newVal) {
      this.minDepositAmount = this.input.priceOfGoodsAmount * newVal.depositRate
    },
    depositAmount(newVal) {
      if (newVal) {
        this.input.depositAmount = newVal
      }
    },
    vehiclePriceAmount(newVal) {
      this.input.vehiclePriceAmount = newVal
      this.minDepositAmount = this.input.priceOfGoodsAmount * this.selectedMode?.depositRate
    },
    additionalResidualValueAmount(newVal) {
      this.input.additionalResidualValueAmount = newVal
    },
    result: {
      immediate: true,
      handler(newVal) {
        this.$emit('input', {
          mode: this.selectedMode,
          input: this.input,
          result: newVal
        })
      }
    }
  },
  created() {
    this.result = new FinanceCalculator().calculate(this.input, this.selectedMode)
  },
})
