<template>
  <v-app class="offer-manager-wrapper pb-16" id="offer-manager-create">
    <v-card class="offer-manager-card-wrapper">
      <v-card-title id="offerFormTitle" ref="offerFormTitle">
        <toolbar-card @openCloseCancelDialog="onClose" :title="title" />
      </v-card-title>

      <router-view :key="$route.fullPath"></router-view>
    </v-card>

    <mapit-dialog :dialog="dialogOpen" custom-width="610px" @close="dialogOpen = false">
      <template v-slot:header-icon>
        <v-icon color="orange">fa-solid fa-triangle-exclamation</v-icon>
      </template>

      <template v-slot:header-text>
        <p class="dialog-title text-center" v-html="dialog.header"></p>
      </template>

      <template v-slot:body>
        <div class="text-center">
          <div v-html="dialog.content"></div>
        </div>
      </template>

      <template v-slot:footer-center>
        <v-btn @click="dialogOpen = false" class="btn-gray">{{ $t('buttons.cancel') }} </v-btn>
        <v-btn @click="onExit" class="btn-primary">{{ dialog.cta }} </v-btn>
      </template>
    </mapit-dialog>
  </v-app>
</template>

<script>
import ToolbarCard from '@/components/registrations/HondaRegistration/ToolbarCard'
import MapitDialog from '@/components/dialog/MapitDialog'

export default {
  name: 'CreateOffer',
  components: { ToolbarCard, MapitDialog },
  props: [],
  data() {
    return {
      dialogOpen: false,
      dialog: {
        header: this.$t('offer-manager.dialog.header'),
        content: this.$t('offer-manager.dialog.content'),
        cta: this.$t('offer-manager.dialog.cta'),
      },
    }
  },
  methods: {
    onClose() {
      if (this.$route.name === 'ResumeOfferMotorbike') {
        this.onExit()
        return
      }

      this.dialogOpen = true
    },
    onExit() {
      this.dialogOpen = false

      if (this.$route.name === 'EditOfferMotorbike') {
        this.$router.push({ name: 'ResumeOfferMotorbike', params: { id: this.$route.params.id } })
        return
      }

      this.$router.push({ name: 'Offers' })
    },
  },
  computed: {
    title() {
      return this.$route.name === 'InitOffer'
        ? this.$t('offer-manager.create.title')
        : this.$t('offer-manager.create.title-offer-motorbike')
    },
  },
  beforeCreate() {
    if (!this.$route.params.id) {
      this.$store.commit('RESET_OFFER')
    }
  },
}
</script>

<style lang="scss" scoped>
.init-image-header {
  max-width: 160px;
  margin: auto;
  text-align: center;
}

.label-init-search {
  text-align: center;
  font: normal normal normal 16px/26px 'ProximaNovaRegular';
  letter-spacing: 0px;
  color: var(--v-gray-darken4);
  opacity: 1;
}
</style>

<style lang="scss">
#offer-manager-create {
  &.offer-manager-wrapper {
    background-color: var(--v-background-base) !important;
  }

  .offer-manager-card-wrapper {
    margin: 0 auto;
    margin-top: 40px;

    @media (min-width: 960px) {
      width: 90%;
    }

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  .my-spacer {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .link {
    text-align: center;
    text-decoration: underline;
    font: normal normal normal 16px/26px 'ProximaNovaRegular';
    letter-spacing: 0px;
    color: #3797f0;
    opacity: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .search-input {
    width: 100%;
    max-width: 400px;

    fieldset {
      border-radius: 0;
      border: 1px solid #bdbdbd;
      height: 55px;
    }

    .v-text-field__slot {
      height: 50px;
    }

    ::placeholder {
      text-align: left;
      font: normal normal normal 16px/22px 'ProximaNovaRegular';
      letter-spacing: 0px;
      color: var(--v-gray-lighten3);
      opacity: 1;
    }

    .v-input__icon--clear button {
      font-family: 'mapitfont-v1' !important;
      text-transform: none;
      height: 50px;
      margin-top: 8px;
      color: var(--v-gray-lighten3) !important;
    }
  }

  .search-btn {
    height: 50px !important;
    font-family: 'mapitfont-v1' !important;
  }

  .text-blue {
    color: #0075eb !important;
  }
}
</style>
