<template>
  <th v-if="isHeader">
    {{title}}
  </th>
  <td v-else>
    <div class="p-t-10 align-left b-l-1" v-if="rowData">
      <div class="col-12 p-b-5 p-l-15 p-r-15 center-parent-left">
				<span class="f-s-14 bold-900">
                    <button @click="activeModal=true">X</button>
                </span><br/>
      </div>
    </div>
    <modal :active="activeModal" @close="activeModal = false" title="ELIMINAR CITA">
      <template slot="body">
        <customer-info :customer="rowData.customer"></customer-info>
      </template>
    </modal>
  </td>


</template>

<script>
  import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';
  import Modal from "@/components/Modal.vue";
  import CustomerInfo from '@/components/dialog/CustomerInfo.vue'

  export default {
    components: {Modal, CustomerInfo},
    mixins: [VuetableFieldMixin],
    props: {
      rowData: {
        type: Object,
        default: () => {
          return {requestState: '-'}
        }
      }
    },
    data() {
      return {activeModal: false};
    },
    methods: {
      showModal: () => {

      }
    },
    name: 'Delete'
  }
</script>
