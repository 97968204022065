<template>
  <expansion-panel
    uid="expansion-panel-loan-data"
    :title="$t('dealer-bike.loan.loan-data-title')"
    :readonly="true"
  >
    <template v-slot:panel-content>
      <m-form-row :label="$t('dealer-bike.loan.loan-data.lbl-date')">
        <m-date-picker
          data-cy="dealerBikeLoanDataDate"
          v-model="dealerBikeLoanDataDate"
          :placeholder="$t('dealer-bike.loan.loan-data.lbl-date-placeholder')"
          readonly
          :locale="locale"
        />
      </m-form-row>

      <m-form-row :label="$t('dealer-bike.loan.loan-data.lbl-time')">
        <m-time-field
          data-cy="dealerBikeLoanDataTime"
          v-model="dealerBikeLoanDataTime"
          :placeholder="$t('dealer-bike.loan.loan-data.lbl-time-placeholder')"
          readonly
        />
      </m-form-row>

      <m-form-row :label="$t('dealer-bike.loan.loan-data.lbl-license')">
        <mapit-input
          uid="dealerBikeLoanLicenseType"
          :value="dealerBikeLoanLicenseType"
          :type="InputTypes.SELECT"
          :listItems="licenseList"
          :placeholder="$t('dealer-bike.loan.loan-data.lbl-license-type-placeholder')"
          @changeValue="(value) => updateLoan('setDealerBikeLoanLicenseType', value)"
          :rules="[
            ...requiredRules,
            value => !!dealerBikeLicense && !!value && checkLicenceIsValid || $t('dealer-bike.loan.loan-data.lbl-invalid-license')
          ]"
          :hideDetails="showValidations ? false : hideDetails['setDealerBikeLoanLicenseType']"
        />
      </m-form-row>

      <m-form-row :label="$t('dealer-bike.loan.loan-data.lbl-license-expedition')">
        <m-date-picker
          data-cy="dealerBikeLoanLicenseExpeditionDate"
          v-model="dealerBikeLoanLicenseExpeditionDate"
          :placeholder="$t('dealer-bike.loan.loan-data.lbl-license-expedition-placeholder')"
          :rules="requiredRules"
          :max="moment().format('YYYY-MM-DD')"
          :hideDetails="hideDetails['setDealerBikeLoanLicenseExpeditionDate']"
          :max-error-text="$t('dealer-bike.loan.loan-data.max-today-error-text')"
          :locale="locale"
        />
      </m-form-row>

      <m-form-row :label="$t('dealer-bike.loan.loan-data.lbl-loan-type')">
        <mapit-input
          uid="dealerBikeLoanUsageType"
          :value="dealerBikeLoanUsageType"
          align="left"
          :type="InputTypes.RADIO_BUTTONS"
          :listItems="usageTypeList"
          @changeValue="(value) => updateLoan('setDealerBikeLoanUsageType', value)"
          :rules="!isCourtesyBike  ? requiredRules : []"
          :hideDetails="showValidations ? false : hideDetails['setDealerBikeLoanUsageType']"
          :disabled="isCourtesyBike"
        />
      </m-form-row>
    </template>
  </expansion-panel>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import MapitInput from '@/components/general/MapitInput.vue'
import {InputTypes} from '@/utils/constants'
import { isRequired } from '@/helpers/validations'
import { UsageType } from '@mapit/dealer-bikes-dynamodb/dist/types'
import { DrivingLicense } from '@mapit/contacts-dynamodb/dist/types'

export default {
  name: 'LoanData',
  components: { ExpansionPanel, MapitInput },
  props: ['showValidations'],
  data() {
    return {
      moment,
      UsageType,
      InputTypes,
      requiredRules: [isRequired],
      hideDetails: {
        setDealerBikeLoanLicenseType: true,
        setDealerBikeLoanLicenseExpeditionDate: true,
        setDealerBikeLoanUsageType: true,
      },
      usageTypeList: [UsageType.Demo, UsageType.Courtesy]
        .map((item) => {
          return { text: this.$t(`dealer-bike.registration.lbl-usage-type-${item.toLowerCase()}`), value: item }
        }),
      licenseList: [DrivingLicense.B, DrivingLicense.A1, DrivingLicense.A2, DrivingLicense.A]
        .map((item) => ({ text: item, value: item }))
    }
  },
  watch:{
    dealerBikeLoanLicenseExpeditionDate: function() {
      this.hideDetails.setDealerBikeLoanLicenseExpeditionDate = false
      this.$nextTick(() => this.$emit('validateForm')) // nextTick fix error validate async on use "picker"
    }
  },
  methods: {
    updateLoan(action, value) {
      this.$store.dispatch(action, value)
      this.hideDetails[action] = false
      this.$emit('validateForm')
    },
  },
  computed: {
    dealerBikeLoanDataDate: {
      get() { return moment().format('YYYY-MM-DD') },
      ...mapMutations({ set: 'SET_DEALER_BIKE_LOAN_DATA_DATE' })
    },
    dealerBikeLoanDataTime: {
      get() { return moment().format('HH:mm') },
      ...mapMutations({ set: 'SET_DEALER_BIKE_LOAN_DATA_TIME'})
    },
    dealerBikeLoanLicenseExpeditionDate: {
      ...mapGetters({get: 'getDealerBikeLoanLicenseExpeditionDate' }),
      ...mapMutations({set: 'SET_DEALER_BIKE_LOAN_LICENSE_EXPEDITION_DATE' })
    },
    ...mapGetters({
      locale: 'getLocale',
      dealerBikeLicense: 'getDealerBikeLicense',
      dealerBikeLoanLicenseType: 'getDealerBikeLoanLicenseType',
      dealerBikeLoanUsageType: 'getDealerBikeLoanUsageType'
    }),
    checkLicenceIsValid() {
      return this.dealerBikeLoanLicenseType === DrivingLicense.A // A puede llevar todas
        || this.dealerBikeLoanLicenseType === DrivingLicense.A2 && this.dealerBikeLicense !== DrivingLicense.A // A2 todas menos las del A
        || ((this.dealerBikeLoanLicenseType === DrivingLicense.A1 || this.dealerBikeLoanLicenseType === DrivingLicense.B) && this.dealerBikeLicense === DrivingLicense.A1) // A1 o B solo pueden las del A1
    },
    isCourtesyBike() {
      return this.$store.getters.getDealerBikeUsageType === UsageType.Courtesy
    },
  },
}
</script>
