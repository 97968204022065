<template>
    <mapit-dialog :dialog="dialog" @close="closeHandler" :hideCloseButton="true">
      <template v-slot:header-icon>
        <v-icon class="icon-mapit text-secondary" v-if="isSuccess">R</v-icon>
        <v-icon class="icon-mapit text-error" v-else-if="isError">T</v-icon>
        <v-icon class="icon-mapit text-warning" v-else-if="isWarning">T</v-icon>
        <v-icon class="icon-mapit text-error" v-else-if="isDelete">[</v-icon>
      </template>
      <template v-slot:header-text>
        <div class="text-center" data-cy="dialog-result-title">{{ textTitle }}</div>
      </template>
      <template v-slot:body>
        <div class="text-center" data-cy="dialog-result-body" v-html="textBody" />
      </template>
      <template v-slot:footer-center>
        <v-btn v-if="cancellable" class="btn-gray" text @click="closeHandler" data-cy="dialog-result-cancel">
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-btn class="btn-primary" :key="buttonKey" text @click.once="nextHandler" data-cy="dialog-result-continue" :disabled="loading" :loading="loading">
          {{ textConfirmButton?  textConfirmButton : $t('buttons.next') }}
        </v-btn>
      </template>
    </mapit-dialog>
</template>

<script>
import {ResultStatus} from '@/types'
import MapitDialog from './MapitDialog.vue'

export default {
  components: { MapitDialog },
  props: ['dialog', 'status', 'textTitle', 'textBody', 'cancellable', 'textConfirmButton', 'loading'],
  data() {
    return {
      buttonKey: 0
    }
  },
  computed: {
    isSuccess() {
      return this.status === ResultStatus.SUCCESS
    },
    isError() {
      return this.status === ResultStatus.ERROR
    },
    isWarning() {
      return this.status === ResultStatus.WARNING
    },
    isDelete() {
      return this.status === ResultStatus.DELETE
    }
  },
  methods: {
    closeHandler(){
      this.$emit('closeHandler')
    },
    nextHandler(){
      this.$emit('nextHandler')
      this.$nextTick(() => {
        this.buttonKey++
      })
    }
  }
}
</script>

<style lang="scss">
.icon-mapit {
  .text-primary {
    color: var(--v-primary-base)
  }
  .text-error{
    color: var(--v-error-base)
  }
  .text-secondary{
    color: var(--v-secondary-base)
  }
  .text-warning{
    color: var(--v-warning-base)
  }
}
</style>
