import { DealerBike, DealerBikeStatus, UsageType } from '@mapit/dealer-bikes-dynamodb/dist/types'
export { DealerBike, UsageType, DealerBikeStatus }

// TODO: update new statuses in @mapit/dealer-bikes-dynamodb
export const DealerBikeStatusPortal = {
  Candidate: 'CANDIDATE',
  Available: DealerBikeStatus.Available,
  Unavailable: DealerBikeStatus.Unavailable,
  HandedOver: 'HANDED_OVER',
  Expired: 'EXPIRED',
  Retired: DealerBikeStatus.Retired,
}

export enum DealerBikeActions {
  Available = 'AVAILABLE',
  Courtesy = 'COURTESY',
  Deactivate = 'DEACTIVATE',
  Detail = 'DETAIL',
  Register = 'REGISTER',
  RemoveBike = 'REMOVE_BIKE',
  Transfer = 'TRANSFER',
  Unavailable = 'UNAVAILABLE',
  Unregister = 'UNREGISTER',
}
