



















































import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import {InputTypes} from '@/utils/constants'
import {Status} from '@mapit/honda-offers-builder/dist/types'
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import {computed, defineComponent, ref, watch} from '@vue/composition-api'
import {mapGetters} from '@/utils/map-store'

// @ts-ignore
import {statusRules} from '@/helpers/offer-manager-rules'

type RadioButtonItem = {
  label: string,
  value: any,
  disabled?: boolean
}

export default defineComponent({
  name: 'StatusData',
  components: {ExpansionPanel, MapitDialog},
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    panelReadonly: {
      type: Boolean,
      default: false
    },
    panelHideDivider: {
      type: Boolean,
      default: false
    },
    forceStatus: {
      type: String,
      default: null
    }
  },
  setup(props, {root, emit}) {
    const dialog = ref<boolean>(false)
    const infoVariant = ref<string>('offer-status')
    const filterList = ref<RadioButtonItem[]>(Object.values([Status.Pending, Status.Accepted, Status.Refused])
      .map(item => ({
          label: root.$t(`offer-manager.create.step-offer-status.lbl-offer-status-${item}`).toString(),
          value: item
        })
      ))
    const statusDisabledList = ref<RadioButtonItem[]>(Object.values([Status.COICreated, Status.Quote, Status.Ordered, Status.Invoiced, Status.Completed])
      .map(item => ({
          label: root.$t(`offer-manager.create.step-offer-status.lbl-offer-status-${item}`).toString(),
          value: item,
          disabled: true
        })
      ))

    const {
      getStatus: status,
      getInitialStatus: initialStatus,
    } = mapGetters(root.$store.getters)
    const isPendingOffer = computed(() => status.value === Status.Pending)

    function updateOffer(action: string, value: any) {
      root.$store.dispatch(action, value)
      emit('validateForm')
    }

    function openCloseInfoDialog(variant: string) {
      dialog.value = !dialog.value
      infoVariant.value = variant
    }

    function setStatusList(list: RadioButtonItem[], statusValue: Status, forced: boolean) {
      if (forced) {
        filterList.value = list.map((item: RadioButtonItem) => {
          item.disabled = item.value !== statusValue
          return item
        })
        return
      }

      switch (statusValue) {
        case Status.Accepted:
          filterList.value = list.map((item: RadioButtonItem) => {
            item.disabled = item.value !== Status.Refused && statusValue !== item.value // Solo enabled el actual y refused
            return item
          })
          break
        case Status.COICreated:
        case Status.Completed:
        case Status.Refused:
          filterList.value = list.map((item: RadioButtonItem) => {
            item.disabled = statusValue !== item.value // Solo enabled el actual
            return item
          })
          break
        case Status.Pending:
        default:
          filterList.value = list.map((item: RadioButtonItem) => { // Por defecto, habilitados Accepted, Refusedm y Pending
            item.disabled = item.value !== Status.Refused && item.value !== Status.Accepted && statusValue !== item.value
            return item
          })
          break
      }
    }

    const forceStatusComputed = computed(() => props.forceStatus);

    watch([initialStatus, forceStatusComputed], () => {
        setStatusList(filterList.value, forceStatusComputed.value || initialStatus.value, forceStatusComputed.value != null)
    })

    return {
      InputTypes,
      statusRules,
      status,
      dialog,
      infoVariant,
      isPendingOffer,
      filterList,
      updateOffer,
      openCloseInfoDialog,
      statusDisabledList
    }
  }
})
