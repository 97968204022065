<template>
  <v-app class="honda-registration-wrapper" id="users-list">
    <v-card class="honda-registration-card-wrapper my-8">
      <v-card-title>
        <toolbar-card :title="$t('honda.users.title')" :hideCloseBtn="true" />
      </v-card-title>
      <v-divider class="stepper-separator mx-6" />

      <v-card-text class="px-6">
        <div class="d-flex flex-inline justify-center">
          <v-text-field
            v-model.trim="search"
            class="search-user"
            data-cy="search-user"
            :placeholder="$t('honda.users.search_placeholder')"
            dense
            hide-details
            clearable
            clear-icon="z"
            outlined
          />
          <v-btn class="btn-primary px-10 search-btn">?</v-btn>

          <v-select
            v-if="isAdmin"
            v-model="forcedGroup"
            class="custom-text-field mx-4"
            :items="dealersList"
            :label="$t('honda.users.group_selector')"
            :placeholder="$t('honda.users.group_selector_placeholder')"
            item-text="fullName"
            item-value="legacyId"
            data-cy="group-selector"
            outlined
          ></v-select>
        </div>
      </v-card-text>

      <v-card-text class="px-6">
        <users-table :search="search" :forcedGroup="forcedGroup" />
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import ToolbarCard from '@/components/registrations/HondaRegistration/ToolbarCard'
import UsersTable from './UsersTable.vue'
import { cloneDeep } from 'lodash'

export default {
  name: 'Users',
  components: { ToolbarCard, UsersTable },
  props: [],
  data() {
    return {
      search: '',
      forcedGroup: '',
    }
  },
  computed: {
    dealersList() {
      const list = cloneDeep(this.$store.getters.getDealersList)
      return list.map((item) => {
        item.fullName = `${item.legacyId} - ${item.name}`
        item.legacyId = `${item.legacyId}`
        return item
      })
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    allowStockAdmin() {
      return this.$store.getters.isStockUser || this.$store.getters.isAdmin
    },
  },
  created() {
    if (this.allowStockAdmin) {
      this.$store.dispatch('loadDalersList', true)
    }
  },
}
</script>

<style lang="less">
#users-list {
  .subtitle {
    text-align: center;
    font: normal normal 600 26px/28px 'ProximaNovaExCnSBold' !important;
    letter-spacing: -0.48px;
    color: var(--v-primary-base);
    text-transform: uppercase;
    opacity: 1;
  }
  .text {
    color: var(--v-gray-darken4);
    text-align: center;
    font: normal normal normal 16px/22px 'ProximaNovaRegular';
    letter-spacing: 0;
    opacity: 1;
  }
  .search-user {
    fieldset {
      border-radius: 0;
      border: 1px solid #bdbdbd;
      height: 55px;
    }
    .v-text-field__slot {
      height: 50px;
    }
    max-width: 400px;
    ::placeholder {
      text-align: left;
      font: normal normal normal 16px/22px 'ProximaNovaRegular';
      letter-spacing: 0;
      color: #bfbfbf;
      opacity: 1;
    }
    .v-input__icon--clear button {
      font-family: 'mapitfont-v1', serif !important;
      text-transform: none;
      height: 50px;
      margin-top: 8px;
      color: #bfbfbf !important;
    }
  }
  .search-btn {
    height: 50px !important;
    font-family: 'mapitfont-v1', serif !important;
  }
  .warning-info-box {
    background-color: rgba(252, 164, 0, 0.12) !important;
    max-width: 625px;
    margin: 15px auto;
    i {
      font-family: 'mapitfont-v1', serif !important;
      text-transform: none;
      font-style: inherit;
      align-self: auto;
      color: #fca400;
    }
    .v-alert__content {
      text-align: left;
      font: normal normal 600 14px/18px 'ProximaNovaRegular';
      letter-spacing: 0;
      color: #fca400;
      opacity: 1;
    }
  }
  .success-info-box {
    background-color: rgba(0, 128, 62, 0.12) !important;
    max-width: 625px;
    margin: 15px auto;
    i {
      font-family: 'mapitfont-v1', serif !important;
      text-transform: none;
      font-style: inherit;
      align-self: auto;
      color: var(--v-secondary-base);
    }
    .v-alert__content {
      text-align: left;
      font: normal normal 600 14px/18px 'ProximaNovaRegular';
      letter-spacing: 0;
      color: var(--v-secondary-base);
      opacity: 1;
    }
  }
  .transparent-info-box {
    visibility: hidden;
    max-width: 625px;
    margin: 15px auto;
  }
  .custom-text-field {
    margin-top: 5px;
    input {
      padding-bottom: 20px;
      color: var(--v-gray-darken4);
      font: normal normal normal 16px/22px 'ProximaNovaRegular';
    }
    i.v-icon {
      padding-bottom: 15px;
    }
    .v-select__selection {
      margin-top: 3px;
      padding-bottom: 10px;
      color: var(--v-gray-darken4);
      font: normal normal normal 16px/22px 'ProximaNovaRegular';
    }
  }
}
</style>
