<template>
  <div id="app">
    <router-view></router-view>

    <div class="overlay" v-if="loading">
      <div class="outer">
        <div class="middle">
          <div class="inner">
            <div class="loading loading-lg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// router import needed for <router-view></router-view>
import '@/router'
import firebase from 'firebase/app'
import 'firebase/database'
import '@mapit/muvue/dist/muvue.css'
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as Sentry from "@sentry/browser"

/**
 * Firebase config
 */
const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDIR_ID,
}

/**
 * Firebase News Realtime db config
 */
var configNews = {
  apiKey: 'AIzaSyAaig0-TEF5iyvrrVRaN8rSi5UFcnNPDpw',
  authDomain: 'mapitnews.firebaseapp.com',
  databaseURL: 'https://mapitnews.firebaseio.com',
  projectId: 'mapitnews',
  storageBucket: 'mapitnews.appspot.com',
  messagingSenderId: '517171381784',
}

//Init firebse connections
firebase.initializeApp(config)

/**
 * The custom HTML `<App>` component.
 *
 * @author Julià Soler
 * @license Mapit IoT S.L.
 */
export default {
  name: 'app',
  created() {
    this.loadCache()
  },
  methods: {
    /**
     * Load firebase storedcache config
     */
    loadCache() {
      /**
       * Update store
       * @param {string} action - Action name
       * @param {object} value - Firebase Config
       */
      this.$store.dispatch('setFirebase', firebase)

      if (['staging', 'production'].includes(process.env.VUE_APP_ENV_MODE)) {
        const secondary = firebase.initializeApp(configNews, 'secondary')
        this.$store.dispatch('setFirebaseNews', secondary)
      }
    },
  },
  beforeCreate() {
    this.$i18n.locale = this.group?.locale?.substring(0, 2).toLowerCase() || 'es'
    moment.locale(this.group?.locale || 'es-ES')
  },
  watch: {
    group: {
      immediate: true,
      handler(val) {
        this.$i18n.locale = val?.locale?.substring(0, 2).toLowerCase() || 'es'
        moment.locale(val?.locale || 'es-ES')

        if (this.$gtm.enabled() && val?.id) {
          if (!window.dataLayer.find((item) => item.dealerId === val?.id)) {
            window.dataLayer?.push({
              event: 'gtm.init',
              dealerName: val?.name,
              dealerId: val?.id,
              branch: val?.branch,
              version: process.env.VUE_APP_GIT_SEMVER,
            });
          }
        }

        Sentry.setTags({
          dealerId: val?.id,
          dealerName: val?.name,
          branch: val?.branch,
          version: process.env.VUE_APP_GIT_SEMVER,
        })
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        if (to.meta?.title) {
          document.title = this.$t('title.template', {
            title: this.$t(to.meta.title),
            brand: this.$t('title.brand')
          })
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      group: 'getGroup'
    }),
    /**
     * Checks login status
     * @returns {boolean} true/false
     */
    loading() {
      return this.$store.getters.getLoading
    },
  },
}
</script>

<style src="@/styles/app.less" lang="less">

</style>
<style lang="scss">
.multiple .v-select__selections {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-filter-status:before {
  content: "\e02b";
}

.v-data-table-header {
  .v-data-table-header__icon:before {
    margin: auto 0 auto 6px;
  }

  [aria-sort="descending"] {
    .v-data-table-header__icon:before {
      content: "\f0dd";
    }
  }

  [aria-sort="ascending"] {
    .v-data-table-header__icon:before {
      content: "\f0de";
    }
  }

  [aria-sort="none"] {
    .v-data-table-header__icon:before {
      content: "\f0dc";
    }
  }
}

.list-menu-actions {
  min-width: 200px !important;

  .v-list-item__title {
    font-family: 'ProximaNovaRegular' !important;
    font-size: 14px !important;
  }
}

.v-list-item__action {
  margin-right: 12px !important;
}

.icon-flag-on:before {
  cursor: pointer;
  color: var(--v-secondary-base);
  content: "\e03d";
}

.icon-flag-off:before {
  cursor: pointer;
  color: var(--v-gray-lighten2);
  content: "\e03d";
}

.v-data-table-header tr th {
  margin-top: 20px
}

.v-data-table-header tr th span,
.v-data-table-header tr th i {
  display: inline !important;
}

#app {
  .v-btn {
    border-radius: 4px !important;
    box-shadow: none;
  }

  .v-btn:focus {
    outline: 3px solid rgba(0, 137, 222, 0.80) !important;
    border: none !important;
    transition: outline 80ms ease-in-out !important;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 5s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
