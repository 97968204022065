var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"app-bar",attrs:{"dense":"","height":"70"}},[(_vm.user)?_c('v-btn',{class:("btn-appbar " + (_vm.hideNavMenu ? 'hidden' : '')),attrs:{"icon":"","data-cy":"btn-navigation-menu"},on:{"click":_vm.openCloseDrawerMenu}},[_c('v-icon',{attrs:{"dense":"","color":"white"}},[_vm._v("fa-light fa-bars")])],1):_vm._e(),_c('v-toolbar-title',{class:{'app-logo-unauthorized' : !_vm.user}},[_c('img',{staticClass:"toolbar-logo click",attrs:{"src":"/images/new-logo.svg"},on:{"click":function($event){$event.preventDefault();return _vm.goDashboard($event)}}})]),_c('div',{staticClass:"my-spacer"}),(_vm.user && _vm.permissions && _vm.permissions.requests.canSee)?_c('v-btn',{staticClass:"btn-appbar btn-appbar-right",attrs:{"icon":"","to":"/requests"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" fa-light fa-message-lines ")])]}}],null,false,1977102185)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.create-incidence')))])])],1):_vm._e(),(_vm.user)?_c('v-btn',{staticClass:"btn-appbar btn-appbar-right",attrs:{"icon":"","to":"/help"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v("fa-light fa-circle-question ")])]}}],null,false,3969443395)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.help')))])])],1):_vm._e(),(_vm.user)?_c('v-btn',{staticClass:"btn-appbar btn-appbar-right mr-2",attrs:{"icon":""},on:{"click":_vm.showNewsSection}},[_c('v-badge',{attrs:{"color":"yellow","dot":"","value":_vm.newNotifyLength > 0}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v("fa-light fa-bell")])]}}],null,false,651677109)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.news')))])])],1)],1):_vm._e(),(_vm.user)?_c('last-news',{attrs:{"branch":_vm.branchLowerCase,"display":_vm.newsSection},on:{"showNews":_vm.showNewsSection,"hideNews":_vm.hideNewsSection}}):_vm._e(),(_vm.user)?_c('v-btn',{staticClass:"btn-appbar btn-appbar-right user-menu mr-4",attrs:{"icon":"","data-cy":"btn-user-menu"},on:{"click":_vm.openUserDrawer}},[_c('v-icon',{attrs:{"dense":"","color":"white"}},[_vm._v("fa-light fa-user")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }