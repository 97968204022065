import { AxiosResponse } from 'axios'
import ApiGatewayClient from './aws/aws-gateway-client'
import { OfferDoc } from '@mapit/opensearch-client/dist/types'

import { Branch } from '@/utils/constants'
import { compactObject } from '@/utils/utils'
import {
  OfferExportParams,
  OpenSearchListRequest,
  OpenSearchResponse,
  SendOfferByEmailResponse,
} from '@/types'

export class OffersApi {
  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  exportOffersList({
    branch = Branch.HondaSpain,
    from,
    to,
  }: OfferExportParams): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'GET',
      path: `/v1/offers/export`,
      queryParams: {
        branch,
        from,
        to,
      },
    })
  }

  createNewOffer(data: any, dealerId: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'POST',
      path: `/v1/dealers/${dealerId}/offers`,
      data,
    })
  }

  editOffer(id: string, data: any, dealerId: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'PUT',
      path: `/v1/dealers/${dealerId}/offers/${id}`,
      data,
    })
  }

  getOfferById(id: string, dealerId: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/offers/${id}`,
    })
  }

  getOfferSummaryById(id: string, dealerId: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/offers/${id}/summary`,
    })
  }

  getOfferByCode(code: string, dealerId: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/offers/code/${code}`,
    })
  }

  getOffersListByDealerId(request: OpenSearchListRequest): Promise<OpenSearchResponse<OfferDoc>> {
    const { params, dealer, text, pagination, range } = request

    const queryParams = {
      dealerCode: dealer.code,
      text: text?.split(' ').filter((i) => i) || '',
      from: pagination?.from || 0,
      size: pagination?.size || 10,
      sort: pagination?.sort || 'desc',
      range: range,
      column: pagination?.column || 'createDate',
      ...params,
    }

    return this.client
      .sendRequest({
        endpoint: process.env.VUE_APP_OFFERS_API,
        method: 'GET',
        path: `/v1/dealers/${dealer.id}/offers`,
        queryParams: compactObject(queryParams),
      })
      .then((response: any) => response.data)
  }

  getOfferPdfById(id: string, dealerId: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/offers/${id}/pdf`,
    })
  }

  updateOfferComments(offerId: string, data: any, dealerId: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'PUT',
      path: `/v1/dealers/${dealerId}/offers/${offerId}/comments`,
      data,
    })
  }

  getDealerCommentsByOfferId(offerId: string, dealerId: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/offers/${offerId}/comments`,
    })
  }

  createDealerComment(data: any, dealerId: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'POST',
      path: `/v1/dealers/${dealerId}/comments`,
      data,
    })
  }

  sendOfferByEmail(
    dealerId: string,
    offerId: string,
  ): Promise<AxiosResponse<SendOfferByEmailResponse>> {
    try {
      return this.client.sendRequest({
        endpoint: process.env.VUE_APP_OFFERS_API,
        method: 'POST',
        path: `/v1/dealers/${dealerId}/offers/${offerId}/send`,
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new OffersApi()
