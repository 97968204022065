<template>
  <v-expansion-panel :key="uid" class="px-6" v-bind="$attrs">
    <v-divider v-if="!hideDivider"/>
    <v-expansion-panel-header
      :data-cy="`${dataCyPrefix}header`"
      class="full-width"
      hide-actions
      tabindex="-1"
    >
      <template v-slot:default="{ open }">
        <div class="d-flex justify-space-between">
          <div class="title-header">
            {{ title }}
            <slot name="title-expand" />
          </div>
          <div class="expand-icon-header">
            <v-icon v-if="!hideIconHeader"
              :class="
                open
                  ? 'icon-mapit rotateRight'
                  : 'icon-mapit rotateRightReverse'
              "
            >
              W
            </v-icon>
          </div>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :data-cy="`${dataCyPrefix}content`" :class="`${safePaddingBottom ? 'safe-padding-bottom' : ''} ${!hideContentMargins ? 'py-6' : ''}`">
      <slot/>
      <slot name="panel-content" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  name: 'ExpansionPanel',
  props: {
    uid: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideDivider: {
      type: Boolean,
      default: false
    },
    safePaddingBottom: {
      type: Boolean,
      default: false
    },
    hideIconHeader: {
      type: Boolean,
      default: false
    },
    hideContentMargins: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dataCyPrefix: this.uid ? `${this.uid}-` : ''
    }
  }
}
</script>

<style lang="scss">
// ANIMATIONS
@-moz-keyframes rotateRight {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(90deg);
  }
}
@-webkit-keyframes rotateRight {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(90deg);
  }
}
@keyframes rotateRight {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}
@-moz-keyframes rotateRightReverse {
  from {
    -moz-transform: rotate(90deg);
  }
  to {
    -moz-transform: rotate(0deg);
  }
}
@-webkit-keyframes rotateRightReverse {
  from {
    -webkit-transform: rotate(90deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes rotateRightReverse {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.expand-icon-header {
  flex: 1;
  justify-content: flex-end;
  text-align: end;
  i {
    font-size: 32px !important;
  }
}
.rotateRight {
  animation: rotateRight 0.3s forwards;
  -webkit-animation: rotateRight 0.3s forwards;
}
.rotateRightReverse {
  animation: rotateRightReverse 0.3s forwards;
  -webkit-animation: rotateRightReverse 0.3s forwards;
}

.v-expansion-panel {
  margin-top: 0 !important;
  .v-expansion-panel-header {
    min-height: 60px !important;
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'ProximaNovaExCnSbold', sans-serif !important;
    font-size: 24px !important;
    color: var(--v-primary-base);
    text-transform: uppercase !important;
    flex: 1;
  }
  .v-expansion-panel-content{
    &__wrap {
      margin: 0 !important;
      padding: 0 !important;
      font-family: 'ProximaNovaRegular', sans-serif;
      font-size: 16px;
    }
    &.safe-padding-bottom{
      padding: 20px 0 125px 0 !important;
      margin: 0 !important;
      font-family: 'ProximaNovaRegular', sans-serif;
      font-size: 16px;
    }
  }
  .v-expansion-panel-header--active {
    min-height: 60px !important;
  }
}

.title-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
