// @ts-ignore
import ApiGatewayClient from './aws/aws-gateway-client'
import {AxiosResponse} from "axios"
import {ContactTicket} from "@/composables/contact";

export class MarketingApi {
  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  createTicket(ticket: ContactTicket): Promise<AxiosResponse<any>>  {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MARKETING_API,
      method: 'POST',
      path: `/v1/tickets`,
      data: ticket
    })
  }
}


export default new MarketingApi()
