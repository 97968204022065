import { DateTime, Duration, DurationLikeObject, DurationUnits } from 'luxon'
import { MAX_AGE_ALLOWED } from '@/utils/constants'

export function toISOString(date: string | Date | DateTime) {
  return date instanceof Date ? date.toISOString() : date instanceof DateTime ? date.toISO() : date
}

export function getLocaleDateOptions(): Intl.DateTimeFormatOptions {
  return { ...DateTime.DATE_SHORT, month: '2-digit', day: '2-digit' }
}

export function getLocaleDateTimeOptions(): Intl.DateTimeFormatOptions {
  return {
    ...DateTime.DATETIME_SHORT,
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
}

export function toDateFormat(
  date: string | Date | DateTime,
  locale = 'es',
  options?: Intl.DateTimeFormatOptions,
): string {
  return DateTime.fromISO(toISOString(date))
    .setLocale(locale)
    .toLocaleString(options ? { ...options } : getLocaleDateOptions())
}

export function toDateTimeFormat(
  date: string | Date,
  locale = 'es',
  options?: Intl.DateTimeFormatOptions,
): string {
  return DateTime.fromISO(toISOString(date))
    .setLocale(locale)
    .toLocaleString(options ? { ...options } : getLocaleDateTimeOptions())
    .replace(/, /g, ' ')
}

export function getDatePattern(locale: 'es') {
  const formatter = new Intl.DateTimeFormat(locale, getLocaleDateOptions()).formatToParts()

  return formatter
    .map((e) => {
      switch (e.type) {
        case 'month':
          return 'MM'
        case 'day':
          return 'DD'
        case 'year':
          return 'AAAA'
        default:
          return e.value
      }
    })
    .join('')
}

export const timeUnitsBetween = (
  startDate: Date,
  endDate: Date,
  locale?: string,
  units?: DurationUnits,
): Duration => {
  const start = DateTime.fromISO(startDate.toISOString()).setLocale(locale || 'es')
  const end = DateTime.fromISO(endDate.toISOString()).setLocale(locale || 'es')
  return end.diff(start, units || ['days', 'hours', 'minutes'])
}

export const humanizeDuration = (duration: Duration, locale?: string): string => {
  const durationObj = duration.toObject()
  // filter out units with 0 values.
  const resultObject: DurationLikeObject = Object.keys(durationObj).reduce(
    (result, key: string) => {
      // @ts-ignore
      const value = durationObj[key]
      return value ? { ...result, [key]: value } : result
    },
    {},
  )

  return Duration.fromObject(resultObject, { locale: locale || 'es' }).toHuman()
}

export const getMonthNames = (
  locale = 'es',
  format: Intl.DateTimeFormatOptions['month'] = 'long',
): string[] => {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  return months.map((month) => {
    const mm = month < 10 ? `0${month}` : month

    return DateTime.fromISO(`2022-${mm}-01T00:00:00+00:00`)
      .setLocale(locale)
      .toLocaleString({ month: format, timeZone: 'UTC' })
  })
}

export const DATE_FORMAT_ISO_MOMENT = 'YYYY-MM-DD' // momentjs
export const DATE_FORMAT_ISO = 'yyyy-MM-dd' // luxon
export const DATE_FORMAT_TABLE = 'dd/MM/yyyy' // luxon

export function getDateFromYearsAgo(years = MAX_AGE_ALLOWED): string {
  return DateTime.now().minus({ years }).toISODate()
}

export function isValidDate(date: string, format?: string): boolean {
  return format ? DateTime.fromFormat(date, format).isValid : DateTime.fromISO(date).isValid
}

export function isValidTime(value: string): boolean {
  return /^([01]\d|2[0-3]):([0-5]\d)$/.test(value)
}

export function formatToISODate(date: string): string {
  return isValidDate(date) ? DateTime.fromISO(date).toFormat(DATE_FORMAT_ISO) : ''
}

export function formatDate(date: string): string {
  return isValidDate(date) ? DateTime.fromISO(date).toFormat(DATE_FORMAT_TABLE) : '-'
}

export function formatTime(date: string): string {
  return isValidDate(date) ? DateTime.fromISO(date).toFormat('HH:mm') : ''
}

export function sameDay(a: string, b: string): boolean {
  return a?.localeCompare(b) === 0
}

export function compareDates(a: string, b: string): number {
  return DateTime.fromISO(a) < DateTime.fromISO(b) ? 1 : -1
}

export function sortByDate(a: string, b: string): number {
  return sameDay(a, b) ? compareDates(a, b) : b?.localeCompare(a)
}

export function isPastToday(date: string) {
  return DateTime.fromISO(date) < DateTime.local().startOf('day')
}

export function addDaysToDate(date: string, days: number) {
  return DateTime.fromISO(date).plus({ days }).toISO()
}
