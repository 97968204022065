import { hondaCatalogApi } from '@/api/honda-catalog.api'
import { CampaignWithDescription } from '@/types'
import { Branch } from '@/utils/constants'
import { ref, Ref, watch } from '@vue/composition-api'

export type UseCampaignProps = {
  branch: Branch
  model: string | undefined
  ym: string | undefined
  type: string | undefined
  color: string | undefined
}

export const generateCampaignId = (campaign: CampaignWithDescription) => {
  return campaign ? `${campaign.name}-${campaign.description}-${campaign.value}` : undefined
}

export const useCampaigns = (propsRef: Ref<UseCampaignProps>) => {
  const campaigns = ref() as Ref<Array<CampaignWithDescription>>
  const isLoading = ref(false)
  const abortController = ref(new AbortController()) as Ref<AbortController>

  watch(propsRef, fetchCampaigns)

  async function fetchCampaigns() {
    try {
      if (!(propsRef.value?.model && propsRef.value?.ym && propsRef.value?.type && propsRef.value?.color)) {
        campaigns.value = []
        return
      }
      if (isLoading.value) {
        abortController.value.abort()
        abortController.value = new AbortController()
      }
      isLoading.value = true

      const response = await hondaCatalogApi.getCampaigns(
        propsRef.value.branch,
        propsRef.value.model,
        propsRef.value.ym,
        propsRef.value.type,
        propsRef.value.color,
      )
      campaigns.value = response?.map((campaign) => ({
        ...campaign,
        id: generateCampaignId(campaign),
      }))
    } catch (e) {
      console.error(`Error fetching materials`, e)
      return
    } finally {
      isLoading.value = false
    }
  }

  return {
    campaigns,
    loading: isLoading,
    fetch: fetchCampaigns,
  }
}
