

















































































































































































































































// @ts-ignore
import api from '@/api'
import CustomInputFile from '@/components/import/CustomInputFile.vue'
import ImportTargets from '@/components/import/ImportTargets.vue'
import RangePicker from '@/components/general/RangePicker.vue'
import DialogExitProcess from '@/components/dialog/DialogExitProcess.vue'
import MapitDialog from '@/components/dialog/MapitDialog.vue'
// @ts-ignore
import apiOffers from '@/api/offers.api'
// @ts-ignore
import {downloadFiles} from '@/helpers/downloadFile.js'
// @ts-ignore
import {s3} from '@/api/lib/s3-client.js'
import XLSX from "xlsx"
// @ts-ignore
import countries from "@/assets/json/country.json"
import {Branch} from "@/utils/constants"
import {Country} from "@mapit/common-utils/dist/types"
import {computed, defineComponent, onMounted, onUnmounted, ref, watch} from "@vue/composition-api"
import {mapGetters} from "@/utils/map-store"
import {PredeliveryResponse} from '@/types'
import {DateTime} from "luxon"
import {sanitizeForS3} from "@/utils/utils"
import {hondaCatalogApi, Import} from '@/api/honda-catalog.api'

/**
 * The custom HTML `<data-import />` component.
 * /import This is a separate page with AWS authentication role security.
 *
 * @author Julià Soler
 * @license Mapit IoT S.L.
 */
export default defineComponent({
  name: 'DataImport',
  components: {RangePicker, CustomInputFile, DialogExitProcess, MapitDialog, ImportTargets},
  setup(props, {root}) {

    const selectedRange = ref([DateTime.now().minus({months: 3}).plus({days: 1}).toFormat("yyyy-MM-dd"), DateTime.now().toFormat("yyyy-MM-dd")])
    const interval = ref<any>(null)

    const importDialog = ref<Partial<{
      open: boolean,
      title: string,
      body: string,
      error: boolean,
      info: boolean,
      showCancel: boolean,
      click: () => void,
      continue: () => void,
      close: () => void
    }>>({
      open: false,
      error: false,
      info: false,
      showCancel: false,
      title: '',
      body: '',
      click: () => closeImportDialog(),
      continue: () => ({}),
      close: () => closeImportDialog()
    })

    const cancelDialog = ref(false)
    const downloadLoading = ref(false)
    const exportLoading = ref(false)
    const importModelsLoading = ref(false)
    const importPredeliveryLoading = ref(false)
    const refreshKey = ref(0)
    const selectedBranch = ref(Branch.HondaSpain)

    const {
      getLocale: locale,
      getGroup: group,
      isImportUser,
      isAdmin,
      getUser
    } = mapGetters(root.$store.getters)

    const allowImport = computed(() => {
      return isImportUser.value || isAdmin.value
    })

    const branches = computed(() => {
      return Object.values(Branch)
        .filter(branch => branch !== Branch.HondaUSA)
        .map(branch => ({
          value: branch,
          label: root.$t(`branch.label.${branch}`)
        }))
    })

    watch(selectedBranch, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        await root.$store.dispatch('setAlertResult', {
          type: 'success',
          stringId: 'import.models-import.branch-selector.snackbar',
          open: true,
        })
      }
    })

    onMounted(() => {
      if (!allowImport.value) {
        root.$router.push('/')
      }
    })

    onUnmounted(() => {
      clearInterval(interval.value)
      interval.value = null
    })

    function setSelectedRange(range: any) {
      selectedRange.value = range
    }

    /**
     * Import the CSV file with the new "VIN" list into BE
     * @param {Object} data - Parsed data recived from file-input
     */
    function importNewVinFile(data: string) {
      const csv = data.replace(/,/g, ';')
      return api.uploadVIN(selectedBranch.value, csv)
    }

    /**
     * Import the Excell file with the new models and insurances list into BE
     * @param {Object} data - Parsed data recived from file-input
     */
    async function importModelsAndInsurancesFile(data: any) {
      importModelsLoading.value = true
      try {
        const arrayData = await data.arrayBuffer()
        const workbook = XLSX.read(arrayData, {type: 'array'})
        if (!workbook.Sheets['config'])
          throw ({code: 'CustomError', errorKey: 'import.error-import-config-missing'})
        const config = XLSX.utils.sheet_to_json(
          workbook.Sheets['config']
        )
        const cr = config[0] as any
        if (![Country.Spain, Country.Portugal].includes(cr.branch) || cr.branch !== selectedBranch.value || !parseInt(cr.vat))
          throw ({code: 'CustomError', errorKey: 'import.error-import-config-error'})

        importDialog.value = {
          open: true,
          error: false,
          info: true,
          showCancel: true,
          title: root.$t('import.import-title') as string,
          body: root.$t('import.import-by-country-confirmation', countries.find((c: any) => c.code === cr.branch)) as string,
          continue: async () => {
            const checkKey = `models/HDP_${DateTime.now().toSeconds().toFixed()}`
            const key = `${checkKey}.xlsx`
            const client = await s3(process.env.VUE_APP_MAPIT_CATALOG_IMPORTS_S3)

            await client.putObject({
              Bucket: process.env.VUE_APP_MAPIT_CATALOG_IMPORTS_S3,
              Key: key,
              Body: data,
              Metadata: {
                "created-by-name": sanitizeForS3(root.$store.state.fullUserName),
                "created-by-email": getUser.value.login
              },
            })
            interval.value = setInterval(checkUploadStatus, 5000, checkKey)
            importModelsLoading.value = true
            closeImportDialog()
          },
          close: () => {
            importModelsLoading.value = false
            closeImportDialog()
          }
        }
      } catch (err: any) {
        const key = err.code === 'CustomError' ? err.errorKey : 'import.error-import-server'
        importModelsLoading.value = false
        importDialog.value = {
          open: true,
          error: true,
          title: root.$t('import.import-title') as string,
          body: root.$t(key) as string,
          continue: () => {
            importModelsLoading.value = false
            closeImportDialog()
          },
          close: () => {
            importModelsLoading.value = false
            closeImportDialog()
          }
        }
      }
    }

    function closeImportDialog() {
      importDialog.value.open = false
      refreshKey.value++
    }

    /**
     * Download excel file with the last list of models and insurances
     */
    function downloadModelsAndInsurancesFile() {
      downloadLoading.value = true
      hondaCatalogApi
        .getLastImport(selectedBranch.value)
        .then(({createdAt, signedUrl}: Import) => {
          let docs = [
            {
              url: signedUrl,
              name: `last_import_${createdAt}.xlsx`,
            },
          ]
          downloadFiles(docs)
        })
        .finally(() => {
          downloadLoading.value = false
          refreshKey.value++
        })
    }

    /**
     * Generate and download updated report of offers done
     * If the user has only selected one date, then the generated report
     * will be of the same day.
     */
    function exportOffersData() {
      exportLoading.value = true
      apiOffers
        .exportOffersList({
          branch: selectedBranch.value,
          from: selectedRange.value[0],
          to: selectedRange.value.length === 2 ? selectedRange.value[1] : selectedRange.value[0]
        })
        .then(res => {
          let docs = [
            {
              url: res.data.downloadUrl,
              name: `export_${DateTime.now().toSeconds().toFixed()}.xlsx`,
            },
          ]
          downloadFiles(docs)
        })
        .finally(() => {
          exportLoading.value = false
          refreshKey.value++
        })

    }

    async function checkUploadStatus(key: string) {
      const client = await s3(process.env.VUE_APP_MAPIT_CATALOG_IMPORTS_S3)
      const promiseError = client.getObject({
        Bucket: process.env.VUE_APP_MAPIT_CATALOG_IMPORTS_S3,
        Key: `${key}.ERROR.txt`
      }).promise()

      const promiseSuccess = client.getObject({
        Bucket: process.env.VUE_APP_MAPIT_CATALOG_IMPORTS_S3,
        Key: `${key}.SUCCESS.json`
      }).promise()

      return Promise.allSettled([promiseError, promiseSuccess]).then(([error, success]: any) => {
        if (error.value?.Body || success.value?.Body) {
          clearInterval(interval.value)
          importModelsLoading.value = false
          if (error.value?.Body) {
            return importDialog.value = {
              open: true,
              error: true,
              title: root.$t('import.import-title') as string,
              body: `${root.$t('import.error-import-format')}${error.value?.Body.toString()}`,
              continue: () => {
                importModelsLoading.value = false
                closeImportDialog()
              },
              close: () => {
                importModelsLoading.value = false
                closeImportDialog()
              }
            }
          } else {
            importDialog.value = {
              open: true,
              error: false,
              title: root.$t('import.import-title') as string,
              body: root.$t('import.success-import-text') as string,
              continue: () => {
                importModelsLoading.value = false
                closeImportDialog()
              },
              close: () => {
                importModelsLoading.value = false
                closeImportDialog()
              }
            }
          }
        }
      })
    }

    async function checkPredeliveriesUploadStatus(key: string) {
      const client = await s3(process.env.VUE_APP_S3_OFFER_DATA_BUCKET)
      const result = await client.getObject({
        Bucket: process.env.VUE_APP_S3_OFFER_DATA_BUCKET,
        Key: `${key}.RESULT.json`
      }).promise()

      if (result.Body) {
        clearInterval(interval.value)
        importPredeliveryLoading.value = false

        const body = JSON.parse(result.Body.toString())
        const isError = Object.hasOwn(body, 'error')

        if (isError) {
          const {error} = body

          let errorMessage = `${root.$t(`import.error-import-server`)}`
          if (error.type === 'WrongImportInput') {
            errorMessage = error.row
              ? `${root.$t(`import.error-import-wrong-column-predeliveries`, {
                row: error.row + 1,
                column: error.column
              })}`
              : `${root.$t(`import.error-import-missing-column-predeliveries`, {column: error.column})}`
          } else if (error.type === "DuplicatedOfferCode") {
            errorMessage = `${root.$t(`import.error-duplicated-code-predeliveries`, {
              row: error.row + 1,
              dealerCode: error.dealerCode,
              offerCode: error.offerCode
            })}`
          }

          return importDialog.value = {
            open: true,
            error: true,
            title: root.$t('import.import-title') as string,
            body: errorMessage,
            continue: () => {
              importPredeliveryLoading.value = false
              closeImportDialog()
            },
            close: () => {
              importPredeliveryLoading.value = false
              closeImportDialog()
            }
          }
        } else {
          const {success} = body as PredeliveryResponse

          importDialog.value = {
            open: true,
            error: false,
            title: root.$t('import.import-title') as string,
            body: root.$t('import.success-import-text-predeliveries', {
              total: success.total,
              updated: success.updated,
              created: success.created
            }) as string,
            continue: () => {
              importPredeliveryLoading.value = false
              closeImportDialog()
            },
            close: () => {
              importPredeliveryLoading.value = false
              closeImportDialog()
            }
          }
        }
      }
    }

    /**
     * Used to open/close main cancel dialog
     */
    function openCloseCancelDialog() {
      cancelDialog.value = !cancelDialog.value
    }

    /**
     * Used to redirect to home page on use cancel dialog
     */
    function exit() {
      openCloseCancelDialog()
      root.$router.push('/')
    }

    async function importPredeliveriesFile(data: any) {
      importPredeliveryLoading.value = true
      try {
        importDialog.value = {
          open: true,
          error: false,
          showCancel: true,
          title: root.$t('import.import-title') as string,
          body: root.$t('import.import-by-country-confirmation-predelivery', countries.find((c: any) => c.code === selectedBranch.value)) as string,
          continue: async () => {
            closeImportDialog()
            importPredeliveryLoading.value = true

            const checkKey = `predeliveries/global/PS_${DateTime.now().toSeconds().toFixed()}`
            const key = `${checkKey}.xlsx`
            const client = await s3(process.env.VUE_APP_S3_OFFER_DATA_BUCKET)

            await client.putObject({
              Bucket: process.env.VUE_APP_S3_OFFER_DATA_BUCKET,
              Key: key,
              Body: data,
              Metadata: {
                "created-by-name": sanitizeForS3(root.$store.state.fullUserName),
                "created-by-email": getUser.value.login
              },
            })
            interval.value = setInterval(checkPredeliveriesUploadStatus, 5000, checkKey)
          },
          close: () => {
            importPredeliveryLoading.value = false
            closeImportDialog()
          }
        }
      } catch (err: any) {
        const key = err.code === 'CustomError' ? err.errorKey : 'import.error-import-server'
        importPredeliveryLoading.value = false
        importDialog.value = {
          open: true,
          error: true,
          title: root.$t('import.import-title') as string,
          body: root.$t(key) as string,
          continue: () => {
            importPredeliveryLoading.value = false
            closeImportDialog()
          },
          close: () => {
            importPredeliveryLoading.value = false
            closeImportDialog()
          }
        }
      }
    }

    return {
      DateTime,
      selectedRange,
      interval,
      importDialog,
      cancelDialog,
      downloadLoading,
      exportLoading,
      importPredeliveryLoading: importPredeliveryLoading,
      importModelsLoading,
      refreshKey,
      selectedBranch,
      locale,
      group,
      isImportUser,
      isAdmin,
      allowImport,
      branches,
      setSelectedRange,
      importNewVinFile,
      importModelsAndInsurancesFile,
      closeImportDialog,
      downloadModelsAndInsurancesFile,
      exportOffersData,
      checkUploadStatus,
      openCloseCancelDialog,
      exit,
      importPredeliveriesFile
    }
  },
})
