import {
  Model,
  Material,
  Price,
  Pack,
  HPConfig,
  Insurance,
  Finance,
  CO2Rates,
} from '@mapit/honda-catalog-dynamodb/dist/types'
import { Branch } from './../utils/constants'
import ApiGatewayClient from './aws/aws-gateway-client'
import { CampaignWithDescription } from '@/types'

export interface ModelSummary extends Material {
  price: Price
  packs: Array<Pack>
  hpConfig: HPConfig | undefined
  insurance: Insurance | undefined
  finance: Finance | undefined
}

export interface Import {
  createdAt: string
  signedUrl: string
}

export class HondaCatalogApi {
  readonly #client: ApiGatewayClient

  constructor() {
    this.#client = new ApiGatewayClient()
  }

  async getModels(branch: Branch): Promise<Array<Model>> {
    return await this.#client
      .sendRequest({
        endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
        method: 'GET',
        path: `/v1/models`,
        queryParams: {
          branch,
        },
      })
      .then((res) => res.data.data)
  }

  async getMaterialsByModel(branch: Branch, model: string, signal: AbortSignal): Promise<Array<Material>> {
    return await this.#client
      .sendRequest({
        endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
        method: 'GET',
        path: `/v1/materials`,
        queryParams: {
          branch,
          model,
        },
        signal,
      })
      .then((res) => res.data.data)
  }

  async getMaterialsById(branch: Branch, id: string): Promise<Material> {
    return await this.#client
      .sendRequest({
        endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
        method: 'GET',
        path: `/v1/materials/${id}`,
        queryParams: {
          branch,
        },
      })
      .then((res) => res.data)
  }

  async getPacks(branch: Branch): Promise<{ packs: { [key: string]: Pack } }> {
    return await this.#client
      .sendRequest({
        endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
        method: 'GET',
        path: `/v1/packs`,
        queryParams: {
          branch,
        },
      })
      .then((res) => res.data.data)
  }

  async getInsurances(branch: Branch): Promise<{ insurances: { [key: string]: Insurance } }> {
    return await this.#client
      .sendRequest({
        endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
        method: 'GET',
        path: `/v1/insurances`,
        queryParams: {
          branch,
        },
      })
      .then((res) => res.data)
  }

  async getCO2Rates(branch: Branch): Promise<CO2Rates> {
    return await this.#client
      .sendRequest({
        endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
        method: 'GET',
        path: `/v1/co2-rates`,
        queryParams: {
          branch,
        },
      })
      .then((res) => res.data.data)
  }

  async getSummaryByIdMaterial(branch: Branch, idMaterial: string, signal?: AbortSignal): Promise<ModelSummary> {
    return await this.#client
      .sendRequest({
        endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
        method: 'GET',
        path: `/v1/materials/${idMaterial}/summary`,
        queryParams: {
          branch,
        },
        signal,
      })
      .then((res) => res.data)
  }

  async getLastImport(branch: Branch): Promise<Import> {
    return await this.#client
      .sendRequest({
        endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
        method: 'GET',
        path: `/v1/imports`,
        queryParams: {
          branch,
        },
      })
      .then((res) => res.data)
  }

  async getLastImportTS(branch: Branch): Promise<number> {
    return await this.#client
      .sendRequest({
        endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
        method: 'GET',
        path: `/v1/imports/ts`,
        queryParams: {
          branch,
        },
      })
      .then((res) => res.data)
  }

  async getCampaigns(
    branch: Branch,
    model: string,
    ym: string,
    type: string,
    color: string,
  ): Promise<Array<CampaignWithDescription>> {
    try {
      return await this.#client
        .sendRequest({
          endpoint: process.env.VUE_APP_HONDA_CATALOG_API,
          method: 'GET',
          path: `/v1/campaigns`,
          queryParams: {
            branch,
            model,
            ym,
            type,
            color,
          },
        })
        .then((res) => res.data.data || [])
    } catch (error) {
      console.error('Error getting the model campaigns', error)
      return []
    }
  }
}

export const hondaCatalogApi = new HondaCatalogApi()
