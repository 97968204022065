<template>
  <message>
    <template slot="icon">
      <i class="fas fa-exclamation-circle text-error" style="font-size: 100px"/>
    </template>
    <template slot="text">
      <h3 class="text-error p-t-20">SE HA PRODUCIDO UN ERROR EN EL SERVIDOR</h3>
    </template>
  </message>
</template>
<script>
  import Message from '@/components/dialog/Message';

  export default {
    components: {
      Message
    }
  }
</script>