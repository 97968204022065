<template>
  <div>
    <v-stepper-content :step="step" class="py-0 px-10">
      <v-form v-if="account" ref="AccountForm" v-model="isValidForm" v-focusNextOnEnter>
        <v-row v-if="!account.withoutEmail">
          <v-col class="col-4 col-lg-4 col-md-12 pt-6">
            <span class="registration-title">
              {{ t('section_email.title') }}
            </span>
          </v-col>
          <v-col class="col-8 col-lg-8 col-md-12 py-2">
            <p class="email-readonly mt-6 mb-1">
              {{ account.email.value }}
            </p>
          </v-col>
        </v-row>

        <v-divider class="divider my-4" v-if="!account.withoutEmail"/>

        <v-row no-gutters>
          <v-col cols="12">
            <span class="registration-title">
              {{ t('section_personal_data.title') }}
            </span>
          </v-col>

          <v-col cols="12">
            <m-form-row :label="t('section_personal_data.label_name')">
              <mapit-input
                uid="registrationClientName"
                :value="account.firstName.value"
                :placeholder="t('section_personal_data.placeholder_name')"
                @changeValue="value => account.firstName.value = toCapitalize(value)"
                :rules="account.firstName.rules"
                :maxlength="100"
              />
            </m-form-row>

            <m-form-row :label="t('section_personal_data.label_lastName')">
              <mapit-input
                uid="registrationClientLastname"
                :value="account.lastName.value"
                :placeholder="t('section_personal_data.placeholder_lastName')"
                @changeValue="value => account.lastName.value = toCapitalize(value)"
                :rules="account.lastName.rules"
                :maxlength="100"
              />
            </m-form-row>

            <m-form-row :label="t('section_personal_data.label_dob')">
              <m-date-picker data-cy="registrationDateOfBirth"
                v-model="dobFormatted"
                :placeholder="dobPlaceholder"
                :rules="account.dob.rules"
                :max="dobMax"
                :max-error-text="$t('honda.registration.account.account_form.error.dob_empty')"
                :min-error-text="$t('honda.registration.account.account_form.error.dob_empty')"
                :format-error-text="$t('honda.registration.account.account_form.error.dob_empty')"
                :locale="locale"
                min="1900-01-01"
              />
            </m-form-row>

            <m-form-row :label="t('section_personal_data.label_documents')">
              <v-row no-gutters>
                <v-col class="col-4 col-lg-4 col-md-12">
                  <mapit-input
                    uid="registrationDocumentType"
                    :listItems="stringDocumentTypes"
                    :placeholder="t('placeholder_default')"
                    :rules="account.documentType.rules"
                    :type="InputTypes.SELECT"
                    :value="normalizedDocumentType"
                    @changeValue="(value) => account.documentType.value = value"
                  />
                </v-col>

                <v-col class="col-8 col-lg-8 col-md-12 pl-lg-2 my-md-2 my-lg-0">
                  <m-id-card-field
                    v-if="account.documentType.value === 'NIF'"
                    data-id="registrationDocument"
                    data-cy="registrationDocument"
                    v-model="account.document.value"
                    :country="country"
                    :rules="account.document.rules"
                    />

                  <m-text-field
                    v-else
                    data-id="registrationDocument"
                    data-cy="registrationDocument"
                    :value="account.document.value"
                    @input="value => account.document.value = value.toUpperCase()"
                    :rules="account.document.rulesOther"
                    :maxlength="40"
                  />
                </v-col>
              </v-row>
            </m-form-row>

          </v-col>
        </v-row>

        <v-divider class="divider my-4"/>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="registration-title">
              {{ t('section_contact.title') }}
            </span>
          </v-col>
          <v-col cols="12">

            <m-form-row>
              <template v-slot:custom-label>
                <div class="align-self-start">{{ t('section_contact.label_address') }}</div>
              </template>

              <template v-slot:default>
                <v-row no-gutters>
                  <v-col class="col-12 col-md-12">
                    <mapit-input
                      uid="registrationClientStreetAddress"
                      :placeholder="t('section_contact.label_address')"
                      :rules="account.address.streetAddress.rules"
                      :value="account.address.streetAddress.value"
                      @changeValue="value => account.address.streetAddress.value = value"
                    />
                  </v-col>

                  <v-col class="col-8 col-lg-8 col-md-12 pt-3">
                    <mapit-input
                      uid="registrationClientAddressNumber"
                      :placeholder="t('section_contact.label_address_ext')"
                      :rules="account.address.addressNumber.rules"
                      :value="account.address.addressNumber.value"
                      @changeValue="value => account.address.addressNumber.value = value"
                    />
                  </v-col>

                  <v-col class="col-4 col-lg-4 col-md-12 pl-lg-2 pt-3">
                    <m-postal-code-field
                      data-cy="registrationClientPostalCode"
                      v-model="account.address.postalCode.value"
                      :rules="account.address.postalCode.rules"
                      :country="country"
                    />
                  </v-col>
                </v-row>
              </template>
            </m-form-row>

            <m-form-row :label="t('section_contact.label_province')">
              <m-region-select
                data-cy="registrationAddressRegion"
                :placeholder="t('section_contact.placeholder_province')"
                :level="2"
                v-model="region"
                :country="country"
                :rules="account.address.addressRegion.rules"
                :attach="false"
              />
            </m-form-row>

            <m-form-row :label="t('section_contact.label_city')">
              <m-region-select
                data-cy="registrationAddressLocality"
                :placeholder="t('section_contact.placeholder_city')"
                :level="3"
                v-model="locality"
                :country="country"
                :parent="region ? region.id : ''"
                :rules="account.address.addressLocality.rules"
                :disabled="!region"
                :attach="false"
              />
            </m-form-row>

            <m-form-row :label="t('section_contact.label_country')">
              <m-text-field
                :value="$t(`countries.${country}`)"
                readonly
              />
            </m-form-row>

            <m-form-row :label="t('section_contact.label_phone')">
              <m-phone-field
                data-cy="registrationClientPhone"
                v-model="account.phone.value"
                :rules="account.phone.rules"
                :country="country"
              />
            </m-form-row>
          </v-col>
        </v-row>

        <v-divider
          v-if="showIBANSection"
          class="divider my-4"
        />

        <v-row
          v-if="showIBANSection"
          no-gutters
        >
          <v-col cols="12">
            <span class="registration-title">
              {{ t('section_payment.title') }} ({{ t('section_payment.title_warning') }})
            </span>
          </v-col>

          <v-col cols="12">
            <m-form-row :label="t('section_payment.label_iban')">
              <iban-stripe
                @setIban="setIBAN"
                :account="getAccount"
                :info="getInfoIBAN"
                :error="ibanError"
              />
            </m-form-row>
          </v-col>
        </v-row>

        <mapit-dialog
          :dialog="isDialogOpen"
          :customWidth="'700'"
          @close="closeIBANDialog()"
        >
          <template v-slot:header-icon>
            <v-icon class="text-primary" size="48">fa-solid fa-octagon-xmark</v-icon>
          </template>

          <template v-slot:header-text>
            <span>{{ t('section_payment.modal_title') }}</span>
          </template>

          <template v-slot:body>
            <div class="dialog-content">
              <p>{{ t('section_payment.modal_content') }}</p>
            </div>
          </template>

          <template v-slot:footer-center>
            <v-btn class="btn-primary" @click="closeIBANDialog()">
              {{ t('section_payment.add_iban') }}
            </v-btn>

            <v-btn class="btn-gray" @click="nextStep(true)">
              {{ t('section_payment.continue') }}
            </v-btn>
          </template>
        </mapit-dialog>
      </v-form>
    </v-stepper-content>

    <footer-actions :step="step">
      <template v-slot:left>
        <v-btn class="btn-gray" @click="backStep" dark tabindex="-1"> {{ $t('buttons.back') }}</v-btn>
      </template>

      <template v-slot:right>
        <v-btn
          :disabled="!isValidForm"
          :key="buttonKey"
          :loading="loading"
          @click="nextStep()"
          class="btn-primary" dark
          data-cy="btn-next"
        >
          {{ $t('buttons.next') }}
        </v-btn>
      </template>
    </footer-actions>
  </div>
</template>

<script>
import moment from 'moment'
import { DateTime } from "luxon";
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

import { Regions } from '@mapit/common-utils'

import { isRequired } from '@/helpers/validations'
import { createPaymentMethod } from '@/helpers/stripe'
import { toCapitalize } from '@/helpers/utils'
import { capitalize } from "@/utils/utils";

import { InputTypes } from '@/utils/constants'
import { getDatePattern, getDateFromYearsAgo, DATE_FORMAT_ISO_MOMENT } from "@/utils/date-helper";

import IbanStripe from './IbanStripe.vue';
import MapitInput from '@/components/general/MapitInput.vue'
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import FooterActions from '../FooterActions'

export default {
  name: "FormData",
  components: { IbanStripe, FooterActions, MapitInput, MapitDialog },
  props: ['step'],
  data() {
    return {
      InputTypes,
      toCapitalize,
      DateTime,
      requiredRules: [isRequired],
      loading: false,
      dobMenu: false,
      validDob: true,
      account: cloneDeep(this.$store.getters.getAccount),
      stringDocumentTypes: [
        { text: this.t('section_personal_data.document_option1'), value: 'NIF' },
        { text: this.t('section_personal_data.document_option2'), value: 'Other '}
      ],
      stripe: undefined,
      paymentMethod: null,
      iban: '',
      ibanError: '',
      buttonKey: 1,
      dobKey: 1,
      isValidForm: false,
      locality: undefined,
      region: undefined,
      isDialogOpen: false,
    }
  },
  watch: {
    'region': {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.account.address.addressRegion.value = val.name
        }
      }
    },
    'locality': {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.account.address.addressLocality.value = val.name
        }
      }
    },
    getAccount: function (val) {
      this.account = val
      this.$refs.AccountForm.resetValidation()
      this.region = Regions[this.country].find(item => item.level === 2 && item.name === this.account.address.addressRegion.value)
      this.locality = Regions[this.country].find(item => item.level === 3 && item.name === this.account.address.addressLocality.value)
    },
    dobMenu(val) {
      val && setTimeout(() => (this.$refs.pickerDob.activePicker = 'YEAR'))
    },
    'account.email.value': function () {
      this.dobKey++
    }
  },
  methods: {
    t(id) {
      return this.$t(`honda.registration.account.account_form.${id}`)
    },
    backStep() {
      this.clearIBAN()
      this.$emit('backStep')
    },
    async nextStep(ignoreIBAN) {
      await this.submitForm()

      if (this.ibanError) {
        return
      }

      if (this.requestIBAN && !ignoreIBAN) {
        this.openIBANDialog()
        return
      }

      this.closeIBANDialog()
      this.$emit('nextStep')
    },
    setIBAN({ iban, stripe }) {
      this.iban = iban
      this.stripe = stripe
      this.account.paymentMethod = undefined
    },
    clearIBAN() {
      this.iban = undefined
      this.stripe = undefined
      this.account.paymentMethod = undefined
    },
    openIBANDialog() {
      this.isDialogOpen = true
    },
    closeIBANDialog() {
      this.isDialogOpen = false
    },
    mapToCreatePaymentMethodData() {
      const { firstName, lastName, email, phone, address } = this.account
      const {
        addressLocality,
        addressNumber,
        addressRegion,
        postalCode,
        streetAddress,
      } = address || {}

      return {
        firstName: toCapitalize(firstName.value),
        lastName: toCapitalize(lastName.value),
        email: email.value,
        phone: phone.value,
        address: {
          addressLocality: addressLocality.value,
          addressNumber: addressNumber.value,
          addressRegion: addressRegion.value,
          country: this.country || 'ES',
          postalCode: postalCode.value,
          streetAddress: streetAddress.value,
        }
      }
    },
    mapAccountDataForStore() {
      const { firstName, lastName, document } = this.account

      this.account.firstName.value = toCapitalize(firstName.value)
      this.account.lastName.value = toCapitalize(lastName.value)
      this.account.document.value = document.value?.toUpperCase()
    },
    async createPaymentMethod() {
      const account = this.mapToCreatePaymentMethodData(this.account)
      const { paymentMethod, error } = await createPaymentMethod(this.stripe, this.iban, account)

      if (error || !paymentMethod?.id ) {
        this.handleError(error?.message || 'error.default', true)
        return
      }

      this.account.paymentMethod = paymentMethod
    },
    async submitForm() {
      this.beforeSubmit()

      if (!this.validDob) {
        return
      }

      if (!this.$refs.AccountForm.validate()) {
        return
      }

      if (this.stripe && this.iban) {
        await this.createPaymentMethod()
      }

      if (this.isMandatoryIBAN && !this.hasValidPaymentMethod) {
        this.handleError('error.iban_empty')
        return
      }

      // map the data before store
      this.mapAccountDataForStore()

      await this.$store.dispatch('addAccount', this.account)
      this.afterSubmit()
    },
    beforeSubmit() {
      this.loading = true
      this.ibanError = undefined
    },
    afterSubmit() {
      this.loading = false
      this.buttonKey++
    },
    handleError(message, clean) {
      this.ibanError = clean
        ? message
        : this.t(message)

      this.afterSubmit()
    },
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
      vehicle: 'getVehicle',
      isHondaMapit: 'getHasHondaMapitService',
      locale: 'getLocale',
    }),
    dobFormatted: {
      get() {
        const date = moment(this.account.dob.value)

        if (!date.isValid()) {
          return undefined
        }

        return date.format(DATE_FORMAT_ISO_MOMENT)
      },
      set(value) {
        if (value) {
          this.account.dob.value = value
        }
      }
    },
    dobPlaceholder() {
      return getDatePattern(this.locale)
    },
    dobMax() {
      return getDateFromYearsAgo()
    },
    normalizedDocumentType() {
      const { documentType } = this.account

      if (documentType?.value === 'NIF') {
        return documentType?.value
      }

      return capitalize(documentType?.value?.toLowerCase())
    },
    getAccount() {
      return cloneDeep(this.$store.getters.getAccount)
    },
    getServices() {
      return cloneDeep(this.$store.getters.getServices)
    },
    hasHondaMapit() {
      return this.getServices['honda-mapit'].selected
    },
    hasInsurance() {
      return this.getServices['honda-seguro-normal'].selected || this.getServices['honda-seguro-plus'].selected
    },
    hasValidPaymentMethod() {
      return this.account.paymentMethod?.id || this.hasPreviousPaymentMethods
    },
    hasPreviousPaymentMethods() {
      return this.account.stripeId && this.account.paymentMethods?.length
    },
    showIBANSection() {
      return this.hasHondaMapit
    },
    isMandatoryIBAN() {
      return this.hasHondaMapit && this.hasInsurance
    },
    requestIBAN() {
      return this.hasHondaMapit
        && !this.iban
        && !this.hasPreviousPaymentMethods
    },
    getInfoIBAN() {
      if (this.isMandatoryIBAN) {
        return this.t('section_payment.label_info_iban_mandatory')
      }

      if (this.hasHondaMapit) {
        return this.t('section_payment.label_info_honda_mapit')
      }

      return ''
    },
  }
}
</script>

<style lang="scss">
.info-demobike {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 14px;
  color: var(--v-secondary-base);
}

.unstyled, .v-input > .v-input-control > .v-input-slot > .v-text-field__slot > input > ::-webkit-inner-spin-button, ::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.custom-radio-button {
  margin-top: 5px !important;

  p {
    width: 100%;
    margin: -5px 0;
    color: var(--v-gray-darken1) !important;
    font-family: 'ProximaNovaRegular', sans-serif;
    font-size: 12px !important;
  }

  .v-radio {
    padding: 10px 40px 10px 0;

    label {
      font-family: 'ProximaNovaRegular', sans-serif;
      color: var(--v-gray-darken4) !important;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0;
    }

    i {
      font-size: 16px;
    }
  }
}

.email-readonly {
  color: var(--v-gray-darken4) !important;
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 16px !important;
}

.custom-text-field {
  margin: 10px 0;

  label {
    margin: -5px 0;
    color: var(--v-gray-darken1) !important;
    font-family: 'ProximaNovaRegular', sans-serif;
    font-size: 16px !important;
  }

  input {
    font-family: 'ProximaNovaRegular', sans-serif;
    color: var(--v-gray-darken4) !important;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
  }

  &.uppercase {
    input {
      text-transform: uppercase;
    }
  }

  &.success-msg {
    .v-messages__message {
      color: var(--v-secondary-base);
    }
  }
}

.noemail-checkbox {
  label {
    font-family: 'ProximaNovaRegular', sans-serif;
    font-size: 14px !important;
    line-height: 18px;
    letter-spacing: 0;
  }

  i {
    font-size: 14px !important;
  }
}

.calendar-icon {
  color: var(--v-gray-darken4) !important;
}

#app {
  .v-date-picker-header, .v-date-picker-table {
    button {
      padding: 0px !important;

      i {
        font-size: 14px !important;
      }
    }
  }

  .v-date-picker-table {
    height: auto;
  }

  .v-date-picker-years {
    margin: 0;
  }
}

input[data-id='registrationDocument'] {
  text-transform: uppercase;
}

.dialog-content {
  font-size: 14px;
  text-align: center;
}

.registration-title {
  text-align: left;
  color: var(--v-primary-base);
  font-size: 24px !important;
  font-family: 'ProximaNovaExCnSbold', sans-serif;
}
</style>
