<template>
    <mapit-dialog :dialog="dialog" @close="close" customId="popup-search-vin">
      <template v-slot:body>
        <v-row no-gutters class="px-8 m-0">
          <v-col class="col-12">
            <h3 class="big-title">xxPxxPPxPxxPPPPPP</h3>
            <h3 class="custom-title">{{ $t(`honda.registration.mapit-wizard.used.vin-title`) }}</h3>
            <p class="custom-subtitle">{{ $t(`honda.registration.mapit-wizard.used.vin-subtitle`) }}</p>
          </v-col>
          <v-col class="col-md-6 col-6 m-auto">
              <m-text-field
                v-model="vin"
                :label="$t('honda.registration.mapit-wizard.used.vin-label-input')"
                :placeholder="$t('honda.registration.mapit-wizard.used.vin-placeholder-input')"
                :rules="vin !== null ? vehicle.vin.rules : []"
                :error-messages="vinValidationError"
                @input="(value) => vin = value.toUpperCase()"
                color="secondary"
                maxlength="17"
                data-cy="popup-search-vin-input"
                :loading="vinLoading"
                outlined
                autofocus
              />
          </v-col>
        </v-row>
        <v-col class="m-0">
          <v-col class="col-md-12 col-12 result-info-box-error" v-if="vinFoundWithoutOwnerEmail">
            <span class="icon-mapit">T</span><div class="info-text">{{ $t('honda.registration.mapit-wizard.used.vin-alert-found-without-email') }}</div>
          </v-col>
          <v-col class="col-md-12 col-12 result-info-box-success" v-else-if="vinFound">
            <span class="icon-mapit">R</span><div class="info-text">{{ $t('honda.registration.mapit-wizard.used.vin-alert-found') }}</div>
          </v-col>
          <v-col class="col-md-12 col-12 result-info-box-warning" v-else-if="vinNotFound">
            <span class="icon-mapit">T</span><div class="info-text">{{ $t('honda.registration.mapit-wizard.used.vin-alert-not-found') }}</div>
          </v-col>
          <v-col class="col-md-12 col-12 result-info-box-error" v-else-if="vinWithMapit">
            <span class="icon-mapit">T</span><div class="info-text">{{ $t('honda.registration.mapit-wizard.used.vin-already-used') }}</div>
          </v-col>
          <v-col class="col-md-12 col-12 result-info-box-error" v-else-if="vinWithMapitPending">
            <span class="icon-mapit">T</span><div class="info-text">{{ $t('honda.registration.mapit-wizard.used.vin-already-used-registration') }}</div>
          </v-col>
          <v-col class="col-md-12 col-12 result-info-box-transp" v-else>
          </v-col>
        </v-col>
      </template>
      <template v-slot:footer-center>
        <v-btn data-cy="popup-search-vin-continue" class="btn-primary" :key="buttonKey" text @click.once="continueRegistration" :disabled="loading || !validVin" :loading="loading">{{
          $t('buttons.next')
        }}</v-btn>
      </template>
    </mapit-dialog>
</template>

<script>
import MapitDialog from '@/components/dialog/MapitDialog'
import { vehicleRegistrationModel } from '@/vuex/models'
import { vinValidation, NUM_CHARACTERS_VALID_VIN } from '@/api/vehicle.api.js'
import { isAlphanumeric} from '@/helpers/validations'
import { VinRegistrationStates } from '@/utils/constants'

export default {
  name: "DialogVinRegistration",
  components: {
    MapitDialog
  },
  props: [ 'dialog', 'loading', 'queryVin' ],
  data() {
    return {
      buttonKey: 1,
      vehicle: vehicleRegistrationModel(),
      vin: null,
      status: null,
      vinValidationError: null,
      vinLoading: false,
      vehicleId: null,
      owner: {
        detail: null,
        email: null
      }
    };
  },
  watch: {
    dialog: function(val) {
      this.vin = null
      this.status = null
      this.vinValidationError= null
    },
    vin: function (val) {
      this.vinValidationError = null
      this.status = null
      if (val && val.length === NUM_CHARACTERS_VALID_VIN) {
        const isAlphanum = isAlphanumeric(val)
        if(typeof isAlphanum !== 'string'){ //si tenemos un "texto" de error
          this.checkVinValidation()
        } else {
          this.vinValidationError = isAlphanum
        }
      }
    },
    queryVin: function (val) {
      if(val) this.vin = val
    }
  },
  created() {
    if(this.queryVin) this.vin = this.queryVin
  },
  computed: {
    validVin(){
      return !!this.status && !this.vinWithMapit && !this.vinValidationError && !this.vinFoundWithoutOwnerEmail && !this.vinWithMapitPending
    },
    vinFoundWithoutOwnerEmail(){
      return this.status === VinRegistrationStates.FOUND_WITHOUT_OWNER_EMAIL
    },
    vinFound(){
      return this.status === VinRegistrationStates.FOUND_WITHOUT_DEVICE
    },
    vinNotFound(){
      return this.status === VinRegistrationStates.NOT_FOUND
    },
    vinWithMapit(){
      return this.status === VinRegistrationStates.FOUND_WITH_DEVICE
    },
    vinWithMapitPending(){
      return this.status === VinRegistrationStates.FOUND_WITH_DEVICE_PENDING
    }
  },
  methods: {
    close(){
      this.$emit('close')
    },
    continueRegistration() {
      if(this.validVin) {
        this.$emit('continue', {
          vin: this.vin,
          status: this.status,
          vehicleId: this.vehicleId,
          owner: this.owner
        })
      }
      this.buttonKey++
    },
    async checkVinValidation() {
      this.vinLoading = true
      const result = await vinValidation(this.vin)
      this.vehicleId = result.vehicleId
      this.owner = result.account
      if(result.error && (result.key === 'ALREADY_USED_VIN' || result.key === 'REALTA_VIN')){
        this.status = result.account.email ? (result.hasDevice ? VinRegistrationStates.FOUND_WITH_DEVICE: VinRegistrationStates.FOUND_WITHOUT_DEVICE) : VinRegistrationStates.FOUND_WITHOUT_OWNER_EMAIL
      } else if(result.key === 'ALREADY_USED_VIN_REGISTRATION'){
        this.status = VinRegistrationStates.FOUND_WITH_DEVICE_PENDING
      } else if(result.key === 'VIN_VALID' || result.key === 'NO_VIN_BDD'){
        this.status = VinRegistrationStates.NOT_FOUND
      } else {
        this.status = null
      }
      this.vinLoading = false
    }
  }
};
</script>

<style lang="scss">
.popup-search-vin{
  padding-left: 0px !important;
  padding-right: 0px !important;
  .big-title{
    text-align: center;
    font: normal normal normal 24px/31px 'mapitfont-v1';
    letter-spacing: 0px;
    color: var(--v-secondary-base);
    opacity: 1;
  }
  .custom-title{
    text-align: center;
    font: normal normal 600 24px/30px 'ProximaNovaExCnSBold';
    letter-spacing: 0px;
    color: var(--v-gray-darken4);
    text-transform: uppercase;
    opacity: 1;
  }

  .custom-subtitle{
    text-align: center;
    font: normal normal normal 16px/26px 'ProximaNovaRegular';
    letter-spacing: 0px;
    color: var(--v-gray-darken4);
    opacity: 1;
  }
  .result-info-box{

    &-success, &-warning, &-error, &-transp{
      margin-top: 20px;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      .info-text{
        text-align: left;
        font: normal normal 600 16px/20px 'ProximaNovaSemibold';
        letter-spacing: 0px;
        padding-left: 15px;
      }
    }

    &-transp{
      visibility: hidden;
    }
    &-success{
      background: rgba(0,128,62,0.1) 0% 0% no-repeat padding-box;
      color: var(--v-secondary-base);
    }
    &-warning{
      background: rgba(252,164,0,0.1) 0% 0% no-repeat padding-box;
      color: var(--v-warning-base);
    }
    &-error{
      background: rgba(220,24,45,0.1) 0% 0% no-repeat padding-box;
      color: var(--v-primary-base);
    }
  }
}

</style>
