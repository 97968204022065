import ridoApi from '@/api/rido.api'
import { onMounted, ref } from '@vue/composition-api'
import { LeadStatus, LeadSummary } from '@mapit/leads-dynamodb/dist/types'
import { getClosestCommentToToday } from '@/utils/comments'

export const useLeadsList = () => {
  const list = ref<Array<LeadSummary>>([])
  const isLoading = ref(false)

  onMounted(async () => {
    await fetchLeads()
  })

  function indexOf(lead: LeadSummary): number {
    return list.value.indexOf(lead)
  }

  function setLeadReadStateInList(lead: LeadSummary, read: boolean) {
    list.value[indexOf(lead)].read = read
  }

  function setLeadStatusInList(lead: LeadSummary, status: LeadStatus) {
    list.value[indexOf(lead)].status = status
  }

  async function fetchLeads() {
    try {
      isLoading.value = true
      const response = await ridoApi.getLeads()
      list.value = mapResponseDataToLeads(response.data)
    } catch (e) {
      console.error('Error fetching leads list', e)
    } finally {
      isLoading.value = false
    }
  }

  async function markLeadAsRead(lead: LeadSummary, read: boolean) {
    try {
      isLoading.value = true
      await ridoApi.markAsRead(lead.id, read)
      setLeadReadStateInList(lead, read)
    } catch (e) {
      console.error(`Error updating read state in lead ${lead.id}`, e)
    } finally {
      isLoading.value = false
    }
  }

  async function maskLeadAsLost(lead: LeadSummary) {
    try {
      isLoading.value = true
      await ridoApi.updateLeadStatus(lead.id, LeadStatus.Lost)
      setLeadStatusInList(lead, LeadStatus.Lost)
    } catch (e) {
      console.error(`Error updating read state in lead ${lead.id}`, e)
    } finally {
      isLoading.value = false
    }
  }

  function mapResponseDataToLeads({ data: leads }: any) {
    return leads.map((lead: any) => ({
      ...lead,
      followUp: getClosestCommentToToday(lead.commentLog),
    }))
  }

  return {
    isLoading,
    data: list,
    refetch: async () => await fetchLeads(),
    markLeadAsRead,
    maskLeadAsLost,
  }
}
