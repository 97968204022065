<template>
  <expansion-panel key="expansion-panel-customer-notes" :title="$t('offer-manager.create.step-customer-notes.title')" :readonly="panelReadonly" :hideDivider="panelHideDivider">
    <template v-slot:panel-content>
      <m-form-row>
        <template v-slot:custom-label>
          <div class="align-self-start">{{ $t('offer-manager.create.step-customer-notes.lbl-annotation') }}</div>
        </template>
        <template v-slot:default>
            <m-textarea
              data-cy="customerNotes"
              :value="annotation"
              clearable
              counter
              @input="value => updateOffer('setAnnotation', value)"
              maxlength="395"
            />
        </template>
      </m-form-row>
    </template>
  </expansion-panel>
</template>

<script>
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import { InputTypes } from '@/utils/constants'

export default {
  name: 'CustomerNotes',
  components: {ExpansionPanel},
  props: [
    'panelReadonly',
    'panelHideDivider'
  ],
  data() {
    return {
      InputTypes
    }
  },
  methods: {
    updateOffer(action, value){
      this.$store.dispatch(action, value)
      this.$emit('validateForm') //this.$refs.InputForm.validate()
    }
  },
  computed: {
    annotation(){ return this.$store.getters.getAnnotation }
  }
}
</script>
