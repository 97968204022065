


















import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import { Product } from "@mapit/honda-offers-builder/dist/types"
import FinancialCalculatorForm from "@/components/offer-manager/forms/FinancialCalculatorForm.vue"
import { computed, defineComponent, inject, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from '@/i18n'
import { mapGetters } from '@/utils/map-store'
import { debounce } from 'lodash'

export default defineComponent({
  name: 'FinancialServicesSection',
  components: { FinancialCalculatorForm, ExpansionPanel },
  props: [
    'hideDetails',
    'panelHideDivider',
    'disabled',
    'loading'
  ],
  setup(props, { root, emit, refs }) {

    const { t } = useI18n()

    const isEditing = inject('isEditing') as Ref<boolean>

    const {
      getSelectedModel: catalogModel,
      getVehiclePriceRateCCAA: vehiclePriceAmount,
      getFinance: finance,
      getCountry: country,
      getTotalPrice: totalPrice,
      getOffer: offer,
      getServicesProducts: servicesProducts,
      isFinanceUpdated,
    } = mapGetters(root.$store.getters)


    const financeCalculation = ref(null)
    const financeCalculatorRefresh = ref(0)

    const isNewOffer = computed(() => {
      return !root.$route.params.id
    })
    const financeMode = computed(() => {
      const financeMode = financeModes.value.find((fm: any) => fm.name === finance.value?.mode?.name)
      return financeMode || finance.value?.mode
    })
    const priceOfGoodsAmount = computed(() => {
      const input = finance.value?.input
      return input?.priceOfGoodsAmount || totalPrice.value.price
    })
    const depositAmount = computed(() => {
      return finance.value?.input?.depositAmount
    })
    const paymentProtectionInsurance = computed(() => {
      return finance.value?.input?.paymentProtectionInsurance || false
    })
    const hasFinance = computed(() => {
      return offer.value?.finance
    })
    const financeSupported = computed(() => {
      return (isEditing?.value && offer?.value?.finance) || (catalogModel.value?.finance && Object.keys(catalogModel.value?.finance || {}).length > 0)
    })
    const financeSelected = computed(() => {
      return servicesProducts.value.includes(Product.Finance)
    })
    const financeModes = computed(() => {
      return catalogModel.value?.finance ? Object.entries(catalogModel.value.finance)
        .sort(([aKey], [bKey]) => bKey.localeCompare(aKey))
        .map(([key, value]) => {
          (value as any).name = key
          return value
        }) : offer.value?.finance ? [offer.value.finance.mode]: []
    })
    const showFinancialCalculatorForm = computed(() => {
      return financeSelected.value && (financeSupported.value || hasFinance.value)
    })
    const additionalResidualValueAmount = computed(() => {
      if (offer.value?.servicesProducts?.includes(Product.HondaPlusGo) && catalogModel.value) {
        const hpgPrice = catalogModel.value?.hpConfig?.HPG?.price || 0
        const hpPrice = catalogModel.value?.hpConfig?.HP?.price || 0
        return Math.max(hpgPrice - hpPrice, 0)
      } else {
        return 0
      }
    })
    const alertMessage = computed(() => {
      if (!financeSupported.value) {
        return t('offer-manager.create.step-financial-services.alerts.not-supported')
      } else if (!financeSelected.value) {
        return t('offer-manager.create.step-financial-services.alerts.not-selected')
      } else if (!isNewOffer.value && showFinancialCalculatorForm.value) {
        return t('offer-manager.create.step-financial-services.alerts.conditions-change')
      } else {
        return null
      }
    })

    watch(financeCalculation, (newValue: any) => {
      root.$store.commit('SET_OFFER_FINANCE', JSON.parse(JSON.stringify(newValue)))
    })

    const stopFinanceWatch = watch(isFinanceUpdated, (value) => {
      if (value) {
        financeCalculatorRefresh.value++
        root.$store.dispatch('resetIsFinanceUpdated')
        stopFinanceWatch()
      }
    })

    function updateOffer(action: string, value: any) {
      root.$store.dispatch(action, value)
      emit('validateForm')
    }

    function validateForm() {
      emit('validateForm')
    }

    return {
      financeCalculation,
      catalogModel,
      vehiclePriceAmount,
      finance,
      country,
      totalPrice,
      offer,
      financeMode,
      priceOfGoodsAmount,
      depositAmount,
      paymentProtectionInsurance,
      financeSupported,
      financeSelected,
      financeModes,
      showFinancialCalculatorForm,
      additionalResidualValueAmount,
      alertMessage,
      financeCalculatorRefresh,
      updateOffer,
      validateForm
    }
  }
})
