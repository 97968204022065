<template>
  <message>
    <template slot="icon">
      <i class="far fa-calendar-times text-error" style="font-size: 100px"/>
    </template>
    <template slot="text">
      <h3 class="text-error p-t-20">ELIMINAR REPARACIÓN</h3>
    </template>
    <template slot="bottom">
      <div>
        ¿Está seguro que desea eliminar la reparación?
      </div>

      <button class='btn col-auto btn-success m-t-20' @click="$emit('confirm')"><i class="fas fa-angle-right"></i>
        Aceptar
      </button>

      <div>
        <i class="fa fa-mobile-alt pr-2 m-t-40"/>Se notificará al cliente en su móvil
      </div>
    </template>
  </message>
</template>

<script>
  import Vue from 'vue';
  import Message from '@/components/dialog/Message';

  export default {
    props: ['request'],
    components: {
      Message
    },
    data() {
      return {modified: false, deleteRevision: { kind: 'NO_CLIENT', comment:null, nextService:null}};
    }
  }
</script>
