






















































import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import {InputTypes} from '@/utils/constants'
import DiscountField from '@/components/general/DiscountField.vue'
import MapitInput from '@/components/general/MapitInput.vue'
// @ts-ignore
import {registrationDiscountRules, registrationDiscountRulesPercentage} from '@/helpers/offer-manager-rules'
import {defineComponent, ref, watch} from "@vue/composition-api";
import {mapGetters} from "@/utils/map-store";

export default defineComponent({
  name: 'VehicleRegistration',
  components: {ExpansionPanel, DiscountField, MapitInput},
  props: {
    hideDetails: {
      type: Boolean,
      default: false
    },
    panelReadonly: {
      type: Boolean,
      default: false
    },
    panelHideDivider: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {root, emit}) {
    const error = ref('')

    const {
      getRegistrationPrice: registrationPrice,
      getRegistrationDiscount: registrationDiscount,
      getRegistrationDiscountPercent: registrationDiscountPercent,
      getRegistrationTotalPrice: registrationTotalPrice
    } = mapGetters(root.$store.getters)

    watch(registrationPrice, validateDiscounts)
    watch(registrationDiscount, validateDiscounts)
    watch(registrationDiscountPercent, validateDiscounts)

    function validateDiscount(value: any) {
      return !value || !registrationPrice.value || registrationPrice.value && parseFloat(value) <= parseFloat(registrationPrice.value) && parseFloat(value) >= 0 || 'Error'
    }

    function updateOffer(action: string, value: any) {
      root.$store.dispatch(action, value)
      emit('validateForm')
    }

    function validateDiscounts() {
      if (validateDiscount(registrationDiscount.value) !== 'Error') {
        if (registrationDiscountPercent.value >= 0 && registrationDiscountPercent.value <= 100) {
          error.value = ''
        } else {
          error.value = root.$t('offer-manager.create.step-vehicle-registration.lbl-error-discount-percentage') as string
        }
      } else {
        error.value = root.$t('offer-manager.create.step-vehicle-registration.lbl-error-discount') as string
      }
    }

    return {
      InputTypes,
      error,
      registrationPrice,
      registrationDiscount,
      registrationDiscountPercent,
      registrationTotalPrice,
      registrationDiscountRules,
      registrationDiscountRulesPercentage,
      validateDiscount,
      updateOffer,
      validateDiscounts
    }
  }
})
