import { render, staticRenderFns } from "./DownloadApp.vue?vue&type=template&id=8c49444a&scoped=true&"
import script from "./DownloadApp.vue?vue&type=script&lang=js&"
export * from "./DownloadApp.vue?vue&type=script&lang=js&"
import style0 from "./DownloadApp.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./DownloadApp.vue?vue&type=style&index=1&id=8c49444a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c49444a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCheckbox,VCol,VForm,VIcon,VImg,VRow,VStepperContent})
