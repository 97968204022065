import { CognitoUser } from 'amazon-cognito-identity-js'
import { Dealer } from '@mapit/core-dynamodb/dist/types'
import { Branch, CognitoGroups } from '@/utils/constants'

export interface CanSee {
  canSee: boolean
}

export interface CanCreate {
  canCreate: boolean
}

export interface CanManage {
  canManage: boolean
}

export interface Permissions {
  leads: CanSee
  offers: CanSee
  registrations: CanSee
  registrationArchivedFilters: CanSee
  customers: CanSee
  appointments: CanSee
  dealerBikes: CanSee & CanCreate
  fleetManagement: CanSee
  userCredentials: CanSee
  stocks: CanSee & CanManage
  imports: CanSee
  dealerProfile: CanSee
  dealerDashboard: CanSee
  sectionLoansSales: CanSee
  requests: CanSee
  help: CanSee
  supportsSubagent: boolean
}

export class PermissionsHelper {
  private readonly cognitoUser: CognitoUser
  private readonly dealer: Dealer

  public constructor(cognitoUser: CognitoUser, dealer: Dealer) {
    this.cognitoUser = cognitoUser
    this.dealer = dealer
  }

  public calculatePermissions(): Permissions {
    return {
      leads: {
        canSee:
          this.allowedByRole([CognitoGroups.DealerAdmin, CognitoGroups.SalesAgent]) &&
          this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
      },
      offers: {
        canSee:
          this.allowedByRole([CognitoGroups.DealerAdmin, CognitoGroups.SalesAgent]) &&
          this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
      },
      registrations: {
        canSee:
          this.allowedByRole([CognitoGroups.DealerAdmin, CognitoGroups.SalesAgent]) &&
          this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
      },
      registrationArchivedFilters: {
        canSee:
          this.allowedByRole([CognitoGroups.DealerAdmin]) &&
          this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
      },
      customers: {
        canSee:
          this.allowedByRole([CognitoGroups.DealerAdmin, CognitoGroups.SalesAgent]) &&
          this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
      },
      appointments: {
        canSee: this.allowedByRole([
          CognitoGroups.DealerAdmin,
          CognitoGroups.SalesAgent,
          CognitoGroups.GarageManager,
        ]),
      },
      dealerBikes: {
        canSee: this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
        canCreate: this.allowedByRole([CognitoGroups.DealerAdmin]),
      },
      fleetManagement: {
        canSee: this.allowedByRole([CognitoGroups.DealerAdmin]),
      },
      userCredentials: {
        canSee:
          this.allowedByRole([CognitoGroups.DealerAdmin]) &&
          this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
      },
      stocks: {
        canSee:
          this.allowedByRole(
            [CognitoGroups.StockAdmin, CognitoGroups.DealerAdmin],
            [CognitoGroups.ServicePartner],
          ) && this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
        canManage: this.allowedByRole([CognitoGroups.StockAdmin]),
      },
      imports: {
        canSee: this.allowedByRole([CognitoGroups.Imports]),
      },
      requests: {
        canSee:
          this.allowedByRole([
            CognitoGroups.DealerAdmin,
            CognitoGroups.GarageManager,
            CognitoGroups.SalesAgent,
          ]) && this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
      },
      dealerProfile: {
        canSee: this.allowedByRole([CognitoGroups.DealerAdmin]),
      },
      dealerDashboard: {
        canSee:
          this.allowedByRole([CognitoGroups.DealerAdmin, CognitoGroups.SalesAgent]) &&
          this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
      },
      sectionLoansSales: {
        canSee:
          this.allowedByRole([CognitoGroups.DealerAdmin, CognitoGroups.SalesAgent]) &&
          this.allowedByBranch([Branch.HondaSpain, Branch.HondaPortugal, Branch.HondaUSA]),
      },
      help: {
        canSee: this.allowedByRole([
          CognitoGroups.DealerAdmin,
          CognitoGroups.GarageManager,
          CognitoGroups.SalesAgent,
        ]),
      },
      supportsSubagent: this.allowedByBranch([Branch.HondaSpain]),
    }
  }

  private allowedByBranch(allowedBranches: Branch[]) {
    return allowedBranches.some((c: Branch) => String(this.dealer.country) === c)
  }

  private allowedByRole(allowedRoles: CognitoGroups[], forbiddenRoles?: CognitoGroups[]) {
    const groups =
      this.cognitoUser?.getSignInUserSession()?.getAccessToken().decodePayload()[
        'cognito:groups'
      ] || []

    if (forbiddenRoles?.some((g: CognitoGroups) => groups.includes(g))) {
      return false
    }

    return [...allowedRoles, CognitoGroups.Admin].some((g: CognitoGroups) => groups.includes(g))
  }
}
