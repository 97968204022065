<template>
  <th class='align-center' v-if="isHeader">
    {{title}}
  </th>
  <td class="align-center" v-else>
    <div v-if="rowData">
      <div class="col-12 p-l-15 p-r-15">
				<span class="f-s-15 bold-900">
          <button class='btn btn-honda-plus-light btn-sm' @click="$emit('vuetable:field-event', {payload: rowData})">Gestionar</button>
        </span><br/>
      </div>
    </div>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';


  export default {
    mixins: [VuetableFieldMixin],
    props: ["rowData"],
    name: 'Button'
  }
</script>
