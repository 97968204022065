<template>
  <div :id="uid" ref="DiscountField">
    <v-menu
      v-model="isOpenMenu"
      offset-y
      :attach="`#${uid}`"
      min-width="50"
      :nudge-right="widthDiscountInput"
    >
      <template v-slot:activator="{}">
        <div id="discount-field" class="mapit-input">
          <mapit-input
            :class="`input-right ${appendType === '%' ? '' : 'd-none'}`"
            :type="InputTypes.PERCENTAGE"
            :value="valuePercentage"
            :uid="`percent_${uid}`"
            :placeholder="placeholder"
            :rules="rulesPercentage"
            :maxlength="maxlength"
            :disabled="disabled"
            :hint="hint"
            :isReadonly="isReadonly"
            :hideDetails="hideDetails"
            @changeValue="value => $emit('changeValuePercentage', value)"
            @validateForm="validateForm"
          >
            <template slot="append-inner-icon">
              <div class="append-icons click d-inline-flex" @click="showHideMenu">
                % <i class="icon-mapit">{{ isOpenMenu ? 5 : 2}}</i>
              </div>
            </template>
          </mapit-input>
          <mapit-input
            :class="`input-right ${appendType === '€' ? '' : 'd-none'}`"
            :type="InputTypes.PRICE"
            :value="valueCurrency"
            :uid="`currency_${uid}`"
            :placeholder="placeholder"
            :rules="rulesCurrencyExtended"
            :maxlength="maxlength"
            :disabled="disabled"
            :hint="hint"
            :isReadonly="isReadonly"
            :hideDetails="hideDetails"
            :negativeValue="true"
            @changeValue="value => $emit('changeValueCurrency', value)"
            @validateForm="validateForm"
          >
            <template slot="append-inner-icon">
              <div class="append-icons click d-inline-flex" @click="showHideMenu">
                € <i class="icon-mapit">{{ isOpenMenu ? 5 : 2}}</i>
              </div>
            </template>
          </mapit-input>
        </div>
      </template>
      <v-list>
        <v-list-item @click="changeType('€')">
          <v-list-item-title>€</v-list-item-title>
        </v-list-item>
        <v-list-item @click="changeType('%')">
          <v-list-item-title>%</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { InputTypes } from '@/utils/constants'
import { isNumberEvent, isNumberFloatEvent } from '@/helpers/validations'
import MapitInput from './MapitInput.vue'

export default {
  components: { MapitInput },
  name: 'DiscountField',
  props: [
    'valuePercentage',
    'valueCurrency',
    'uid',
    'hideDetails',
    'isReadonly',
    'placeholder',
    'rulesPercentage',
    'rulesCurrency',
    'maxCurrency',
    'maxlength',
    'disabled',
    'hint'
  ],
  data() {
    return {
      InputTypes,
      appendType: '€',
      isOpenMenu: false,
      widthDiscountInput: 0,
      rulesCurrencyExtended: [
        ...this.rulesCurrency,
        value => !value || !this.maxCurrency || this.maxCurrency && parseFloat(value.replace('.','').replace('-','').replace(',','.')) <= parseFloat(this.maxCurrency) && parseFloat(value.replace('.','').replace('-','').replace(',','.')) >= 0 || this.$t('offer-manager.create.step-vehicle-registration.lbl-error-discount')
      ]
    }
  },
  computed: {
  },
  methods: {
    isNumber: isNumberEvent,
    isNumberFloat: isNumberFloatEvent,
    showHideMenu() {
      this.widthDiscountInput = this.$refs.DiscountField ? this.$refs.DiscountField.clientWidth - 50 : 0 // Is used to move the menu to the right so that it appears below the append-icon
      this.isOpenMenu = !this.isOpenMenu
    },
    changeType(type) {
      this.appendType = type
    },
    validateForm(){
      this.$emit('validateForm')
    }
  }
}
</script>
<style lang="scss">
#discount-field{
  .append-icons{
    z-index: 1;
    color: var(--v-gray-darken1) !important;
  }
  .v-menu__content{
      top: unset !important;
      bottom: -70px;
  }
  //FOCUS STATE
  .v-input--is-focused {
    fieldset {
      border: 1px solid #0075eb !important;
      background-color: var(--v-gray-lighten1) !important;
    }
    input,
    .v-select__selection {
      color: var(--v-gray-darken4) !important;
      font: normal normal normal 15px/25px 'ProximaNovaRegular' !important;
      letter-spacing: 0px;
    }
  }
  //ACTIVE STATE (WITH VALUE)
  .v-input--is-label-active {
    fieldset {
      border: 1px solid var(--v-gray-lighten3);
      background-color: #ecf3ff !important;
    }
    input,
    .v-select__selection {
      color: var(--v-gray-darken4) !important;
      font: normal normal normal 15px/25px 'ProximaNovaSemibold';
      letter-spacing: 0px;
    }
  }
  //NORMAL STATE
  .v-input--is-booted {
    input {
      color: var(--v-gray-darken4) !important;
      font: normal normal normal 15px/25px 'ProximaNovaRegular';
      letter-spacing: 0px;
    }
  }
  //DISABLED
  .v-input--is-disabled {
    fieldset {
      border: 1px solid var(--v-gray-lighten2) !important;
    }
    ::placeholder {
      color: var(--v-gray-lighten3);
    }
  }
  fieldset {
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--v-gray-lighten3);
    height: 45px;
    padding-left: 16px;
    margin: 0 !important;
  }
  .v-select__selections {
    height: 40px;
    padding-top: 0 !important;
  }
  .v-select__selection {
    margin-top: 5px !important;
  }
  .v-text-field__slot,
  .v-input__slot {
    height: 40px;
  }
  ::placeholder {
    text-align: left;
    font: normal normal normal 15px/25px 'ProximaNovaRegular';
    letter-spacing: 0px;
    color: var(--v-gray-darken1);
    opacity: 1;
  }
  .v-input__append-inner {
    margin-top: 8px;
    padding-left: 10px;
  }
  .append-icon-mapit{
    .v-input__icon--append i {
      font-family: 'mapitfont-v1' !important;
      font-size: 24px;
      text-transform: none;
      height: 40px;
      color: var(--v-gray-darken1) !important;
    }
  }
  .v-input__icon--append i{
    font: normal normal normal 15px/25px 'ProximaNovaRegular' !important;
  }
  .v-input__icon--append button {
    font: normal normal normal 15px/25px 'ProximaNovaRegular' !important;
    padding: 0px 10px 0px 10px !important;
  }
  .v-input__icon--clear {
    .v-icon {
      font-family: 'mapitfont-v1' !important;
      text-transform: none;
      height: 40px;
      color: var(--v-gray-darken1) !important;
    }
  }
  .v-text-field__details {
    padding: 0 0 10px 0 !important;
    margin: 0 !important;
    .v-messages__message {
      font: normal normal normal 14px/18px 'ProximaNovaRegular';
      letter-spacing: 0px;
      color: var(--v-gray-darken1);
    }
    .error--text {
      .v-messages__message {
        font: normal normal normal 14px/18px 'ProximaNovaRegular';
        letter-spacing: 0px;
        color: var(--v-primary-base);
      }
    }
  }

  .v-input--radio-group__input {
    justify-content: space-between;
    .v-label {
      color: var(--v-gray-darken1) !important;
    }
  }
  .align-left .v-input--radio-group__input {
    justify-content: start;
  }
  .align-right .v-input--radio-group__input {
    justify-content: end;
  }
  .v-input--selection-controls__ripple {
    &:hover {
      background-color: #0075eb !important;
    }
  }

  //icons default
  .fa-caret-down:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    text-transform: none;
    color: var(--v-gray-darken1) !important;
    content: '\55';
  }
  .fa-caret-up:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\58';
    text-transform: none;
    color: var(--v-gray-darken1) !important;
  }
  .fa-dot-circle:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\52';
    text-transform: none;
    color: #0075eb !important;
  }
  .fa-circle:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\50';
    text-transform: none;
    color: var(--v-gray-darken1) !important;
  }
  //other
  .readonly {
    pointer-events: none;
  }
  .input-right {
    input {
      text-align: right !important;
    }
    ::placeholder {
      text-align: right;
    }
  }
}
</style>
