


























import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import ContactForm from '@/components/contact/ContactForm.vue'
import {OfferType} from '@mapit/honda-offers-builder/dist/types'
import {computed, defineComponent, inject, onMounted} from '@vue/composition-api'
import {mapGetters} from '@/utils/map-store'
import {ContactWithExtras} from '@/types'

export default defineComponent({
  name: 'CustomerData',
  components: {ContactForm, ExpansionPanel},
  props: {
    panelReadonly: Boolean,
    panelHideDivider: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: String,
      default: null,
      required: false
    }
  },
  inject: ['leadId'],
  setup(props, {root, emit}) {
    const leadId = inject('leadId')

    const {
      getUser: user,
      getCountry: country,
      getOfferType: offerType
    } = mapGetters(root.$store.getters)

    const isSubagent = computed(() => {
      return offerType.value === OfferType.subagent || false
    })

    const invalidEmails = computed(() => [user.value.login])

    const contact = computed({
      get: (): ContactWithExtras => root.$store.getters.getOfferContact,
      set: (value: ContactWithExtras) => {
        if (!value?.address?.addressCountry) value.address.addressCountry = country.value
        root.$store.dispatch('prepareOfferContact', value)
      }
    })

    onMounted(() => {
      root.$store.dispatch('clearOfferContact')
    })

    function validateForm() {
      emit('validateForm')
    }

    return {
      user,
      country,
      invalidEmails,
      isSubagent,
      isOfferFromLead: !!leadId,
      contact,
      validateForm
    }
  }
})
