<template>
  <expansion-panel
    uid="expansion-panel-return-feedback-data"
    :title="$t('dealer-bike.return.feedback-data-title')"
    :readonly="false"
  >
    <template v-slot:panel-content>
      <m-form-row :label="`${$t('dealer-bike.return.feedback.lbl-probability')}*`" class="mb-4">
        <mapit-input
          uid="dealerBikeReturnSaleProbability"
          :value="dealerBikeReturnSaleProbability"
          align="left"
          :type="InputTypes.RADIO_BUTTONS"
          :listItems="probabilityList"
          @changeValue="(value) => setDealerBikeReturnSaleProbability(value)"
          :rules="requiredRules"
          hideDetails="auto"
        />
      </m-form-row>

      <m-form-row>
        <template v-slot:custom-label>
          <div class="align-self-start">{{ $t('dealer-bike.return.feedback.lbl-comments') }}</div>
        </template>
        <template v-slot:default>
          <m-textarea
            data-cy="dealerBikeReturnComments"
            v-model="dealerBikeReturnComments"
            maxlength="200"
            rows="4"
            no-resize
            counter
          />
        </template>
      </m-form-row>
    </template>
  </expansion-panel>
</template>

<script>
import ExpansionPanel from '@/components/general/ExpansionPanel'
import MapitInput from '@/components/general/MapitInput'
import {InputTypes} from "@/utils/constants";
import {isRequired} from "@/helpers/validations";
import {ProbabilityOfSale} from "@mapit/dealer-bikes-dynamodb/dist/types";
import { mapGetters, mapMutations } from 'vuex'
import {SET_DEALER_BIKE_RETURN_COMMENTS, SET_DEALER_BIKE_RETURN_SALE_PROBABILITY} from "@/vuex/mutation-types";

export default {
  name: 'FeedbackData',
  components: { ExpansionPanel, MapitInput },
  props: ['showValidations'],
  data() {
    return {
      InputTypes,
      requiredRules: [isRequired],
      probabilityList: Object.values(ProbabilityOfSale).map((item) => {
        return { text: this.$t(`dealer-bike.return.feedback.lbl-probability-${item.toLowerCase()}`), value: item }
      }),
    }
  },
  methods: {
    ...mapMutations({
      'setDealerBikeReturnSaleProbability': SET_DEALER_BIKE_RETURN_SALE_PROBABILITY,
    }),
  },
  computed:{
    dealerBikeReturnComments: {
      ...mapGetters({ get: 'getDealerBikeReturnComments' }),
      ...mapMutations({ set: SET_DEALER_BIKE_RETURN_COMMENTS })
    },
    ...mapGetters({
      dealerBikeReturnSaleProbability: 'getDealerBikeReturnSaleProbability',
    })
  }
}
</script>
