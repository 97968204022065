import Vue from 'vue'
import Vuex from 'vuex'
import registration from './modules/registration'
import offer from './modules/offer'
import ui from './modules/ui'
import stocks from './modules/stocks'
import usersCredential from './modules/usersCredential'
import dealerBike from './modules/dealerBike'
import contacts from './modules/contacts'
import coreApi from '@/api/core.api'
import createPersistedState from 'vuex-persistedstate'
import { CognitoGroups } from '@/utils/constants'
import { Auth } from 'aws-amplify'
import { PermissionsHelper } from '@/utils/permission-helper'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const initialState = {
  user: null,
  cognitoUser: null,
  fullUserName: '',
  group: null,
  token: null,
  loading: false,
  drawer: false,
  userDrawer: false,
  firebase: null,
  firebaseNews: null,
  dealerMid: null,
  userGroups: [],
  permissions: null,
  version: undefined,
}

const state = () => ({ ...initialState })

const getters = {
  getLoading: (state) => state.loading,
  getUser: (state) => state.user,
  getUserGroups: (state) => state.userGroups,
  getToken: (state) => state.token,
  getFirebase: (state) => state.firebase,
  getFirebaseNews: (state) => state.firebaseNews,
  getGroup: (state) => state.group,
  getCapabilities: (state) => state.group?.branchCapabilities,
  getDealerMid: (state) => state.dealerMid,
  getDealerId: (state) => state.group.dealerId,
  getDealerCreatedAt: (state) => state.group.createdAt,
  getDrawerState: (state) => state.drawer,
  getUserDrawerState: (state) => state.userDrawer,
  getCognitoUserName: (state) => state.fullUserName,
  getIdToken: (state) => state.cognitoUser.signInUserSession.idToken.jwtToken || '',
  isStockUser: (state) =>
    state.userGroups.includes(CognitoGroups.StockAdmin) ||
    state.userGroups.includes(CognitoGroups.Admin),
  isImportUser: (state) => state.userGroups.includes(CognitoGroups.Imports),
  isAdmin: (state) => state.userGroups.includes(CognitoGroups.Admin),
  isDealerAdmin: (state) =>
    state.userGroups.includes(CognitoGroups.DealerAdmin) ||
    state.userGroups.includes(CognitoGroups.Admin),
  isServicePartner: (state) => state.userGroups.includes(CognitoGroups.ServicePartner),
  isGarageManager: (state) =>
    state.userGroups.includes(CognitoGroups.GarageManager) ||
    state.userGroups.includes(CognitoGroups.Admin),
  isSalesAgent: (state) =>
    state.userGroups.includes(CognitoGroups.SalesAgent) ||
    state.userGroups.includes(CognitoGroups.Admin),
  getPermissions: (state) => state.permissions,
  getCountry: (state) => state.group?.country,
  getLocale: (state) => state.group?.locale.replace('_', '-'),
  getBranch: (state) => state.group?.branch,
  getAppVersion: (state) => state.version,
}

const actions = {
  setFirebase({ commit }, firebase) {
    commit('SET_FIREBASE', firebase)
  },
  setFirebaseNews({ commit }, firebase) {
    commit('SET_FIREBASE_NEWS', firebase)
  },
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading)
  },
  setDrawerState({ commit }, drawer) {
    commit('SET_DRAWER', drawer)
  },
  setUserDrawerState({ commit }, userDrawer) {
    commit('SET_USER_DRAWER', userDrawer)
  },
  async logout({ commit }) {
    return Auth.signOut().then(() => {
      commit('RESET_STORE')
      commit('SET_USER_DRAWER', false)
      commit('CLEAR_STORAGE')
    })
  },
  async loadDealer({ commit, state, dispatch }) {
    const { data: dealer } = await coreApi.getDealer(
      state.user.dealerId ? state.user.dealerId : state.user.groupId,
    )

    const group = {
      ...dealer,
      dealerId: dealer.id,
    }
    commit('SET_GROUP', group)
    commit('SET_DEALER_MID', group.dealerId)
    await dispatch('calculatePermissions')
  },
  calculatePermissions: ({ commit, state }) => {
    commit(
      'SET_PERMISSIONS',
      new PermissionsHelper(state.cognitoUser, state.group).calculatePermissions(),
    )
  },
  setAppVersion({ commit }, version) {
    commit('SET_APP_VERSION', version)
  },
  resetStore({ commit }) {
    commit('RESET_STORE')
  },
}

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_COGNITO_USER(state, cognitoUser) {
    state.cognitoUser = cognitoUser
    state.fullUserName =
      cognitoUser && cognitoUser.attributes
        ? `${cognitoUser.attributes.given_name} ${cognitoUser.attributes.family_name}`
        : ''
  },
  SET_USER_GROUPS(state, groups) {
    state.userGroups = groups
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_FIREBASE(state, firebase) {
    state.firebase = firebase
  },
  SET_FIREBASE_NEWS(state, firebaseNews) {
    state.firebaseNews = firebaseNews
  },
  SET_DEALER_MID: (state, mid) => {
    state.dealerMid = mid
  },
  SET_GROUP(state, group) {
    state.group = group
  },
  SET_DRAWER(state, drawer) {
    state.drawer = drawer
  },
  SET_USER_DRAWER(state, userDrawer) {
    state.userDrawer = userDrawer
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_APP_VERSION: (state, version) => {
    state.version = version
  },
  RESET_STORE(state) {
    Object.keys(initialState).forEach((key) => {
      state[key] = initialState[key]
    })
  },
  CLEAR_STORAGE() {
    localStorage.clear()
    sessionStorage.clear()
  },
}

const dataState = createPersistedState({
  paths: [
    'dealerMid',
    'token',
    'user',
    'drawer',
    'ui.subagentSuggestions',
    'offer.attendedBySuggestions',
    'offer.vehicleModelsOptions',
    'offer.campaignSuggestions',
    'offer.complementSuggestions',
  ],
})

const store = new Vuex.Store({
  modules: {
    registration,
    ui,
    offer,
    stocks,
    usersCredential,
    dealerBike,
    contacts,
  },
  state,
  getters,
  actions,
  mutations,
  strict: debug,
  plugins: [dataState],
})

export default store
