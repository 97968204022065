





























































































import { computed, defineComponent, nextTick, ref, watch } from '@vue/composition-api'
import { DateTime } from 'luxon'

import { attendedByRules, offerTypeRules } from '@/helpers/offer-manager-rules'
import { getDatePattern, DATE_FORMAT_ISO } from '@/utils/date-helper'
import { InputTypes } from '@/utils/constants'
import { mapState } from '@/utils/map-store'
import { toCapitalize } from '@/utils/utils'
import { OfferType, offerTypes } from '@/types/offers'

import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import MapitInput from '@/components/general/MapitInput.vue'

export default defineComponent({
  name: 'OfferData',
  components: { ExpansionPanel, MapitInput, MapitDialog },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    panelReadonly: {
      type: Boolean,
      default: false,
    },
    panelHideDivider: {
      type: Boolean,
      default: false,
    },
    disableAttach: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const infoVariant = ref('')
    const dialog = ref(false)
    const datePickerRefreshKey = ref(0)
    const offerTypeSelected = ref('')

    const { permissions } = mapState(root.$store.state)
    const {
      getLocale: locale,
      getAttendedBy: attendedBy,
      getSubagentName: subagentName,
      getSubagentSuggestions: subagentSuggestions,
      getOfferId: offerId,
      getOfferType: offerType,
      isOfferLoading,
    } = mapState(root.$store.getters)

    const createDate = computed({
      // TODO: replace on change vue3
      get: (): string | undefined => root.$store.getters.getCreateDate,
      set: (value: string | undefined) => root.$store.commit('SET_CREATE_DATE', value),
    })

    const createDateDisplay = computed(() => {
      return (!offerId.value && isSubagentOffer.value) || offerId.value
    })

    const createDateRules = computed(() => {
      const today = DateTime.now()

      return {
        max: today.plus({ years: 1 }).toFormat(DATE_FORMAT_ISO),
        min: '1900-01-01',
      }
    })

    const createDatePlaceholder = computed(() => {
      return getDatePattern(locale.value)
    })

    const isSubagentOffer = computed(() => {
      return (
        permissions.value.supportsSubagent &&
        (offerTypeSelected.value === OfferType.subagent || offerType.value === OfferType.subagent)
      )
    })

    const offerTypeText = computed(() => {
      return getOfferTypeText(offerType.value)
    })

    const offerTypesOptions = computed(() => {
      return offerTypes
        .map((offerType) => {
          if (offerType === OfferType.subagent && permissions.value.supportsSubagent === false) {
            return
          }

          return {
            text: getOfferTypeText(offerType),
            value: offerType,
          }
        })
        .filter((option) => option)
    })

    watch(createDate, () => {
      nextTick(() => emit('validateForm')) // nextTick fix error validate async on use "picker"
    })

    watch(isOfferLoading, () => {
      datePickerRefreshKey.value = datePickerRefreshKey.value + 1
    })

    watch(offerTypeSelected, () => {
      root.$store.dispatch('setOfferType', offerTypeSelected.value)
    })

    function updateOffer(action: string, value: any) {
      root.$store.dispatch(action, value)
      emit('validateForm')
    }

    function openCloseInfoDialog(variant: string) {
      dialog.value = !dialog.value
      infoVariant.value = variant
    }

    function getOfferTypeText(offerType: string) {
      return root.$t(`offer-manager.create.step-offer-data.offer-types.${offerType}`)
    }

    return {
      attendedBy,
      attendedByRules,
      createDate,
      createDateDisplay,
      createDatePlaceholder,
      createDateRules,
      datePickerRefreshKey,
      dialog,
      infoVariant,
      InputTypes,
      isSubagentOffer,
      locale,
      offerId,
      offerType,
      offerTypeRules,
      offerTypeSelected,
      offerTypesOptions,
      offerTypeText,
      openCloseInfoDialog,
      permissions,
      subagentName,
      subagentSuggestions,
      toCapitalize,
      updateOffer,
    }
  },
})
