<template>
  <v-app class="users-manager-wrapper pb-10" id="users-manager">
    <v-card class="users-manager-card-wrapper">
      <v-card-title>
        <toolbar-card :hideCloseBtn="true" :title="$t('users-manager.title')" />
      </v-card-title>
      <v-divider class="stepper-separator mx-6 m" />
      <v-card-text class="px-6">
        <v-row class="pt-6">
          <v-col class="col-6">
            <mapit-input
              uid="usersManagerSearchFilter"
              :value="search"
              :placeholder="$t('users-manager.lbl-search')"
              @changeValue="(value) => (search = value)"
              :clearable="true"
            />
          </v-col>
          <v-spacer/>
          <v-col class="col-6 col-md-4 col-lg-3 col-xl-2">
            <m-btn
              variant="outlined"
              color="primary"
              class="truncate full-width"
              data-cy="create-user-btn"
              @click="create()"
              :loading="isLoading"
              :disabled="isLoading"
            >
              <template>
                <v-icon class="icon-mapit mr-1">&#xe006;</v-icon>
                {{ $t('users-manager.create-user') }}
              </template>
            </m-btn>
          </v-col>
        </v-row>
        <v-data-table
          id="users-table-list"
          :loading="isLoading"
          :loading-text="$t('users-manager.loading')"
          :headers="headers"
          :items="filteredUsers"
          :items-per-page="25"
          class="elevation-0 pt-4"
          sort-by="email"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            'items-per-page-text': $t('users-manager.footer-num-x-page'),
            'items-per-page-options': [10, 25, 50, -1],
          }"
          :no-data-text="
            $t(
              users && filteredUsers.length > 0
                ? 'users-manager.no-data-search'
                : 'users-manager.no-data',
              { search },
            )
          "
          :no-results-text="$t('users-manager.no-data-search')"
        >
          <template #footer.page-text="props">
            {{ props.pageStart }}-{{ props.pageStop }} {{ $t('common.of') }}
            {{ props.itemsLength }}
          </template>

          <template v-slot:item.email="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="
                    (getFullName(item).length >= 30 ||
                      (item.email && item.email.length >= 30)) &&
                    on
                  "
                  class="customer py-3 lbl-registration-bold"
                >
                  {{
                    getFullName(item).trim()
                      ? trimName(getFullName(item), 30)
                      : item.email
                  }}<br />
                  <span
                    class="customer-email"
                    v-if="getFullName(item).trim()"
                    >{{ item.email }}</span
                  >
                </div>
              </template>
              <span>{{ getFullName(item) }}<br />{{ item.email }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.status="{ item }">
            <span :data-cy="`item-status-${item.id}`">{{
              $t(
                `users-manager.lbl-status.${item.status
                  .toLowerCase()
                  .replaceAll('_', '-')}`,
              )
            }}</span>
          </template>
          <template v-slot:item.groups="{ item }">
            <v-tooltip bottom v-for="role in item.groups" :key="role" :data-cy="`item-roles-${item.id}-${role}`">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  :class="`ma-1 role-text lbl-registration-bold ${
                    chipStyles(role).style
                  }`"
                  :color="chipStyles(role).color"
                  small
                  label
                  v-bind="attrs"
                  v-on="on"
                  >
                  <div class="mx-2"> {{
                    $t(
                      `users-manager.lbl-roles.${role
                        .toLowerCase()
                        .replaceAll('_', '-')}-short`,
                    )
                  }}</div>
                </v-chip>
              </template>
              <span>{{
                $t(
                  `users-manager.lbl-roles.${role
                    .toLowerCase()
                    .replaceAll('_', '-')}`,
                )
              }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.enabled="{ item }">
              <i :class="`icon-mapit ${item.enabled ? 'secondary--text' : 'base--text'}`">&#xe03d;</i>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu transition="slide-x-transition" bottom right>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon class="icon-mapit" :data-cy="`menu-${item.id}`">)</v-icon>
                </div>
              </template>

              <v-list dense class="list-menu-actions">
                <v-list-item v-if="item.status === 'PENDING_APPROVAL'" @click="sendApprovalEmail(item)">
                  <v-list-item-title data-cy="menu-resend-approval-btn">
                    {{ $t('users-manager.lbl-resend-approval') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="edit(item)">
                  <v-list-item-title data-cy="menu-edit-btn">
                    {{ $t('users-manager.lbl-edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="cancel(item)" :disabled="user.login === item.email" >
                  <v-list-item-title>
                    {{ $t('users-manager.lbl-delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <mapit-dialog
      :dialog="dialogManageUser"
      @close="openCloseDialogManageUser()"
      :key="buttonKey"
      custom-id="create-user-dialog"
      custom-width="680px"
    >
      <template v-slot:header-text>
        <div class="text-left">
          {{
            $t(`users-manager.lbl-dialog-${editMode ? 'edit' : 'create'}-title`)
          }}
        </div>
      </template>
      <template v-slot:body>
        <v-form v-model="manageUserFormValid">
        <m-form-row class="equal-size" :label="`${$t('users-manager.lbl-dialog-email')}*`">
          <m-email-field v-model="email"
           data-cy="user-email"
           :disabled="editMode"
           :maxlength="100"
           required
          />
        </m-form-row>
        <m-form-row class="equal-size" :label="`${$t('users-manager.lbl-dialog-first-name')}*`">
          <m-text-field
            data-cy="user-first-name"
            v-model="firstName"
            :placeholder="$t('users-manager.lbl-dialog-first-name-placeholder')"
            :maxlength="50"
            required
          />
        </m-form-row>
        <m-form-row class="equal-size" :label="`${$t('users-manager.lbl-dialog-last-name')}*`">
          <m-text-field
            v-model="lastName"
            :placeholder="$t('users-manager.lbl-dialog-last-name-placeholder')"
            :maxlength="50"
            required
          />
        </m-form-row>

        <m-form-row>
          <template v-slot:custom-label>
            <div class="d-flex align-center text-left">
              {{ $t('users-manager.lbl-dialog-roles') }}*
            </div>
          </template>
          <template v-slot:default>
            <mapit-input v-if="!isServicePartner && user.login !== email"
              uid="dialogUserCredentialAdminCheckbox"
              :value="roleDealerAdmin"
              :type="InputTypes.CHECKBOX"
              @changeValue="(value) => (roleDealerAdmin = value)"
              :hideDetails="true"
            >
              <template v-slot:label>
                {{ $t('users-manager.lbl-dialog-roles-dealer-admin') }}
              </template>
            </mapit-input>
            <mapit-input
              uid="dialogUserCredentialCommercialCheckbox"
              :value="roleSalesAgent"
              :type="InputTypes.CHECKBOX"
              @changeValue="(value) => (roleSalesAgent = value)"
              :hideDetails="true"
            >
              <template v-slot:label>
                {{ $t('users-manager.lbl-dialog-roles-sales-agent') }}
              </template>
            </mapit-input>
            <mapit-input
              uid="dialogUserCredentialAdminCheckbox"
              :value="roleGarageManager"
              :type="InputTypes.CHECKBOX"
              @changeValue="(value) => (roleGarageManager = value)"
              :hideDetails="true"
            >
              <template v-slot:label>
                {{ $t('users-manager.lbl-dialog-roles-garage-manager') }}
              </template>
            </mapit-input>
          </template>
        </m-form-row>

        <m-form-row v-if="editMode && user.login !== email">
          <template v-slot:custom-label>
            <div class="d-flex align-center text-left"></div>
          </template>
          <template v-slot:default>
            <v-switch
              inset
              v-model="enabled"
              :disabled="!canEditStatus"
              class="px-1"
              :label="
                $t(
                  enabled
                    ? 'users-manager.lbl-dialog-status-enabled'
                    : 'users-manager.lbl-dialog-status-disabled',
                )
              "
            ></v-switch>
          </template>
        </m-form-row>

        <div class="align-center pt-4 text-error" v-if="error">
          {{ $t('users-manager.lbl-main-error') }}
        </div>
        </v-form>
      </template>
      <template v-slot:footer-center>
        <v-btn
          :loading="isLoading"
          :disabled="isLoading"
          class="btn-gray"
          @click="openCloseDialogManageUser()"
          tabindex="-1"
        >
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-btn
          data-cy="confirm-btn"
          :key="buttonKey"
          :loading="isLoading"
          :disabled="
            isLoading ||
            !manageUserFormValid ||
            (!roleDealerAdmin && !roleSalesAgent && !roleGarageManager)
          "
          class="btn-primary"
          @click.once="!editMode ? postUser() : patchUser()"
        >
          {{ $t('buttons.confirm') }}
        </v-btn>
      </template>
    </mapit-dialog>

    <mapit-dialog
      :dialog="dialogConfirm"
      @close="openCloseDialogConfirm()"
    >
      <template v-slot:header-icon>
        <v-icon class="text-secondary">fa fa-check</v-icon>
      </template>
      <template v-slot:body>
        <div class="text-center" v-html="$t(`users-manager.lbl-dialog-confirm-delete`, {email})"></div>
      </template>
      <template v-slot:footer-center>
        <v-btn
          :loading="isLoading"
          :disabled="isLoading"
          class="btn-gray"
          @click="openCloseDialogConfirm()"
        >
          {{ $t('buttons.no') }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          :disabled="isLoading"
          class="btn-primary"
          :key="buttonKey"
          @click.once="deleteUser()"
        >
          {{ $t('buttons.yes') }}
        </v-btn>
      </template>
    </mapit-dialog>
  </v-app>
</template>

<script>
import ToolbarCard from '@/components/registrations/HondaRegistration/ToolbarCard'
import MapitInput from '@/components/general/MapitInput.vue'
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import { InputTypes, CognitoGroups } from '@/utils/constants'
import coreApi from '@/api/core.api'
import {mapActions} from "vuex";
import {toCapitalize} from "@/helpers/utils"
import Vue from 'vue'

export default {
  name: 'UsersManager',
  components: { ToolbarCard, MapitInput, MapitDialog },
  props: [],
  data() {
    return {
      InputTypes,
      search: '',
      loading: false,
      buttonKey: 1,
      headers: [
        {
          text: this.$t('users-manager.lbl-title-email'),
          align: 'left',
          value: 'email',
          class: 'text-header',
          width: '30%',
          sortable: true,
        },
        {
          text: this.$t('users-manager.lbl-title-status'),
          align: 'left',
          value: 'status',
          class: 'text-header',
          width: '30%',
          sortable: true,
        },
        {
          text: this.$t('users-manager.lbl-title-roles'),
          align: 'left',
          value: 'groups',
          class: 'text-header',
          width: '15%',
          sortable: false,
        },
        {
          text: this.$t('users-manager.lbl-title-activated'),
          align: 'center',
          value: 'enabled',
          class: 'text-header',
          width: '15%',
          sortable: false,
        },
        {
          align: 'center',
          value: 'actions',
          class: 'text-header',
          width: '10%',
          sortable: false,
        },
      ],
      dialogInfoRoles: false,
      dialogManageUser: false,
      dialogConfirm: false,
      editMode: false,
      editUserId: null,
      email: '',
      firstName: '',
      lastName: '',
      enabled: false,
      roleDealerAdmin: false,
      roleGarageManager: false,
      roleSalesAgent: false,
      selectedUser: null,
      headersPermissions: [
        {
          text: this.$t('users-manager.lbl-header-permission-name'),
          align: 'left',
          value: 'name',
          class: 'text-header',
          width: '47.5%',
          sortable: false,
        },
        {
          text: this.$t('users-manager.lbl-header-dealer-admin'),
          align: 'center',
          value: 'admin',
          class: 'text-header',
          width: '18.5%',
          sortable: false,
        },
        {
          text: this.$t('users-manager.lbl-header-sales-agent'),
          align: 'center',
          value: 'sales',
          class: 'text-header',
          width: '18.5%',
          sortable: false,
        },
        {
          text: this.$t('users-manager.lbl-header-garage-manager'),
          align: 'center',
          value: 'garage',
          class: 'text-header',
          width: '18.5%',
          sortable: false,
        },
      ],
      permissionsList: [
        {
          name: this.$t('users-manager.lbl-roles.info-permission-1'),
          admin: true,
          sales: true,
          garage: false,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-2'),
          admin: true,
          sales: false,
          garage: true,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-3'),
          admin: true,
          sales: true,
          garage: false,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-4'),
          admin: true,
          sales: true,
          garage: true,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-5'),
          admin: true,
          sales: false,
          garage: false,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-6'),
          admin: true,
          sales: true,
          garage: false,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-7'),
          admin: true,
          sales: true,
          garage: true,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-8'),
          admin: true,
          sales: false,
          garage: false,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-9'),
          admin: true,
          sales: false,
          garage: true,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-10'),
          admin: true,
          sales: false,
          garage: false,
        },
        {
          name: this.$t('users-manager.lbl-roles.info-permission-11'),
          admin: true,
          sales: false,
          garage: false,
        },
      ],
      error: false,
      manageUserFormValid: false
    }
  },
  computed: {
    isServicePartner() {
      return this.$store.getters.isServicePartner
    },
    user() {
      return this.$store.getters.getUser
    },
    canEditStatus() {
      return this.selectedUser?.status !== 'PENDING_APPROVAL'
    },
    isLoading(){
      return this.loading || this.$store.getters.getLoadingUsersCredentialList
    },
    group() {
      return this.$store.getters.getGroup
    },
    users() {
      return this.$store.getters.getUserCredential
    },
    filteredUsers() {
      return this.users
        ? this.users.filter((item) =>
          this.search
            ? item.email?.toLowerCase().includes(this.search.toLowerCase()) ||
            (item.firstName?.toLowerCase().includes(this.search.toLowerCase())) ||
            (item.lastName?.toLowerCase().includes(this.search.toLowerCase()))
            : true,
        )
        : []
    },
  },
  watch: {
    firstName(val) {
      this.firstName = val && toCapitalize(val)
    },
    lastName(val) {
      this.lastName = val && toCapitalize(val)
    },
  },
  created() {
    this.refreshUsersList()
  },
  methods: {
    ...mapActions([
      'resendApprovalEmail'
    ]),
    cleanForms() {
      this.error = null
      this.selectedItem = null
      this.editMode = false
      this.editUserId = null
      this.email = null
      this.firstName = ''
      this.lastName = ''
      this.enabled = false
      this.roleDealerAdmin = false
      this.roleGarageManager = false
      this.roleSalesAgent = false
      this.buttonKey++
    },
    openCloseDialogConfirm(){
      this.dialogConfirm = !this.dialogConfirm
    },
    openInfoRoles() {
      this.dialogInfoRoles = !this.dialogInfoRoles
    },
    create() {
      this.cleanForms()
      this.openCloseDialogManageUser()
    },
    edit(selectedItem) {
      this.cleanForms()
      this.selectedUser = selectedItem
      this.email = selectedItem.email
      this.firstName = selectedItem.firstName || ''
      this.lastName = selectedItem.lastName || ''
      this.editMode = true
      this.editUserId = selectedItem.id
      this.enabled = selectedItem.enabled
      if (selectedItem.groups.includes(CognitoGroups.DealerAdmin))
        this.roleDealerAdmin = true
      if (selectedItem.groups.includes(CognitoGroups.SalesAgent))
        this.roleSalesAgent = true
      if (selectedItem.groups.includes(CognitoGroups.GarageManager))
        this.roleGarageManager = true
      this.openCloseDialogManageUser()
    },
    cancel(selectedItem) {
      if(selectedItem.email !== this.user.login){
        this.openCloseDialogConfirm()
        this.editUserId = selectedItem.id
        this.email = selectedItem.email
      }
    },
    sendApprovalEmail(selectedItem) {
      this.resendApprovalEmail({
        userId: selectedItem.id,
        dealerId: this.group.dealerId
      })
    },
    postUser() {
      this.loading = true
      let groups = []
      if (this.roleDealerAdmin) groups.push(CognitoGroups.DealerAdmin)
      if (this.roleSalesAgent) groups.push(CognitoGroups.SalesAgent)
      if (this.roleGarageManager) groups.push(CognitoGroups.GarageManager)
      if (
        !this.email ||
        !this.firstName ||
        !this.lastName ||
        groups.length === 0
      ) {
        this.buttonKey++
      } else {
        coreApi
          .postUserCredential(this.group.dealerId, {
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            groups: groups,
          })
          .then(() => {
            this.cleanForms()
            this.openCloseDialogManageUser()
            this.refreshUsersList()
            this.$store.dispatch('showAlertSuccess', 'users-manager.lbl-user-created')
          })
          .catch((error) => {
            this.error = true
            console.error(error)
          })
          .finally(() => {
            this.loading = false
            this.buttonKey++
          })
      }
    },
    patchUser() {
      this.loading = true
      let groups = []
      if (this.roleDealerAdmin) groups.push(CognitoGroups.DealerAdmin)
      if (this.roleSalesAgent) groups.push(CognitoGroups.SalesAgent)
      if (this.roleGarageManager) groups.push(CognitoGroups.GarageManager)
      if (
        !this.email ||
        !this.firstName ||
        !this.lastName ||
        groups.length === 0
      ) {
        this.loading = false
        this.buttonKey++
      } else {
        coreApi
          .patchUserCredential(this.group.dealerId, this.editUserId, {
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            groups: groups,
            enabled: this.enabled,
          })
          .then(() => {
            this.cleanForms()
            this.openCloseDialogManageUser()
            this.refreshUsersList()
            this.$store.dispatch("showAlertSuccess", 'users-manager.lbl-user-updated')
          })
          .catch((error) => {
            this.error = true
            console.error(error)
          })
          .finally(() => {
            this.loading = false
            this.buttonKey++
          })
      }
    },
    deleteUser() {
      this.loading = true
      coreApi
        .deleteUserCredential(this.group.dealerId, this.editUserId)
        .then(() => {
          this.$store.dispatch("showAlertSuccess", 'users-manager.lbl-user-deleted')
        })
        .catch((error) => {
          this.$store.dispatch("showAlertError", 'users-manager.lbl-main-error')
          console.error(error)
        })
        .finally(() => {
          this.refreshUsersList()
          this.cleanForms()
          this.openCloseDialogConfirm()
          this.loading = false
          this.buttonKey++
        })
    },
    openCloseDialogManageUser() {
      this.dialogManageUser = !this.dialogManageUser
      this.buttonKey++
    },
    trimName(name, max) {
      return name.length >= max ? name.substring(0, max) + '...' : name
    },
    getFullName(item) {
      return `${item.firstName || ''} ${item.lastName || ''}`
    },
    chipStyles(role) {
      switch (role) {
        case CognitoGroups.Admin:
          return {
            color: '#B3E5FC',
            style: 'color-black',
          }
        case CognitoGroups.DealerAdmin:
          return {
            color: '#FFE0B2',
            style: 'color-black',
          }
        case CognitoGroups.GarageManager:
          return {
            color: '#C5CAE9',
            style: 'color-black',
          }
        case CognitoGroups.Imports:
          return {
            color: '#FFCDD2',
            style: 'color-black',
          }
        case CognitoGroups.StockAdmin:
          return {
            color: '#E1BEE7',
            style: 'color-black',
          }
        case CognitoGroups.ServicePartner:
          return {
            color: '#FFECB3',
            style: 'color-black',
          }
        case CognitoGroups.SalesAgent:
          return {
            color: '#DCEDC8',
            style: 'color-black',
          }
        default:
          return {
            color: '#FFCCBC',
            style: 'color-black',
          }
      }
    },
    refreshUsersList() {
      this.$store.dispatch('loadUsersCredentialList', this.group.dealerId)
    },
  },
}
</script>

<style lang="scss" src="./users-manager-styles.scss" />
<style lang="scss">
[data-cy='create-user-dialog'] {
  .equal-size {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .form-row-label {
      height: 40px;
    }
  }

  .form-row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
