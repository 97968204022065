<template>
  <div v-if="hasRows">
  <vuetable ref="vuetable"
            :id="tableId"
            :api-mode=false
            :data="rows"
            :fields="fields"
            :css='css.table'
            pagination-path="pagination"
            :per-page='perPage'
            :row-class="onRowClass"
            @vuetable:field-event="onFieldEvent"
  ></vuetable>
      <div style="padding-top:10px">
          <vuetable-pagination ref="pagination"></vuetable-pagination>
      </div>
  </div>
  <div v-else class="m-t-40" style="background-color: lightgray">No hay elementos</div>
</template>

<script>
  import Vue from 'vue';
  import Vuetable from 'vuetable-2';
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import getComponent from '../tablecolumns/index';

  const prepareCssTable = () => {
    return {
      table: {
        tableWrapper: '',
        tableHeaderClass: 'mb-0',
        tableBodyClass: 'tr-body ',
        tableClass: 'table table-scroll table-bordered custom-table col-12',
        loadingClass: 'loading',
        ascendingIcon: 'fa fa-chevron-up',
        descendingIcon: 'fa fa-chevron-down',
        ascendingClass: 'sorted-asc',
        descendingClass: 'sorted-desc',
        sortableIcon: 'fa fa-sort',
        detailRowClass: 'vuetable-detail-row',
        handleIcon: 'fa fa-bars text-secondary',
        renderIcon(classes, options) {
          return `<i class="${classes.join(' ')}"></span>`

        }
      }
    }
  }

  const prepareTableHeaders = (headers) => {
    return headers.map((header ,index)=>{
      return {
        name: getComponent(header.component),
        title: header.caption,
        titleClass: header.class,
        dataClass: header.class2,
      }
    });
  }

  export default {
    components: {
      Vuetable,
      VuetablePagination
    },
    methods: {
      onFieldEvent(...args) {
        this.$emit("openModal", ...args);
      },
      onRowClass(dataItem, index) {
        return (dataItem.id) ? `row-id-${dataItem.id}` : ''
      }
    },
    props: ['headers', 'rows', 'pagination', 'id'],
    data(){
      return {
        fields: prepareTableHeaders(this.headers),
        css: prepareCssTable(),
        perPage: 10,
        tableId: this.id
      }
    },
    computed: {
      hasRows() {
        return this.rows && this.rows.length>0
      },
    },

  }
</script>
