<template>
  <v-form
    id="dealer-bike-registration-form"
    ref="DealerBikeRegistrationForm"
    v-model="validForm"
    v-focusNextOnEnter
  >
    <v-card elevation="5" class="mx-0 mx-md-12 px-0 px-md-6 py-6">
      <v-expansion-panels v-model="panels" multiple flat>
        <vehicle-data :showValidations="showValidations" description-list />
        <mapit-data :showValidations="showValidations" />
      </v-expansion-panels>
    </v-card>

    <v-card-actions class="px-12 pt-10 pb-6">
      <div class="my-spacer"></div>
      <v-btn
        :disabled="!isValidForm || loading"
        :key="buttonKey"
        :loading="loading"
        @click="saveDealerBike"
        class="btn-primary"
        data-cy="btnCreateOrEditDealerBike"
      >
        {{ $t('buttons.confirm-data') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SET_DEALER_BIKE_REGISTRATION_PANELS } from '@/vuex/mutation-types'

import DealerBikesApi from '@/api/dealerBikes.api'
import VehicleData from './sections/VehicleData'
import MapitData from './sections/MapitData.vue'

export default {
  name: 'DealerBikesRegistration',
  components: { VehicleData, MapitData },
  data() {
    return {
      showValidations: false,
      loading: false,
      validForm: false,
      buttonKey: 1,
    }
  },
  methods: {
    ...mapActions([
      'loadDealerBike',
      'cleanDealerBike',
      'setAlertResult',
      'loadVehicleByRegistrationNumber',
    ]),
    handleError() {
      this.showValidations = true
      this.loading = false
      this.buttonKey++
      this.$root.$emit('validateMapitCode') // force validate mapit input
    },
    async saveDealerBike() {
      this.loading = true

      if (!this.isValidForm) {
        this.handleError()
        return
      }

      const isRegistrationNumberFree = await this.isRegistrationNumberFree(
        this.getRequestDealerBikeData.vehicle.registrationNumber,
      )
      if (!isRegistrationNumberFree) {
        this.handleError()
        return
      }

      try {
        await DealerBikesApi.createDealerBike(this.getRequestDealerBikeData)
        await DealerBikesApi.deleteDealerBike(this.dealerBikeSelected.id) // delete candidate bike

        this.setAlertResult({
          type: 'success',
          stringId: 'dealer-bike.registration.alert-create-success',
          open: true,
        })

        this.$router.push({ name: 'DealerBikesList' })
      } catch (err) {
        let errorMessage = 'dealer-bike.registration.alert-create-error'

        if (err.response?.data.code === 'RegistrationNumberDuplicated') {
          errorMessage = 'dealer-bike.registration.free-plate-error'
        }

        this.setAlertResult({
          type: 'error',
          stringId: errorMessage,
          open: true,
        })

        this.handleError()
      }
    },
    async isRegistrationNumberFree(registrationNumber) {
      if (this.isMigration === false) {
        try {
          const bikeWithPlate = await this.loadVehicleByRegistrationNumber(registrationNumber)
          if (bikeWithPlate.data.data.length > 0) {
            this.setAlertResult({
              type: 'error',
              stringId: 'dealer-bike.registration.free-plate-error',
              open: true,
            })
            this.loading = false
            return false
          } else {
            return true
          }
        } catch (err) {
          console.error('Error isRegistrationNumberFree:', err)
          return false
        }
      }
      this.loading = false
      return true
    },
  },
  computed: {
    ...mapGetters({
      dealerBikeSelected: 'getDealerBikeSelected',
      getRequestDealerBikeData: 'getRequestDealerBikeData',
      isMigration: 'isMigrationDealerBike',
      isValidDealerBikeMapit: 'isValidDealerBikeMapit',
    }),
    isValidForm() {
      return this.validForm && this.isValidDealerBikeMapit
    },
    panels: {
      ...mapGetters({ get: 'getDealerBikeRegistrationPanels' }),
      ...mapMutations({ set: SET_DEALER_BIKE_REGISTRATION_PANELS }),
    },
  },
  created() {
    if (this.$route.params.dealerBikeId) {
      this.loadDealerBike(this.$route.params.dealerBikeId)
    } else {
      this.cleanDealerBike()
    }
  },
}
</script>
