

























































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { Country, CurrencyOptions } from '@mapit/common-utils/dist/types'
import { findSecondLevelRegionById } from '@/utils/administrative-region-helper'
import { LeadStatus, Product } from '@mapit/leads-dynamodb/dist/types'
import { mapGetters } from '@/utils/map-store'
import { Status } from '@mapit/honda-offers-builder/dist/types'
import { useLead, useOffer } from '@/composables'

import CardWrapper from '@/components/general/CardWrapper.vue'
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import LeadDetailCard from '@/components/leads/LeadDetailCard.vue'
import MapitDialog from '@/components/dialog/MapitDialog.vue'

export default defineComponent({
  name: 'LeadDetail',
  components: { CardWrapper, LeadDetailCard, ExpansionPanel, MapitDialog },
  setup(props, { root }) {
    const { id } = root.$route.params
    const { getCountry, getGroup: group } = mapGetters(root.$store.getters)

    const currencyOptions = CurrencyOptions[getCountry.value as Country]

    const panels = ref([0, 1])
    const dialog = ref<boolean>(false)

    const {
      commentLog,
      data: lead,
      isCommentLogLoading,
      isLoading,
      lastUpdatedByMe,
      markAsRead,
      maskLeadAsLost,
    } = useLead({ id })

    const offerId = computed(() => {
      return lead.value?.offerId || undefined
    })

    const dealerId = computed(() => lead.value?.dealer.id)

    const {
      data: offer,
      offerPdf,
      refetch: refetchOffer,
      isLoading: isOfferLoading,
    } = useOffer({ id: offerId, dealerId })

    const clientData = computed(() => {
      return lead.value?.prospect
    })

    const isNewStatus = computed(() => {
      return lead.value?.status === LeadStatus.New
    })

    const isLost = computed(() => lead.value?.status === LeadStatus.Lost)
    const leadCountry = computed(
      () => (lead.value?.prospect.address?.country as Country) || (getCountry.value as Country),
    )

    const offerTitle = computed(() => {
      return offer.value?.status === Status.Completed || offer.value?.status === Status.Accepted
        ? offer.value?.code?.split('-')[1]
        : root.$t('leads.detail.offer-title')
    })

    const offerFileName = computed(() => {
      return offer.value?.status === Status.Completed || offer.value?.status === Status.Accepted
        ? `${offer.value?.code}.pdf`
        : `${group.value.code}-${offer.value?.id}.pdf`
    })

    const clientItems = computed(() => {
      return [
        {
          id: 'name',
          type: 'text',
          text: root.$t('leads.detail.client.name'),
          value:
            clientData.value?.firstName && clientData.value?.lastName
              ? `${clientData.value?.firstName} ${clientData.value?.lastName}`
              : undefined,
        },
        {
          id: 'email',
          type: 'text',
          text: root.$t('leads.detail.client.email'),
          value: clientData.value?.email || undefined,
        },
        {
          id: 'phone',
          type: 'phone',
          text: root.$t('leads.detail.client.phone'),
          value: clientData.value?.phone || undefined,
        },
        {
          id: 'address',
          type: 'text',
          text: root.$t('leads.detail.client.address'),
          value:
            clientData.value?.address?.streetAddress && clientData.value?.address?.addressNumber
              ? `${clientData.value?.address?.streetAddress}, ${clientData.value?.address?.addressNumber}`
              : undefined,
        },
        {
          id: 'postal-code',
          type: 'text',
          text: root.$t('leads.detail.client.postal-code'),
          value: clientData.value?.address?.postalCode || undefined,
        },
        {
          id: 'city',
          type: 'text',
          text: root.$t('leads.detail.client.city'),
          value: clientData.value?.address?.addressLocality || undefined,
        },
        {
          id: 'region',
          type: 'text',
          text: root.$t('leads.detail.client.region'),
          value: findSecondLevelRegionById(
            clientData.value?.address?.addressRegion as string,
            clientData.value?.address?.country as Country,
          )?.name,
        },
        {
          id: 'country',
          type: 'text',
          text: root.$t('leads.detail.client.country'),
          value: clientData.value?.address?.country
            ? root.$t(`countries.${clientData.value?.address?.country as string}`).toString()
            : undefined,
        },
        {
          id: 'license',
          type: 'text',
          text: root.$t('leads.detail.client.license'),
          value: undefined,
        },
        {
          id: 'document',
          type: 'text',
          text: root.$t('leads.detail.client.document'),
          value: clientData.value?.document?.number || undefined,
        },
      ]
    })

    const leadItems = computed(() => {
      return [
        {
          id: 'createdAt',
          type: 'date',
          text: root.$t('leads.detail.lead.createdAt'),
          value: lead.value?.createdAt,
        },
        {
          id: 'type',
          type: 'text',
          text: root.$t('leads.detail.lead.type'),
          value: lead.value?.opportunity.type
            ? getItemType(lead.value?.opportunity.type)
            : undefined,
        },
        {
          id: 'assignee',
          type: 'text',
          text: root.$t('leads.detail.lead.assignee'),
          value: lead.value?.assignee || undefined,
          loading: isCommentLogLoading.value && !lastUpdatedByMe.value,
        },
        {
          id: 'status',
          type: 'text',
          text: root.$t('leads.detail.lead.status'),
          value: `<span class="text-item status status-${lead.value?.status?.toLowerCase()}">${root.$t(
            `leads.status.${lead.value?.status}`,
          )}</span>`,
          loading: isCommentLogLoading.value && isNewStatus.value,
        },
        {
          id: 'offerId',
          type: 'link',
          text: root.$t('leads.detail.lead.offerId'),
          value: lead.value?.offerId
            ? {
                text: offerTitle.value,
                url: offerPdf?.value,
                fileName: offerFileName.value,
              }
            : undefined,
          loading: isOfferLoading.value,
        },
      ]
    })

    const infoItems = computed(() => {
      return [
        {
          id: 'model',
          type: 'text',
          text: root.$t('leads.detail.info.model'),
          value: (lead.value?.opportunity?.product as Product).model || undefined,
        },
        {
          id: 'variant',
          type: 'text',
          text: root.$t('leads.detail.info.variant'),
          value: (lead.value?.opportunity?.product as Product).variant || undefined,
        },
        {
          id: 'year',
          type: 'text',
          text: root.$t('leads.detail.info.year'),
          value: getModelYear(lead.value?.opportunity?.product as Product),
        },
        {
          id: 'color',
          type: 'text',
          text: root.$t('leads.detail.info.color'),
          value: (lead.value?.opportunity?.product as Product).color || undefined,
        },
        {
          id: 'reservation',
          type: 'text',
          text: root.$t('leads.detail.info.reservation'),
          value: lead.value?.opportunity?.reservation?.number || undefined,
        },
        {
          id: 'reservationStatus',
          type: 'text',
          text: root.$t('leads.detail.info.reservation-status'),
          value: lead.value?.opportunity?.reservation?.status || undefined,
        },
        {
          id: 'amount',
          type: 'text',
          text: root.$t('leads.detail.info.amount'),
          value: lead.value?.opportunity?.expectedRevenue
            ? Intl.NumberFormat(currencyOptions.locale, {
                style: 'currency',
                currency: currencyOptions.currency,
              }).format(lead.value?.opportunity?.expectedRevenue as number)
            : undefined,
        },
        {
          id: 'channel.supplier',
          type: 'text',
          text: root.$t('leads.detail.info.supplier'),
          value: lead.value?.channel?.supplier || undefined,
        },
        {
          id: 'testRide.requestedDate',
          type: 'text',
          text: root.$t('leads.detail.info.test-ride.requested-date'),
          value: lead.value?.opportunity?.testRide?.requestedDate || undefined,
        },
        {
          id: 'testRide.timeslot',
          type: 'text',
          text: root.$t('leads.detail.info.test-ride.timeslot'),
          value: lead.value?.opportunity?.testRide?.timeslot || undefined,
        },
        {
          id: 'comments',
          type: 'text',
          text: root.$t('leads.detail.info.comments'),
          value: lead.value?.opportunity?.comments || undefined,
        },
      ]
    })

    watch(isLoading, async () => {
      if (!isLoading.value) {
        if (!lead.value?.read) await markAsRead()
        await refetchOffer()
      }
    })

    function getModelYear(product: Product | undefined) {
      let res = undefined
      try {
        if (product?.variant) {
          const match = product?.variant.match(/.+(\d{4})/)
          res = match && match.length > 1 ? match[1] : undefined
        }
      } catch (e) {
        console.error(e)
      }
      return res
    }

    function returnToLeadList() {
      root.$router.push({ name: 'Leads' })
    }

    function createOffer() {
      root.$router.push({ name: 'CreateOfferMotorbike', params: { leadId: lead.value?.id || '' } })
    }

    function seeOffer() {
      root.$router.push({
        name: 'ResumeOfferMotorbike',
        params: {
          id: lead.value?.offerId || '',
          leadId: lead.value?.id || '',
        },
      })
    }

    function closeConfirmDialog() {
      dialog.value = false
    }

    function openConfirmDialog() {
      dialog.value = true
    }

    function confirmLostLead() {
      maskLeadAsLost()
      closeConfirmDialog()
    }

    function getItemType(key: string) {
      return root.$te(`leads.types.${key}`) ? root.$t(`leads.types.${key}`) : key
    }

    return {
      offer,
      commentLog,
      isLost,
      offerId,
      isLoading,
      panels,
      clientData,
      clientItems,
      leadItems,
      infoItems,
      isNewStatus,
      dialog,
      leadCountry,
      returnToLeadList,
      createOffer,
      seeOffer,
      confirmLostLead,
      openConfirmDialog,
      closeConfirmDialog,
    }
  },
})
