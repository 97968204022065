














































































































import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import MapitInput from '@/components/general/MapitInput.vue'
import { InputTypes } from '@/utils/constants'
// @ts-ignore
import { additionalDiscountRules } from '@/helpers/offer-manager-rules'
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import {defineComponent, computed, ref} from "@vue/composition-api";
import {mapGetters} from "@/utils/map-store";
import PriceField from '@/components/general/PriceField.vue'

export default defineComponent({
  name: 'TotalPriceData',
  components: { ExpansionPanel, MapitInput, MapitDialog, PriceField },
  props: {
    hideDetails: {
      type: Boolean,
      default: false
    },
    panelReadonly:  {
      type: Boolean,
      default: false
    },
    panelHideDivider: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {root, emit}) {
    const dialog = ref(false)
    const infoVariant = ref('total-discounts-price')

    const {
      getVehiclePriceRateCCAA: vehiclePriceRateCCAA,
      getVehicleDiscountPriceRatePercent: vehicleDiscountPriceRatePercent,
      getTotalPrice: totalPrice,
      getAdditionalDiscountPriceRate: additionalDiscountPriceRate
    } = mapGetters(root.$store.getters)

    const additionalDiscountRulesExtra = computed(() => [
      (value: any) => {
        if(!value) return true
        const fValue = typeof value === 'string' ? parseFloat(value.replace('.','').replace('-','').replace(',','.')) : value
        return totalPrice.value.rate - totalPrice.value.discount - fValue >= 0 || root.$t('error.final-discount')
      }
    ])

    const totalPriceDiscounts = computed(() => {
      const floatValue = parseFloat(totalPrice.value.discount || 0) * -1
      return floatValue.toFixed(2)
    })

    function updateOffer(action: string, value: any){
      root.$store.dispatch(action, value)
      validateForm()
    }

    function validateForm(){
      emit('validateForm')
    }

    function openCloseInfoDialog(variant: string) {
      dialog.value = !dialog.value
      infoVariant.value = variant
    }

    return {
      InputTypes,
      additionalDiscountRules,
      dialog,
      infoVariant,
      additionalDiscountRulesExtra,
      vehiclePriceRateCCAA,
      vehicleDiscountPriceRatePercent,
      totalPrice,
      additionalDiscountPriceRate,
      totalPriceDiscounts,
      updateOffer,
      validateForm,
      openCloseInfoDialog
    }
  }
})
