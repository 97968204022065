<template>
  <div>
    <v-divider/>

    <div :class="`mt-2 d-flex justify-space-evenly py-4 customer-style ${getCss(modified)}`">
			<span class="f-s-15">
        <i class="fa fa-motorcycle redbg"></i>
        {{model}}
      </span><br/>
			<span class="f-s-15">
        <i class="fa fa-ad redbg"></i>
        {{plate}}
      </span><br/>
			<span class="f-s-15">
        <i class="far fa-user redbg"></i>
        {{fullName}}
      </span><br/>
			<span class="f-s-15 d-flex align-center">
        <i class="fa fa-phone-alt redbg m-r-5"></i>
        <m-phone-label :value="phone" />
      </span><br/>
			<span class="f-s-14">
        <i class="far fa-envelope redbg"></i>
        {{email}}
      </span><br/>
    </div>
    <v-divider/>
  </div>
</template>

<script>
  const getFullName = (customer) => {
    let fullname = '';
    if (customer && customer.detail) {
      let detail = JSON.parse(customer.detail);
      fullname = detail.Name + ' ' + detail.Surname;
    }
    return fullname;
  };

  const getPhone = (customer) => {
    let phone = '';
    if (customer && customer.detail) {
      let detail = JSON.parse(customer.detail);
      phone = detail.Phone;
    }
    return phone;
  };

  const getEmail = (customer) => {
    let email = '';
    if (customer) {
      email = customer.login;
    }
    return email;
  };

  const getContainerDetailParam = (container, key) => {
    const detail = JSON.parse(container.detail);
    return detail[key];
  }

  export default {
    props: {
      customer: {
        type: Object,
        default: () => {
          return {login: '', detail: {Name: '', Surname: '', Phone: ''}};
        }
      },
      container: {
        type: Object,
        default: () => {
          return {detail: {Plate: '', Model: ''}};
        }
      },
      modified: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      const fullName = getFullName(this.customer);
      const phone = getPhone(this.customer);
      const email = getEmail(this.customer);
      const model = getContainerDetailParam(this.container, 'Model');
      const plate = getContainerDetailParam(this.container, 'Plate')

      return {model, plate, fullName, phone, email};
    },
    methods: {
      getCss: (modified) => {
        let style = '';
        if (modified) {
          style = style + ' yellowbg';
        }
        else {
          style = style + ' graybg';
        }
        return style;
      }
    },
    watched: {
      customer() {
        this.fullName = getFullName(this.customer);
        this.phone = getPhone(this.customer);
        this.email = getEmail(this.customer);
      },
      container() {
        this.model = getContainerDetailParam(this.container, 'Model');
        this.plate = getContainerDetailParam(this.container, 'Plate');
      }
    }
  }
</script>
<style scoped>
  .redbg {
    color: red !important;
  }

  .graybg {
    background-color: #f1f1f1;
  }

  .yellowbg {
    background-color: yellow;
  }

  .customer-style{
    font-family:'ProximaNovaExCnReg','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
  }
  .justify-space-evenly {
    justify-content: space-evenly;
  }
</style>
