<template>
  <v-form ref="ServicesForm" @submit="submitForm" v-focusNextOnEnter lazy-validation>
    <v-stepper-content :step="step" class="py-0 px-0">
      <v-row no-gutters class="justify-center wrapper">
        <v-col class="text-center">
          <service-checkbox
            :isSelectable="true"
            title="Honda Mapit"
            :error="mapitError"
            variant="honda-mapit"
            :checkValue="services['honda-mapit'].selected"
            @changeCheckValue="changeCheckValue"
            @showInfo="showInfo"
            data-cy="contracted-services-honda-mapit"
            :forcedSelected="isForcedHondaMapit || this.vehicle.forcedDemoBike"
          />
          <div class="px-8">
            <p class="label-field py-1 my-0 align-center">
              {{ $t(`honda.registration.services.honda-mapit.lbl-honda-mapit`)}}
            </p>
            <v-form v-model="validMapitCode" id="mapit-form">
              <available-device-lookup
                id="device-input"
                v-model="mapitCode"
                :ignore-check-rules="(device) => exDealerBike && exDealerBike.device.imei === device.imei"
                :required="services['honda-mapit'].selected"
                :disabled="!services['honda-mapit'].selected"
              />
            </v-form>
            <div v-if="services['honda-mapit'].selected && validMapitCode && exDealerBike != null" data-cy="ex-demo-bike-mapit-info">
              <div class="color-green" v-if="exDealerBike.device.imei === mapitCode.imei">
                {{ $t('honda.registration.services.honda-mapit.ex-demo-bike.mapit-info-text') }}
              </div>
              <div class="color-orange" v-else>
                {{ $t('honda.registration.services.honda-mapit.ex-demo-bike.mapit-warning-text') }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-stepper-content>
    <footer-actions :step="step">
      <template v-slot:left>
        <v-btn data-cy="registration-contracted-services-button-back" class="btn-gray" @click="backStep()" dark tabindex="-1" :disabled="isTransferVehicleRegistration"> {{ $t('buttons.back') }} </v-btn>
      </template>
      <template v-slot:right>
        <v-btn data-cy="registration-contracted-services-button-continue" class="btn-primary" :key="buttonKey" @click.once="nextStep()" :disabled="!validSelectedServices"> {{ $t('buttons.next') }} </v-btn>
      </template>
    </footer-actions>

    <mapit-dialog :dialog="dialog" @close="closeInfo" v-if="variantSelected">
      <template v-slot:header-text>
        <span>{{
          $t(`honda.registration.services.${variantSelected}.title`)
        }}</span>
      </template>
      <template v-slot:body>
        <div class="mb-6"
          v-html="$t(`honda.registration.services.${variantSelected}.body`)"
        />
      </template>
    </mapit-dialog>
  </v-form>
</template>

<script>
import ServiceCheckbox from './ServiceCheckbox'
import FooterActions from '../FooterActions'
import MapitDialog from '@/components/dialog/MapitDialog'
import { servicesRegistrationModel } from '@/vuex/models'
import { RegistrationTypes } from '@/utils/constants'
import { Product } from '@mapit/honda-offers-builder/dist/types'
import {mapGetters} from "vuex"
import AvailableDeviceLookup from "@/components/general/AvailableDeviceLookup"
import { Country } from '@mapit/common-utils/dist/types'

export default {
  name: 'ServicesOnlyMapit',
  components: {AvailableDeviceLookup, ServiceCheckbox, FooterActions, MapitDialog },
  props: ['step', 'codeVinValidation'],
  data() {
    return {
      Country,
      services: servicesRegistrationModel(),
      mapitCode: null,
      dialog: false,
      variantSelected: null,
      validMapitCode: false,
      buttonKey: 1
    }
  },
  watch: {
    isForcedDemoBike: function (val) {
      this.loadServiceStatus({forcedDemoBike: val})
    },
    getVinStatus: function (val) {
      this.loadServiceStatus({vinStatus: val})
    },
    async mapitCode(val) {
      await this.$store.dispatch('addDevice', val)
    }
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
      offer: 'getOffer',
      hasGoModel: 'getHasGoModel',
      getVinStatus: 'getVinStatus',
      vehicle: 'getVehicle',
      exDealerBike: 'getExDealerBike',
      capabilities: 'getCapabilities',
      branch: 'getBranch',
    }),
    isCreatedFromOffer() {
      return this.$store.getters.getForcedType === RegistrationTypes.OFFER_REGISTRATION
    },
    isForcedDemoBike() {
      return this.vehicle.forcedDemoBike
    },
    mapitError() {
      return this.getVinStatus === 'REALTA_VIN_AND_MAPIT' &&
        this.mapitCode.id
        ? this.$t('honda.registration.services.honda-mapit.vin.status_5')
        : ''
    },
    validVin() {
      return this.getVinStatus !== 'VIN_VALID'
        ? this.getVinStatus === 'ALREADY_USED_VIN'
          ? this.$t('honda.registration.services.honda-mapit.vin.status_1')
          : this.getVinStatus === 'REALTA_VIN' ||
            this.getVinStatus === 'REALTA_VIN_AND_MAPIT'
          ? this.$t('honda.registration.services.honda-mapit.vin.status_2')
          : this.$t('honda.registration.services.honda-mapit.vin.status_3')
        : ''
    },
    validSelectedServices: function () {
      if (this.services['honda-mapit'].selected) {
        return this.mapitCode && this.validMapitCode && !(this.services['honda-plus'].selected && this.services['honda-plus-go'].selected)
      } else {
        return this.services['honda-plus'].selected ^ this.services['honda-plus-go'].selected
      }
    },
    isForcedHondaMapit() {
      return (this.$store.getters.getForcedType === RegistrationTypes.HONDA_MAPIT) || (this.$store.getters.getForcedType === RegistrationTypes.HONDA_MAPIT_TRANSFERRED)
    },
    isTransferVehicleRegistration(){
      return this.$route.query.forcedType === RegistrationTypes.HONDA_MAPIT_TRANSFERRED && this.$store.getters.getSelectedTransfer
    },
  },
  methods: {
    loadServiceStatus(data) {
      if(data.forcedDemoBike || this.isForcedDemoBike){
        this.mapitCode = null
        this.services['honda-mapit'].selected = true
      }else{
        //reset HondaMapit but always seslected
        if (!this.services['honda-mapit'].selected) {
          this.mapitCode= null
          this.services['honda-mapit'].selected = true
        }

        //ex-Demo Bike
        if (this.exDealerBike?.device) {
          this.mapitCode = this.exDealerBike.device
        }

        //Load services from offer
        if(this.isCreatedFromOffer) {
          const servicesProducts = this.offer.servicesProducts
          this.services['honda-mapit'].selected = servicesProducts.includes(Product.HondaMapit)
        }
      }
    },
    submitForm(e) {
      e.preventDefault()
    },
    backStep() {
      this.$emit('backStep')
    },
    async nextStep() {
      if (this.validSelectedServices) {
        if(this.services['honda-mapit'].selected && this.mapitCode.imei && this.validMapitCode) {
          this.services['honda-mapit'].value = this.mapitCode.imei
        }
        await this.$store
          .dispatch('addServices', this.services)
          .then(() => {
            this.$emit('nextStep')
            this.buttonKey++
          })
      }
    },
    changeCheckValue(variant) {
      this.services[variant].selected = !this.services[variant].selected
      if (variant === 'honda-mapit') {
        if (this.services[variant].selected) {
          if (this.exDealerBike?.device) {
            this.mapitCode = this.exDealerBike.device
          }
        } else {
          this.mapitCode = null
        }
      }
    },
    closeInfo() {
      this.infoServiceSelected = null
      this.dialog = false
    },
    showInfo(variant) {
      if (variant) {
        this.variantSelected = variant
        this.dialog = true
      } else {
        this.closeInfo()
      }
    },
  },
}
</script>
<style>
#device-input .m-text-field input,
#device-input .m-text-field .v-messages__message {
  text-align: center !important;
}

#mapit-form .device-lookup {
  max-width: 250px;
  margin: auto;
}
</style>
<style lang="scss" scoped>
.wrapper {
  padding: 5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 1rem 1.5rem;
  box-shadow: 3px 3px 6px 0px #00000020;
}
.arrow-divider {
  width: 48px;
  height: 48px;
  background-color: var(--v-gray-base);
  border-radius: 100px;
  color: white;
  padding: 16px;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 25px;
}
</style>
