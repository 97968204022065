<template>
    <div class="d-flex">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
      <span class="ml-2">{{ $t('common.loading') }}</span>
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
}
</script>
