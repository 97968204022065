import {
  isDiscountPercentage,
  isEmailOptional,
  isPhone,
  isRequired,
  isRequiredTrueCheck,
} from './validations'

export const additionalDiscountRules = []
export const attendedByRules = [isRequired]
export const campaignRules = []
export const complementDiscountRules = []
export const complementDiscountRulesPercentage = [isDiscountPercentage]
export const createDateRules = []
export const discountPercentagePriceRateCCAARules = [isDiscountPercentage]
export const discountPriceRateCCAARules = []
export const drivingLicenceDateRules = []
export const emailRules = [isEmailOptional]
export const modelDescriptionRules = [isRequired]
export const modelRules = [isRequired]
export const nameRules = [isRequired]
export const offerTypeRules = [isRequired]
export const phoneRules = [isRequired, isPhone]
export const priceRateCCAARules = [isRequired]
export const privacityRules = [isRequiredTrueCheck]
export const probabilityOfSaleRules = [isRequired]
export const provinceRules = [isRequired]
export const registrationDiscountRules = []
export const registrationDiscountRulesPercentage = [isDiscountPercentage]
export const servicesDiscountRules = []
export const servicesDiscountRulesPercentage = [isDiscountPercentage]
export const statusRules = [isRequired]
export const surnameRules = []
