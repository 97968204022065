import axios from 'axios'
import store from '../vuex'
import { formatToISODate } from '@/utils/date-helper'

export class ServiceApi {
  constructor(url, tokenProvision = () => store.getters.getToken) {
    if (url === undefined || url === '') {
      url = process.env.VUE_APP_MAPIT_API || 'http://localhost:8080'
    }

    this.tokenProvision = tokenProvision
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 20000,
      headers: { 'X-User-Agent': 'honda-plus' },
    })
  }

  getAppointmentById(id) {
    return this.axiosInstance.get(`/v2/admin/services/appointments/${id}`, {
      headers: {
        Authorization: this.tokenProvision(),
        'Content-Type': 'application/json',
      },
    })
  }

  createAppointment(appointment) {
    appointment.state = 'PENDING'

    return this.axiosInstance.put('/request', appointment, {
      headers: {
        Authorization: this.tokenProvision(),
        'Content-Type': 'application/json',
      },
    })
  }

  updateAppointment(appointment) {
    const payload = {
      state: 'ACCEPTED',
      requested: appointment.requested,
      petitionDate: formatToISODate(appointment.requestDate),
      petitionHour: appointment.requestTime,
      pickupDate: appointment.pickupDate,
      pickupHour: appointment.pickupTime,
      courtesyBikeStatus: appointment.courtesyStatus,
      substitution: false,
    }

    return this.axiosInstance.post('/admin/request', payload, {
      params: { requestId: appointment.id },
      headers: {
        Authorization: this.tokenProvision(),
        'Content-Type': 'application/json',
      },
    })
  }

  deleteAppointment(id) {
    const payload = {
      id: Number(id),
      state: 'REJECTED',
    }

    return this.axiosInstance.post('/request/state', payload, {
      headers: {
        Authorization: this.tokenProvision(),
        'Content-Type': 'application/json',
      },
    })
  }

  getServices(groupId, pagination) {
    const payload = {
      groupId,
      priority: false,
      status: 0,
      page: pagination.page,
      max: pagination.limit,
      order: 'PETITION_DESC',
      request_data: {
        accepted: true,
        cancelled: true,
        pending: true,
      },
    }

    return this.axiosInstance.post('/v2/admin/services', payload, {
      headers: {
        Authorization: this.tokenProvision(),
        'Content-Type': 'application/json',
      },
    })
  }

  // @deprecated
  confirmAppointment(id, appointment) {
    appointment.state = 'ACCEPTED'
    appointment.containerId = String(appointment.containerId)
    appointment.groupId = String(appointment.groupId)

    return this.axiosInstance.post('/admin/request', appointment, {
      params: { requestId: id },
      headers: {
        Authorization: this.tokenProvision(),
        'Content-Type': 'application/json',
      },
    })
  }
}

export default new ServiceApi()
