<template>
    <v-row>
      <v-col class="col-5 col-md-5 col-sm-12 bold-900">
        ¿DÓNDE PASÓ LA REPARACIÓN?
      </v-col>
      <v-col class="col-7 col-md-7 col-sm-12 d-flex flex-column">
        <label class="form-radio">
          <input type="radio" data-cy="dealer-radio" v-model="location.kind" value="DEALER"/><i class="form-icon"></i>
          Concesionario Honda
        </label>
        <label class="form-radio">
          <input type="radio" v-model="location.kind" value="SUBAGENT"/><i class="form-icon"></i> Subagente
        </label>
        <div class="d-flex flex-column">
          <label class="form-radio">
            <input type="radio" v-model="location.kind" value="OTHER"/><i class="form-icon"></i> Otros{{ location.kind === 'OTHER' ? ':' : ""}}
          </label>
          <m-textarea v-if="location.kind==='OTHER'" id="input-3" placeholder="No hay comentarios" rows="1"
                      v-model="location.description" auto-grow solo
          ></m-textarea>
        </div>
      </v-col>
    </v-row>
</template>

<script>

export default {
   props: {
       location : {
           type: Object,
           default: ()=>{
               return { kind: 'DEALER', description:null}
           }
       }
   },
}
</script>
