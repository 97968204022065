



















































































































































































































































































































































import MapitDialog from '@/components/dialog/MapitDialog.vue'
import DiscountField from '@/components/general/DiscountField.vue'
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import MapitInput from '@/components/general/MapitInput.vue'
import ServiceCheckbox from '@/components/general/ServiceCheckbox.vue'
// @ts-ignore
import {drivingLicenceDateRules, servicesDiscountRules, servicesDiscountRulesPercentage} from '@/helpers/offer-manager-rules'
import {useI18n} from '@/i18n'
import {InsuranceTypeListItem} from '@/types'
import {InputTypes} from '@/utils/constants'
import {mapGetters} from '@/utils/map-store'
import {InsuranceType, Product} from '@mapit/honda-offers-builder/dist/types'
import {computed, defineComponent, inject, Ref, ref, watch} from '@vue/composition-api'
import {DateTime} from 'luxon'

import { getDatePattern } from '@/utils/date-helper'

export default defineComponent({
  name: 'Services',
  components: {
    ExpansionPanel,
    MapitInput,
    ServiceCheckbox,
    MapitDialog,
    DiscountField
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    panelReadonly: {
      type: Boolean,
      default: false
    },
    panelHideDivider: {
      type: Boolean,
      default: false
    },
    disableAttach: {
      type: Boolean,
      default: false
    },
    safePaddingBottom: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {root, emit}) {
    const { t } = useI18n()

    /* REFS */
    const variantSelected = ref<string | undefined>(undefined)
    const error = ref<string | undefined>(undefined)
    const dialog = ref<boolean | undefined>(undefined)
    const insuranceTypeList = ref<InsuranceTypeListItem[]>(Object.values(InsuranceType).map((item) => {
      return {
        text: t(`offer-manager.create.step-services.lbl-types-insurances.${item.toLowerCase()}`).toString(),
        value: item,
      }
    }) || [])

    const isEditing = inject('isEditing') as Ref<boolean>
    const mainLoading = inject('mainLoading') as Ref<boolean>

    /* COMPUTED */
    const {
      getBranch: branch,
      getCountry: country,
      getLocale: locale,
      getCanHaveHP: canHaveHP,
      getCanHaveHPGo: canHaveHPGo,
      getServicesProducts: servicesProducts,
      getServicesDiscount: servicesDiscount,
      getServicesDiscountPercent: servicesDiscountPercent,
      getServicesTotalDiscount: servicesTotalDiscount,
      getServicesTotalPrice: servicesTotalPrice,
      getServicesPrice: servicesPrice,
      getServicesInsuranceType: servicesInsuranceType,
      getVehicleModelName: vehicleModelName,
      getVehicleModelSubname: vehicleModelSubname,
      getVehicleModelInsurance: vehicleModelInsurance,
      getSelectedModel: catalogModel,
      getOffer: offer,
      getCapabilities: capabilities,
      getDecollapsedPanels: decollapsedPanels,
      getServicesDiscountError: servicesDiscountError,
      getServicesDiscountPercentError: servicesDiscountPercentError
    } = mapGetters(root.$store.getters)

    const availableFinancialServicesOption = computed(() => {
      return catalogModel.value && catalogModel.value.finance && Object.keys(catalogModel.value.finance).length > 0 && (capabilities.value?.finance?.supported ?? root.$preferences(branch.value).finance.supported)
    })

    const isHondaPlusSupported = computed(() => {
      return capabilities.value?.hondaPlus?.supported ?? root.$preferences(branch.value).hondaPlus.supported
    })

    const servicesHondaMapit = computed(() => {
      return {
        priceBase: offer.value?.servicesHondaMapitPriceBase,
        discount: offer.value?.servicesHondaMapitPriceDiscount,
        total: offer.value?.servicesHondaMapitPrice
      }
    })

    const servicesHondaPlusPrice = computed(() => {
      return offer.value?.servicesHondaPlusPrice
    })

    const drivingLicenceDate = computed({
      get: (): string | undefined => root.$store.getters.getClientDrivingLicenceDate,
      set: (value: string | undefined) => {
        root.$store.commit('SET_DRIVING_LICENCE_DATE', { drivingLicenceDate: value, country: country.value })
        root.$nextTick(() => emit('validateForm'))
      }
    })

    const dobPlaceholder = computed(() => {
      return getDatePattern(locale.value)
    })

    const drivingLicenceDateMin = computed(() => {
      return DateTime.local().minus({ years: 100 }).toFormat('yyyy-MM-dd')
    })

    const drivingLicenceDateMax = computed(() => {
      return  DateTime.now().toISODate()
    })

    /* METHODS */
    function validateForm(){
      emit('validateForm')
    }
    function updateOffer(action: string, value: any) {
      root.$store.dispatch(action, value)
      validateForm()
    }
    function closeInfo() {
      variantSelected.value = undefined
      dialog.value = false
    }
    function showInfo(variant: string) {
      if (variant) {
        variantSelected.value = variant
        dialog.value = true
        root.$gtm.trackEvent({category:'Offers', action: 'OpenModal', label: `InfoService-${variant}`})
      } else {
        closeInfo()
      }
    }
    function validateServiceDiscount(value: string) {
      return !value || !servicesPrice.value || servicesPrice.value && parseFloat(value) <= parseFloat(servicesPrice.value) && parseFloat(value) >= 0 || 'Error'
    }
    function validateDiscounts(){
      if(validateServiceDiscount(servicesDiscount.value) !== 'Error') {
        if(servicesDiscountPercent.value >= 0 && servicesDiscountPercent.value <= 100){
          error.value = undefined
        }else{
          error.value = t('offer-manager.create.step-vehicle-registration.lbl-error-discount-percentage').toString()
        }
      }else{
        error.value = t('offer-manager.create.step-vehicle-registration.lbl-error-discount').toString()
      }
    }

    /* WATCH */
    watch(servicesPrice, () => { validateDiscounts() })
    watch(servicesDiscountError, (errorMessage) => {
      if (errorMessage) {
          error.value = t('offer-manager.create.step-vehicle-registration.lbl-error-discount').toString()
      } else {
        error.value = undefined
      }
    }, {immediate: true})
    watch(servicesDiscountPercentError, (errorMessage) => {
      if (errorMessage) {
        error.value = t('offer-manager.create.step-vehicle-registration.lbl-error-discount-percentage').toString()
      } else {
        error.value = undefined
      }
    }, {immediate: true})
    watch(servicesProducts, (actual) => {
      const isDecollapsedPanel9 = decollapsedPanels.value.includes(9)
      if (actual.includes(Product.Finance) && !isDecollapsedPanel9)
        root.$store.dispatch('decollapsePanel', 9)
    })

    return {
      t,
      DateTime,
      Product,
      InputTypes,
      drivingLicenceDateRules,
      servicesDiscountRulesPercentage,
      servicesDiscountRules,
      insuranceTypeList,
      variantSelected,
      error,
      dialog,
      branch,
      locale,
      canHaveHP,
      canHaveHPGo,
      servicesProducts,
      servicesDiscount,
      servicesDiscountPercent,
      servicesTotalDiscount,
      servicesDiscountError,
      servicesTotalPrice,
      servicesPrice,
      servicesInsuranceType,
      vehicleModelName,
      vehicleModelSubname,
      vehicleModelInsurance,
      catalogModel,
      availableFinancialServicesOption,
      isHondaPlusSupported,
      servicesHondaMapit,
      servicesHondaPlusPrice,
      drivingLicenceDate,
      drivingLicenceDateMin,
      drivingLicenceDateMax,
      dobPlaceholder,
      isEditing,
      mainLoading,
      updateOffer,
      validateForm,
      closeInfo,
      showInfo,
      validateServiceDiscount,
      validateDiscounts
    }
  }
})
