<template>
  <div>
    <v-row class="pt-2" v-if="!simpleUi">
      <v-col class="col-md-6 col-lg-5 col-sm-12">
        <m-select
          data-cy="manageOrdersStatusFilter"
          :value="selectedStatus"
          @input="value => selectedStatus = value"
          :placeholder="$t('stocks-manager.manage-orders.lbl-filter-status')"
          :items="filterList"
          :showSelectedCounter="true"
          :multiple="true"
          :clearable="true"
        >
          <template v-slot:prepend-inner>
            <i class="icon-mapit icon-filter-status"></i>
          </template>
        </m-select>
      </v-col>
      <v-spacer/>
      <v-col class="col-md-6 col-lg-5 col-sm-12">
        <mapit-input
          uid="manageOrdersSearchFilter"
          :value="search"
          :placeholder="$t('stocks-manager.manage-orders.lbl-search')"
          @changeValue="(value) => (search = value)"
          :clearable="true"
        />
      </v-col>
    </v-row>

    <v-data-table
      id="orders-table-list"
      :loading="loading"
      :loading-text="$t('stocks-manager.manage-orders.loading')"
      :headers="headers"
      :items="filteredOrders"
      :items-per-page="10"
      class="elevation-0 pt-2"
      sort-by="createdAt"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right',
        'items-per-page-text': $t(
          'stocks-manager.manage-orders.footer-num-x-page',
        ),
        'items-per-page-options': [10, 25, 50, -1],
      }"
      :no-data-text="
        $t(
          orders && orders.length > 0
            ? 'stocks-manager.manage-orders.no-data-search'
            : 'stocks-manager.manage-orders.no-data',
          { search },
        )
      "
      :no-results-text="$t('stocks-manager.manage-orders.no-data-search')"
    >
      <template #footer.page-text="props">
        {{ props.pageStart }}-{{ props.pageStop }} {{ $t('common.of') }}
        {{ props.itemsLength }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        <span class="text-item">{{ getCreatedData(item.createdAt) }}</span>
      </template>
      <template v-slot:item.code="{ item }">
        <span class="text-item lbl-bold">{{ item.code }}</span>
      </template>
      <template v-slot:item.product>
        <span class="text-item">{{
          $t('stocks-manager.manage-orders.lbl-product-mapit')
        }}</span>
      </template>
      <template v-slot:item.quantity="{ item }">
        <span class="text-item lbl-bold">{{ item.quantity }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <span class="text-item lbl-bold">
          {{
            $t(
              `stocks-manager.manage-orders.lbl-status-${item.status.toLowerCase()}`,
            )
          }}
        </span>
      </template>

      <template v-slot:item.applicant="{ item }">
        <div class="customer py-3 align-left">
          <span class="lbl-bold">{{ !simpleUi ? getDealerName(item.applicant.dealer.id) : item.applicant.name }}</span
          ><br />
          <span class="customer-email" v-if="!simpleUi">{{ item.applicant.name }}</span>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu transition="slide-x-transition" bottom right v-if="!simpleUi && !(item.status === OrderStatus.Cancelled || item.status === OrderStatus.Denied || item.status === OrderStatus.Withdrawn)">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-icon class="icon-mapit">)</v-icon>
            </div>
          </template>

          <v-list dense class="list-menu-actions">
            <v-list-item @click="changeStatus(item.id, OrderStatus.InProgress)" :disabled="!(item.status === OrderStatus.Requested)">
              <v-list-item-title>
                {{ $t('stocks-manager.manage-orders.lbl-status-in_progress') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeStatus(item.id, OrderStatus.Sent)" :disabled="!(item.status === OrderStatus.Requested || item.status === OrderStatus.InProgress)">
              <v-list-item-title>
                {{ $t('stocks-manager.manage-orders.lbl-status-sent') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeStatus(item.id, OrderStatus.Denied)" :disabled="!(item.status === OrderStatus.Requested || item.status === OrderStatus.InProgress)">
              <v-list-item-title>
                {{ $t('stocks-manager.manage-orders.lbl-status-denied') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeStatus(item.id, OrderStatus.Cancelled)" :disabled="!(item.status === OrderStatus.Sent)">
              <v-list-item-title>
                {{ $t('stocks-manager.manage-orders.lbl-status-cancelled') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else-if="simpleUi && item.status === OrderStatus.Requested">
          <span class="icon-mapit click px-6" @click="changeStatus(item.id, OrderStatus.Withdrawn)">@</span>
        </div>
      </template>
    </v-data-table>
    <dialog-result
      :dialog="dialog"
      :status="ResultStatus.ERROR"
      :textTitle="$t('stocks-manager.manage-orders.lbl-popup-error-title')"
      :textBody="$t('stocks-manager.manage-orders.lbl-popup-error-body')"
      @nextHandler="handlerDialogResult"
      @closeHandler="handlerDialogResult"
    />
    <dialog-result
      :dialog="dialogConfirmUpdate"
      :status="dialogDataToChangeStatus.dialogType || ResultStatus.ERROR"
      :textTitle="dialogDataToChangeStatus.title || ''"
      :textBody="dialogDataToChangeStatus.description || ''"
      @nextHandler="updateOrder"
      @closeHandler="dialogConfirmUpdate = !dialogConfirmUpdate"
      :loading="loading"
      :cancellable="true"
      :textConfirmButton="dialogDataToChangeStatus.dialogType === ResultStatus.DELETE ? $t('buttons.confirm-delete') : $t('buttons.confirm')"
    />
  </div>
</template>

<script>
import {OrderStatus} from '@mapit/honda-stock-dynamodb/dist/types'
import MapitInput from '@/components/general/MapitInput.vue'
import { InputTypes } from '@/utils/constants'
import {ResultStatus} from '@/types'
import DialogResult from '../../dialog/DialogResult.vue'
import apiStocks from '@/api/stocks.api'
import moment from 'moment'

export default {
  name: 'ManageOrders',
  components: { MapitInput, DialogResult },
  props: ['orders', 'simpleUi'],
  data() {
    return {
      InputTypes,
      ResultStatus,
      OrderStatus,
      dialog: false,
      error: false,
      loading: false,
      search: '',
      selectedStatus: [],
      filterList: Object.values(OrderStatus).map((item) => {
        return {
          text: this.$t(
            `stocks-manager.manage-orders.lbl-status-${item.toLowerCase()}`,
          ),
          value: item,
        }
      }),
      headers: [
        {
          text: this.$t('stocks-manager.manage-orders.lbl-num-order'),
          align: 'left',
          value: 'code',
          class: 'text-header',
          width: '15%',
          sortable: true,
        },
        {
          text: this.$t('stocks-manager.manage-orders.lbl-creation'),
          align: 'left',
          value: 'createdAt',
          class: 'text-header',
          width: '15%',
          sortable: true,
          sort: (a, b) => (moment(a).isBefore(moment(b)) ? 1 : -1),
        },
        {
          text: this.$t('stocks-manager.manage-orders.lbl-ordered-by'),
          align: 'left',
          value: 'applicant',
          class: 'text-header',
          width: '20%',
          sortable: true,
        },
        {
          text: this.$t('stocks-manager.manage-orders.lbl-product'),
          align: 'left',
          value: 'product',
          class: 'text-header',
          width: '15%',
          sortable: true,
        },
        {
          text: this.$t('stocks-manager.manage-orders.lbl-amount'),
          align: 'center',
          value: 'quantity',
          class: 'text-header',
          width: '15%',
          sortable: true,
        },
        {
          text: this.$t('stocks-manager.manage-orders.lbl-status'),
          align: 'left',
          value: 'status',
          class: 'text-header',
          width: '15%',
          sortable: true,
          sort: (a, b) => a === OrderStatus.InProgress ? -1 : b === OrderStatus.InProgress ? 1 : (a > b ? 1 : a < b ? -1 : 0),
        },
        {
          align: 'center',
          value: 'actions',
          class: 'text-header',
          width: '5%',
          sortable: false,
        },
      ],
      dialogConfirmUpdate: false,
      updateData: {
        id: null,
        status: OrderStatus.Requested
      }
    }
  },
  methods: {
    handlerDialogResult() {
      this.dialog = !this.dialog
    },
    getCreatedData(created) {
      return moment(created).format('DD/MM/YYYY')
    },
    getDealerName(dealerId){
      return this.getDealersList.find(item => item.id === dealerId)?.name || dealerId
    },
    changeStatus(id, status){
      this.updateData = { id, status }
      if(status === OrderStatus.InProgress){
        this.updateOrder()
      }else{
        this.dialogConfirmUpdate = true
      }
    },
    updateOrder() {
      this.loading = true
      apiStocks.updateOrder(this.updateData.id, this.updateData.status)
        .then(() => {
          this.$store.dispatch('loadOrdersList')
        })
        .finally(()=> {
          this.dialogConfirmUpdate = false
          this.loading = false;
        })
    }
  },
  computed: {
    filteredOrders() {
      return this.orders ? this.orders
        .filter((item) =>
          this.selectedStatus.length > 0
            ? this.selectedStatus.includes(item.status)
            : true,
        )
        .filter((item) =>
          this.search
            ? item.applicant.name.toLowerCase().includes(this.search.toLowerCase()) ||
              item.applicant.dealer.id.toLowerCase().includes(this.search.toLowerCase()) ||
              item.code.toLowerCase().includes(this.search.toLowerCase()) ||
              `${item.quantity}`.includes(this.search.toLowerCase())
            : true,
        ) : []
    },
    getDealersList(){
      return this.$store.getters.getDealersList
    },
    dialogDataToChangeStatus(){
      switch(this.updateData.status){
        case OrderStatus.Withdrawn:
          return {
            title: this.$t('stocks-manager.manage-orders.lbl-dialog-withdrawn-order-title'),
            description: this.$t('stocks-manager.manage-orders.lbl-dialog-withdrawn-order-description'),
            dialogType: ResultStatus.DELETE
          }
        case OrderStatus.Denied:
          return {
            title: this.$t('stocks-manager.manage-orders.lbl-dialog-denied-order-title'),
            description: this.$t('stocks-manager.manage-orders.lbl-dialog-denied-order-description'),
            dialogType: ResultStatus.ERROR
          }
        case OrderStatus.Cancelled:
          return {
            title: this.$t('stocks-manager.manage-orders.lbl-dialog-cancelled-order-title'),
            description: this.$t('stocks-manager.manage-orders.lbl-dialog-cancelled-order-description'),
            dialogType: ResultStatus.ERROR
          }
        case OrderStatus.Sent:
        default:
          return {
            title: this.$t('stocks-manager.manage-orders.lbl-dialog-sent-order-title'),
            description: this.$t('stocks-manager.manage-orders.lbl-dialog-sent-order-description'),
            dialogType: ResultStatus.SUCCESS
          }
      }
    },
    allowStockAdmin() {
      return this.$store.getters.isStockUser || this.$store.getters.isAdmin
    },
  },
  created() {
    if (this.allowStockAdmin) {
      this.$store.dispatch('loadDalersList', true)
    }
  },
}
</script>

<style lang="scss" src="../stocks-manager-styles.scss" />
