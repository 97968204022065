import { DateTime, DateTimeFormatOptions } from 'luxon'

export const newRegistration = {
  MAX_STEPS_HONDA_REGISTRATION: 7,
  MIN_STEPS_HONDA_REGISTRATION: 1,
}

export const WizardTypes = {
  platform: {
    HONDA_MAPIT: 'HONDA_MAPIT',
    MAPIT_CONNECT: 'MAPIT_CONNECT',
  },
  status: {
    NEW: 'NEW',
    TRANSFERRED: 'TRANSFERRED',
    USED: 'USED',
  },
}

export const RegistrationTypes = {
  HONDA_PLUS: 'HONDA_PLUS',
  HONDA_MAPIT: 'HONDA_MAPIT',
  HONDA_MAPIT_TRANSFERRED: 'HONDA_MAPIT_TRANSFERRED',
  OFFER_REGISTRATION: 'OFFER_REGISTRATION',
}

export const VinRegistrationStates = {
  FOUND_WITH_DEVICE: 'FOUND_WITH_DEVICE',
  FOUND_WITH_DEVICE_PENDING: 'FOUND_WITH_DEVICE_PENDING',
  FOUND_WITHOUT_DEVICE: 'FOUND_WITHOUT_DEVICE',
  NOT_FOUND: 'NOT_FOUND',
  FOUND_WITHOUT_OWNER_EMAIL: 'FOUND_WITHOUT_OWNER_EMAIL',
}

export const InputTypes = {
  TEXT_FIELD: 'TEXT_FIELD',
  SELECT: 'SELECT',
  PHONE: 'PHONE',
  PRICE: 'PRICE',
  PERCENTAGE: 'PERCENTAGE',
  RADIO_BUTTONS: 'RADIO_BUTTONS',
  TIME_PICKER: 'TIME_PICKER',
  TEXT_FIELD_WITH_SUGGESTIONS: 'TEXT_FIELD_WITH_SUGGESTIONS',
  CHECKBOX: 'CHECKBOX',
}

export enum CognitoGroups {
  Admin = 'admin',
  DealerAdmin = 'dealer-admin',
  GarageManager = 'garage-manager',
  Imports = 'imports',
  SalesAgent = 'sales-agent',
  ServicePartner = 'service-partner',
  StockAdmin = 'stock-admin',
}

export type DemoBikesRoles =
  | CognitoGroups.Admin
  | CognitoGroups.DealerAdmin
  | CognitoGroups.GarageManager
  | CognitoGroups.SalesAgent

export const licenseArrayOrder = ['B', 'A1', 'A2', 'A']

export const digitalSignatureStatus = {
  waiting: 'WAITING_SIGNATURE',
  sucess: 'SUCCESS_SIGNATURE',
  error: 'ERROR_SIGNATURE',
}

export enum Branch {
  HondaSpain = 'ES',
  HondaPortugal = 'PT',
  HondaUSA = 'US',
}

export const customFormatDateTimeOutput: DateTimeFormatOptions = {
  ...DateTime.DATETIME_SHORT,
  day: '2-digit',
  month: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}
export const customFormatDateOutput: DateTimeFormatOptions = {
  ...DateTime.DATE_SHORT,
  day: '2-digit',
  month: '2-digit',
}

// business constants
export const MAX_AGE_ALLOWED = 16
