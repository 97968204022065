import store from '../vuex'
import axios from 'axios'

const api = {
  url: process.env.VUE_APP_MAPIT_API,
  __zohoUrl: 'https://zohoserver.mapit.me/tickets?xml=',
  sendRequest(type, url, cb, token, body,) {
    const http = new XMLHttpRequest();
    http.open(type, `${this.url}${url}`, true);
    http.setRequestHeader('Content-type', 'application/json');
    if (token) http.setRequestHeader('Authorization', token);
    http.setRequestHeader('X-App-Origin', "honda");
    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        if (http.status === 200) {
          cb(null, http.responseText && http.responseText.length > 0 ? JSON.parse(http.responseText) : http.responseText);
        } else {
          try {
            if(http.status == 401){
              store.dispatch('logout');
              window.location.replace("/login");
            }
            let error = JSON.parse(http.responseText);
            error._status = http.status;
            cb(error, null);
          }catch(err){ //catch for special errors response
            cb({error: "Response server error", response: http.response, err }, null);
          }
        }
      }
    };
    if (body) {
      http.send(JSON.stringify(body));
    } else {
      http.send();
    }
  },
  async sendAxiosRequest({method, url, params, body}) {
    return axios
      .request({
        url,
        params,
        method,
        baseURL: this.url,
        data: body,
        headers: {
          'Authorization': store.getters.getToken,
          'X-App-Origin': 'honda'
        }
      })
      .then(res => {
        return res.data
      })
      .catch(err => {
        if (err.response && err.response.data.error) throw Error(err.response.data.error)
        else throw err
      })
  },

  //region --------------------------------------------------------------SUPERVISOR ---------------
  //

  getMandateSepa(stripeId, cb) {
    this.sendRequest('GET', `supervisor/mandate?stripeId=${stripeId}`, cb, store.getters.getToken);
  },
  getSupervisors(filters, cb, numPage, maxPage) {
    if (numPage) {
      this.sendRequest("POST", `admin/supervisors?page=${numPage}&max=${(maxPage ? maxPage : 200)}`, (err, resp) => {
        if (err) {
          return cb(err)
        }
        cb(null, resp.map(item => {
          if (item.ownerDetail) {
            item.ownerDetail = JSON.parse(item.ownerDetail);
            item.fullName = (item.ownerDetail.Name + " " + item.ownerDetail.Surname).toLowerCase();
            item.phone = item.ownerDetail.Phone;
            if (!item.ownerLogin) {
              if (item.ownerDetail.InfoEmail)
                item.ownerLogin = item.ownerDetail.InfoEmail.toLowerCase() + " (detail)";
              else
                item.ownerLogin = "-";
            }
            item.showDetail = false;
            item.containers = {};
          }
          return item
        }));
      }, store.getters.getToken, filters);
    } else {
      this.sendRequest("POST", "admin/supervisors/", (err, resp) => {
        if (err) {
          return cb(err)
        }
        for (let i = 0; i < resp.length; i++) {
          if (resp[i].ownerDetail) {
            resp[i].ownerDetail = JSON.parse(resp[i].ownerDetail);
            resp[i].fullName = (resp[i].ownerDetail.Name + " " + resp[i].ownerDetail.Surname).toLowerCase();
            resp[i].phone = resp[i].ownerDetail.Phone;
            if (!resp[i].ownerLogin) {
              if (resp[i].ownerDetail.InfoEmail)
                resp[i].ownerLogin = resp[i].ownerDetail.InfoEmail.toLowerCase() + " (detail)";
              else
                resp[i].ownerLogin = "-";
            }
            resp[i].showDetail = false;
            resp[i].containers = {};
          }
        }
        cb(null, resp);
      }, store.getters.getToken, filters);
    }

  },

  //
  //endregion -----------------------------------------------------------SUPERVISOR ---------------

  //region --------------------------------------------------------------CONTAINER ----------------
  //
  removeRequestRevisionAdmin(id, body, cb) {
    this.sendRequest('DELETE', `request/archive?id=${id}`, cb, store.getters.getToken, body);
  },
  updateRequest(id, body, cb) {
    this.sendRequest("POST", `admin/request?requestId=${id}`, cb, store.getters.getToken, body);
  },
  updateRequestRevision(body, cb) {
    this.sendRequest('POST', 'request/state', cb, store.getters.getToken, body);
  },
  services(body, cb) {
    //this.sendRequest('POST', 'admin/services/', cb, store.getters.getToken, body);
    this.sendRequest('POST', 'v2/admin/services/', cb, store.getters.getToken, body);
  },
  appointment(id, cb){
    this.sendRequest('GET', `v2/admin/services/appointments/${id}`, cb, store.getters.getToken);
  },
  repair(id, cb){
    this.sendRequest('GET', `v2/admin/services/repairs/${id}`, cb, store.getters.getToken);
  },
  acceptRepair(id, body, cb) {
    this.sendRequest('POST', `admin/request/done?requestId=${id}`, cb, store.getters.getToken, body);
  },
  getContainers(filters, cb) {
    this.sendRequest("POST", "admin/containers/", (err, resp) => {
      if (err) {
        return cb(err)
      }
      for (let i = 0; i < resp.length; i++) {
        resp[i].device = {};
        resp[i].services = null;
        resp[i].open = false;
        resp[i].detail = JSON.parse(resp[i].containerDetail)
      }
      cb(null, resp);
    }, store.getters.getToken, filters);
  },
  getCounters(cb){
    this.sendRequest('GET', `admin/counters`, cb, store.getters.getToken);
  },
  //
  //endregion -----------------------------------------------------------CONTAINER ----------------

  //region --------------------------------------------------------------DEVICE -------------------
  //

  sellConnect({number, subAgent}) {
    return this.sendAxiosRequest({method: "POST", url: "registration/draft/registerconnect", params: {number}, body: {subAgent}})
  },
  getKeys(detail, cb) {
    this.sendRequest('POST', 'admin/getkeys/', cb, store.getters.getToken, detail);
  },
  changeStateDevice(id, body, cb) {
    this.sendRequest('POST', `admin/device/state?id=${id}`, cb, store.getters.getToken, body);
  },
  getDevices(filters, cb) {
    this.sendRequest("POST", "admin/devices/", (err, resp) => {
      if (err) {
        return cb(err)
      }
      cb(null, resp);
    }, store.getters.getToken, filters);
  },
  detachDeviceToContainer(deviceId, containerId, reason_code, cb) {
    this.sendRequest('DELETE', `admin/device/attach?id=${deviceId}&containerId=${containerId}&reason=${reason_code}`, cb, store.getters.getToken);
  },

  //
  //endregion -----------------------------------------------------------DEVICE -------------------

  //region --------------------------------------------------------------SPECIFIC FUNCTION --------
  //

  getSummaryGroup(groupId, cb) {
    this.sendRequest("GET", `admin/services/summary?groupId=${groupId}`, (err, resp) => {
      if (err) {
        return cb(err)
      }
      cb(null, resp);
    }, store.getters.getToken);
  },
  changeShopRequest(login, detail, cb) {
    this.sendRequest("POST", 'group/detach', cb, store.getters.getToken, {
      detail: JSON.stringify(detail),
      login: login
    });
  },
  sendZohoTicketCB(body, cb) {
    this.sendRequest('POST','admin/zoho/tickets', cb, store.getters.getToken, body)
  },
  uploadVIN(branch, csv) {
    return this.sendAxiosRequest({method: 'post', url: 'vin/upload', body: {
      vins: csv,
      branch
    }})
  },

  //
  //endregion -----------------------------------------------------------SPECIFIC FUNCTION---------
  getOpeningHours(containerId, date, cb){
    return this.sendRequest('GET', `container/group/openinghours?containerId=${containerId}&date=${date}`, (err, resp) => {
      if (err) {
        return cb(err)
      }
      cb(null, resp);
    },store.getters.getToken);
  },

  async getAppointmentStats(startMonth, endMonth, year, cb){
    try {
      const response = await this.sendAxiosRequest({method: 'GET', url: 'admin/insights/appointments', params: {month: startMonth, year}})
      return cb(null, response)
    } catch (ex) {
      return cb(ex)
    }
  },

  async getRegistrationStats(startMonth, endMonth, year, cb){
    try {
      const response = await this.sendAxiosRequest({method: 'GET', url: 'admin/insights/registrations', params: {month: startMonth, year}})
      return cb(null, response)
    } catch (ex) {
      return cb(ex)
    }
  }
};

export default api
