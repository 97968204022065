import { debounce } from 'lodash'
import { onMounted, Ref, ref, watch } from '@vue/composition-api'

import apiOffers from '@/api/offers.api'
import { mapResponseDataToOffers } from '@/mappers/offers'
import { OpenSearchListRequest, OpenSearchPagination } from '@/types'
import { OFFER_EXPIRE_DAYS } from '@/utils/offers'

interface Props {
  dealerId: string
  dealerCode: string
  search?: string
  pagination: Partial<OpenSearchPagination>
  queryParams?: Record<string, any>
}

export const useOffersList = <T>(props: Props) => {
  const list = ref<Array<T>>([]) as Ref<Array<T>>
  const text = ref('')
  const queryParams = ref(props.queryParams || {})
  const isLoading = ref(false)
  const total = ref(0)
  const pagination = ref<Partial<OpenSearchPagination>>(props.pagination)
  const range = ref(false)

  const debounceFetch = debounce(fetchOffers, 500)

  onMounted(async () => {
    await fetchOffers()
  })

  watch([text, range, queryParams], debounceFetch, { deep: true })

  async function fetchOffers() {
    isLoading.value = true

    try {
      const request: OpenSearchListRequest = {
        dealer: { id: props.dealerId, code: props.dealerCode },
        pagination: pagination.value,
        params: props.queryParams,
        text: cleanSearchInput(text.value),
        range: !range.value ? OFFER_EXPIRE_DAYS : undefined,
      }

      const response = await apiOffers.getOffersListByDealerId(request)
      const { value: totalHits } = response.total
      const data = mapResponseDataToOffers(response.hits || response.data)

      list.value = data || []
      total.value = totalHits
    } catch (e) {
      console.error('Error fetching the offer list', e)
    }

    isLoading.value = false
  }

  function cleanSearchInput(input?: string) {
    if (!input) return ''
    return input.trim().replace(/^\+/, '')
  }

  return {
    isLoading,
    data: list,
    text,
    range,
    total,
    queryParams,
    pagination,
    refetch: fetchOffers,
    debounceRefetch: debounceFetch,
  }
}
