

























import { computed, defineComponent, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'CardBars',
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    current: {
      type: Number,
      required: false,
    },
    labels: {
      type: Array,
      required: false,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { current, labels, data } = toRefs(props)

    const isEmpty = computed(() => {
      return !current?.value && current?.value !== 0
    })

    const options = computed(() => {
      return {
        color: ['#dc192d'],
        grid: {
          top: 10,
          left: 8,
          height: '100%',
          width: '75%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          show: false,
        },
        yAxis: {
          type: 'category',
          inverse: true,
          data: labels?.value,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            color: '#000',
            fontFamily: 'ProximaNovaRegular',
            fontSize: 14,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 12,
            silent: true,
            label: {
              show: true,
              position: 'right',
              color: '#000',
              fontFamily: 'ProximaNovaRegular',
              fontSize: 14,
              valueAnimation: true,
            },
            data: data.value,
          },
        ],
      }
    })

    return {
      isEmpty,
      options,
    }
  },
})
