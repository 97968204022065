<template>
  <v-row no-gutters>
    <v-col class="col-5 m-t-15 col-md-5 col-sm-12 bold-900">
        RECEPCIÓN DE MOTO
    </v-col>
    <v-col class='col-7 col-md-7 col-sm-12'>
      <time-interval-form v-bind="$attrs"
                          :clearable="false"
                          title1="FECHA DE RECEPCIÓN"
                          title2="HORA DE RECEPCIÓN"
                          :interval='interval'
                          :containerId='containerId'
                          :apiFunction='getOpeningHours'
                          :notBefore="notBefore"
                          id="reception-date"/>

    </v-col>
  </v-row>
</template>

<script>
  import Vue from 'vue';
  import TimeIntervalForm from '@/components/other/TimeIntervalForm'
  import api from '@/api';


  export default {
    components: {
      TimeIntervalForm
    },
    props: {
      containerId:{
        type: String
      },
      interval: {
        type: Object
      },
      notBefore: Date,
    },
    methods: {
      getOpeningHours: (p1, p2, p3) => {
        api.getOpeningHours(p1, p2, p3)
      }
    }
  }
</script>
<style scoped>
  .redbg {
    color: red !important;
  }

</style>
