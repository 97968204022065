import { DashboardStatResponse, StatsFetchType } from '@/types'
import { DateTime } from 'luxon'
import ApiGatewayClient from './aws/aws-gateway-client'
import api from '@/api/index.js'

export class CoreDataApi {
  readonly #client: ApiGatewayClient

  constructor() {
    this.#client = new ApiGatewayClient()
  }

  async getDealerStats<T extends DashboardStatResponse>(
    dealerId: string,
    type: StatsFetchType,
    year: number,
    month?: number,
  ): Promise<Array<T>> {
    const cacheResponse = localStorage.getItem(`stats-${type}-${year}${month ? `-${month}` : ''}`)
    const cachedData: { expireDate: string; data: Array<T> } = cacheResponse
      ? JSON.parse(cacheResponse)
      : undefined

    const needsUpdate = !cachedData || DateTime.fromISO(cachedData.expireDate) <= DateTime.now()
    let storeData = true

    const dealerTypes = ['leads', 'demo-bikes', 'offers', 'honda-plus', 'sales']
    let response: Array<T> = []

    if (!needsUpdate) {
      return Promise.resolve(cachedData.data)
    }

    if (type === 'appointments') {
      const countersResponse = await api
        .sendAxiosRequest({
          method: 'GET',
          url: 'admin/counters',
          params: { month, year },
          body: undefined,
        })
        .catch((error) => {
          console.error(error)
          return []
        })

      const appointmentsResponse = await api
        .sendAxiosRequest({
          method: 'GET',
          url: 'admin/insights/appointments',
          params: { month, year },
          body: undefined,
        })
        .catch((error) => {
          console.error(error)
          return []
        })

      // TODO: include month and year to be able to extract the data if appointment fails, remove this when appointments works
      response = [{ ...countersResponse, ...appointmentsResponse, month, year }]
      storeData = false
    }

    if (dealerTypes.includes(type)) {
      response = await this.#client
        .sendRequest({
          endpoint: process.env.VUE_APP_MAPIT_CORE_DATA_API,
          method: 'GET',
          path: `/v1/dealers/${dealerId}/${type}/${year}`,
          queryParams: month ? { month } : undefined,
        })
        .then((res) => {
          return res.data.data
        })
        .catch((error) => {
          console.error(error)
          return []
        })
    }

    if (storeData) {
      const now = DateTime.now()
      const expireDate = now.set({ hour: 5, minute: 0, second: 0 }).plus({ days: 1 }).toISO()

      localStorage.setItem(
        `stats-${type}-${year}${month ? `-${month}` : ''}`,
        JSON.stringify({
          expireDate,
          data: response,
        }),
      )
    }

    return response
  }
}
