import parsePhoneNumber from 'libphonenumber-js'
import { Country } from '@mapit/common-utils/dist/types'

export function formatPhone(value: any): string {
  if (!value) {
    return '-'
  }

  if (typeof value !== 'string' && typeof value !== 'number') {
    return '-'
  }

  const phone = String(value).replace(/\s/g, '')
  const phoneParsed = parsePhoneNumber(phone, Country.Spain)

  if (phoneParsed?.isValid()) {
    return phoneParsed.formatInternational()
  }

  return phone
}
