<template>
  <th v-if="isHeader">
      {{title}}
  </th>
  <td class='column-500' v-else>
	  <div class="p-20 f-s-14">
		  <b>{{getContainerDetailSubAgent(rowData.container)}}</b><br/>
	  </div>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
      rowData: {
        type: Object,
        default: () => {
          return { container: {detail: {SubAgent: 'No informado'} }};
        }
      }
  },
  methods:{
    getContainerDetailSubAgent(container){
      const key = 'SubAgent'
      const detail = JSON.parse(container.detail);
      const ret = detail && detail[key]?detail[key]: 'No informado';
      return ret;
    }
  },
  name: 'SubAgent'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
