import {
  GetObjectCommand,
  GetObjectCommandInput, GetObjectCommandOutput,
  PutObjectCommand,
  PutObjectCommandInput,
  S3Client
} from '@aws-sdk/client-s3'
import {getSignedUrl} from "@aws-sdk/s3-request-presigner"
import {Credentials, Provider, RequestPresigningArguments} from "@aws-sdk/types"

export default class MapitS3Client {
  private readonly s3: S3Client

  constructor(credentials?: Credentials | Provider<Credentials>) {
    this.s3 = new S3Client({
      region: 'eu-west-1',
      credentials
    })
  }

  getObject(params: GetObjectCommandInput): Promise<GetObjectCommandOutput> {
    return this.s3.send(new GetObjectCommand(params))
  }

  async getObjectAsBlob(params: GetObjectCommandInput): Promise<{ obj: GetObjectCommandOutput, blob: Blob }> {
    const obj = await this.getObject(params)
    const blob = await this.streamToBlob(obj.Body as ReadableStream)
    return { obj, blob }
  }

  putObject(params: PutObjectCommandInput) {
    return this.s3.send(new PutObjectCommand((params)))
  }

  getSignedUrl(params: GetObjectCommandInput, options: RequestPresigningArguments = { expiresIn: 3600 }) {
    return getSignedUrl(this.s3, new GetObjectCommand(params), options)
  }

  private streamToBlob(stream: ReadableStream): Promise<Blob> {
    return new Promise((resolve) => {
      const chunks: Buffer[] = []

      const reader = stream.getReader()
      const processRead = ({ done, value }: ReadableStreamDefaultReadResult<Buffer>) => {
        if (done) {
          resolve(new Blob(chunks))
          return
        }

        chunks.push(Buffer.from(value))
        reader.read().then(processRead)
      }

      reader.read().then(processRead)
    })
  }
}
