import { hondaCatalogApi } from '@/api/honda-catalog.api';
import { MaterialWithDescription } from '@/types';
import { Branch } from '@/utils/constants';
import { CO2Rates, CO2RateValues, Material } from "@mapit/honda-catalog-dynamodb/dist/types";
import { onMounted, ref, Ref, watch } from "@vue/composition-api";

interface Props {
  branch: Branch,
  immediate?: boolean,
  material: Ref<Material | MaterialWithDescription | undefined>
}

export const useCO2Rates = ({ branch, material, immediate = true }: Props) => {

  const co2Rates = ref({}) as Ref<CO2Rates>
  const isLoading = ref(false)
  const selectedRate = ref({}) as Ref<CO2RateValues | undefined>

  watch(co2Rates, fetchMaterialRates)

  onMounted(() => {
    if (immediate) fetchCO2Rates()
  })

  async function fetchCO2Rates() {
    try {
      isLoading.value = true
      const rates = await hondaCatalogApi.getCO2Rates(branch)
      co2Rates.value = rates ?? {}
    } catch (e) {
      console.error(`Error fetching models`, e)
    } finally {
      isLoading.value = false
    }
  }

  async function fetchMaterialRates() {
    if (co2Rates.value && material.value) {
      const co2Rate = co2Rates.value[material.value?.idMat]
      selectedRate.value = co2Rate
    } else {
      selectedRate.value = undefined
    }
  }


  return {
    selectedRate,
    rates: co2Rates,
    loading: isLoading,
    fetch: fetchCO2Rates,
    fetchRate: fetchMaterialRates
  }
}
