 <template>
      <v-row no-gutters>
        <div class='divider'></div>
        <v-col class='col-5 bold-900 col-md-12'>
          MOTIVO
        </v-col>
        <v-col class='col-7 col-md-12'>
          <v-row>
            <v-col class='col-12 col-md-12'>
              <div class="form-group">
                <div class='col-12'>
                  <label class="form-radio">
                    <input type="radio" v-model="deleteRevision.kind" value='NO_CLIENT' /><i class="form-icon"></i> El cliente no se ha presentado
                  </label>
                </div>
              </div>
            </v-col>
            <v-col class='col-12 col-md-12' v-if="isRevision">
              <div class="form-group">
                <v-col class="col-12">
                <v-col class='col-12'>
                  <label class="form-radio">
                    <input type="radio" v-model="deleteRevision.kind" value='REV_ERROR' /><i class="form-icon"></i> Cálculo de revisión erróneo
                  </label>
                </v-col>
                <v-col class='col-12'>
                    <label class="form-label" for="nextService">KM actuales de la moto</label>
                    <input class='form-input' type='Number' :disabled="deleteRevision.kind!=='REV_ERROR'" v-model='deleteRevision.nextService' placeholder='Km de la moto' />
                </v-col>
                </v-col>
              </div>
            </v-col>

            <v-col class='col-12'>
              <div class="form-group">
                <v-col class='col-12'>
                  <label class="form-radio">
                    <input type="radio" v-model="deleteRevision.kind" value='OTHER' /><i class="form-icon"></i>Otros:
                  </label>
                  <textarea class="form-input" :disabled="deleteRevision.kind!=='OTHER'" id="input-example-3" placeholder="No hay comentarios del cliente" rows="3"
                            v-model="deleteRevision.comment"></textarea>
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
</template>

<script>
export default {
   props:{
      deleteRevision : {
           type: Object,
           default: ()=>{
               return { kind: 'DEALER', comment:null, nextService: null};
           }
      },
      isRevision :{
        type: Boolean,
        default: true
      }
    },
   data() {
      return {modified: false};
   }
}
</script>

