import { hondaCatalogApi, ModelSummary } from '@/api/honda-catalog.api';
import { Model } from "@mapit/honda-catalog-dynamodb/dist/types"
import { ref, onMounted } from "@vue/composition-api"
import { Branch } from '@/utils/constants';
import { createModelName } from '@/utils/utils';
import { ModelWithDescription } from '@/types';

interface Props {
  branch: Branch,
  immediate?: boolean
}

export const useModels = ({branch, immediate = true}: Props) => {

  const models = ref<Array<ModelWithDescription>>([])
  const isLoading = ref(false)
  const isSummaryLoading = ref(false)

  const lastImportServer = ref(0)

  onMounted(async () => {
    if (immediate) await fetchModels()
  })

  function formatModelName(model: Model) {
    return {
      ...model,
      description: createModelName(model)
    } as ModelWithDescription
  }

  function getLocalStorageModels(): Array<ModelWithDescription> {
    return localStorage.getItem('catalog-models') ? JSON.parse(localStorage.getItem('catalog-models') || '') : []
  }

  function setLocalStorageModels(models: Array<ModelWithDescription> = []) {
    localStorage.setItem('catalog-models', JSON.stringify(models))
  }

  function getLocalStorageLastImportTS(): number {
    return localStorage.getItem('catalog-last-import-ts') ? JSON.parse(localStorage.getItem('catalog-last-import-ts') || '') : -1
  }

  function setLocalStorageLastImportTS(ts: number) {
    localStorage.setItem('catalog-last-import-ts', JSON.stringify(ts))
  }

  async function fetchLastImportTS() {
    try {
      return await hondaCatalogApi.getLastImportTS(branch)
    } catch (e) {
      console.error(`Error fetching last import TS`, e)
      return 0
    }
  }

  async function fetchModels() {
    try {
      isLoading.value = true

      const lastImportClient = getLocalStorageLastImportTS()
      const localStorageModels = getLocalStorageModels()

      lastImportServer.value = await fetchLastImportTS()

      if (lastImportClient !== lastImportServer.value || localStorageModels.length === 0) {
        setLocalStorageLastImportTS(lastImportServer.value)
        
        const response = await hondaCatalogApi.getModels(branch)
        models.value = response.map(formatModelName)

        setLocalStorageModels(models.value)
      } else {
        models.value = localStorageModels
      }
      
    } catch (e) {
      console.error(`Error fetching models`, e)
    } finally {
      isLoading.value = false
    }
  }

  async function getSummaryByIdMaterial(idMaterial: string | undefined, signal?: AbortSignal): Promise<ModelSummary | undefined>  {
    try {
      if (!idMaterial) throw new Error('idMaterial is required')
      isSummaryLoading.value = true
      return await hondaCatalogApi.getSummaryByIdMaterial(branch, idMaterial, signal)
    } catch(e) {
      console.error(`Error fetching summary by idMaterial -> ${idMaterial}`, e)
      return undefined
    } finally {
      isSummaryLoading.value = false
    }
  }

  return {
    models,
    loading: isLoading,
    summaryLoading: isSummaryLoading,
    fetch: fetchModels,
    getSummaryByIdMaterial,
    lastImportTimestamp: lastImportServer
  }
}