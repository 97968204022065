



































































import Vue from 'vue'
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import {Product} from '@mapit/core-dynamodb/dist/types'

type BaseData = {
  signedDocs: boolean,
  buttonKey: number
}

export default Vue.extend({
  name: 'UploadRegistrationDocsDialog',
  components: {
    MapitDialog
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    contracts: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data(): BaseData {
    return {
      signedDocs: false,
      buttonKey: 1
    }
  },
  computed: {
    contractHP(): any {
      return this.contracts.find((contract: any) => contract.name === Product.HondaPlus && Object.hasOwn(contract, 's3Key'))
    },
    contractSEPA(): any {
      return this.contracts.find((contract: any) => contract.name === 'SEPA' && Object.hasOwn(contract, 's3Key'))
    },
    contractHM(): any {
      return this.contracts.find((contract: any) => contract.name === Product.HondaMapit && Object.hasOwn(contract, 's3Key'))
    }
  },
  methods: {
    signAndContinue() {
      if (this.signedDocs) {
        this.signedDocs = false
        this.$emit('continue')
      }
      this.buttonKey++
    },
    close() {
      this.signedDocs = false
      this.$emit('close')
    }
  }
})
