<template>
  <div :class="['box', type, 'text-left', 'd-flex', 'justify-center']">
    <v-icon class="mr-3" :class="iconClass"></v-icon>
    <span v-html="text"/>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";

export default defineComponent({
  name: 'Banner',
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'info',
      validator: function (value) {
        return ['warning', 'error', 'info'].includes(value);
      },
    },
  },
  computed: {
    iconClass() {
      const typeToIcon = {
        warning: 'fas fa-exclamation-triangle',
        error: 'fas fa-exclamation-circle',
        info: 'fas fa-info-circle',
      };
      return typeToIcon[this.type] || 'fas fa-info-circle';
    },
  },
})
</script>

<style lang="scss" scoped>
.box {
  font-family: 'ProximaNovaSemibold', sans-serif;
  font-size: 16px !important;
  padding: 14px 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  &.warning {
    background-color: rgba(252, 164, 0, 0.12) !important;
    color: var(--v-warning-base);

    i {
      color: var(--v-warning-base);
    }
  }

  &.error {
    background-color: rgba(220, 24, 45, 0.1) !important;
    color: var(--v-primary-base);

    i {
      color: var(--v-primary-base);
    }
  }

  &.info {
    background-color: rgba(0, 214, 252, 0.12) !important;
    color: var(--v-secondary-base);

    i {
      color: var(--v-secondary-base);
    }
  }
}

.v-icon {
  color: inherit; /* Inherit color from the parent div */
}
</style>
