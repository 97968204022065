
export const generateArrayOfHours = () => {
  const x = 30; //minutes interval
  let times = []; // time array
  let tt = 0; // start time

  //loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    let mm = (tt % 60); // getting minutes of the hour in 0-55 format
    times[i] = ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2);
    tt = tt + x;
  }
  return times;
}

export const parseSchedule = (schedule) => {
  const parsedScheduled = schedule.split(" - ");
  for (let i = 0; i < parsedScheduled.length; i++) {
    if (parsedScheduled[i].length == 4) {
      parsedScheduled[i] = '0' + parsedScheduled[i];
    }
  }
  return parsedScheduled;
}

