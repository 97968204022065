import { AxiosResponse } from 'axios'
import { DealerBike, LoanStatus } from '@mapit/dealer-bikes-dynamodb/dist/types'
import ApiGatewayClient from './aws/aws-gateway-client'

export class DealerBikesApi {
  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  createDealerBike(data: any): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'POST',
      path: `/v1/dealer-bikes/`,
      data,
    })
  }

  updateDealerBike(id: string, data: any): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'PATCH',
      path: `/v1/dealer-bikes/${id}`,
      data,
    })
  }

  createLoan(id: string, data: any): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'POST',
      path: `/v1/dealer-bikes/${id}/loans`,
      data,
    })
  }

  getLoansFinishedByMonth(id: string, month: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'GET',
      path: `/v1/dealer-bikes/${id}/loans`,
      queryParams: {
        month,
        status: LoanStatus.Completed,
      },
    })
  }

  getDealerBike(id: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'GET',
      path: `/v1/dealer-bikes/${id}`,
    })
  }

  getDealerBikeByVin(
    vin: string,
    signal?: AbortSignal,
  ): Promise<AxiosResponse<{ data: DealerBike[] }>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'GET',
      path: `/v1/dealer-bikes`,
      queryParams: {
        vin,
      },
      signal,
    })
  }

  getDealerBikeList(): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'GET',
      path: `/v1/dealer-bikes`,
    })
  }

  getDealerBikeLoan(id: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'GET',
      path: `/v1/loans/${id}`,
    })
  }

  getDealerBikeLoanList({ onGoing }: { onGoing: boolean }): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'GET',
      path: `/v1/loans`,
      queryParams: {
        onGoing,
      },
    })
  }

  signHandOverLoan(id: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'POST',
      path: `/v1/loans/${id}/handover/sign`,
    })
  }

  returnLoanDealerBike(loanId: string, data: any): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'POST',
      path: `/v1/loans/${loanId}/return`,
      data,
    })
  }

  signReturnLoan(id: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'POST',
      path: `/v1/loans/${id}/return/sign`,
    })
  }

  cancelLoan(id: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'POST',
      path: `/v1/loans/${id}/cancel`,
    })
  }

  makeDealerBikeAvailable(id: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'DELETE',
      path: `/v1/dealer-bikes/${id}/unavailable`,
    })
  }

  makeDealerBikeUnavailable(id: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'PUT',
      path: `/v1/dealer-bikes/${id}/unavailable`,
    })
  }

  deleteDealerBike(id: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'DELETE',
      path: `/v1/dealer-bikes/${id}`,
    })
  }

  retireDealerBike(id: string, data: any): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'PUT',
      path: `/v1/dealer-bikes/${id}/retired`,
      data,
    })
  }

  changeDealerBikeToCourtesy(id: string): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'PUT',
      path: `/v1/dealer-bikes/${id}/courtesy`,
    })
  }

  sendEmail(data: any): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'POST',
      path: `/v1/notifications/email`,
      data,
    })
  }

  getDealerBikeByRegistrationNumber(
    registrationNumber: string,
    signal?: AbortSignal,
  ): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALER_BIKES_API,
      method: 'GET',
      path: `/v1/dealer-bikes`,
      queryParams: {
        registrationNumber,
      },
      signal,
    })
  }
}

export default new DealerBikesApi()
