






























































import MapitDialog from '@/components/dialog/MapitDialog.vue'
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import PriceField from '@/components/general/PriceField.vue'
import { useCO2Rates } from '@/composables'
import { useMaterials } from '@/composables/materials'
import {
  // @ts-ignore
  modelDescriptionRules,
  modelRules,
  priceRateCCAARules
} from '@/helpers/offer-manager-rules'
import { useI18n } from '@/i18n'
import { MaterialWithDescription, ModelWithDescription } from '@/types'
import { InputTypes } from '@/utils/constants'
import { mapGetters } from '@/utils/map-store'
import { computed, defineComponent, inject, ref, Ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'MotorbikeData',
  components: { ExpansionPanel, MapitDialog, PriceField },
  props: {
    hideDetails: Boolean,
    panelReadonly: Boolean,
    panelHideDivider: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const { t } = useI18n()

    const mainLoading = inject('mainLoading') as Ref<boolean>
    const isEditing = inject('isEditing') as Ref<boolean>
    const models = inject('models') as Ref<ModelWithDescription[]>
    const isModelsLoading = inject('isModelsLoading') as Ref<boolean>
    const isModelsSummaryLoading = inject('isModelsSummaryLoading') as Ref<boolean>
    const getSummaryByIdMaterial = inject('getSummaryByIdMaterial') as (idMaterial: string | number) => MaterialWithDescription

    const needsCleaning = ref(true)
    const confirmEditing = ref(false)
    const abortController = ref<AbortController>(new AbortController())

    const {
      getVehicleModelName: vehicleModelName,
      getVehicleModelSubname: vehicleModelSubname,
      getVehicleModelPriceRate: vehicleModelPriceRate,
      getVehiclePriceRateCCAA: vehiclePriceRateCCAA,
      getVehicleDiscountPriceRate: vehicleDiscountPriceRate,
      getVehicleDiscountPriceRatePercent: vehicleDiscountPriceRatePercent,
      getVehicleTotalPrice: vehicleTotalPrice,
      getBranch: branch,
      getOffer: offer,
      isOfferLoading
    } = mapGetters(root.$store.getters)

    const listCampaigns = ref([
      { text: 'Campaña Summer', value: '#0001' },
      { text: 'Campaña Winter', value: '#0002' },
      { text: 'Campaña Black friday', value: '#0003' },
    ])

    const selectedModel = ref() as Ref<ModelWithDescription | undefined>
    const selectedMaterial = ref() as Ref<MaterialWithDescription | undefined>

    const { materials, loading: isMaterialsLoading } = useMaterials({
      branch: branch.value,
      model: selectedModel,
    })

    const {
      rates,
      selectedRate,
      loading: isCO2RateLoading,
    } = useCO2Rates({
      branch: branch.value,
      material: selectedMaterial,
    })

    const haveCO2Rates = computed(() => Object.keys(rates.value).length > 0)



    const isDoneMainLoading = computed(() => {
      return (
        !mainLoading?.value &&
        !isOfferLoading?.value &&
        !isModelsLoading?.value
      )
    })

    watch(isDoneMainLoading, (isDoneMainLoading) => {
      if (!isDoneMainLoading) return

      if (!selectedModel.value && vehicleModelName.value && models.value?.length) {
        const alreadySelectedModel = models.value?.find((model) => model.description === vehicleModelName.value)

        if (alreadySelectedModel) {
          selectedModel.value = alreadySelectedModel
          needsCleaning.value = false
        }
      }
    })

    watch(selectedModel, (value) => {
      if (needsCleaning.value) root.$store.dispatch('cleanPacksAndServices')

      updateOffer('setVehicleModelName', value?.description)
      selectedMaterial.value = undefined

      needsCleaning.value = true
    })

    watch(selectedMaterial, async (material,prev) => {
      if (needsCleaning.value) root.$store.dispatch('cleanPacksAndServices')

      if (material) {
        const summary = await getSummaryByIdMaterial(material.idMat)
        updateOffer('setVehicleModelBySubname', {
          summary,
          model: selectedModel.value,
          material, rates: rates.value,
          needsUpdate: needsCleaning.value,
          isEditing: isEditing.value
        })
      } else {
        updateOffer('setVehicleModelBySubname', undefined)
      }
      if(prev) updateOffer('setCampaignClean',undefined)

      needsCleaning.value = true
    })

    watch(materials, (newMaterialsValue) => {
      if (isEditing.value && !selectedMaterial.value && vehicleModelSubname.value && newMaterialsValue?.length) {
        const alreadySelectedMaterials = newMaterialsValue?.find((material) => material.description === vehicleModelSubname.value)

        if (alreadySelectedMaterials) {
          selectedMaterial.value = alreadySelectedMaterials
          needsCleaning.value = false
        }
      } else if (newMaterialsValue.length === 1) {
        selectedMaterial.value = newMaterialsValue[0]
      }
    })

    watch(rates, (value) => {
      root.$store.dispatch('setCatalogCCAARatesOptions', value)
    })

    function updateOffer(action: string, param: any) {
      root.$store.dispatch(action, param)
      validateForm()
    }

    function validateForm() {
      emit('validateForm')
    }

    function handleCO2RateChange(value: string) {
      updateOffer('setVehiclePriceRateCCAA', value)
    }

    function handleEnableEditing() {
      confirmEditing.value = false
    }

    return {
      t,
      offer,
      confirmEditing,
      InputTypes,
      modelRules,
      modelDescriptionRules,
      priceRateCCAARules,
      vehicleModelName,
      vehicleModelSubname,
      vehicleModelPriceRate,
      listCampaigns,
      vehiclePriceRateCCAA,
      vehicleDiscountPriceRate,
      vehicleDiscountPriceRatePercent,
      vehicleTotalPrice,
      models,
      isModelsLoading,
      materials,
      isMaterialsLoading,
      selectedModel,
      selectedMaterial,
      isModelsSummaryLoading,
      selectedRate,
      isCO2RateLoading,
      rates,
      isEditing,
      haveCO2Rates,
      handleCO2RateChange,
      updateOffer,
      validateForm,
      handleEnableEditing
    }
  },
})
