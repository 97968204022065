<template>
  <mapit-dialog :dialog="dialog" @close="close" customId="popup-search-mapit">
    <template v-slot:body>
      <v-row no-gutters class="px-8 m-0">
        <v-col class="col-12">
          <h3 class="big-title">xxxxxLxxxxxLxxxxx</h3>
          <h3 class="custom-title">{{ $t(`honda.registration.mapit-wizard.used.mapit-title`) }}</h3>
          <p class="custom-subtitle">{{ $t(`honda.registration.mapit-wizard.used.mapit-subtitle`) }}</p>
        </v-col>
        <v-col class="col-md-6 col-6 m-auto">
          <p class="label-field py-1 my-0 align-left">
            {{ $t(`honda.registration.services.honda-mapit.lbl-honda-mapit`)}}
          </p>
          <v-form v-model="validMapitCode">
            <available-device-lookup v-model="mapit" required autofocus />
          </v-form>
        </v-col>
      </v-row>
      <v-row no-gutters class="m-0">
        <v-col class="col-md-12 col-12 result-info-box-success" v-if="success">
          <span class="icon-mapit">R</span><div class="info-text">{{ $t('honda.registration.mapit-wizard.used.mapit-success') }}</div>
        </v-col>
        <v-col class="col-md-12 col-12 result-info-box-error" data-cy="result-info-box-error" v-else-if="error">
          <span class="icon-mapit">T</span><div class="info-text">{{ error }}</div>
        </v-col>
        <v-col class="col-md-12 col-12 result-info-box-transp" v-else>
        </v-col>
        <v-col class="col-md-12 col-12 result-info-box-success px-0 pb-6" v-if="success && account.email">
          <div class="info-text text-center px-0">{{ account.email }}<br/>{{ account.firstName }} {{ account.lastName }} {{ account.document && `(${account.document})` }}</div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer-center>
      <v-btn data-cy="popup-search-mapit-continue" class="btn-primary" :key="buttonKey" text @click.once="continueRegistration" :disabled="loading || !validMapitCode" :loading="loading">{{
          $t('buttons.install-device')
        }}</v-btn>
    </template>
  </mapit-dialog>
</template>

<script>
import MapitDialog from '@/components/dialog/MapitDialog'
import api from '@/api';
import AvailableDeviceLookup from "@/components/general/AvailableDeviceLookup"

export default {
  name: "DialogMapitRegistration",
  components: {
    AvailableDeviceLookup,
    MapitDialog
  },
  props: [ 'dialog', 'loading', 'account'],
  data() {
    return {
      buttonKey: 1,
      codemapit: '',
      error: '',
      success: false,
      mapit: null,
      validMapitCode: false
    };
  },
  watch: {
    dialog: function(val) {
      this.codemapit = ''
      this.error = '',
        this.success = false,
        this.mapit = null
    },
    mapit(val) {
      if (val) {
        this.success = true
      }
    }
  },
  methods: {
    close(){
      this.$emit('close')
    },
    continueRegistration() {
      if(this.validMapitCode) {
        this.$emit('continue', this.mapit)
      }
      this.buttonKey++
    },
  }
};
</script>

<style lang="scss">
.popup-search-mapit{
  padding-left: 0px !important;
  padding-right: 0px !important;
  .big-title{
    text-align: center;
    font: normal normal normal 24px/31px 'mapitfont-v1';
    letter-spacing: 0px;
    color: var(--v-secondary-base);
    opacity: 1;
  }
  .custom-title{
    text-align: center;
    font: normal normal 600 24px/30px 'ProximaNovaExCnSBold';
    letter-spacing: 0px;
    color: var(--v-gray-darken4);
    text-transform: uppercase;
    opacity: 1;
  }

  .custom-subtitle{
    text-align: center;
    font: normal normal normal 16px/26px 'ProximaNovaRegular';
    letter-spacing: 0px;
    color: var(--v-gray-darken4);
    opacity: 1;
  }
  .mapit-text-field-vehicle{
    fieldset{z-index: 2;}
    input {
      padding-bottom: 22px;
      z-index: 1;
    }
  }
  .result-info-box{
    &-success, &-warning, &-error, &-transp{
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      .info-text{
        text-align: left;
        font: normal normal 600 16px/20px 'ProximaNovaSemibold';
        letter-spacing: 0px;
        padding-left: 15px;
      }
    }

    &-transp{
      visibility: hidden;
    }
    &-success{
      background: rgba(0,128,62,0.1) 0% 0% no-repeat padding-box;
      color: var(--v-secondary-base);
    }
    &-warning{
      background: rgba(252,164,0,0.1) 0% 0% no-repeat padding-box;
      color: var(--v-warning-base);
    }
    &-error{
      background: rgba(220,24,45,0.1) 0% 0% no-repeat padding-box;
      color: var(--v-primary-base);
    }
  }
}

</style>
