<template>
  <v-list-item
    v-on="$listeners"
    v-bind="$attrs"
    @click="loan.document ? downloadLoanDocument() : $emit('toggleDialog', loan)"
  >
    <v-list-item-title>
      {{ label || $t('dealer-bike.detail.actions.download-loan-doc') }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import { downloadBlob } from '@/helpers/downloadFile'
import { DealerBikeHelper } from '@/helpers/dealer-bike-helper'

export default {
  name: 'DownloadLoanListItem',
  props: [ 'loan', 'label' ],
  methods: {
    ...mapActions([ 'setAlertResult' ]),
    async downloadLoanDocument() {
      try {
        const helper = new DealerBikeHelper(await Auth.currentUserCredentials())
        const res = await helper.fetchLoanDocument(this.loan.document)
        const filenameI18nKey = this.loan.returnedAt ? 'dealer-bike.loans.download-doc.return-filename' : 'dealer-bike.loans.download-doc.handover-filename'
        const filename = this.$t(filenameI18nKey, {
          regNumber: this.loan.dealerBike.vehicle.registrationNumber,
          date: moment(this.loan.scheduledFor).utc().format('YYYYMMDDHHmm'),
        })
        downloadBlob(res.blob, filename)
        await this.setAlertResult({
          type: 'success',
          stringId: 'toast.download-doc.success',
          open: true,
        })
      } catch ( e ) {
        console.error('Problem downloading document', e)
        await this.setAlertResult({
          type: 'error',
          stringId: 'toast.download-doc.error',
          open: true,
        })
      }
    },
  },
}
</script>
