






import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/utils/map-store'

import CardWrapper from '@/components/general/CardWrapper.vue'
import OfferTable from './OfferTable.vue'

export default defineComponent({
  name: 'Offers',
  components: { CardWrapper, OfferTable },
  setup(props, { root }) {
    const { getGroup: group } = mapGetters(root.$store.getters)

    function refreshOffersList() {
      root.$store.dispatch('loadOffersList', group.value.dealerId)
    }

    return {
      group,
      refreshOffersList,
    }
  },
})
