
import { computed, defineComponent, PropType } from '@vue/composition-api'

import { Country } from '@mapit/common-utils/dist/types'
import { getCountry, PostalAddress } from '@/utils/administrative-region-helper'

import AddressFormGroup from '@/components/repairshop/AddressFormGroup.vue'
import OpeningHoursSchedule from '@/components/repairshop/OpeningHoursSchedule.vue'

export default defineComponent({
  name: 'LocationFormGroup',
  components: {
    OpeningHoursSchedule,
    AddressFormGroup,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    translationPrefix: {
      type: String,
      required: false,
    },
    dealerCountry: {
      type: String as PropType<Country>,
      required: false,
    },
  },
  setup(props) {
    const country = computed(() => {
      return getCountry(props.value.address as PostalAddress, props.dealerCountry)
    })

    return {
      country,
      location: props.value,
    }
  },
})
