












import { defineComponent, onMounted, onUnmounted, watch, ref } from '@vue/composition-api'
import { formatToISODate } from '@/utils/date-helper'
import MIframe from '@/components/atom/MIframe.vue'

export default defineComponent({
  name: 'LastNews',
  components: { MIframe },
  props: {
    branch: {
      type: String,
      required: true,
      default: 'es',
    },
    display: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { root, emit }) {
    const status = ref('')

    const iframeSrc = `${process.env.VUE_APP_WEB}/news-${
      props.branch === 'us' ? 'es' : props.branch
    }`

    function showNews() {
      status.value = 'fade-in'
    }

    function hideNews() {
      status.value = 'fade-out'
      setTimeout(() => (status.value = ''), 490)
    }

    function handleClose() {
      emit('hideNews')
    }

    function getIframeMessage(event: any) {
      if (event.origin !== process.env.VUE_APP_WEB) {
        return
      }

      const featureDateFromEvent = formatToISODate(event.data)
      const featureDateFromStore = localStorage?.getItem('last-news')

      localStorage?.setItem('last-news', featureDateFromEvent)
      if (featureDateFromEvent !== featureDateFromStore && featureDateFromStore !== 'disable-news') {
        emit('showNews')
      }
    }

    onMounted(() => {
      window.addEventListener('message', getIframeMessage)
    })

    onUnmounted(() => {
      window.removeEventListener('message', getIframeMessage)
    })

    watch(
      () => props.display,
      (display) => (display ? showNews() : hideNews()),
    )

    return {
      handleClose,
      iframeSrc,
      status,
    }
  },
})
