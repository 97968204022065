















































import { defineComponent, computed, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'CardTrend',
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    title1: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    value1: {
      type: [Number, String],
      required: false,
    },
    percent: {
      type: Number,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
    title2: {
      type: String,
      required: false,
    },
    value2: {
      type: [Number, String],
      required: false,
    },
    title3: {
      type: String,
      required: false,
    },
    value3: {
      type: [Number, String],
      required: false,
    },
  },
  setup(props) {
    const { value1, value2, value3 } = toRefs(props)

    const isEmpty = computed(() => {
      return (
        !(value1?.value || value1?.value === 0) &&
        !(value2?.value || value2?.value === 0) &&
        !(value3?.value || value3?.value === 0)
      )
    })

    return {
      isEmpty,
    }
  },
})
