export enum Variant {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
  Disabled = 'disabled',
}

export type Theme = {
  [key in Variant]: {
    colorIcon: string
    colorText: string
    colorBackground: string
  }
}
