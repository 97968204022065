<template>
  <v-stepper v-model="currentStep" alt-labels class="stepper-wrapper">
    <v-stepper-header class="header-stepper-wrapper">
      <v-divider class="first_divider_stepper" :style="divider_style(0)" />

      <v-stepper-step
        :complete="currentStep > 1"
        class="stepper_step2"
        step="1"
      >
        {{ $t('honda.registration.stepper.step_2') }}
      </v-stepper-step>

      <v-divider class="divider_stepper" :style="divider_style(1)" />
      <v-stepper-step
        :complete="currentStep > 2"
        class="stepper_step3"
        step="2"
      >
        {{ $t('honda.registration.stepper.step_3') }}
      </v-stepper-step>

      <v-divider class="divider_stepper" :style="divider_style(2)" />
      <v-stepper-step
        :complete="currentStep > 3"
        class="stepper_step4"
        step="3"
      >
        {{ $t('honda.registration.stepper.step_4') }}
      </v-stepper-step>

      <v-divider class="divider_stepper" :style="divider_style(3)" />
      <v-stepper-step
        :complete="currentStep > 4"
        class="stepper_step5"
        step="4"
      >
        {{ $t('honda.registration.stepper.step_5') }}
      </v-stepper-step>

      <v-divider class="divider_stepper" :style="divider_style(4)" />
      <v-stepper-step
        :complete="currentStep > 5"
        class="stepper_step6"
        step="5"
      >
        {{ $t('honda.registration.stepper.step_6') }}
      </v-stepper-step>

      <v-divider class="divider_stepper" :style="divider_style(5)" />
      <v-stepper-step
        :complete="currentStep > 6"
        class="stepper_step7"
        step="6"
      >
        {{ $t('honda.registration.stepper.step_7') }}
      </v-stepper-step>

      <v-divider class="divider_stepper" :style="divider_style(6)" />
      <v-stepper-step step="7" class="stepper_step8">
        {{ $t('honda.registration.stepper.step_8') }}
      </v-stepper-step>
      <v-divider class="last_divider_stepper" :style="divider_style(0)" />
    </v-stepper-header>

    <v-stepper-items class="mx-0 my-0">
      <vehicle :step="1" @nextStep="nextStep" @backStep="backStep"/>

      <services-only-mapit v-if="isHondaMapitRegistration" :step="2" @nextStep="nextStep" @backStep="backStep"/>
      <services v-else :step="2" @nextStep="nextStep" @backStep="backStep"/>

      <account :step="3" @nextStep="nextStep" @backStep="backStep"/>
      <detail :step="4" @nextStep="nextStep" @backStep="backStep" />
      <download-app :step="5" @nextStep="nextStep" @backStep="backStep"/>
      <signature :step="6" @nextStep="nextStep" @backStep="backStep"/>
      <finish-detail :step="7" @nextStep="nextStep" @backStep="backStep" @archiving="archiving"/>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapGetters } from "vuex";
import { newRegistration } from '@/utils/constants'

import Account from './account/Account.vue'
import Detail from './detail/Detail.vue'
import DownloadApp from './after/DownloadApp.vue'
import FinishDetail from './detail/FinishDetail.vue'
import Services from './services/Services.vue'
import ServicesOnlyMapit from "@/components/registrations/HondaRegistration/services/ServicesOnlyMapit.vue";
import Signature from './after/Signature.vue'
import Vehicle from './vehicle/Vehicle.vue'

export default {
  name: 'Stepper',
  components: { Vehicle, Services, Account, Detail, Signature, DownloadApp, FinishDetail, ServicesOnlyMapit },
  computed: {
    ...mapGetters({
      services: 'getServices',
      account: 'getAccount',
      getId: 'getId',
      currentStep: 'getCurrentStep',
      vehicle: 'getVehicle',
      subtype: 'getRegistrationSubtype'
    }),
    isHondaMapitRegistration() {
      return this.$route.query.forcedType === 'HONDA_MAPIT'
    },
  },
  methods: {
    divider_style(step) {
      return this.currentStep > step
        ? 'border: 1px solid var(--v-primary-base) !important; background-color:var(--v-primary-base);'
        : 'border: 1px solid var(--v-white-base) !important;'
    },
    backStep() {
      if (this.currentStep > newRegistration.MIN_STEPS_HONDA_REGISTRATION) {
        if(!this.getId || this.getId && this.currentStep === 6 && this.services && this.services['honda-mapit'].selected) {
          this.$store.dispatch('changeCurrentStep', this.currentStep - 1)
        }
      } else {
        this.$store.dispatch('resetRegistration')
        this.$router.push('/registrations/mapit-only')
      }
    },
    nextStep(numNextSteps = 1) {
      if (this.currentStep < newRegistration.MAX_STEPS_HONDA_REGISTRATION) {
        if(this.currentStep === 4 && (this.vehicle.demoBike || this.vehicle.forcedDemoBike)){
          this.$router.push('/')
          this.$store.dispatch('resetRegistration')
        } else if(this.currentStep === 4 && this.services && !this.services['honda-mapit'].selected) { //detail step whitout email
          this.$store.dispatch('changeCurrentStep', this.currentStep + 2)
        } else {
          this.$store.dispatch('changeCurrentStep', this.currentStep + numNextSteps)
        }
      }
    },
    archiving(state) {
      this.$emit('archiving', state)
    }
  },
}
</script>

<style scoped>
.header-stepper-wrapper {
  box-shadow: none;
}
.divider_stepper {
  margin: 50px -70px !important;
}
.first_divider_stepper {
  margin: 50px -60px 0px 2% !important;
}
.last_divider_stepper {
  margin: 50px -30px !important;
  visibility: hidden;
}
</style>
