<template>
  <div class="user-details data-card">
    <h2>{{ client.name || $t('users.loading.no-customer') }}</h2>

    <div class="details">
      <template v-if="client">
        <div class="user-details">
          <h3>{{ $t('users.detail.title') }}</h3>

          <dl>
            <dt>{{ $t('users.detail.document') }}</dt>
            <dd>{{ client.document || '-' }}</dd>

            <dt>{{ $t('users.detail.birth-date') }}</dt>
            <dd>{{ client.dob | formatDate }}</dd>

            <dt>{{ $t('users.detail.bank-data') }}</dt>
            <dd>{{ hasBankData }}</dd>
          </dl>
        </div>

        <div class="contact-details">
          <h3>{{ $t('users.contact.title') }}</h3>

          <dl>
            <dt>{{ $t('users.contact.email') }}</dt>
            <dd>{{ client.email }}</dd>

            <dt>{{ $t('users.contact.address') }}</dt>
            <dd>{{ client.address }}</dd>

            <dt>{{ $t('users.contact.phone') }}</dt>
            <dd>{{ client.phone | formatPhone }}</dd>
          </dl>
        </div>
      </template>
    </div>

    <p class="warning-text">{{ $t('users.contact.consent') }}</p>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { useI18n } from '@/i18n'
import { buildAddress } from '@/utils/account'

export default defineComponent({
  name: 'UserDetail',
  props: {
    user: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n()

    const client = computed(() => {
      const { firstName = '', lastName = '', document = {}, address = {} } = props.user || {}

      return {
        ...props.user,
        name: `${firstName} ${lastName}`.trim(),
        document: document?.number,
        address: buildAddress(address),
      }
    })

    const hasBankData = computed(() => {
      return t(`users.detail.${props.user?.stripeId ? 'saved' : 'not-saved'}`)
    })

    return {
      client,
      hasBankData,
    }
  },
})
</script>

<style scoped>
h3 {
  font-family: 'ProximaNovaSemibold', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 0.6em;
  color: #000;
}

dt {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  color: #777;
}

dd {
  font-family: 'ProximaNovaSemibold', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.6em;
  color: #000;
}

p {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  color: #777;
}

.details {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  margin-bottom: 1em;
}

.user-details,
.contact-details {
  flex: 1 1 50%;
}

.warning-text {
  color: red;
  margin-bottom: 0;
}
</style>
