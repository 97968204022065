
import {computed, defineComponent, ref} from "@vue/composition-api";
import {mapGetters} from "@/utils/map-store";

export default defineComponent({
  name: "Landbot",
  setup(props, {root}) {
    const {
      getBranch: branch,
      getCapabilities: capabilities,
    } = mapGetters(root.$store.getters)

    const show = ref(false)

    const instanceLoaded = ref(false)
    const zoomInTransition = {
      enterActiveClass: 'animated faster transform-origin fadeIn zoomIn',
      leaveActiveClass: 'animated faster transform-origin fadeOut zoomOut',
    }

    const isChatbotPermitted = computed(() => {
      return capabilities.value.chatbot?.mode === 'FULL'
    })

    function fetchLandbot() {
      if (instanceLoaded.value) return
      // @ts-ignore
      // eslint-disable-next-line no-undef,@typescript-eslint/no-unused-vars
      const landbot = new Landbot.Container({
        configUrl: getConfigUrl(),
        container: '#landbot-anchor',
      })

      if (landbot) {
        instanceLoaded.value = true
      }
    }

    function handleClick() {
      show.value = false
    }

    function getConfigUrl() {
      switch (branch.value) {
        case 'ES':
          return process.env.VUE_APP_LANDBOT_CONFIG_URL_ES
        case 'PT':
          return process.env.VUE_APP_LANDBOT_CONFIG_URL_PT
        default:
          return process.env.VUE_APP_LANDBOT_CONFIG_URL_EN
      }
    }

    function toggleLandbot() {
      show.value = !show.value
    }

    return {
      show,
      zoomInTransition,
      isChatbotPermitted,
      fetchLandbot,
      handleClick,
      toggleLandbot
    }
  }
})
