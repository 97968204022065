
















import {computed, defineComponent, onMounted, ref} from "@vue/composition-api";

export default defineComponent({
  name: 'LabelTooltip',
  props: {
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: String,
      default: '200px'
    },
    show: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup(props, {refs, slots, root}) {
    const hasTooltipSlot = computed(() => {
      return !!slots.tooltip
    })
    const hasOverflow = ref(false)

    onMounted(() => {
      root.$nextTick(() => {
        const element = refs.content as HTMLElement

        if (element) {
          for (const child of element.children) {
            if (hasElementOverflow(child)) {
              hasOverflow.value = true
              break
            }
          }
        }
      })
    })

    function hasElementOverflow(element: Element) {
      return element?.scrollHeight > element?.clientHeight || element?.scrollWidth > element?.clientWidth
    }
    return {
      hasTooltipSlot,
      hasOverflow
    }
  }
})
