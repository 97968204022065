import { ref } from '@vue/composition-api'

import { Dialog } from '@/types/dialog'
import { useI18n } from '@/i18n'

export const useDialog = () => {
  const i18n = useI18n()
  const scope = ref<string>('')

  const status = ref<boolean>(false)
  const confirm = ref<any>()
  const content = ref<Dialog>({
    title: 'title',
    content: 'content',
    confirm: 'confirm',
  })

  function t(key: string) {
    return i18n.t(`${scope.value}.${key}`)
  }

  function setConfirm(action: any) {
    confirm.value = action
  }

  function setContent(scopeId: string, id?: string) {
    scope.value = scopeId

    content.value = {
      icon: t('icon'),
      iconColor: t('iconColor'),
      title: t('title'),
      content: t(id || 'content'),
      confirm: t('confirm'),
    }
  }

  function open() {
    status.value = true
  }

  function close() {
    status.value = false
  }

  return {
    close,
    confirm,
    content,
    open,
    setConfirm,
    setContent,
    status,
  }
}
