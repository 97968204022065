import coreApi from '@/api/core.api'

export const NUM_CHARACTERS_VALID_VIN = 17

export const status = {
  VIN_VALID: { status: 'honda.registration.vehicle.validations.vin_valid', error: false, key: 'VIN_VALID' },
  REALTA_VIN: { status: 'honda.registration.vehicle.validations.vin_unsubscribed', error: true, key: 'REALTA_VIN' },
  NO_VIN_BDD: { status: 'honda.registration.vehicle.validations.vin_invalid', error: false, key: 'NO_VIN_BDD' },
  ERROR: { status: 'honda.registration.vehicle.validations.vin_error', error: true, key: 'ERROR' },
  ALREADY_USED_VIN: {
    status: 'honda.registration.vehicle.validations.vin_already_used',
    error: true,
    key: 'ALREADY_USED_VIN',
  },
  ALREADY_USED_VIN_REGISTRATION: {
    status: 'honda.registration.vehicle.validations.vin_already_used_registration',
    error: true,
    key: 'ALREADY_USED_VIN_REGISTRATION',
  },
}

const defaultResp = {
  model: null,
  hasDevice: false,
  vehicleId: null,
  account: {
    firstName: null,
    lastName: null,
    email: null,
    document: null,
  },
}

export const vinValidation = async (vin, signal) => {
  return coreApi
    .getVinStatus(vin.toUpperCase(), signal)
    .then(({ data }) => {
      if (data.vinInUse) {
        return data.account
          ? { ...status.ALREADY_USED_VIN, ...data }
          : data.registrationId
          ? { ...status.ALREADY_USED_VIN_REGISTRATION, ...data }
          : { ...status.REALTA_VIN, ...data }
      }
      return !data.vin ? { ...status.NO_VIN_BDD, ...data } : { ...status.VIN_VALID, ...data }
    })
    .catch(() => {
      return { ...defaultResp, ...status.ERROR }
    })
}
