



















import { defineComponent, PropType } from '@vue/composition-api'
import ToolbarCard from '@/components/registrations/HondaRegistration/ToolbarCard.vue'
import VueI18n from 'vue-i18n'

export default defineComponent({
  name: 'CardWrapper',
  components: { ToolbarCard },
  props: {
    title: {
      type: String as PropType<string | VueI18n.TranslateResult>,
      required: true,
    },
    hideCloseBtn: {
      type: Boolean,
      default: true,
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
    paddingText: {
      type: String,
      default: 'pa-8',
    },
  },
})
