<template>
  <v-app class="stocks-manager-wrapper pb-10" id="stocks-manager">
    <v-card class="stocks-manager-card-wrapper">
      <v-card-title>
        <toolbar-card
          :hideCloseBtn="true"
          :title="$t('stocks-manager.title')"
        />
      </v-card-title>
      <v-divider class="stepper-separator mx-6 m" />
      <v-card-text class="px-6">
        <!-- TABS & CONTENT TABLES-->
        <v-tabs
          v-model="tab"
          centered
          icons-and-text
          hide-slider
          class="full-width pb-4 tabs"
        >
          <v-tab href="#tab-1" class="tab-item">
            <span class="text-title">{{ $t('stocks-manager.tab-create-title') }}</span>
            <v-icon class="icon-mapit">y</v-icon>
          </v-tab>

          <v-tab href="#tab-2" class="tab-item">
            <span class="text-title">{{ $t('stocks-manager.tab-dealer-list-title') }}</span>
            <v-icon class="icon-mapit">&#xe005;</v-icon>
          </v-tab>

          <v-tab href="#tab-3" class="tab-item" v-if="permissions.stocks.canManage">
            <span class="text-title">{{ $t('stocks-manager.tab-admin-list-title') }}</span>
            <v-icon class="icon-mapit">&#xe021;</v-icon>
          </v-tab>
          <!-- END ADMIN DEALER LIST TAB -->
        </v-tabs>


        <v-tabs-items v-model="tab">

          <v-tab-item value="tab-1">
            <v-card flat>
              <create-order @goTab="createdOrder"/>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-card flat>
              <manage-orders :orders="myOrders" :simpleUi="true" />
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-3" v-if="permissions.stocks.canManage">
            <v-card flat>
              <manage-orders :orders="orders"/>
            </v-card>
          </v-tab-item>
          <!-- END ADMIN DEALER LIST TAB -->

        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import ToolbarCard from '@/components/registrations/HondaRegistration/ToolbarCard'
import CreateOrder from './sections/CreateOrder.vue'
import ManageOrders from './sections/ManageOrders.vue'
import {OrderStatus} from '@mapit/honda-stock-dynamodb/dist/types'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'StocksManager',
  components: { ToolbarCard, CreateOrder, ManageOrders },
  props: [],
  data() {
    return {
      tab: ''
    }
  },
  methods: {
    refreshStocksList() {
      this.$store.dispatch('loadOrdersList')
    },
    createdOrder(newTab) {
      this.$store.dispatch('loadOrdersList')
        .finally(() => {
          if(newTab) this.tab = newTab
        })

    }
  },
  created() {
    this.refreshStocksList()
    if(this.allowStockAdmin){
        this.$store.dispatch('loadDalersList', true)
    }
  },
  computed: {
    ...mapState(['permissions']),
    ...mapGetters({
      group: 'getGroup',
      orders: 'getOrders'
    }),
    myOrders(){
      return this.orders.filter(item => item.applicant.dealer.id === this.group.dealerId && item.status !== OrderStatus.Withdrawn)
    },
    allowStockAdmin() {
      return this.$store.getters.isStockUser || this.$store.getters.isAdmin
    },
  },
}
</script>

<style lang="scss" src="./stocks-manager-styles.scss" />
