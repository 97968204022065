<template>
  <message>
    <template slot="icon">
      <i class="far fa-calendar-times text-error" style="font-size: 100px"/>
    </template>
    <template slot="text">
      <h3 class="text-error p-t-20">ELIMINAR CITA</h3>
      <p>¿Está seguro que desea eliminar la cita? <br/>
        Para programar una nueva cita pongáse en contacto con el cliente.
      </p>
    </template>
    <template slot="bottom">

      <button class='btn col-auto btn-success' @click="$emit('confirm')"><i class="fas fa-angle-right"></i>
        Aceptar
      </button>

    </template>
  </message>
</template>
<script>
  import Message from '@/components/dialog/Message';

  export default {
    name: 'RevisionDelete',
    components: {
      Message
    }
  }
</script>
