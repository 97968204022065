<template>
  <div class="field">
    <input
      v-bind="$attrs"
      ref="input"
      :name="name"
      @input="$emit('input', $event.target.value);"
      :value="value"
      class="form-input"
      v-validate="rules"
      :class="{ 'error': errors.collect(name).length>0 }"
    />
    <small v-if="errors.collect(name).length>0" class="field-text text-error">{{  errors.first(name) }}</small>
  </div>
</template>

<script>


export default {
    inject: ['$validator'],
    /*$_veeValidate: {
      // value getter
      value() {
        return this.$el.value;
      },
      // name getter
      name() {
        return this.name;
      }
    },*/
    props: ["name", "value", "rules"],
    mounted() {
      // synbc the input to the initial value
      //this.$refs.input.value = this.value;
    }
  };
</script>
