






























































import {InputTypes} from '@/utils/constants'
import MapitInput from '@/components/general/MapitInput.vue'
import DiscountField from '@/components/general/DiscountField.vue'
import {computed, defineComponent} from '@vue/composition-api'
import {Complement, Complements} from '@mapit/honda-offers-builder/dist/types'
import {mapGetters} from '@/utils/map-store'

// @ts-ignore
import {complementDiscountRules, complementDiscountRulesPercentage} from '@/helpers/offer-manager-rules'
import PriceField from '@/components/general/PriceField.vue'

export default defineComponent({
  name: 'Complements',
  components: { MapitInput, DiscountField, PriceField },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {root, emit}){
    const {
      getPacks: packsList,
      getComplementsTotalPrice: complementsTotal,
      getComplementSuggestions: complementSuggestions
    } = mapGetters(root.$store.getters)

    const complementsList = computed({ // TODO: replace on change vue3
      get: (): Complements | undefined => root.$store.getters.getComplements,
      set: (value: Complements | undefined) => root.$store.commit('SET_COMPLEMENTS', value)
    })

    function validateForm() {
      emit('validateForm')
    }

    function addComplement() {
      root.$store.dispatch('addComplement')
      validateForm()
    }

    function updateOffer(mutation: string, value: any) {
      root.$store.commit(mutation, value)
      validateForm()
    }

    function setComplementPrice(item: Complement, value: string, index: number) {
      if(item.price !== parseFloat(value)) { // The m-price-field edits the value with a mask. Only modify if the value changes... sample 50,00 === 50.00
        updateOffer('SET_COMPLEMENT_PRICE', { price: parseFloat(value), index })
      }
    }

    return {
      InputTypes,
      complementDiscountRules,
      complementDiscountRulesPercentage,
      complementsList,
      packsList,
      complementsTotal,
      complementSuggestions,
      setComplementPrice,
      addComplement,
      validateForm,
      updateOffer
    }
  }
})
