<template>

	<div>

		<span v-if="data.PACK === 'HONDAPLUS' || data.PACK === 'HONDAPLUSMAPIT'"  style="position: fixed; top:10px; right:20px; " class="f-s-10">Contrato Honda plus {{data.shop_name}} | {{data.Vin}}</span>

		<div v-if="data.PACK === 'HONDAPLUS' || data.PACK === 'HONDAPLUSMAPIT'" id="seleccion">
			<p class="p-t-40"><strong>Contrato para la Extensión de la Garantía {{data.shop_name}} | {{data.Vin}} GARANTÍA COMERCIAL ADICIONAL<br />"HONDA PLUS"</strong></p><p>La presente garantía comercial adicional (en adelante, la “Garantía Comercial Adicional”) al plazo de garantía legal se otorga sin perjuicio y además de los derechos reconocidos al usuario en el Manual de Mantenimiento y Garantía Comercial, o en la ley por la falta de conformidad del producto.</p><p>1.- La presente Garantía Comercial Adicional se otorga específicamente para el producto con el número de bastidor indicado en este documento, sujeta al alcance y condiciones aquí también establecidas, así como al pago del precio convenido.</p><p>2.- El garante es <strong>Honda Motor Europe Limited Sucursal en España</strong> (en adelante, “<strong>HONDA</strong>”), con domicilio en C/ del Mar del Nord, s/n, Polígono Industrial Can Roca, Santa Perpètua de Mogoda, 08130 Barcelona. Esta garantía se prestará materialmente a través de los Concesionarios y Servicios Oficiales HONDA que en cada momento formen parte de la Red Oficial de Concesionarios y Servicios Oficiales HONDA siempre que además estén adheridos a este programa. </p>
			<p>3.- El plazo de duración de la Garantía Comercial Adicional será de <strong>VEINTICUATRO MESES</strong>, contados a partir de la fecha de finalización del plazo de conformidad legal de dos años, en el bien entendido que, en cualquier caso, el plazo máximo de duración total conjunto de la garantía legal y la Garantía Comercial Adicional será de CUARENTA Y OCHO MESES desde la fecha de matriculación de la moto, o si ésta permanece sin matricular, desde la fecha de venta al usuario. Si la garantía inicial o periodo de conformidad del producto excediera de dos años, la presente garantía tendrá una duración igual al periodo comprendido entre la expiración de ese plazo de garantía inicial y los CUARENTA Y OCHO MESES conforme al cómputo anterior.</p>
			<p>4.- La presente Garantía Comercial Adicional sólo será de aplicación en Península y Baleares además de todos los países que figuran en el Manual de Garantía de la moto y siempre que se trate de una unidad que haya sido importada al mercado europeo por HONDA. </p>
			<p>Esta garantía alcanza solamente a piezas originales HONDA o instaladas y vendidas por HONDA. Asimismo, la presente garantía alcanza a los accesorios originales HONDA o accesorios comercializados por HONDA, que en su caso el usuario haya adquirido e incorporado a la moto objeto de la presente garantía únicamente en el momento de su compra, y siempre que dichos accesorios sean adquiridos de HONDA e instalados por HONDA o por cualquiera de sus Concesionarios o Servicios Oficiales en el mismo momento de compra de la moto. Los accesorios que, en su caso, quedan cubiertos por la presente garantía son únicamente los descritos al final del presente documento.</p>
			<p>5.- En caso de falta de conformidad –dentro del plazo de duración antes indicado- de cualquiera de las piezas montadas por HONDA –o por cualquiera de sus Concesionarios o Servicios Oficiales previa aceptación expresa de HONDA - en la moto, o de los accesorios referidos en el punto anterior el usuario tendrá derecho a exigir –siempre que se cumpla con todas las condiciones aquí establecidas- que se lleve a cabo alguno de los siguientes remedios: (i) la reparación de la pieza no conforme; o (ii) la sustitución de la pieza no conforme. </p>
			<p>6.- La obligación del Concesionario o Servicio Oficial HONDA, o de HONDA por extensión, bajo esta Garantía Comercial Adicional es únicamente la de reparar o sustituir cualquier pieza o accesorio de las indicados anteriormente. Corresponderá exclusivamente al Concesionario o Servicio Oficial HONDA, o en su caso a HONDA, la elección del remedio que en cada caso corresponda.</p>
			<p>7. Los materiales y/o la mano de obra serán gratuitos. No quedan cubiertos otros daños, o costes, ni la reparación de daños o sustituciones vinculados con cambios de piezas no originales HONDA o no instaladas y vendidas por HONDA. Tampoco los resultantes de trabajos inadecuados. Es competencia de HONDA la determinación del alcance y tipo de actuación a realizar en cada caso, así como las piezas a instalar. La propiedad de las piezas sustituidas pasarán a ser de HONDA, o del Concesionario o Servicio Oficial HONDA, sin que pueda exigirse su entrega al usuario.</p>
			<p class="break-print p-t-40">8.- Asimismo, con la contratación de la presente Garantía Comercial Adicional, los Concesionarios y Servicios Oficiales HONDA que estén adheridos a este programa:</p>
			<p>- Pondrán a disposición del usuario una moto de sustitución durante el periodo que dure la intervención conforme a este documento, sujeto en todo caso a disponibilidad de unidades para este fin.</p>
			<p>- Realizarán un diagnóstico gratuito anual del estado de la moto.</p>
			<p>- Ofrecerán al usuario una política de descuento en accesorios originales HONDA.</p>
			<p>9.- Son condiciones para mantener la vigencia de la presente Garantía Comercial Adicional el cumplimiento de todos los siguientes requisitos:</p>
			<p> 9.1 El usuario deberá acreditar que la pieza sustituida era la original o fue vendida e instalada por HONDA o un Concesionario o Servicio Oficial HONDA conforme a este documento y que los trabajos de montaje fueron realizados por HONDA o un Concesionario o Servicio Oficial HONDA (o personal autorizado) mediante factura de compra al efecto y, sólo en caso del accesorio, mediante evidencia del pertinente registro de la instalación incluido en el Manual de Mantenimiento y Garantía Comercial. <br />
				  9.2 El usuario deberá haber realizado todas las revisiones de la moto, conforme a las instrucciones que oportunamente figuran en el Manual de Mantenimiento y Garantía Comercial, así como aquellas otras solicitadas por el fabricante, incluidas las campañas de rellamada u otras acciones. <br />
				  9.3 El usuario deberá acreditar que ha pasado, al menos, una revisión anual de la moto.<br />
				  9.4 El usuario deberá haber utilizado piezas de recambio vendidas por HONDA o vendidas e instaladas por un Concesionario o Servicio Oficial HONDA.<br />
				  9.5 El usuario deberá haber cumplido con los presupuestos necesarios para que resulte de aplicación la garantía legal, así como cumplir con las instrucciones del fabricante, indicados en el Manual de Mantenimiento y Garantía Comercial entregado junto con el producto a su adquisición.<br /><br />
				10.- Resultan de aplicación a la presente Garantía Comercial Adicional las mismas exclusiones generales contenidas en el Manual de Mantenimiento y Garantía Comercial entregado al usuario junto con el producto a su adquisición. <br /><br />
				11.- En caso que concurra alguna de las circunstancias que permiten al usuario utilizar esta garantía, éste deberá dirigirse directamente de forma inmediata a un Concesionario o Servicio Oficial HONDA autorizado adherido a este programa y concertar una visita para que efectúen el trabajo necesario. Deberá aportar el comprobante de compra y pago del producto, donde necesariamente debe figurar la fecha de adquisición del mismo, así como la tarjeta acreditativa de la contratación de la presente garantía Comercial Adicional. No se admitirá ninguna reclamación que no cumpla estos requisitos. Adicionalmente, el usuario deberá entregar la información y documentación que razonablemente le sea requerida para verificar la concurrencia de todos los requerimientos que dan derecho al beneficio de esta garantía.</p>
			<p>12.-Información Básica sobre Protección de Datos<br/>
				•	Responsable: Honda Motor Europe Limited, Sucursal en España ("Honda")<br/>
				•	Finalidad: Registro de cesión y autorización de uso vehículo de cortesía<br/>
				•	Legitimación: Ejecución de un contrato<br/>
				•	Destinatarios: Sociedades del Grupo Honda (https://global.honda/about/group.html), Concesionarios Oficiales Honda (http://www.honda.es/motorcycles/dealer-search.html) y terceras empresas encargadas del tratamiento.<br/>
				•	Derechos: Acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional<br/>
				•	Información adicional:  Puede consultar la información adicional y detallada sobre Protección de Datos en nuestra página web: http://www.honda.es/motorcycles/useful-links/privacy-policy.html<br/>
			</p>
			<p class="break-print p-t-40">13.- Se entiende como lugar de cumplimiento de las obligaciones que derivan de este contrato, la dirección consignada en el apartado 2 anterior.</p>
			<p>14.- Para la solución de cualquier divergencia que emane de este contrato, las Partes se someten voluntariamente a los juzgados y tribunales competentes en el lugar de cumplimiento de la obligación según este documento.<br /></p>
			<p>{{data.shop_city}}, {{data.current_date}}</p>
			<table width="90%" border="0" align="center" cellpadding="0" cellspacing="0">
				<tbody>
				<tr>
					<td width="322"><strong>FIRMA Y DATOS DEL CONCESIONARIO</strong></td>
					<td width="14">&nbsp;</td>
					<td width="339"><strong>MOTO A LA QUE SE REFIERE ESTA GARANTÍA</strong></td>
				</tr>
				<tr>
					<td>Firma</td>
					<td>&nbsp;</td>
					<td>Marca: Honda</td>
				</tr>
				<tr>
					<td>Concesionario {{data.shop_name}}<br /></td>
					<td>&nbsp;</td>
					<td>Modelo: {{data.Model}}<br /></td>
				</tr>
				<tr>
					<td>Dirección {{data.shop_address}}</td>
					<td>&nbsp;</td>
					<td>Número de bastidor: {{data.Vin}}<br /></td>
				</tr>
				<tr>
					<td>Teléfono {{data.shop_phone}} </td>
					<td>&nbsp;</td>
					<td>Mapit: {{data.mapit_code}}</td>
				</tr>
				<tr v-if="data.Accessories">
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<td>Accesorios: {{data.Accessories}}</td>
				</tr>
				<tr>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td><strong>FIRMA Y DATOS DEL USUARIO</strong></td>
					<td>&nbsp;</td>
					<td rowspan="7">Por la presente el usuario confirma que con este documento se le hace entrega del Manual de Mantenimiento y Garantía Comercial de HONDA.</td>
				</tr>
				<tr>
					<td>Firma</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td>{{data.Name_Surname}}<br /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td>{{data.Address}}<br /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td>DNI {{data.Document}}<br /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td>{{data.Phone}}<br /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td>{{data.Mail}}<br /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td>{{data.City}}</td>
					<td>&nbsp;</td>
				</tr>
				</tbody>
			</table> <br /><br /><br /><br />
		</div>

		<!--
		<div class="WordSection1 p-t-40" v-if="data.PACK === 'HONDAMAPIT' || data.PACK === 'HONDAPLUSMAPIT'" :class="{'break-print':data.PACK === 'HONDAPLUSMAPIT'}">
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif"></span></p>
			<div id="id_1">
				<h4>Orden de domiciliación de adeudo directo SEPA</h4>
			</div>
			<br/>
			<br/>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif"></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Estimado usuario,</span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Mediante la firma de esta orden de domiciliación, el deudor autoriza (A) al acreedor a enviar instrucciones a la entidad del deudor para adeudar en su cuenta y (B) a la entidad para efectuar los adeudos en su cuenta siguiendo las instrucciones del acreedor. Como parte de sus derechos, el deudor está legitimado al reembolso por su entidad en los términos y condiciones del contrato suscrito con la misma. La solicitud de reembolso deberá efectuarse dentro de las ocho semanas que siguen a la fecha de adeudo en su cuenta, Puede obtener información adicional sobre sus derechos en su entidad financiera.</span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Código Mapit: {{data.mapit_code}}</span></p>
			<br/>
			<br/>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Referencia de la orden de domiciliación: <i style="mso-bidi-font-style:normal">{{data.creditor_ref}}</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family: &quot;Verdana&quot;,sans-serif">Identificador del acreedor: Mapit IoT</span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Nombre del acreedor: <i style="mso-bidi-font-style:normal">Mapit IoT, S.L.</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Dirección: <i style="mso-bidi-font-style:normal">Calle Casp 172 4B</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Código Postal - Población - Provincia: <i style="mso-bidi-font-style:normal">08013 Barcelona</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">País: España</span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif"></span></p>
			<br/>
			<br/>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Nombre del deudor: <i style="mso-bidi-font-style:normal"> {{data.Name_Surname}}</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Dirección del deudor: <i style="mso-bidi-font-style:normal">{{data.Address}}</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Código Postal - Población - Provincia: <i style="mso-bidi-font-style: normal">{{data.City}}</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">País del deudor: <i style="mso-bidi-font-style:normal">{{data.Country}}</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Switf BIC (puede contener 8 u 11 posiciones): <i style="mso-bidi-font-style:normal">{{data.SwiftBIC}}</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif"></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Número de cuenta - IBAN: <i style="mso-bidi-font-style:normal">{{data.IBAN}}</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif"></span></p>
			<p class=MsoNormal>
	            <span style='font-size:8.0pt;line-height:107%;font-family: "Verdana",sans-serif'>
	                <span style='mso-spacerun:yes'> </span>Tipo de pago:
	            <span style='mso-spacerun:yes'></span>
	            <i class="fa fa-check-square-o" aria-hidden="true"></i> Pago recurrente<span style='mso-spacerun:yes'>              </span>
	            <i class="fa fa-square-o" aria-hidden="true"></i> Pago único
	            </span>
			</p>
			<br/>
			<br/>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Fecha - Localidad: <i style="mso-bidi-font-style:normal">{{data.current_date}} - {{data.shop_city}}</i></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif"></span></p>
			<p class="MsoNormal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">Firma del deudor: </span></p>
			<br/>
			<br/>
			<br/>
			<br/>
			<p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif">TODOS LOS CAMPOS HAN DE SER CUMPLIMENTADOS OBLIGATORIAMENTE. UNA VEZ FIRMADA ESTA ORDEN DE DOMICILIACIÓN DEBE SER ENVIADA AL ACREEDOR PARA SU CUSTODIA.</span></b><span style="font-size:8.0pt;line-height:107%;font-family:&quot;Verdana&quot;,sans-serif"></span></p>
		</div>
		-->
	</div>
</template>

<script>
  import api from '@/api';
  import router from '@/router';

  export default {
    components: { },
    name: 'ContractHondaMapit',
    props: ["params"],
    data() {
      return {
        msg: ' ',
        error: null,
        isLoading: false
      };
    },
    created() {
      setTimeout(function(){
        console.log(this.data)
        print();
      }, 500);

    },
    mounted() {
    },
    methods: {
    },
    computed: {
      data(){
        if(this.params)
          return JSON.parse(this.params);
        else
          return {};
      }
    },
  };
</script>

<style lang="less">

	#seleccion{

		p {
			font-size: 12px;
		}

		table {
			font-size: 12px;
		}

		td {
			font-size: 12px;
		}
	}



	/* Font Definitions */
	#app, body{
		height: auto !important;

	}

	@media print {
		@page { size: auto; margin: 0mm;  /* this affects the margin in the printer settings */ }
		body { margin: auto 40px 30px 40px; }
		* { overflow: visible !important; }
	}

	@font-face {
		font-family: "MS Gothic";
		panose-1: 2 11 6 9 7 2 5 8 2 4;
		mso-font-alt: "\FF2D\FF33 \30B4\30B7\30C3\30AF";
		mso-font-charset: 128;
		mso-generic-font-family: modern;
		mso-font-pitch: fixed;
		mso-font-signature: -536870145 1791491579 134217746 0 131231 0;
	}

	@font-face {
		font-family: "Cambria Math";
		panose-1: 2 4 5 3 5 4 6 3 2 4;
		mso-font-charset: 1;
		mso-generic-font-family: roman;
		mso-font-pitch: variable;
		mso-font-signature: 0 0 0 0 0 0;
	}

	@font-face {
		font-family: Calibri;
		panose-1: 2 15 5 2 2 2 4 3 2 4;
		mso-font-charset: 0;
		mso-generic-font-family: swiss;
		mso-font-pitch: variable;
		mso-font-signature: -536859905 -1073732485 9 0 511 0;
	}

	@font-face {
		font-family: Verdana;
		panose-1: 2 11 6 4 3 5 4 4 2 4;
		mso-font-charset: 0;
		mso-generic-font-family: swiss;
		mso-font-pitch: variable;
		mso-font-signature: -1593833729 1073750107 16 0 415 0;
	}

	@font-face {
		font-family: "Segoe UI Symbol";
		panose-1: 2 11 5 2 4 2 4 2 2 3;
		mso-font-charset: 0;
		mso-generic-font-family: swiss;
		mso-font-pitch: variable;
		mso-font-signature: -2147483165 302055407 262144 0 1 0;
	}

	@font-face {
		font-family: "\@MS Gothic";
		panose-1: 2 11 6 9 7 2 5 8 2 4;
		mso-font-charset: 128;
		mso-generic-font-family: modern;
		mso-font-pitch: fixed;
		mso-font-signature: -536870145 1791491579 134217746 0 131231 0;
	}
	/* Style Definitions */

	p.MsoNormal,
	li.MsoNormal,
	div.MsoNormal {
		mso-style-unhide: no;
		mso-style-qformat: yes;
		mso-style-parent: "";
		margin-top: 0cm;
		margin-right: 0cm;
		margin-bottom: 8.0pt;
		margin-left: 0cm;
		line-height: 107%;
		mso-pagination: widow-orphan;
		font-size: 11.0pt;
		font-family: "Calibri", sans-serif;
		mso-ascii-font-family: Calibri;
		mso-ascii-theme-font: minor-latin;
		mso-fareast-font-family: Calibri;
		mso-fareast-theme-font: minor-latin;
		mso-hansi-font-family: Calibri;
		mso-hansi-theme-font: minor-latin;
		mso-bidi-font-family: "Times New Roman";
		mso-bidi-theme-font: minor-bidi;
		mso-fareast-language: EN-US;
	}

	.MsoChpDefault {
		mso-style-type: export-only;
		mso-default-props: yes;
		font-family: "Calibri", sans-serif;
		mso-ascii-font-family: Calibri;
		mso-ascii-theme-font: minor-latin;
		mso-fareast-font-family: Calibri;
		mso-fareast-theme-font: minor-latin;
		mso-hansi-font-family: Calibri;
		mso-hansi-theme-font: minor-latin;
		mso-bidi-font-family: "Times New Roman";
		mso-bidi-theme-font: minor-bidi;
		mso-fareast-language: EN-US;
	}

	.MsoPapDefault {
		mso-style-type: export-only;
		margin-bottom: 8.0pt;
		line-height: 107%;
	}

	@page WordSection1 {
		size: 595.3pt 841.9pt;
		margin: 0;
		mso-header-margin: 35.4pt;
		mso-footer-margin: 35.4pt;
		mso-paper-source: 0;
	}

	div.WordSection1 {
		page: WordSection1;
	}


</style>

