























import { defineComponent, ref, watch } from '@vue/composition-api'
import { mapGetters } from '@/utils/map-store'
import { setAppVersionToStore } from '@/utils/versions'
import MapitDialog from '@/components/dialog/MapitDialog.vue'

export default defineComponent({
  name: 'DialogVersion',
  components: { MapitDialog },
  setup(props, { root }) {
    const dialogOpen = ref(false)
    const { getAppVersion: appVersion } = mapGetters(root.$store.getters)

    const dialog = {
      title: root.$t('reload.title'),
      content: root.$t('reload.content'),
      cancel: root.$t('reload.cancel'),
      confirm: root.$t('reload.confirm'),
    }

    function onCancel() {
      setAppVersionToStore('pending', 30)
      root.$store.dispatch('setAppVersion') // reset version of the state
      dialogOpen.value = false
    }

    function onUpdate() {
      setAppVersionToStore(appVersion.value, 60 * 24)
      location.reload()
    }

    watch(appVersion, (newVersion) => {
      if (newVersion) {
        dialogOpen.value = true
      }
    })

    return {
      dialog,
      dialogOpen,
      onCancel,
      onUpdate,
    }
  },
})
