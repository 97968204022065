<template>
  <th class='align-left' v-if="isHeader">
      {{title}}
  </th>
  <td v-else>
    <v-row class="container-column" v-if="rowData">
		  <v-col class="col-12 p-r-5 align-left" >
			  <span class="f-s-14">{{getContainerDetailParam(rowData.container, 'Plate')}}</span><br/>
		  </v-col>
    </v-row>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {

      rowData: {
        type: Object,
        default: () => {
          return {
              container:{
                detail:{
                  Plate: '-',
                },
              },
          }
        }
      }
  },
  methods: {
    getContainerDetailParam(container, key){
      const detail = JSON.parse(container.detail);
      return detail[key];
    }
  },
  name: 'Plate'
}
</script>

<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>

