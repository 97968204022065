<template>
  <modal :active="active" @close="closeModal" :title="title">
    <template slot="body">
      <div class='columns' v-if='loading'>
        <div class='col-2 col-mx-auto p-t-20'>
          <loading-spinner ></loading-spinner>
        </div>
      </div>
      <div v-else>
        <v-row no-gutters v-if="mStep==0">
          <v-col class='col-12'>
            <revision-detail :request="cloned" :container="container" :customer="customer"></revision-detail>
          </v-col>
          <v-col class="col-12 p-t-20">
            <v-row no-gutters>
              <v-col class='col-5 text-right'>
                <button class='btn col-auto btn-success' @click="acceptAppointment()"><i class="fas fa-angle-right"></i>
                  Aceptar Cita
                </button>
              </v-col>
              <v-col class='col-1'></v-col>
              <v-col class='col-5 text-left'>
                <button class='btn col-auto btn-error' @click="rejectAppointment"><i class="fas fa-angle-right"></i> Eliminar Cita
                </button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <revision-confirm v-if="mStep==1"/>
        <revision-delete v-if="mStep==2" @confirm="confirmRejectAppointment"/>
        <revision-delete-confirm v-if="mStep==3" />
        <request-modified v-if="mStep==4" />
        <error-message v-if="mStep==5" />
      </div>
    </template>
  </modal>
</template>
<script>

  import RevisionDetail from './RevisionDetail';
  import RevisionConfirm from './RevisionConfirm';
  import RevisionDelete from './RevisionDelete';
  import RevisionDeleteConfirm from './RevisionDeleteConfirm'
  import RequestModified from '@/components/dialog/RequestModified';
  import ErrorMessage from '@/components/dialog/ErrorMessage';
  import Modal from "@/components/Modal.vue";
  import api from '@/api';
  import {eventBus} from "@/main";
  import LoadingSpinner from '@/components/other/LoadingSpinner';

  export default {
    name: 'RevisionModal',
    props: {
      active:{
          type: Boolean,
          default: () => {
              return false
          }
      },
      request:{
          type: Object,
          default: null
      },
      container:{
          type: Object,
          default: null
      },
      customer: {
           type: Object,
           default:null
      },
      step:{
        type: Number,
        default: ()=>{
          return 0;
        }
      }
    },
    components: {
      Modal,
      RevisionDetail,
      RevisionConfirm,
      RevisionDelete,
      RevisionDeleteConfirm,
      RequestModified,
      ErrorMessage,
      LoadingSpinner
    },
    data() {
      let cloned = JSON.parse(JSON.stringify(this.request)); //TODO: Hack to avoid v-model aliasing with parent rowData;
      cloned.pickup = cloned.pickup || { day: null, hour: null }
      return {
        cloned,
        mStep: 0,
        loading: false
      }
    },
    created(){
      this.mStep = this.step;
    },
    computed:{
      title() {
        switch(this.mStep) {
          case 0: return "DETALLE DE CITA";
          default: return ""
        }
      }
    },
    methods:{
      closeModal() {
        eventBus.$emit("refresh-list");
        this.emitClose();
      },
      emitClose() {
        eventBus.$emit("refresh-counters");
        this.$emit('closePopup');
      },
      acceptAppointment() {
        const appointmentId = parseInt(this.cloned.id)
        const appointment = {
          state: "ACCEPTED",
          requested: this.cloned.requested,
          petitionDate: this.cloned.appointment.day,
          petitionHour: this.cloned.appointment.hour,
          pickupDate: this.cloned.pickup && this.cloned.pickup.day,
          pickupHour: this.cloned.pickup && this.cloned.pickup.hour,
          substitution: this.cloned.courtesyBike.requested,
          courtesyBikeStatus: this.cloned.courtesyBike.status,
          comment: this.comment
        }

        this.loading = true;
        api.updateRequest(appointmentId, appointment,(err) => {
          let step = 1;
          if (err) {
            if (err._status >=500){
              step = 5;
            }else{
              step = 4;
            }
          }
          this.loading = false;
          this.mStep = step;
        });

      },
      confirmRejectAppointment() {
        let {cloned: {id}} = this;
        let state = "REJECTED";
        id = parseInt(id);
        this.loading = true;
        api.updateRequestRevision({
          id,
          state
        }, (err) => {
          let step = 3;
          if (err){
            step = 4;
          }
          this.mStep = step;
          this.loading = false;
          //this.closeModal();
        })
      },
      rejectAppointment() {
        this.mStep = 2;
      }
    }
  }
</script>
