
import {computed, defineComponent, Ref, ref, watch} from "@vue/composition-api";
import CardWrapper from "@/components/general/CardWrapper.vue";
import AvailableDeviceLookup from "@/components/general/AvailableDeviceLookup.vue";
import {MapitDevice, Product} from "@mapit/core-dynamodb/dist/types";
import MapitDialog from "@/components/dialog/MapitDialog.vue";
import leviathanApi from "@/api/leviathan.api";
import {mapGetters} from "@/utils/map-store";

export default defineComponent({
  name: "RegistrationMapitConnect",
  components: {MapitDialog, AvailableDeviceLookup, CardWrapper},
  setup(props, {root}) {
    const imei = ref('')
    const device = ref(null) as Ref<MapitDevice | null>
    const loading = ref(false)
    const dialog = ref(false)
    const dialogLoading = ref(false)
    const error = ref(null) as Ref<string | null>

    const {
      getGroup: group
    } = mapGetters(root.$store.getters)

    const buttonStyles = computed(() => {
      return `
        text-transform: uppercase !important;
        padding: 8px 24px !important;
        font-family: 'ProximaNovaExCnSbold', sans-serif !important;
        letter-spacing: 0.01em !important;
     `
    })

    const isValidImei = computed(() => {
      return imei.value.length === 15
    })

    watch(isValidImei, (valid) => {
      if (!valid) {
        device.value = null
      }
    })

    async function handleRegisterSale() {
      if (!device.value) return
      loading.value = true
      try {
        await leviathanApi.sellDevice(group.value.dealerId, device.value.id, [Product.MapitConnect])
      } catch (e: any) {
        error.value = e.message
      } finally {
        loading.value = false
        dialog.value = true
      }
    }

    async function handleCloseModal() {
      if (error.value) {
        dialog.value = false
        error.value = null
      } else {
        dialogLoading.value = true
        await root.$router.push('/registrations/mapit-only')
      }
    }

    return {
      imei,
      device,
      loading,
      dialog,
      buttonStyles,
      isValidImei,
      error,
      dialogLoading,
      handleRegisterSale,
      handleCloseModal
    }
  }
})
