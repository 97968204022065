import { computed, ComputedRef, onMounted, Ref, ref, watch } from '@vue/composition-api'

import ridoApi from '@/api/rido.api'
import { Lead, LeadStatus, LogItem } from '@/types'
import { mapDataToComments } from '@/mappers/comments'

interface Props {
  id: Ref<string> | ComputedRef<string> | string
}

export const useLead = ({ id }: Props) => {
  const lead = ref<Lead | null>(null)
  const isLoading = ref(false)

  const commentLog = ref<LogItem[] | undefined>()
  const isCommentLogLoading = ref(false)

  const lastUpdatedByMe = computed(() => {
    return lead.value?.updatedBy?.includes(lead.value?.assignee as string)
  })

  onMounted(async () => {
    await fetchLead(mapProps({ id }))
    commentLog.value = mapDataToComments(lead.value?.commentLog)
  })

  watch(commentLog, async (oldComments, newComments) => {
    if (newComments && oldComments && oldComments !== newComments) {
      await updateComments()
    }
  })

  function mapProps({ id }: Props) {
    return { id: typeof id === 'string' ? id : id.value }
  }

  async function fetchLead({ id }: { id: string | undefined }, showLoading = true) {
    if (!id) {
      return Promise.resolve()
    }

    try {
      if (showLoading) {
        isLoading.value = true
      }

      const { data: leadResponse } = await ridoApi.getLead(id)
      lead.value = leadResponse
    } catch (e) {
      console.error(`Error fetching lead ${id}`, e)
    } finally {
      if (showLoading) {
        isLoading.value = false
      }
    }
  }

  async function markAsRead() {
    try {
      const { id: leadId } = mapProps({ id })
      return ridoApi.markAsRead(leadId, true)
    } catch (e) {
      console.error(`Error fetching offer ${id}`, e)
      return Promise.reject(e)
    }
  }

  async function maskLeadAsLost() {
    if (!lead.value) {
      return
    }

    try {
      isLoading.value = true
      await ridoApi.updateLeadStatus(lead.value.id || '', LeadStatus.Lost)
      lead.value.status = LeadStatus.Lost
    } catch (e) {
      console.error(`Error updating read state in lead ${lead.value?.id}`, e)
    } finally {
      isLoading.value = false
    }
  }

  async function updateComments() {
    if (!commentLog.value) {
      return
    }

    try {
      isCommentLogLoading.value = true

      const { id: leadId } = mapProps({ id })
      await ridoApi.updateComments(leadId, mapDataToComments(commentLog.value))
      await fetchLead({ id: leadId }, false)

      if (lead.value?.status === LeadStatus.New) {
        lead.value.status = LeadStatus.InProgress
      }
    } catch (e) {
      console.error(`Error adding comment to lead`, e)
    } finally {
      isCommentLogLoading.value = false
    }
  }

  return {
    commentLog,
    data: lead,
    isCommentLogLoading,
    isLoading,
    lastUpdatedByMe,
    markAsRead,
    maskLeadAsLost,
    refetch: async () => await fetchLead(mapProps({ id })),
    updateComments,
  }
}
