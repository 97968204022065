import moment from 'moment'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import es from './locales/es.json'
import pt from './locales/pt.json'

Vue.use(VueI18n)

const locale = navigator.language.substring(0, 2).toLowerCase() || 'es'

moment.locale(navigator.language)

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'es',
  messages: {
    en,
    es,
    pt,
  },
})

export function useI18n(scope = '') {
  function translate(key, options) {
    const k = scope ? `${scope}.${key}` : key
    return i18n.t(k, options)
  }

  return {
    i18n,
    t: translate,
  }
}

export default i18n
