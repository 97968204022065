import {Translations} from '@aws-amplify/ui-components'

export default {
  [Translations.SIGN_IN_HEADER_TEXT]: "Inicio de sesión",
  [Translations.SIGN_IN_ACTION]: "Inicio",
  [Translations.SIGN_IN_TEXT]: "Inicio",
  [Translations.SIGN_UP_HEADER_TEXT]: "Registro",
  [Translations.FORGOT_PASSWORD_TEXT]: "¿Has olvidado la contraseña?",
  [Translations.CREATE_ACCOUNT_TEXT]: "Registrarse",
  [Translations.RESET_PASSWORD_TEXT]: "Restablecer",
  [Translations.NO_ACCOUNT_TEXT]: "¿Sin cuenta?",
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "¿Tienes cuenta?",
  [Translations.EMAIL_LABEL]: "Correo electrónico",
  [Translations.EMAIL_PLACEHOLDER]: "Correo electrónico",
  [Translations.PASSWORD_LABEL]: "Contraseña",
  [Translations.PASSWORD_PLACEHOLDER]: "Contraseña",
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Código de confirmación",
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "123456",
  [Translations.SIGN_IN_WITH_AWS]: "Inicio con AWS",
  [Translations.CREATE_ACCOUNT_TEXT]: "Crear cuenta",
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Confirmar correo",
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "¿No lo encuentras?",
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Enviar de nuevo",
  [Translations.BACK_TO_SIGN_IN]: "Inicio",
  [Translations.SEND_CODE]: "Enviar código",
  [Translations.RESET_YOUR_PASSWORD]: "Restablecer contraseña",
  [Translations.USERNAME_LABEL]: "Correo electrónico *",
  [Translations.USERNAME_PLACEHOLDER]: "Introduce tu correo electrónico",
  [Translations.CODE_LABEL]: "Código de verificación",
  [Translations.CODE_PLACEHOLDER]: "Introduce el código recibido en tu email",
  [Translations.CHANGE_PASSWORD]: "Cambiar contraseña",
  [Translations.CHANGE_PASSWORD_ACTION]: "Cambiar",
  [Translations.NEW_PASSWORD_LABEL]: "Nueva contraseña",
  [Translations.NEW_PASSWORD_PLACEHOLDER]: "Introduce tu contraseña",
  [Translations.SUBMIT]: "Enviar",
  [Translations.EMPTY_PASSWORD]: "Contraseña requerida",
  [Translations.EMPTY_CODE]: "Código requerido",
  [Translations.EMPTY_USERNAME]: "Email requerido",
  [Translations.INVALID_USERNAME]: "Usuario incorrecto",
  ['Password required']: "Contraseña requerida",
  ['Email required']: "Email requerido",
  ["Incorrect username or password."]: "Usuario o contraseña incorrectos",
  ["UserMigration failed with error Bad user or password."]: "ERROR: Usuario o contraseña incorrectos.",
  ["Password reset required for the user"]: "Es necesario restablecer la contraseña",
  ["Invalid verification code provided, please try again."]: "Código no válido o caducado",
  ["Password does not conform to policy: Password not long enough"]: "La contraseña debe tener un mínimo de 10 caracteres",
  ["User password cannot be reset in the current state."]: "La contraseña no se puede restablecer en el estado actual.",
  ["Attempt limit exceeded, please try after some time."]: "Se ha excedido el límite de intentos, inténtalo de nuevo más tarde.",
  ["PostConfirmation invocation failed due to error Socket timeout while invoking Lambda function"]: "Error de conexión",
  ["UserMigration failed with error Bad username."]: "ERROR: Nombre de usuario incorrecto",
  ["Network error"]: "Error de conexión",
  ["User is disabled."]: "El usuario está desactivado",
  ["Temporary password has expired and must be reset by an administrator."]: "La contraseña temporal ha expirado y tu cuenta ha quedado invalidada. Necesita ser eliminada y creada de nuevo por tu administrador",
  ["User does not exist."]: "Usuario o contraseña incorrectos"
}
