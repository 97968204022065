












































import {defineComponent, nextTick, onMounted, ref, watch} from "@vue/composition-api";

export default defineComponent({
  name: "MapitDialog",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    customWidth: {
      type: String,
      default: '550px'
    },
    customId: {
      type: String,
      default: 'mapit-dialog'
    }
  },
  setup(props, {slots}) {
    const computedDialog = ref(props.dialog)
    const footerCenterItemCount = ref(0)

    watch(() => props.dialog, (value) => {
      computedDialog.value = value
    })

    onMounted(async () => {
      await nextTick()
      footerCenterItemCount.value = slots["footer-center"] ? slots["footer-center"].length : 0
    })

    return {
      computedDialog,
      footerCenterItemCount
    }
  }
})
