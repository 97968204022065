












































































































import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import {InputTypes} from '@/utils/constants'
import MapitInput from '@/components/general/MapitInput.vue'
import {discountPercentagePriceRateCCAARules, discountPriceRateCCAARules} from '@/helpers/offer-manager-rules'
import {computed, defineComponent, Ref, ref, watch} from "@vue/composition-api";
import {mapGetters} from "@/utils/map-store";
import {useI18n} from "@/i18n";
import {UseCampaignProps, useCampaigns} from "@/composables/campaigns";
import Banner from "@/components/atom/Banner.vue";
import {Status} from '@mapit/honda-offers-builder/dist/types'

export default defineComponent({
  name: 'Campaign',
  components: {Banner, ExpansionPanel, MapitInput},
  props: {
    hideDetails: {
      type: Boolean,
      default: false
    },
    panelReadonly: {
      type: Boolean,
      default: false
    },
    panelHideDivider: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, {root, emit}) {
    const {t} = useI18n()
    const error = ref('')

    const {
      getRegistrationPrice: registrationPrice,
      getRegistrationDiscount: registrationDiscount,
      getRegistrationDiscountPercent: registrationDiscountPercent,
      getRegistrationTotalPrice: registrationTotalPrice,
      getVehicleTotalPrice: vehicleTotalPrice,
      getVehicleDiscountPriceRate: vehicleDiscountPriceRate,
      getVehicleDiscountPriceRatePercent: vehicleDiscountPriceRatePercent,
      getVehiclePriceRateCCAA: vehiclePriceRateCCAA,
      getBranch: branch,
      getLocale: locale,
      getVehicleModelName: vehicleModelName,
      getVehicleModelSubname: vehicleModelSubname,
      getSelectedModel: vehicleModel,
      getHondaCampaignValue: hondaCampaignValue,
      getHondaCampaignStartDate: hondaCampaignStartDate,
      getHondaCampaignEndDate: hondaCampaignEndDate,
      getHondaCampaign: hondaCampaign,
      getDealerCampaignName: dealerCampaignName,
      getDealerCampaignValue: dealerCampaignValue,
      getStatus: offerStatus,
    } = mapGetters(root.$store.getters)

    // Honda Campaign
    const campaignFetchProps = ref() as Ref<UseCampaignProps>

    watch(vehicleModel, (vehicleModel) => {
      campaignFetchProps.value = {
        branch: branch.value,
        model: vehicleModel?.model?.name,
        ym: vehicleModel?.model?.ym,
        type: vehicleModel?.model?.type,
        color: vehicleModel?.color?.code
      }
    })

    const {campaigns: hondaCampaignsList, loading: isCampaignsLoading} = useCampaigns(campaignFetchProps)

    // Add the honda campaign to the list if is not there
    watch(hondaCampaignsList, (campaignsList) => {
      if (vehicleModel.value && hondaCampaign.value?.name && campaignsList && !campaignsList.some(obj => obj.name === hondaCampaign.value.name)) {
        hondaCampaignsList.value?.push({...hondaCampaign.value, isNotAvailable: true})
      }
    })

    // Clean the list if the vehicle changes
    watch([vehicleModelName,vehicleModelSubname], () => {
      hondaCampaignsList.value = []
    })

    watch(registrationPrice, validateDiscounts)
    watch(registrationDiscount, validateDiscounts)
    watch(registrationDiscountPercent, validateDiscounts)

    function validateDiscount(value: any) {
      return !value || !registrationPrice.value || registrationPrice.value && parseFloat(value) <= parseFloat(registrationPrice.value) && parseFloat(value) >= 0 || 'Error'
    }

    function updateOffer(action: string, value: any) {
      root.$store.dispatch(action, value)
      emit('validateForm')
    }

    function validateDiscounts() {
      if (validateDiscount(registrationDiscount.value) !== 'Error') {
        if (registrationDiscountPercent.value >= 0 && registrationDiscountPercent.value <= 100) {
          error.value = ''
        } else {
          error.value = root.$t('offer-manager.create.step-campaign.lbl-error-discount-percentage') as string
        }
      } else {
        error.value = root.$t('offer-manager.create.step-campaign.lbl-error-discount') as string
      }
    }

    const customDealerCampaignPriceRules = computed(() => {
      return [
        (value: string | undefined | any) => {
          if (!value) return true
          const fValue =
            typeof value === 'string' ? parseFloat(value.replace('.', '').replace('-', '').replace(',', '.')) : value
          return (
            (fValue >= 0 && fValue <= parseFloat(vehiclePriceRateCCAA.value)) || t('error.discount-price-rate').toString()
          )
        },
      ]
    })

    const customDealerCampaignNameRules = computed(() => {
      return [
        (name: string | undefined) => {
          if ((!name || !name?.trim()) && dealerCampaignValue.value) return t('error.required').toString()
        },
      ]
    })

    return {
      t,
      InputTypes,
      error,
      registrationPrice,
      registrationDiscount,
      registrationDiscountPercent,
      registrationTotalPrice,
      validateDiscount,
      updateOffer,
      validateDiscounts,
      vehicleTotalPrice,
      vehicleDiscountPriceRate,
      vehicleDiscountPriceRatePercent,
      locale,
      hondaCampaignsList,
      isCampaignsLoading,
      hondaCampaign,
      discountPriceRateCCAARules,
      discountPercentagePriceRateCCAARules,
      customDealerCampaignPriceRules,
      customDealerCampaignNameRules,
      vehiclePriceRateCCAA,
      vehicleModel,
      vehicleModelName,
      vehicleModelSubname,
      hondaCampaignStartDate,
      hondaCampaignEndDate,
      dealerCampaignName,
      dealerCampaignValue,
      hondaCampaignValue,
      offerStatus,
      Status
    }
  }

})
