import {Country} from "@mapit/common-utils/dist/types";
import {Regions} from "@mapit/common-utils";


export interface PostalAddress {
  addressCountry?: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  streetAddress: string;
  postOfficeBoxNumber: string;
}

/**
 * Returns a Country enum value based on the country code.
 * @param {string} countryCode - 2-letter country code.
 * @author Miguel Collado
 */
export const toCountry = (countryCode?: string | Country ): Country | undefined => {
  if (!countryCode) return undefined;

  const strCountry = countryCode as string

  return Object.values(Country).find(country => country.toUpperCase() === strCountry.toUpperCase()) as Country;
}

/**
 * Returns a Country enum value based on the country code inside a PostalAddress object.
 * @param {PostalAddress} address
 *
 * @param {Country} fallback - Country to return if toCountry returns undefined.
 * @author Miguel Collado
 */
export const getCountry = (address?: PostalAddress, fallback?: string | Country ): Country => {
  return toCountry(address?.addressCountry) ?? toCountry(fallback) ?? Country.Spain;
}

/**
 * Returns the first level administrative region of a country based on its id.
 * @param {string} regionId
 * @param {Country} country
 *
 * @author Miguel Collado
 */
export const findFirstLevelRegionById = (regionId: string, country: Country = Country.Spain): any => {
  return Regions[country].find((region: any) => region.id === regionId && region.level === 1)
}

/**
 * Returns the second level administrative region of a country based on its name.
 * @param {string} regionName
 * @param {Country} country
 *
 * @author Miguel Collado
 */
export const findSecondLevelRegionByName = (regionName: string, country: Country = Country.Spain): any => {
  return Regions[country].find((region: any) => region.name === regionName && region.level === 2)
}

/**
 * Returns the second level administrative region of a country based on its ID.
 * @param {string} id
 * @param {Country} country
 *
 * @author Miguel Collado
 */
export const findSecondLevelRegionById = (id: string, country: Country = Country.Spain): any => {
  return Regions[country].find((region: any) => region.id === id && region.level === 2)
}

/**
 * Returns the second level administrative region of a country based on its postalCode.
 * @param {string} postalCode
 * @param {Country} country
 *
 * @author Miguel Collado
 */
export const findSecondLevelRegionByPostalCode = (postalCode: string, country: Country = Country.Spain): any => {
  const provinceParent = Regions[country].find((region: any) => region.id.startsWith(postalCode.slice(0, 2)) && region.level === 3)?.parent
  return Regions[country].find((region: any) => region.id === provinceParent && region.level === 2)
}