import moment from 'moment'
import { DateTime } from 'luxon'
import { head, cloneDeep } from 'lodash'

import geoApi from '@/api/geo.api'
import DealerBikesApi from '@/api/dealerBikes.api'

import { DealerBikeActions, UsageType, DealerBikeStatus } from '@/types/dealer-bikes'
import { mapDealerBike, mapDealerBikesCollection } from '@/mappers/dealer-bikes'
import { digitalSignatureStatus } from '@/utils/constants'
import { DrivingLicense } from '@mapit/contacts-dynamodb/dist/types'
import {
  RESET_DEALER_BIKE_LOAN_RETURN_DATA,
  RESET_DEALER_BIKE_STORE,
  SET_DEALER_BIKE,
  SET_DEALER_BIKE_LICENSE,
  SET_DEALER_BIKE_LIST,
  SET_DEALER_BIKE_LOAN_CONTACT,
  SET_DEALER_BIKE_LOAN_DATA_DATE,
  SET_DEALER_BIKE_LOAN_DATA_TIME,
  SET_DEALER_BIKE_LOAN_HANDOVER_DAMAGE_LOG,
  SET_DEALER_BIKE_LOAN_LICENSE_EXPEDITION_DATE,
  SET_DEALER_BIKE_LOAN_LICENSE_TYPE,
  SET_DEALER_BIKE_LOAN_PANELS,
  SET_DEALER_BIKE_LOAN_RETURN_DAMAGE_LOG,
  SET_DEALER_BIKE_LOAN_RETURN_DATE,
  SET_DEALER_BIKE_LOAN_RETURN_TIME,
  SET_DEALER_BIKE_LOAN_USAGE_TYPE,
  SET_DEALER_BIKE_LOANS_LIST,
  SET_DEALER_BIKE_MAPIT,
  SET_DEALER_BIKE_MODEL_NAME,
  SET_DEALER_BIKE_MODEL_SUBNAME,
  SET_DEALER_BIKE_MODEL_ID,
  SET_DEALER_BIKE_REGISTRATION_DATE,
  SET_DEALER_BIKE_REGISTRATION_NUMBER,
  SET_DEALER_BIKE_REGISTRATION_PANELS,
  SET_DEALER_BIKE_RETURN_COMMENTS,
  SET_DEALER_BIKE_RETURN_SALE_PROBABILITY,
  SET_DEALER_BIKE_SELECTED,
  SET_DEALER_BIKE_SELECTED_STATUS,
  SET_DEALER_BIKE_USAGE_TYPE,
  SET_DEALER_BIKE_VIN,
  SET_DIGITAL_SIGNATURE_STATUS,
  SET_IS_MIGRATION_DEALER_BIKE,
  SET_IS_VALID_DEALER_BIKE_MAPIT,
  SET_LOADING,
  SET_LOAN_DATA,
  SET_LOAN_DISTANCE,
  SET_LOAN_ID,
  SET_MIGRATION_DEMO_BIKE_DATA,
  SET_VEHICLE_KM,
  SET_IS_DEALER_BIKE_MAPIT_LOADING,
} from '@/vuex/mutation-types'

const initialState = {
  loanContact: null,
  dealerBikeList: [],
  dealerBikeLoansList: [],
  dealerBikeSelected: null,
  panels: [0, 1],
  loanPanels: [0, 1, 2, 3],
  loading: false,
  mapit: null,
  distance: null,
  vehicleTotalKm: 0,
  isValidDealerBikeMapit: false,
  modelName: '',
  modelSubname: '',
  modelId: '',
  registrationNumber: '',
  vin: '',
  usageType: null,
  registrationDate: '',
  license: null,
  contactId: null,
  contactName: '',
  contactLastname: '',
  contactEmail: '',
  contactPhone: '',
  contactProvince: null,
  contactConsentAgreement: null,
  contactPrivacyAgreement: null,
  loanId: null,
  loanDate: moment().format('YYYY-MM-DD'),
  loanTime: moment().format('HH:mm'),
  loanHandoverDamageLog: [],
  loanLicenseType: null,
  loanLicenseExpeditionDate: null,
  loanUsageType: UsageType.Demo,
  loanReturnDate: moment().format('YYYY-MM-DD'),
  loanReturnTime: moment().format('HH:mm'),
  loanReturnSaleProbability: null,
  loanReturnComments: '',
  loanReturnDamageLog: [],
  digitalSignatureStatus: digitalSignatureStatus.waiting,
  isMigration: false,
  registrationId: '',
  vehicleId: '',
  retiredReason: 'OTHER',
  isDealerBikeMapitLoading: false,
}

const persitedKeys = ['panels', 'loanPanels', 'dealerBikeList', 'dealerBikeLoansList']
const actionsDialog = 'dealer-bike.list.dialog'

const getters = {
  isLoadingDealerBikes: (state) => state.loading,
  // Lists
  getDealerBikeList: (state) => state.dealerBikeList,
  getDealerBikeLoansList: (state) => state.dealerBikeLoansList,
  // Panels
  getDealerBikeRegistrationPanels: (state) => state.panels,
  getDealerBikeLoanPanels: (state) => state.loanPanels,
  // Dealer Bike data
  getDealerBikeSelected: (state) => state.dealerBikeSelected,
  getDealerBikeMapit: (state) => state.mapit,
  isMigrationDealerBike: (state) => state.isMigration,
  isValidDealerBikeMapit: (state) => state.isValidDealerBikeMapit,
  getDealerBikeVehicleModelName: (state) => state.modelName,
  getDealerBikeVehicleModelSubname: (state) => state.modelSubname,
  getDealerBikeVehicleModelId: (state) => state.modelId,
  getDealerBikeVehicleRegistrationNumber: (state) => state.registrationNumber,
  getDealerBikeVehicleVin: (state) => state.vin,
  getDealerBikeUsageType: (state) => state.usageType,
  getDealerBikeRegistrationDate: (state) => state.registrationDate,
  getDealerBikeLicense: (state) => state.license ?? '',
  // Loan data
  getDealerBikeLoanContact: (state) => state.loanContact,
  getDealerBikeLoanHandoverDamageLog: (state) => state.loanHandoverDamageLog,
  getDealerBikeLoanDataDate: (state) => state.loanDate,
  getDealerBikeLoanDataTime: (state) => state.loanTime,
  getDealerBikeLoanLicenseType: (state) => state.loanLicenseType,
  getDealerBikeLoanLicenseExpeditionDate: (state) => state.loanLicenseExpeditionDate,
  getDealerBikeLoanUsageType: (state) => state.loanUsageType,
  getDealerBikeLoanReturnDamageLog: (state) => state.loanReturnDamageLog,
  getDealerBikeLoanReturnDate: (state) => state.loanReturnDate,
  getDealerBikeLoanReturnTime: (state) => state.loanReturnTime,
  getDealerBikeReturnSaleProbability: (state) => state.loanReturnSaleProbability,
  getDealerBikeReturnComments: (state) => state.loanReturnComments,
  getLoanId: (state) => state.loanId,
  getReturnedAt: (state) =>
    moment(`${state.loanReturnDate} ${state.loanReturnTime}`, 'YYYY-MM-DD HH:mm').format(),
  getScheduledFor: (state) =>
    moment(`${state.loanDate} ${state.loanTime}`, 'YYYY-MM-DD HH:mm').format(),
  getDigitalSignatureStatus: (state) => state.digitalSignatureStatus,
  getDistance: (state) => state.distance,
  getVehicleTotalKm: (state) => state.vehicleTotalKm,
  // Request data
  getRequestLoanData: (state) => ({
    contact: {
      ...state.loanContact,
      license: state.loanLicenseExpeditionDate
        ? {
            type: state.loanLicenseType,
            expeditedAt: moment(state.loanLicenseExpeditionDate).format('YYYY-MM-DD'),
          }
        : undefined,
    },
    damageLog: state.loanHandoverDamageLog,
    scheduledFor: moment(`${state.loanDate} ${state.loanTime}`, '').format(),
    usageType: state.loanUsageType,
  }),
  getRequestDealerBikeData: (state) => {
    const requestData = {
      vehicle: {
        color: state.modelSubname || '',
        model: state.modelName,
        license: state.license || DrivingLicense.A,
        registrationNumber: state.registrationNumber,
        vin: state.vin,
        registrationDate: state.registrationDate || moment().format('YYYY-MM-DD'),
        modelId: state.modelId,
      },
      device: state.mapit,
      usageType: state.usageType || UsageType.Demo,
    }
    if (state.isMigration) {
      requestData.vehicle.id = state.vehicleId
      requestData.registration = {
        id: state.registrationId,
      }
    }
    return requestData
  },
  isDealerBikeMapitLoading: (state) => state.isDealerBikeMapitLoading,
}

const actions = {
  // API
  loadLoansFinishedByMonth: ({ commit }, payload) => {
    commit('SET_LOADING', true)
    return DealerBikesApi.getLoansFinishedByMonth(payload.dealerBikeId, payload.month)
      .then(({ data: { data } }) => {
        return data
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  signHandOverLoan: ({ commit }, loanId) => {
    commit('SET_LOADING', true)
    return DealerBikesApi.signHandOverLoan(loanId)
      .then((res) => {
        commit('SET_ALERT_RESULT', {
          type: 'success',
          stringId: 'dealer-bike.loans.sign-handover-loan-paper.success-sign',
          open: true,
        })
        return res
      })
      .catch((err) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          stringId: 'dealer-bike.loans.sign-handover-loan-paper.error-sign',
          open: true,
        })
        throw err
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  signReturnLoan: ({ commit }, loanId) => {
    commit('SET_LOADING', true)
    return DealerBikesApi.signReturnLoan(loanId)
      .then((res) => {
        commit('SET_ALERT_RESULT', {
          type: 'success',
          stringId: 'dealer-bike.loans.sign-return-loan-paper.success-sign',
          open: true,
        })
        return res
      })
      .catch((err) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          stringId: 'dealer-bike.loans.sign-return-loan-paper.error-sign',
          open: true,
        })
        throw err
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  cancelledLoan: ({ commit }, loanId) => {
    commit('SET_LOADING', true)
    return DealerBikesApi.cancelLoan(loanId)
      .then((res) => {
        commit('SET_ALERT_RESULT', {
          type: 'success',
          stringId: 'dealer-bike.loans.cancelled.success',
          open: true,
        })
        return res
      })
      .catch((err) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          stringId: 'dealer-bike.loans.cancelled.error',
          open: true,
        })
        throw err
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  loadOnGoingDealerBikeLoansList: ({ commit }) => {
    commit('SET_LOADING', true)

    return DealerBikesApi.getDealerBikeLoanList({ onGoing: true })
      .then(({ data: { data } }) => {
        commit('SET_DEALER_BIKE_LOANS_LIST', data)
        return data
      })
      .catch((e) => {
        commit('SET_DEALER_BIKE_LOANS_LIST', [])
        throw e
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  loadDealerBikeList: async ({ commit }) => {
    commit('SET_LOADING', true)

    return DealerBikesApi.getDealerBikeList()
      .then((response) => {
        const { data } = response?.data || {}
        commit(SET_DEALER_BIKE_LIST, { data })
      })
      .catch((e) => {
        commit(SET_DEALER_BIKE_LIST, { data: [] })
        throw e
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  loadLoanById: async ({ commit }, id) => {
    return DealerBikesApi.getDealerBikeLoan(id)
      .then(({ data }) => {
        commit('SET_LOAN_DATA', data)
        return data
      })
      .catch((e) => console.error(e))
  },
  loadDealerBikeByRegistrationNumber: async ({ commit }, registrationNumber) => {
    return DealerBikesApi.getDealerBikeByRegistrationNumber(registrationNumber)
      .then((data) => {
        commit(SET_IS_MIGRATION_DEALER_BIKE, !head(data.data.data))
        return data
      })
      .catch((e) => {
        if (e.response.status === 404) {
          commit(SET_IS_MIGRATION_DEALER_BIKE, true)
          return null
        } else {
          commit(SET_IS_MIGRATION_DEALER_BIKE, false)
          console.error(e)
          throw e
        }
      })
  },
  changeDealerBikeToCourtesy: async ({ getters, commit }, { id }) => {
    commit('SET_LOADING', true)
    const message = `${actionsDialog}.${DealerBikeActions.Courtesy}`

    return DealerBikesApi.changeDealerBikeToCourtesy(id)
      .then(() => {
        commit('SET_DEALER_BIKE_USAGE_TYPE', UsageType.Courtesy)
        commit('SET_ALERT_RESULT', {
          type: 'success',
          open: true,
          stringId: `${message}.success`,
        })

        commit(SET_DEALER_BIKE_LIST, {
          data: getters.getDealerBikeList,
          usageType: UsageType.Courtesy,
          id,
        })
      })
      .catch((e) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          open: true,
          stringId: `${message}.error`,
        })

        return Promise.reject(e)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  activateDealerBike: async ({ getters, commit }, { id, usage }) => {
    commit('SET_LOADING', true)
    const message = `${actionsDialog}.${DealerBikeActions.Available}`
    const content = { usageType: usage }

    return DealerBikesApi.makeDealerBikeAvailable(id)
      .then(() => {
        commit('SET_DEALER_BIKE_SELECTED_STATUS', DealerBikeActions.Available)
        commit('SET_ALERT_RESULT', {
          type: 'success',
          open: true,
          stringId: `${message}.success`,
          content,
        })

        commit(SET_DEALER_BIKE_LIST, {
          data: getters.getDealerBikeList,
          status: DealerBikeStatus.Available,
          id,
        })
      })
      .catch((e) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          open: true,
          stringId: `${message}.error`,
          content,
        })

        return Promise.reject(e)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  unavailDealerBike: async ({ getters, commit }, { id, usage }) => {
    commit('SET_LOADING', true)
    const message = `${actionsDialog}.${DealerBikeActions.Unavailable}`
    const content = { usageType: usage }

    return DealerBikesApi.makeDealerBikeUnavailable(id)
      .then(() => {
        commit('SET_DEALER_BIKE_SELECTED_STATUS', DealerBikeStatus.Unavailable)
        commit('SET_ALERT_RESULT', {
          type: 'success',
          open: true,
          stringId: `${message}.success`,
          content,
        })

        commit(SET_DEALER_BIKE_LIST, {
          data: getters.getDealerBikeList,
          status: DealerBikeStatus.Unavailable,
          id,
        })
      })
      .catch((e) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          open: true,
          stringId: `${message}.error`,
          content,
        })

        return Promise.reject(e)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  loadDealerBike: async ({ dispatch, commit }, id) => {
    commit('RESET_DEALER_BIKE_STORE')
    commit('SET_LOADING', true)

    return DealerBikesApi.getDealerBike(id)
      .then((response) => {
        const { data } = response || {}
        const dealerBikeMapped = mapDealerBike({ dealerBike: data })

        commit('SET_DEALER_BIKE', dealerBikeMapped)
        dispatch('setDealerBikeSelected', dealerBikeMapped)
        return data
      })
      .catch((e) => {
        dispatch('setDealerBikeSelected', null)
        throw e
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  setVehicleKms: ({ commit }, payload) => {
    commit('SET_VEHICLE_KM', payload)
  },
  updateDealerBike: ({ commit, state }, payload) => {
    commit('SET_LOADING', true)
    payload.damageLog = payload.damageLog?.map((i) => ({
      timestamp: moment(i.timestamp).toISOString(),
      text: i.text,
    }))
    return DealerBikesApi.updateDealerBike(state.dealerBikeSelected.id, payload).finally(() => {
      commit('SET_LOADING', false)
    })
  },
  processDealerBikeLoanReturn: async ({ commit, state }, loanId) => {
    commit('SET_LOADING', true)

    return DealerBikesApi.returnLoanDealerBike(loanId, {
      comment: state.loanReturnComments || '',
      probabilityOfSale: state.loanReturnSaleProbability,
      returnedAt: DateTime.fromISO(`${state.loanReturnDate}T${state.loanReturnTime}:00.000`)
        .toUTC()
        .toISO(),
      damageLog: state.loanReturnDamageLog,
    })
      .then((res) => res)
      .catch((e) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          stringId: 'dealer-bike.return.alert-create-error',
          open: true,
        })
        throw e
      })
      .finally(async () => {
        commit('SET_LOADING', false)
      })
  },
  // set / get
  setLoanDistance: ({ commit }, distance) => {
    commit('SET_LOAN_DISTANCE', distance)
  },
  setDealerBikeSelected: ({ commit }, dealerBike) => {
    commit('SET_DEALER_BIKE_SELECTED', dealerBike)
  },
  setDealerBikesList: ({ commit }, dealerBikeList) => {
    commit(SET_DEALER_BIKE_LIST, dealerBikeList)
  },
  setSearchedContact: ({ commit, isSearched }) => {
    commit('SET_SEARCHED_CONTACT', isSearched)
  },
  // Panels
  setDealerBikeLoanPanels: ({ commit }, panels) => {
    commit('SET_DEALER_BIKE_LOAN_PANELS', panels)
  },
  setDealerBikeRegistrationPanels: ({ commit }, panels) => {
    commit('SET_DEALER_BIKE_REGISTRATION_PANELS', panels)
  },
  // Dealer Bike data
  setDealerBikeMapit: ({ commit }, mapit) => {
    commit(SET_DEALER_BIKE_MAPIT, mapit)
  },
  setIsValidDealerBikeMapit: ({ commit }, isValid) => {
    commit(SET_IS_VALID_DEALER_BIKE_MAPIT, isValid)
  },
  setDealerBikeVehicleModelName: ({ commit }, name) => {
    commit('SET_DEALER_BIKE_MODEL_NAME', name)
  },
  setDealerBikeVehicleModelBySubname: ({ commit }, subname) => {
    commit('SET_DEALER_BIKE_MODEL_SUBNAME', subname)
  },
  setDealerBikeVehicleModelByModelId: ({ commit }, id) => {
    commit('SET_DEALER_BIKE_MODEL_ID', id)
  },
  setDealerBikeVehicleRegistrationNumber: ({ commit }, registrationNumber) => {
    commit(SET_DEALER_BIKE_REGISTRATION_NUMBER, registrationNumber)
  },
  setDealerBikeVehicleVin: ({ commit }, vin) => {
    commit('SET_DEALER_BIKE_VIN', vin)
  },
  setDealerBikeUsageType: ({ commit }, usageType) => {
    commit('SET_DEALER_BIKE_USAGE_TYPE', usageType)
  },
  setDealerBikeRegistrationDate: ({ commit }, registrationDate) => {
    commit('SET_DEALER_BIKE_REGISTRATION_DATE', registrationDate)
  },
  setDealerBikeLicense: ({ commit }, license) => {
    commit('SET_DEALER_BIKE_LICENSE', license)
  },
  prepareDealerBikeLoanContact: ({ commit, rootState }, contact) => {
    if (!contact?.address?.addressRegion) {
      const dealerRegion = rootState.group.profile.shop.address?.addressRegion
      contact.address = { ...contact.address, addressRegion: dealerRegion }
    }
    commit('SET_DEALER_BIKE_LOAN_CONTACT', contact)
  },
  setDealerBikeLoanContactProvince: ({ commit, rootState }, province) => {
    const dealerRegion = rootState.group.profile.shop.address?.addressRegion
    commit('SET_DEALER_BIKE_LOAN_CONTACT_PROVINCE', province ? province : dealerRegion)
  },
  resetDealerBikeLoanContact: ({ commit }) => {
    commit('SET_DEALER_BIKE_LOAN_CONTACT', null)
  },
  setDealerBikeLoanDataDate: ({ commit }, date) => {
    commit('SET_DEALER_BIKE_LOAN_DATA_DATE', date)
  },
  setDealerBikeLoanDataTime: ({ commit }, time) => {
    commit('SET_DEALER_BIKE_LOAN_DATA_TIME', time)
  },
  setDealerBikeLoanLicenseType: ({ commit }, type) => {
    commit('SET_DEALER_BIKE_LOAN_LICENSE_TYPE', type)
  },
  setDealerBikeLoanLicenseExpeditionDate: ({ commit }, date) => {
    commit('SET_DEALER_BIKE_LOAN_LICENSE_EXPEDITION_DATE', date)
  },
  setDealerBikeLoanUsageType: ({ commit }, usageType) => {
    commit('SET_DEALER_BIKE_LOAN_USAGE_TYPE', usageType)
  },
  setDealerBikeLoanReturnDate: ({ commit }, date) => {
    commit('SET_DEALER_BIKE_LOAN_RETURN_DATE', date)
  },
  setDealerBikeLoanReturnTime: ({ commit }, time) => {
    commit('SET_DEALER_BIKE_LOAN_RETURN_TIME', time)
  },
  // All
  cleanDealerBike: ({ commit }) => {
    commit('RESET_DEALER_BIKE_STORE')
  },
  deleteDealerBike: async ({ getters, commit }, { id }) => {
    commit('SET_LOADING', true)
    const message = `${actionsDialog}.${DealerBikeActions.RemoveBike}`

    return DealerBikesApi.deleteDealerBike(id)
      .then(() => {
        commit('SET_ALERT_RESULT', {
          type: 'success',
          open: true,
          stringId: `${message}.success`,
        })

        const dealerBikesList = getters.getDealerBikeList.filter((bike) => bike.id !== id)

        commit(SET_DEALER_BIKE_LIST, { data: dealerBikesList })
      })
      .catch((e) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          open: true,
          stringId: `${message}.error`,
        })

        return Promise.reject(e)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  retireDealerBike: async ({ getters, commit, state }, { id, usage, showAlert }) => {
    commit('SET_LOADING', true)
    const message = `${actionsDialog}.${DealerBikeActions.Unregister}`
    const content = { usageType: usage }

    return DealerBikesApi.retireDealerBike(id, { reason: state.retiredReason })
      .then(() => {
        commit('SET_DEALER_BIKE_SELECTED_STATUS', DealerBikeStatus.Retired)

        if (showAlert) {
          commit('SET_ALERT_RESULT', {
            type: 'success',
            open: true,
            stringId: `${message}.success`,
            content,
          })
        }

        commit(SET_DEALER_BIKE_LIST, {
          data: getters.getDealerBikeList,
          status: DealerBikeStatus.Retired,
          id,
        })
      })
      .catch((e) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          open: true,
          stringId: `${message}.error`,
          content,
        })

        return Promise.reject(e)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  startLoanHandoverProcess: async ({ commit, dispatch }, dealerBikeId) => {
    commit('SET_LOADING', true)
    const dealerBike = await dispatch('loadDealerBike', dealerBikeId)
    commit('SET_DEALER_BIKE_LOAN_HANDOVER_DAMAGE_LOG', dealerBike.damageLog)
    await dispatch('setDealerBikeLoanDataTime', moment().format('HH:mm'))
    await dispatch('setDealerBikeLoanUsageType', dealerBike.usageType)
    commit('SET_LOADING', false)
  },
  getVehicleRoutes: (ctx, { vehicleId, from, includeInProgress }) => {
    return geoApi
      .getRoutes(vehicleId, from, includeInProgress)
      .then(({ data }) => data.data)
      .catch((e) => {
        console.error(e)
        return []
      })
  },
  startLoanReturnProcess: async ({ commit, dispatch }, loanId) => {
    commit('SET_LOADING', true)
    const loan = (await DealerBikesApi.getDealerBikeLoan(loanId)).data
    commit(SET_LOAN_DATA, loan)
    commit(RESET_DEALER_BIKE_LOAN_RETURN_DATA)
    const dealerBike = (await DealerBikesApi.getDealerBike(loan.dealerBike.id)).data
    commit('SET_DEALER_BIKE_SELECTED', dealerBike)
    const routes = await dispatch('getVehicleRoutes', {
      vehicleId: dealerBike.vehicle.id,
      from: loan.scheduledFor,
      includeInProgress: true,
    })
    const distance = Math.ceil(
      routes.map((route) => route.distance).reduce((prev, curr) => prev + curr, 0) / 1000,
    )
    commit('SET_LOAN_DISTANCE', distance)
    commit('SET_DEALER_BIKE_LOAN_RETURN_DAMAGE_LOG', dealerBike.damageLog)
    commit('SET_LOADING', false)
  },
  resendSignHandoverEmail: ({ commit }, loanId) => {
    commit('SET_LOADING', true)
    return DealerBikesApi.sendEmail({
      destination: {
        loanId,
      },
      name: 'HM_DB_Firma_Entrega',
    })
      .then(() => {
        commit('SET_ALERT_RESULT', {
          type: 'success',
          stringId: 'dealer-bike.loans.resend-sign-handover-email.success',
          open: true,
        })
      })
      .catch((e) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          stringId: 'dealer-bike.loans.resend-sign-handover-email.error',
          open: true,
        })
        return Promise.reject(e)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  resendSignReturnEmail: ({ commit }, loanId) => {
    commit('SET_LOADING', true)
    return DealerBikesApi.sendEmail({
      destination: {
        loanId,
      },
      name: 'HM_DB_Firma_Devolución',
    })
      .then(() => {
        commit('SET_ALERT_RESULT', {
          type: 'success',
          stringId: 'dealer-bike.loans.resend-sign-return-email.success',
          open: true,
        })
      })
      .catch((e) => {
        commit('SET_ALERT_RESULT', {
          type: 'error',
          stringId: 'dealer-bike.loans.resend-sign-return-email.error',
          open: true,
        })
        return Promise.reject(e)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  setIsDealerBikeMapitLoading: ({ commit }, isLoading) => {
    commit(SET_IS_DEALER_BIKE_MAPIT_LOADING, isLoading)
  },
}

const mutations = {
  [SET_DEALER_BIKE_RETURN_SALE_PROBABILITY]: (state, probability) => {
    state.loanReturnSaleProbability = probability
  },
  [SET_DEALER_BIKE_RETURN_COMMENTS]: (state, comments) => {
    state.loanReturnComments = comments
  },
  [SET_LOAN_DATA]: (state, data) => {
    state.loanId = data.id
    state.loanDate = moment(data.scheduledFor).format('YYYY-MM-DD')
    state.loanTime = moment(data.scheduledFor).format('HH:mm')
  },
  [SET_IS_MIGRATION_DEALER_BIKE]: (state, isMigration) => {
    state.isMigration = isMigration
  },
  [SET_MIGRATION_DEMO_BIKE_DATA]: (state, { registrationId, vehicleId }) => {
    state.registrationId = registrationId
    state.vehicleId = vehicleId
  },
  [SET_DEALER_BIKE_LOAN_CONTACT]: (state, contact) => {
    state.loanContact = contact
    if (contact?.license) {
      state.loanLicenseType = contact.license.type
      state.loanLicenseExpeditionDate = moment(contact.license.expeditedAt)
    }
  },
  [RESET_DEALER_BIKE_LOAN_RETURN_DATA]: (state) => {
    state.loanReturnDate = DateTime.now().toFormat('yyyy-MM-dd')
    state.loanReturnTime = DateTime.now().toFormat('HH:mm')
    state.loanReturnSaleProbability = null
    state.loanReturnComments = ''
    state.loanReturnDamageLog = []
  },
  [SET_DEALER_BIKE_LOAN_DATA_DATE]: (state, date) => {
    state.loanDate = date
  },
  [SET_DEALER_BIKE_LOAN_DATA_TIME]: (state, time) => {
    state.loanTime = time
  },
  [SET_DEALER_BIKE_LOAN_HANDOVER_DAMAGE_LOG]: (state, damageLog) => {
    state.loanHandoverDamageLog = damageLog
  },
  [SET_DEALER_BIKE_LOAN_LICENSE_TYPE]: (state, type) => {
    state.loanLicenseType = type
  },
  [SET_DEALER_BIKE_LOAN_LICENSE_EXPEDITION_DATE]: (state, date) => {
    state.loanLicenseExpeditionDate = date
  },
  [SET_DEALER_BIKE_LOAN_USAGE_TYPE]: (state, usageType) => {
    state.loanUsageType = usageType
  },
  [SET_DEALER_BIKE_LOAN_RETURN_DATE]: (state, date) => {
    state.loanReturnDate = date
  },
  [SET_DEALER_BIKE_LOAN_RETURN_TIME]: (state, time) => {
    state.loanReturnTime = time
  },
  [SET_DEALER_BIKE_LOAN_RETURN_DAMAGE_LOG]: (state, damageLog) => {
    state.loanReturnDamageLog = damageLog
  },
  [SET_DIGITAL_SIGNATURE_STATUS]: (state, status) => {
    state.digitalSignatureStatus = status
  },
  [SET_DEALER_BIKE]: (state, data) => {
    state.registrationNumber = data.vehicle?.registrationNumber || ''
    state.modelName = data.vehicle.model
    state.modelSubname = data.vehicle.color
    state.vin = data.vehicle.vin
    state.license = data.vehicle.license
    state.modelId = data.vehicle.modelId
    state.registrationDate = data.vehicle.registrationDate
    state.usageType = data.usageType
    state.mapit = data.device
    state.isValidDealerBikeMapit = !!data.device?.id
  },
  // Lists
  [SET_DEALER_BIKE_LIST]: (state, update) => {
    state.dealerBikeList = mapDealerBikesCollection(update)
  },
  [SET_DEALER_BIKE_LOANS_LIST]: (state, dealerBikeLoansList) => {
    state.dealerBikeLoansList = dealerBikeLoansList
  },
  // Panels
  [SET_DEALER_BIKE_REGISTRATION_PANELS]: (state, panels) => {
    state.panels = panels
  },
  [SET_DEALER_BIKE_LOAN_PANELS]: (state, panels) => {
    state.loanPanels = panels
  },
  // Dealer Bike data
  [SET_DEALER_BIKE_SELECTED]: (state, dealerBike) => {
    state.dealerBikeSelected = dealerBike
  },
  [SET_LOAN_DISTANCE]: (state, distance) => {
    state.distance = distance
  },
  [SET_VEHICLE_KM]: (state, km) => {
    state.vehicleTotalKm = km
  },
  [SET_DEALER_BIKE_SELECTED_STATUS]: (state, status) => {
    state.dealerBikeSelected.status = status
  },
  [SET_DEALER_BIKE_MAPIT]: (state, mapit) => {
    state.mapit = mapit
  },
  [SET_IS_VALID_DEALER_BIKE_MAPIT]: (state, isValid) => {
    state.isValidDealerBikeMapit = isValid
  },
  [SET_DEALER_BIKE_MODEL_NAME]: (state, name) => {
    state.modelName = name
  },
  [SET_DEALER_BIKE_MODEL_SUBNAME]: (state, subname) => {
    state.modelSubname = subname
  },
  [SET_DEALER_BIKE_MODEL_ID]: (state, id) => {
    state.modelId = id
  },
  [SET_DEALER_BIKE_REGISTRATION_NUMBER]: (state, registrationNumber) => {
    state.registrationNumber = registrationNumber
  },
  [SET_DEALER_BIKE_VIN]: (state, vin) => {
    state.vin = vin
  },
  [SET_DEALER_BIKE_USAGE_TYPE]: (state, usageType) => {
    state.usageType = usageType
    if (state.dealerBikeSelected) state.dealerBikeSelected.usageType = usageType
  },
  [SET_DEALER_BIKE_REGISTRATION_DATE]: (state, registrationDate) => {
    state.registrationDate = registrationDate
  },
  [SET_DEALER_BIKE_LICENSE]: (state, license) => {
    state.license = license
  },
  [SET_LOADING]: (state, loading) => {
    state.loading = loading
  },
  [SET_LOAN_ID]: (state, id) => {
    state.loanId = id
  },
  [RESET_DEALER_BIKE_STORE](state) {
    const newState = cloneDeep(initialState)
    Object.keys(newState).forEach((key) => {
      if (!persitedKeys.includes(key)) {
        state[key] = newState[key]
      }
    })
  },
  [SET_IS_DEALER_BIKE_MAPIT_LOADING]: (state, isLoading) => {
    state.isDealerBikeMapitLoading = isLoading
  },
}

export default {
  namespaced: false,
  state: cloneDeep(initialState),
  getters,
  actions,
  mutations,
}
