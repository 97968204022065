




import {mapGetters} from "@/utils/map-store";
import {computed, defineComponent} from "@vue/composition-api";
import MIframe from "@/components/atom/MIframe.vue";

export default defineComponent({
  name: 'HelpCenter',
  components: {MIframe},
  setup(props, {root}) {
    const {getBranch: branch} = mapGetters(root.$store.getters)

    const helpCenterURL = computed(() => {
      switch(branch.value) {
        case 'PT':
          return 'https://mapit.me/pt-pt/perguntas-frequentes-revendedor/'
        case 'ES':
        default:
          return 'https://mapit.me/preguntas-frecuentes-concesionarios'
      }
    })

    return {
      helpCenterURL
    }
  }
})
