<template>
  <v-expansion-panels v-model="panels" multiple flat class="my-0 ml-0 ml-md-0 ml-sm-0 ml-lg-0">
    <expansion-panel key="expansion-panel-pdf-viewer" :title="$t('offer-manager.create.step-resume.title')" :readonly="true" :hideDivider="true" >
      <template v-slot:panel-content>
        <div class="d-flex justify-center flex-column align-center">
          <v-card class="align-right" elevation="0" width="900px">
            <v-card-actions class="d-flex justify-end">
              <m-pagination
                v-if="pageCount > 1"
                v-model="currentPage"
                :length="pageCount"
                color="#F1A2AB"/>
            </v-card-actions>
          <v-card elevation="5"
                  width="100%">
            <pdf :src="src"
                :page="currentPage"
                @num-pages="pageCount = $event"
                @page-loaded="currentPage = $event"
                @loaded="pdfLoaded"
                style="display: inline-block; width: 100%"
                ref="myPdfComponent"
                :data-cy="uid"
            >
              <template slot="loading">
                {{ $t("common.loading") }}
              </template>
            </pdf>

          </v-card>
            <v-card-actions class="d-flex justify-end">
              <m-pagination
                v-if="pageCount > 1"
                v-model="currentPage"
                :length="pageCount"
                color="#F1A2AB"/>
            </v-card-actions>
          </v-card>
        </div>
      </template>
    </expansion-panel>
  </v-expansion-panels>
</template>

<script>
import pdf from "@mapit/vue-pdf";
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'

export default {
  name: "PdfViewer",
  props: ['uid', 'pdfPath', 'showPdfViewer'],
  components: {
    pdf,
    ExpansionPanel
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
      src: null,
      zoom: 100,
      panels: [0]
    }
  },
  created() {
    this.$root.$on('printPdf', this.printPdf)
  },
  mounted() {
    this.src = this.$props.pdfPath
  },
  computed: {
    noPrevPage() {
      return this.currentPage <= 1;
    },
    noNextPage() {
      return this.currentPage === this.pageCount;
    }
  },
  methods: {
    prevPage() {
      this.currentPage--;
    },
    nextPage() {
      this.currentPage++;
    },
    printPdf() {
      this.$refs.myPdfComponent.print(100)
    },
    pdfLoaded() {
      this.$root.$emit('pdfLoaded')
    }
  },
  beforeDestroy() {
    Object.assign(this.$data, {
      currentPage: 1,
      pageCount: 0,
      src: null,
      zoom: 100,
      panels: [0]
    });
  }
};
</script>
