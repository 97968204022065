export function calculateLabelDistance(value: number): number {
  if ((value >= 0 && value <= 5) || (value >= 45 && value <= 55) || (value >= 95 && value <= 100)) {
    return -40
  }

  if ((value >= 20 && value <= 30) || (value >= 70 && value <= 80)) {
    return -52
  }

  return -50
}
