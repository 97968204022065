import {
  CreatePaymentMethodData,
  PaymentMethod,
  Stripe,
  StripeIbanElement,
} from '@stripe/stripe-js'
import { AccountInput } from '@mapit/core-dynamodb/dist/types'

export async function createPaymentMethod(
  stripe: Stripe,
  ibanElement: StripeIbanElement,
  account: AccountInput
) {
  let response: any
  let error: any = undefined

  try {
    const { firstName, lastName, email, phone, address } = account
    const {
      addressLocality,
      addressNumber,
      addressRegion,
      country,
      postalCode,
      streetAddress,
    } = address || {}

    const data = {
      type: 'sepa_debit',
      sepa_debit: ibanElement,
      billing_details: {
        email,
        name: `${firstName} ${lastName}`,
        phone,
        address: {
          city: addressLocality,
          country: country || 'ES',
          line1: streetAddress,
          line2: addressNumber,
          postal_code: postalCode,
          state: addressRegion,
        },
      },
    }

    response = await stripe.createPaymentMethod(data as CreatePaymentMethodData)
  } catch (e) {
    console.error(e)
    error = e
  }

  return { paymentMethod: response?.paymentMethod as PaymentMethod, error }
}
