import {Translations} from '@aws-amplify/ui-components'

export default {
  [Translations.SIGN_IN_HEADER_TEXT]: 'Início de sessão',
  [Translations.SIGN_IN_ACTION]: 'Início',
  [Translations.SIGN_IN_TEXT]: 'Início',
  [Translations.SIGN_UP_HEADER_TEXT]: 'Registo',
  [Translations.FORGOT_PASSWORD_TEXT]: 'Esqueceu-se da sua palavra-passe?',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Registar-se',
  [Translations.RESET_PASSWORD_TEXT]: 'Repor',
  [Translations.NO_ACCOUNT_TEXT]: 'Não tem conta?',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'Tem conta?',
  [Translations.EMAIL_LABEL]: 'E-mail',
  [Translations.EMAIL_PLACEHOLDER]: 'E-mail',
  [Translations.PASSWORD_LABEL]: 'Palavra-passe',
  [Translations.PASSWORD_PLACEHOLDER]: 'Palavra-passe',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Código de confirmação',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: '123456',
  [Translations.SIGN_IN_WITH_AWS]: 'Início com AWS',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Criar conta',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Confirmar e-mail',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'Não encontra?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Enviar novamente',
  [Translations.BACK_TO_SIGN_IN]: 'Início',
  [Translations.SEND_CODE]: 'Enviar código',
  [Translations.RESET_YOUR_PASSWORD]: 'Repor palavra-passe',
  [Translations.USERNAME_LABEL]: 'E-mail',
  [Translations.USERNAME_PLACEHOLDER]: 'Introduza o seu e-mail',
  [Translations.CODE_LABEL]: 'Código de verificação',
  [Translations.CODE_PLACEHOLDER]: 'Introduza o código que recebeu por e-mail',
  [Translations.CHANGE_PASSWORD]: 'Mudar palavra-passe',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Mudar',
  [Translations.NEW_PASSWORD_LABEL]: 'Nova palavra-passe',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Introduza a sua palavra-passe',
  [Translations.SUBMIT]: 'Enviar',
  [Translations.EMPTY_PASSWORD]: 'Palavra-passe necessária',
  [Translations.EMPTY_CODE]: 'Código necessário',
  [Translations.EMPTY_USERNAME]: 'E-mail necessário',
  [Translations.INVALID_USERNAME]: 'Utilizador incorreto',
  ['Password required']: 'Palavra-passe necessária',
  ['Email required']: 'E-mail necessário',
  ['Incorrect username or password.']: 'Utilizador ou palavra-passe incorreto',
  ['UserMigration failed with error Bad user or password.']: 'ERRO: Utilizador ou palavra-passe incorreto.',
  ['Password reset required for the user']: 'É necessário repor a palavra-passe',
  ['Invalid verification code provided, please try again.']: 'Código inválido ou caducado',
  ['Password does not conform to policy: Password not long enough']: 'A palavra-passe deve ter no mínimo 10 caracteres',
  ['User password cannot be reset in the current state.']: 'A palavra-passe não pode ser reposta no estado atual.',
  ['Attempt limit exceeded, please try after some time.']: 'Excedeu o limite de tentativas, tente novamente mais tarde.',
  ['PostConfirmation invocation failed due to error Socket timeout while invoking Lambda function']: 'Erro de ligação',
  ['UserMigration failed with error Bad username.']: 'ERRO: Nome de utilizador incorreto',
  ['Network error']: 'Erro de ligação',
  ['User is disabled.']: 'O utilizador está desativado',
  ['Temporary password has expired and must be reset by an administrator.']: 'A palavra-passe temporária expirou e a sua conta foi invalidada. Tem de ser eliminada e criada novamente pelo seu administrador',
  ['User does not exist.']: 'Utilizador ou palavra-passe incorreto'
}
