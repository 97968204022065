export default {
  externalLinks: {
    privacyPolicyUrl: "https://www.honda.pt/motorcycles/useful-links/privacy-policy.html"
  },
  finance: {
    supported: false
  },
  hondaPlus: {
    supported: false
  },
  maintenance: {
    supported: false
  },
  transfer: {
    supported: false
  }
}
