
import {Product} from '@mapit/core-dynamodb/dist/types'
import {generateUUID} from "@/utils/utils";
import registrationsApi from "@/api/registrations.api";
import {computed, defineComponent, Ref, ref, watch} from "@vue/composition-api";
import {mapGetters} from "@/utils/map-store";
import {RegistrationDoc} from "@/types";
import RegistrationStatusCard from "@/components/registrations/HondaRegistration/detail/RegistrationStatusCard.vue";

export default defineComponent({
  name: "FinishDetail",
  components: {RegistrationStatusCard},
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  setup(props, {root}) {

    const loading = ref(false)
    const registration = ref(null) as Ref<RegistrationDoc | null>
    const {
      getId,
      getGroup: group,
      getCurrentStep: currentStep,
      getServices: services
    } = mapGetters(root.$store.getters)

    const hasHondaMapit = computed(() => {
      return registration.value?.products.includes(Product.HondaMapit)
    })
    const hasHondaPlusOrInsurance = computed(() => {
      return registration.value?.products.includes(Product.HondaPlus)
        || registration.value?.products.includes(Product.Insurance)
        || registration.value?.products.includes(Product.HondaPlusGo)
    })

    const hasComms = computed(() => {
      return hasHondaMapit.value && registration.value?.deviceHealthChecked !== undefined && registration.value?.deviceHealthChecked
    })

    const willHaveHondaMapit = computed(() => {
      return services.value?.['honda-mapit'].selected
    })

    const willHaveHondaPlus = computed(() => {
      return services.value?.['honda-plus'].selected
        || services.value?.['honda-plus-go'].selected
        || services.value?.['honda-seguro-normal'].selected
        || services.value?.['honda-seguro-plus'].selected
    })

    const willHaveComms = computed(() => {
      return willHaveHondaMapit.value
    })

    const statusChecks = computed(() => {
      return loading.value ? [] : [
        {show: hasHondaPlusOrInsurance.value, valid: registration.value?.contractsSigned, name: 'HP', icon: 'fa-solid fa-signature'},
        {show: hasHondaMapit.value, valid: registration.value?.accountRegistrationCompleted, name: 'HM'},
        {show: hasHondaMapit.value, valid: hasComms.value, name: 'COMMS', icon: 'fa-solid fa-wifi'}
      ].filter((item) => item.show)
    })

    const placeholderChecks = computed(() => {
      return loading.value ? [
        willHaveHondaPlus.value,
        willHaveHondaMapit.value,
        willHaveComms.value
      ].filter(check => check) :  []
    })

    const buttonStyle = computed(() => {
      return `
        text-transform: uppercase !important;
        padding: 8px 24px !important;
        font-family: 'ProximaNovaExCnSbold', sans-serif !important;
        letter-spacing: 0.01em !important;
      `
    })

    watch(currentStep, (val) => {
      if (val === 7) getRegistration()
    })

    async function goToRegistrations() {
      if (group.value && group.value.dealerId) root.$store.dispatch('setPendingRegistrations', group.value.dealerId)

      await root.$router.push('/registrations')
      root.$store.dispatch('resetRegistration')
    }

    function getRegistration() {
      if (!loading.value && getId.value) {
        loading.value = true
        registrationsApi
          .getRegistration(group.value.dealerId, getId.value)
          .then(({data}) => {
            registration.value = data
          })
          .catch((e) => {
            console.log('Error get registration: ', e)
          })
          .finally(() => {
            loading.value = false
          })
      }
    }

    return {
      loading,
      registration,
      getId,
      group,
      currentStep,
      hasHondaMapit,
      hasHondaPlusOrInsurance,
      hasComms,
      statusChecks,
      buttonStyle,
      willHaveHondaMapit,
      willHaveHondaPlus,
      willHaveComms,
      placeholderChecks,
      generateUUID,
      goToRegistrations,
      getRegistration
    };
  }
})
