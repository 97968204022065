import {Offer} from "@mapit/honda-offers-builder/dist/types";
import {onMounted, Ref, ref} from "@vue/composition-api";
import offersApi from "@/api/offers.api";

interface Props {
  id: Ref<string | undefined> | string
  dealerId: Ref<string | undefined> | string;
}

export const useOffer = ({id, dealerId}: Props) => {
  const offer = ref<Offer | null>(null)
  const offerPdf = ref<string | undefined>(undefined)
  const isLoading = ref(false)

  onMounted(async () => {
    await fetchOffer(mapProps({id, dealerId}))
  })

  function mapProps({id, dealerId}: Props) {
    return {
      id: typeof id === 'string' ? id : id.value,
      dealerId: typeof dealerId === 'string' ? dealerId : dealerId.value,
    }
  }

  async function fetchOffer({id, dealerId}: { id: string | undefined, dealerId: string | undefined }) {
    if (!id || !dealerId) return Promise.resolve()

    try {
      isLoading.value = true

      const {data: offerResponse} = await offersApi.getOfferById(id, dealerId)
      const {data: {pdfUrl}} = await offersApi.getOfferPdfById(id, dealerId)

      offer.value = offerResponse
      offerPdf.value = pdfUrl
    } catch (e) {
      console.error(`Error fetching offer ${id}`, e)
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    data: offer,
    offerPdf,
    refetch: async () => await fetchOffer(mapProps({id, dealerId}))
  }
}
