<template>
  <th class="align-left" v-if="isHeader">
      {{title}}
  </th>
  <td class='column-500' v-else>
		<div class="align-left" v-if="rowData">
			<div class="col-12 p-l-15 p-r-15">
				<span class="f-s-14"  v-if="showField(rowData.request, 'service')">
          <b>Revisión/Mantenimiento</b><br/>
        </span>
        <span class="f-s-14" v-if="showField(rowData.request, 'tyres')">
          Cambio de neumáticos<br/>
        </span>
        <span class="f-s-14" v-if="showField(rowData.request, 'paint')">
          Reparación Golpe/Pintura<br/>
        </span>
        <span class="f-s-14" v-if="showField(rowData.request, 'failure')">
          Reparación Avería/Ruido<br/>
        </span>
        <span class="f-s-14" v-if="showField(rowData.request, 'other')">
          Otros<br/>
        </span>
			</div>
		</div>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

const reasons = {
  service: "Revisión",
  tyres: "Cambio de neumáticos",
  paint: "Pintura",
  failure: "Avería"
}

const getTextReason = (key) => {
  return reasons[key];
}

export default {
  mixins: [VuetableFieldMixin],
  props: {
      rowData: {
        type: Object,
        default: () => {
          return { request:{ requested: null }};
        }
      }
  },
  methods:{
    showField: (request, key) => {
      let ret = false;
      if(request && request.requested && request.requested[key]){
        ret = true;
      }
      return ret;
    }
  },
  name: 'Reason'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>


