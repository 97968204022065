import { render, staticRenderFns } from "./ReturnData.vue?vue&type=template&id=486b9b26&scoped=true&"
import script from "./ReturnData.vue?vue&type=script&lang=ts&"
export * from "./ReturnData.vue?vue&type=script&lang=ts&"
import style0 from "./ReturnData.vue?vue&type=style&index=0&id=486b9b26&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486b9b26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VProgressLinear})
