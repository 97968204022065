




































































































































import moment from 'moment'
import { head } from 'lodash'
import { computed, defineComponent, Ref, ref, watch } from '@vue/composition-api'

import dealerBikesApi from '@/api/dealerBikes.api'
import { NUM_CHARACTERS_VALID_VIN, status, vinValidation } from '@/api/vehicle.api.js'
import { useMaterials, useModels } from '@/composables'
import { isAlphanumeric, isEmpty, isRequired, lengthEqualTo } from '@/helpers/validations'
import { useI18n } from '@/i18n'
import { MaterialWithDescription, ModelWithDescription } from '@/types'
import { InputTypes } from '@/utils/constants'
import { mapGetters } from '@/utils/map-store'
import {
  SET_DEALER_BIKE_LICENSE,
  SET_DEALER_BIKE_MAPIT,
  SET_DEALER_BIKE_MODEL_ID,
  SET_DEALER_BIKE_MODEL_NAME,
  SET_DEALER_BIKE_MODEL_SUBNAME,
  SET_DEALER_BIKE_REGISTRATION_DATE,
  SET_DEALER_BIKE_REGISTRATION_NUMBER,
  SET_DEALER_BIKE_VIN,
  SET_IS_MIGRATION_DEALER_BIKE,
  SET_IS_VALID_DEALER_BIKE_MAPIT,
  SET_MIGRATION_DEMO_BIKE_DATA,
} from '@/vuex/mutation-types'
import { DrivingLicense } from '@mapit/contacts-dynamodb/dist/types'
import { DealerBikeStatus, UsageType } from '@mapit/dealer-bikes-dynamodb/dist/types'

import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import MapitInput from '@/components/general/MapitInput.vue'

export default defineComponent({
  name: 'VehicleData',
  components: { ExpansionPanel, MapitInput },
  props: {
    showValidations: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    reduced: {
      type: Boolean,
      default: false,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    descriptionList: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { root }) {
    const { t } = useI18n()

    const hideDetails = ref({
      setDealerBikeRegistrationDate: true,
      setDealerBikeUsageType: true,
      setDealerBikeVehicleRegistrationNumber: undefined as boolean | undefined,
    })

    const selectedModel = ref() as Ref<ModelWithDescription | undefined>
    const selectedMaterial = ref() as Ref<MaterialWithDescription | undefined>

    const vin = ref<string | undefined>(undefined)
    const vinAvailableError = ref('')
    const vinAvailableMigration = ref('')
    const vinValidationLoader = ref(false)
    const vinLoading = ref(false)
    const abortController = ref<AbortController>(new AbortController())

    const requiredRules = ref([isRequired])
    const vinRules = ref([
      (value: string) => isEmpty(value, 'honda.registration.vehicle.validations.vin_empty'),
      (value: string) => isAlphanumeric(value, 'honda.registration.vehicle.validations.vin_incorrect_format'),
      (value: string) => lengthEqualTo(value, 17, 'honda.validation.fields.vin'),
    ])
    const plateRules = ref([
      isEmpty,
      (value: string) =>
        isAlphanumeric(value, 'honda.registration.vehicle.validations.registration_number_incorrect_format'),
    ])

    const usageTypeList = computed(() => {
      return [UsageType.Demo, UsageType.Courtesy].map((item) => {
        return { text: t(`dealer-bike.registration.lbl-usage-type-${item.toLowerCase()}`), value: item }
      })
    })

    const licenseList = computed(() => {
      return [DrivingLicense.A1, DrivingLicense.A2, DrivingLicense.A].map((item) => ({
        text: item === DrivingLicense.A1 ? 'A1/B' : item,
        value: item,
      }))
    })

    const {
      isMigrationDealerBike: isMigration,
      getDealerBikeUsageType: dealerBikeUsageType,
      getDealerBikeSelected: dealerBikeSelected,
      getCountry: country,
      getLocale: locale,
      getBranch: branch,
      isDealerBikeMapitLoading,
      getDealerBikeRegistrationDate,
      getDealerBikeVehicleVin,
      getDealerBikeLicense,
      getDealerBikeVehicleRegistrationNumber,
      getDealerBikeVehicleModelName,
      getDealerBikeVehicleModelSubname,
    } = mapGetters(root.$store.getters)

    const { models, loading: isModelsLoading } = useModels({
      branch: branch.value,
      immediate: !props.showLabels,
    })

    const { materials, loading: isMaterialsLoading } = useMaterials({
      branch: branch.value,
      model: selectedModel,
    })

    const selectedModelName = computed(() => {
      return props.showLabels || props.descriptionList
        ? selectedModel.value?.name ?? getDealerBikeVehicleModelName.value
        : ''
    })

    const selectedMaterialName = computed(() => {
      return props.showLabels || props.descriptionList
        ? selectedMaterial.value?.description ?? getDealerBikeVehicleModelSubname.value
        : ''
    })

    const dealerBikeRegistrationDate = computed({
      get: () => getDealerBikeRegistrationDate.value,
      set: (value: string) => commit(SET_DEALER_BIKE_REGISTRATION_DATE, value),
    })

    const dealerBikeVehicleVin = computed({
      get: () => getDealerBikeVehicleVin.value,
      set: (value: string) => {
        if (vinLoading.value) {
          abortController.value.abort()
          abortController.value = new AbortController()
          vinLoading.value = false
        }
        validateVin(value.toUpperCase())
      },
    })

    const dealerBikeLicense = computed({
      get: () => getDealerBikeLicense.value,
      set: (value: string) => commit(SET_DEALER_BIKE_LICENSE, value),
    })

    const dealerBikeVehicleRegistrationNumber = computed({
      get: () => getDealerBikeVehicleRegistrationNumber.value,
      set: (value: string) => commit(SET_DEALER_BIKE_REGISTRATION_NUMBER, value.toUpperCase().replace(/[^A-Z0-9]/g, '')),
    })

    watch(dealerBikeRegistrationDate, () => {
      hideDetails.value.setDealerBikeRegistrationDate = false
    })

    watch(selectedModel, (value) => {
      commit(SET_DEALER_BIKE_MODEL_NAME, value?.description)
    })

    watch(selectedMaterial, (value) => {
      commit(SET_DEALER_BIKE_MODEL_SUBNAME, value?.description)
    })

    watch(selectedMaterial, (value) => {
      commit(SET_DEALER_BIKE_MODEL_ID, value?.idMat)
    })

    watch(materials, (value, oldValue) => {
      if (!props.readonly && oldValue.length === 0 && value.length === 1) {
        selectedMaterial.value = value[0]
      }
    })

    async function loadDealerBikeByRegistrationNumber(registrationNumber: string, signal?: AbortSignal) {
      return dealerBikesApi
        .getDealerBikeByRegistrationNumber(registrationNumber, signal)
        .then((data) => {
          commit(SET_IS_MIGRATION_DEALER_BIKE, !head(data.data.data))
          return data
        })
        .catch((e) => {
          if (e.response.status === 404) {
            commit(SET_IS_MIGRATION_DEALER_BIKE, true)
            return null
          } else {
            commit(SET_IS_MIGRATION_DEALER_BIKE, false)
            console.error(e)
            throw e
          }
        })
    }

    function commit(mutation: string, payload: any) {
      root.$store.commit(mutation, payload)
    }

    async function updateVehicle(action: keyof typeof hideDetails.value, value: any) {
      await root.$store.dispatch(action, value)
      hideDetails.value[action] = false
    }

    function resetVinErrors() {
      vinAvailableMigration.value = ''
      vinAvailableError.value = ''
    }
    async function checkVinIsAvailable(vin: string) {
      const [maybeExDealerBike, result] = await Promise.all([
        dealerBikesApi
          .getDealerBikeByVin(vin, abortController.value.signal)
          .then((res) => head(res.data.data.filter((db) => db.status === DealerBikeStatus.Retired))),
        vinValidation(vin, abortController.value.signal),
      ])

      if (maybeExDealerBike) {
        vinAvailableError.value = t('honda.registration.vehicle.validations.vin_already_used_demo_bike').toString()
        return false
      } else if (result.key === status.ALREADY_USED_VIN.key && result.isDemoBike) {
        try {
          await loadDealerBikeByRegistrationNumber(result.registrationNumber, abortController.value.signal)
          hideDetails.value['setDealerBikeVehicleRegistrationNumber'] = false

          commit(SET_DEALER_BIKE_REGISTRATION_NUMBER, result.registrationNumber)
          commit(SET_DEALER_BIKE_MAPIT, { imei: result.deviceImei, id: result.deviceId })
          commit(SET_IS_VALID_DEALER_BIKE_MAPIT, true)
          commit(SET_MIGRATION_DEMO_BIKE_DATA, { registrationId: result.registrationId, vehicleId: result.vehicleId })

          resetVinErrors()
          isMigration.value
            ? (vinAvailableMigration.value = t('honda.registration.vehicle.validations.vin_migrated_demo_bike').toString())
            : (vinAvailableError.value = t('honda.registration.vehicle.validations.vin_already_used_demo_bike').toString())
        } catch (e) {
          resetVinErrors()
        }
        return
      }
      vinAvailableError.value = result.error ? t(result.status).toString() : ''
    }
    async function validateVin(vin: string) {
      vinLoading.value = true
      vinValidationLoader.value = true
      resetVinErrors()

      commit(SET_DEALER_BIKE_VIN, vin.toUpperCase())
      commit(SET_DEALER_BIKE_REGISTRATION_NUMBER, '')
      commit(SET_IS_MIGRATION_DEALER_BIKE, false)
      commit(SET_DEALER_BIKE_MAPIT, null)
      commit(SET_IS_VALID_DEALER_BIKE_MAPIT, false)
      root.$store.dispatch('imeiNeedsClearing', true)

      const validVin = vin.length === NUM_CHARACTERS_VALID_VIN && typeof isAlphanumeric(vin.toUpperCase()) !== 'string'
      if (validVin) await checkVinIsAvailable(vin.toUpperCase())
      vinValidationLoader.value = false
    }

    function handleUsageTypeChange(value: typeof usageTypeList.value) {
      updateVehicle('setDealerBikeUsageType', value)
    }

    return {
      t,
      moment,
      InputTypes,
      NUM_CHARACTERS_VALID_VIN,
      hideDetails,
      usageTypeList,
      licenseList,
      requiredRules,
      vin,
      vinAvailableError,
      vinAvailableMigration,
      vinValidationLoader,
      vinRules,
      vinLoading,
      plateRules,
      isMigration,
      dealerBikeUsageType,
      dealerBikeSelected,
      country,
      locale,
      isDealerBikeMapitLoading,
      dealerBikeRegistrationDate,
      dealerBikeVehicleVin,
      dealerBikeLicense,
      dealerBikeVehicleRegistrationNumber,
      models,
      materials,
      isModelsLoading,
      isMaterialsLoading,
      selectedModel,
      selectedMaterial,
      selectedModelName,
      selectedMaterialName,
      loadDealerBikeByRegistrationNumber,
      commit,
      updateVehicle,
      resetVinErrors,
      checkVinIsAvailable,
      validateVin,
      handleUsageTypeChange,
    }
  },
})
