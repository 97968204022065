import { computed } from '@vue/composition-api'

export const mapGetters = (getters: any) => {
  return Object.fromEntries(
    Object.keys(getters).map((getter) => [getter, computed(() => getters[getter])]),
  )
}

export const mapState = (state: any) => {
  return Object.fromEntries(Object.keys(state).map((key) => [key, computed(() => state[key])]))
}

export const mapMutations = (commit: any) => {
  return Object.fromEntries(
    Object.keys(commit).map((key) => [key, (payload: any) => commit[key](payload)]),
  )
}
