



























































import Vue, {PropType} from 'vue'
// @ts-ignore
import {isPhoneNumberEvent} from "@/helpers/validations"
import {Contact} from "@mapit/contacts-dynamodb/dist/types"
import {ContactsApi} from "@/api/contacts.api"
import ResultInfoBox from "@/components/general/ResultInfoBox.vue"
import {PhoneNumber} from '@mapit/contacts-dynamodb/dist/util/phone-number'
import {Country} from '@mapit/common-utils/dist/types'
import {ContactWithExtras} from '@/types'

type BaseData = {
  searchInput: string,
  contactNotFound: boolean,
  error: boolean,
  loading: boolean,
  isFormValid: boolean,
  searched: boolean
}

export default Vue.extend({
  name: "ContactLookup",
  components: {ResultInfoBox},
  props: {
    value: {
      type: Object as () => Contact,
      required: false,
    },
    text: String,
    messageNotFound: {
      type: String,
      default: () => 'No se ha encontrado el contacto'
    },
    messageError: {
      type: String,
      default: () => 'Error'
    },
    country: {
      type: String as PropType<Country>,
      default: Country.Spain
    },
  },
  data(): BaseData {
    return {
      searchInput: '',
      contactNotFound: false,
      error: false,
      loading: false,
      isFormValid: false,
      searched: false
    }
  },
  watch: {
    searchInput: function (val) {
      this.searched = false
    },
    searched: function (newVal, val) {
      if (val && !newVal) {
        this.$emit('input', null)
        this.$emit('search', '', null)
        this.contactNotFound = false
      }
    }
  },
  methods: {
    async searchContact(): Promise<void> {
      this.error = false
      this.contactNotFound = false

      if (this.isFormValid) {
        this.loading = true
        try {
          const res = await new ContactsApi().getContactByPhone(this.searchInput)
          const contact = res.data.data.length > 0 ? res.data.data[0] as ContactWithExtras : null

          if (contact) {
            if (!contact.gdprConsent) contact.gdprConsent = true
            if (!contact.privacyTermsAccepted) contact.privacyTermsAccepted = true
            contact.phone = this.searchInput
          } else {
            this.contactNotFound = true
          }
          this.searched = true
          this.$emit('input', contact)
          this.$emit('search', this.searchInput, contact)
        } catch (e) {
          console.error("Problem finding contact", e)
          this.$emit('search', null)
          this.error = true
        } finally {
          this.loading = false
        }
      }
    },
    stickToPhoneNumberDigits: isPhoneNumberEvent
  }
})
