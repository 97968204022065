<template>
  <div id="range-picker-input">
  <v-menu
    ref="datePickerMenu"
    v-model="datePickerMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-bind="attrs" v-on="on"
                    :placeholder="placeholder"
                    class="append-icon-mapit"
                    :ref="`mapitInput_${uid}`"
                    :data-cy="uid"
                    v-model="dateRangeText"
                    append-icon="u"
                    readonly
                    outlined
                    hide-details="true"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="pickerDob"
      v-model="dateRangeValue"
      :max="calculatedMaxDate"
      :min="calculatedMinDate"
      range
      :locale="locale || 'es-ES'"
      :first-day-of-week="getFirstDayOfWeek"
    ></v-date-picker>
  </v-menu>
  </div>
</template>

<script>
import moment from 'moment'
import {mapGetters} from "vuex";

export default {
  name: "RangePicker",
  props: ["hint", "disabled", "placeholder", "uid", "maxDate", "minDate", "initialRange", "maxRange", "unit", "locale"],
  data: () => {
    return {
      dateRangeValue: [],
      datePickerMenu: false,
    }
  },
  computed: {
    getFirstDayOfWeek() {
      return moment().locale(this.locale || 'es-ES').localeData().firstDayOfWeek()
    },
    ...mapGetters({
      country: 'getCountry',
    }),
    dateRangeText () {
      return this.dateRangeValue.map(d => moment(d).format("DD/MM/YYYY")).join(' - ')
    },
    calculatedMaxDate() {
      if (this.dateRangeValue && this.dateRangeValue.length === 1 && this.maxRange) {
        const newMaxDate = moment(this.dateRangeValue[0]).add(this.maxRange || 0, this.unit)
        if (!this.maxDate || (this.maxDate && newMaxDate.isBefore(this.maxDate)))
          return newMaxDate.format('YYYY-MM-DD')
      }
      return this.maxDate
    },
    calculatedMinDate() {
      if (this.dateRangeValue && this.dateRangeValue.length === 1 && this.maxRange) {
        const newMinDate = moment(this.dateRangeValue[0]).subtract(this.maxRange || 0, this.unit).add(1, "days")
        if (!this.minDate || (this.minDate && newMinDate.isAfter(this.minDate)))
          return newMinDate.format('YYYY-MM-DD')
      }
      return this.minDate
    }
  },
  watch: {
    dateRangeValue(val) {
      if (val.length === 2) {
        if (moment(val[0]).isAfter(moment(val[1])))
          return this.dateRangeValue = val.sort((a, b) => moment(a).valueOf() - moment(b).valueOf())
        this.datePickerMenu = false
      }
      this.$emit('changeValue', val)
    },
  },
  created() {
    this.dateRangeValue = this.initialRange.map(d => d)
  }
}
</script>

<style lang="scss">
#range-picker-input {
  //FOCUS STATE
  .v-input--is-focused {
    fieldset {
      border: 1px solid #0075eb !important;
      background-color: var(--v-gray-lighten1) !important;
    }
    input,
    .v-select__selection {
      color: var(--v-gray-darken4) !important;
      font: normal normal normal 16px/26px 'ProximaNovaRegular' !important;
      letter-spacing: 0px;
    }
  }
  //ACTIVE STATE (WITH VALUE)
  .v-input--is-label-active {
    fieldset {
      border: 1px solid var(--v-gray-lighten3);
      background-color: #ecf3ff !important;
    }
    input,
    .v-select__selection {
      color: var(--v-gray-darken4) !important;
      font: normal normal normal 16px/26px 'ProximaNovaSemibold';
      letter-spacing: 0px;
    }
  }
  //NORMAL STATE
  .v-input--is-booted {
    input {
      color: var(--v-gray-darken4) !important;
      font: normal normal normal 16px/26px 'ProximaNovaRegular';
      letter-spacing: 0px;
    }
  }
  //DISABLED
  .v-input--is-disabled {
    fieldset {
      border: 1px solid var(--v-gray-lighten2) !important;
    }
    ::placeholder {
      color: var(--v-gray-lighten3);
    }
  }
  fieldset {
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--v-gray-lighten3);
    height: 50px;
    padding-left: 16px;
    margin: 0 !important;
    top: 0px
  }
  ::placeholder {
    text-align: left;
    font: normal normal normal 16px/26px 'ProximaNovaRegular';
    letter-spacing: 0px;
    color: var(--v-gray-darken1);
    opacity: 1;
  }
  .v-input__icon--prepend-inner, .v-input__prepend-inner .icon-mapit {
    z-index: 1 !important;
    margin-right: 10px;
  }

  .v-input__prepend-inner,
  .v-input__append-inner {
    margin-top: 10px;
  }
  .append-icon-mapit{
    .v-input__icon--append i, .v-input__icon--append button {
      font-family: 'mapitfont-v1' !important;
      font-size: 24px;
      text-transform: none;
      height: 44px;
      color: var(--v-gray-darken1) !important;
    }
  }
  .v-input__icon--prepend-inner i, .v-input__prepend-inner .icon-mapit {
    font-family: 'mapitfont-v1' !important;
    font-size: 24px;
    text-transform: none;
    height: 44px;
    color: var(--v-gray-darken1) !important;
    font-style: normal;
  }
  .v-input__icon--append i {
    font: normal normal normal 16px/26px 'ProximaNovaRegular';
    color: var(--v-gray-darken1) !important;
    top: 5px
  }
  .v-input__icon--clear {
    .v-icon {
      font-family: 'mapitfont-v1' !important;
      text-transform: none;
      height: 44px;
      color: var(--v-gray-darken1) !important;
    }
  }
  .v-text-field__details {
    padding: 0 0 10px 0 !important;
    margin: 0 !important;
    .v-messages__message {
      font: normal normal normal 14px/18px 'ProximaNovaRegular';
      letter-spacing: 0px;
      color: var(--v-gray-darken1);
    }
    .error--text {
      .v-messages__message {
        font: normal normal normal 14px/18px 'ProximaNovaRegular';
        letter-spacing: 0px;
        color: var(--v-primary-base);
      }
    }
  }
  //other
  .readonly {
    pointer-events: none;
  }
  .input-right {
    input {
      text-align: right !important;
    }
    ::placeholder {
      text-align: right;
    }
  }
}
</style>
