<template>
  <div class="service-card">
    <div :class="['d-flex align-end justify-center', isSelectable && !isReduced && !hideInfoButton ? 'ml-10': ''] ">
      <div
        data-cy="service-card"
        @click="changeCheckValue"
        :class="[
          isSelectable ? (checkValue ? 'selected' : '') : 'noSelectable',
          serviceClass,
          typeStyled ? typeStyled: '',
          'mx-0'
        ]"
      >
        <i
          v-if="isSelectable && !isReduced"
          aria-hidden="true"
          class="checkbox fa"
          :class="{ 'fa-square-o': !checkValue, 'fa-check-square': checkValue }"
        />
      </div>
      <div
        v-if="isSelectable && !isReduced && !hideInfoButton"
        @click="showInfo"
      >
        <v-icon dense class="fa-light fa-circle-info ml-1 px-2 click" color="tertiary" />
      </div>
    </div>
    <h4 v-if="isSelectable && !isReduced" class="service-title align-center">
      {{ title }}
    </h4>
    <p v-if="error && !isReduced" id="error-lbl" class="color-red" v-html="error" />
    <p v-if="info && !isReduced" id="info-lbl" class="color-green" v-html="info" />
  </div>
</template>

<script>
export default {
  name: 'ServiceCheckbox',
  components: {},
  props: [
    'checkValue',
    'variant',
    'title',
    'error',
    'info',
    'disabled',
    'isSelectable',
    'typeStyled',
    'forcedSelected',
    'hideInfoButton'
  ],
  data() {
    return {}
  },
  computed: {
    serviceClass() {
      return !this.error && !this.isDisabled
        ? `service-box ${this.variant}`
        : `service-box-disabled ${this.variant}-disabled${this.getStyleForcedSelected}`
    },
    getStyleForcedSelected(){
      return this.forcedSelected ? '-forced-selected' : ''
    },
    isDisabled() {
      return this.disabled || this.forcedSelected
    },
    isReduced() {
      return this.typeStyled && this.typeStyled.includes('reduced')
    }
  },
  methods: {
    changeCheckValue() {
      if(!this.isDisabled) this.$emit('changeCheckValue', this.variant)
    },
    showInfo() {
      this.$emit('showInfo', this.variant)
    },
  },
}
</script>

<style lang="scss">
  #info-lbl, #error-lbl {
    font-family: 'ProximaNovaRegular' !important;
    font-size: 14px;
  }
  .fs-10 {
    font-size: 10px !important;
  }
  .fs-12 {
      font-size: 12px !important;
  }
</style>

<style lang="scss" scoped>
.service-card {
  .service-box {
    &:not(.selected){
      &:hover {
        border: 2px solid var(--v-primary-base);
        cursor: pointer;
      }
    }
  }
  .noSelectable {
    border: 2px solid var(--v-primary-base) !important;
    margin: 5px !important;
    user-select: none !important;
    cursor: default !important;
  }

  .service-box,
  .service-box-disabled {
    height: 170px;
    width: 170px;
    border: 2px solid var(--v-gray-lighten2);
    margin: auto;
    color: #333;
    border-radius: 6px;
    transform: perspective(1px) translateZ(0);

    &.reduced {
      height: 100px;
      width: 100px;

      &.honda-plus {
        background-size: 100px !important;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      &.honda-plus-go {
        background-size: 100px !important;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      &.honda-mapit {
        background-size: 100px !important;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      &.honda-seguro-normal {
        background-size: 100px !important;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      &.honda-seguro-plus {
        background-size: 100px !important;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      &.honda-finance {
        background-size: 100px !important;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      i.fa {
        position: absolute;
        top: 2.5px;
        left: 2.5px;
        font-size: 0.8em;
      }
    }
    &-disabled {
      border: 2px solid var(--v-gray-lighten2) !important;
    }

    &.honda-plus {
      background: url(/images/services/HP.svg);
      background-size: 170px;
      &.selected {
        background: url(/images/services/HP_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
      }
      &:active {
        background: url(/images/services/HP_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
        opacity: 0.6;
      }
      &-disabled {
        background: url(/images/services/HP_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
        &-forced-selected {
          background: url(/images/services/HP_selected.svg);
          pointer-events: none;
          opacity: 0.6;
        }
      }
    }

    &.honda-plus-go {
      background: url(/images/services/HPGo.svg);
      background-size: 170px;
      &:not(.selected) {
        &:active {
          background: url(/images/services/HPGo_selected.svg);
          background-color: var(--v-primary-base);
          opacity: 0.6;
        }
      }
      &.selected {
        background: url(/images/services/HPGo_selected.svg);
        background-color: var(--v-primary-base);
        background-size: 170px;
        &:active {
          opacity: 0.6;
        }
      }
      &-disabled {
        background: url(/images/services/HPGo_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
        &-forced-selected {
          background: url(/images/services/HPGo_selected.svg);
          pointer-events: none;
          background-color: var(--v-primary-base);
          opacity: 0.6;
        }
      }
    }


    &.honda-mapit {
      background: url(/images/services/HM.svg);
      background-size: 170px;
      &.selected {
          background: url(/images/services/HM_selected.svg);
          background-size: 170px;
          background-color: var(--v-primary-base);
      }
      &:active {
        background: url(/images/services/HM_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
        opacity: 0.6;
      }
      &-disabled {
        background: url(/images/services/HM_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
        &-forced-selected {
          background-size: 170px;
          background: url(/images/services/HM_selected.svg);
          background-color: var(--v-primary-base);
          pointer-events: none;
          opacity: 0.6;
        }
      }
    }

    &.honda-seguro-normal {
      background: url(/images/services/HS.svg);
      background-size: 170px;
      &.selected {
        background: url(/images/services/HS_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
      }
      &:active {
        background: url(/images/services/HS_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
        opacity: 0.6;
      }
      &-disabled {
        background: url(/images/services/HS_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
      }
    }

    &.honda-seguro-plus {
      background: url(/images/services/HSA.svg);
      background-size: 170px;
      &.selected {
        background: url(/images/services/HSA_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
        background-size: 170px;
      }
      &:active {
        background: url(/images/services/HSA_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
        opacity: 0.6;
      }
      &-disabled {
        background: url(/images/services/HSA_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
      }
    }

    &.honda-finance {
      background: url(/images/services/HFS.svg);
      background-size: 170px;
      &.selected {
        background: url(/images/services/HFS_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
        background-size: 170px;
      }
      &:active {
        background: url(/images/services/HFS_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
        opacity: 0.6;
      }
      &-disabled {
        background: url(/images/services/HFS_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;

        &-forced-selected {
          background: url(/images/services/HFS_selected.svg);
          background-color: var(--v-primary-base);
          pointer-events: none;
          opacity: 0.6;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: var(--v-primary-base);
      color: white;
    }

    &:not(.selected) {
      &:not(-disabled) {
        i.checkbox {
          color: var(--v-gray-lighten2);
        }
      }
    }
    i.checkbox {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 1.5em;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 60px;
      text-shadow: 2px 2px #aaaaaa;
    }
  }
  i.btn-info {
    position: relative;
    top: -25px;
    right: -105px;
  }

  .service-title {
    letter-spacing: 0;
    text-align: center;
    font: normal normal 600 24px/30px 'ProximaNovaExCnSbold';
    letter-spacing: 0px;
    color: #222222;
    text-transform: uppercase;
    opacity: 1;
    font-size: 24px;
  }

  .btn-info {
    font-size: 12px;
    background-color: var(--v-gray-base);
    height: 22.5px;
    width: 22.5px;
    border-radius: 100px;
    padding: 5px;
    color: white;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
