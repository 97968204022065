<template>
  <span>
    {{ getImeiFormatted(value) }}
  </span>
</template>

<script>
export default {
  name: 'Imei',
  props: { value: String },
  methods: {
    getImeiFormatted(imei) {
      if (imei) {
        return imei.replace(/(\d{5})(?!$)/g, '$1-')
      }
    },
  },
}
</script>
