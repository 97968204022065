import { z } from 'zod'
import { ref } from '@vue/composition-api'
import moment from 'moment'

import marketingApi from '@/api/marketing.api'
import { s3 } from '@/api/lib/s3-client'
import { generateUUID } from '@/utils/utils'

export const contactTicketSchema = z.object({
  subject: z
    .string()
    .min(3)
    .transform((value) => {
      const preSubject = `[B2B] [HONDAPLUS] ${value}`
      const subject = process.env.VUE_APP_ENV_MODE === 'development' ? `[DEV] ${preSubject}` : preSubject
      return subject.trim()
    }),
  email: z.string().email(),
  record: z.string().min(3),
  attachment: z.string().optional(),
})

export type ContactTicket = z.infer<typeof contactTicketSchema>

export const attachmentSchema = z.object({
  name: z.string(),
  lastModified: z.number(),
  lastModifiedDate: z.date(),
  size: z.number(),
  type: z.string(),
  webkitRelativePath: z.string().optional(),
})

export type Attachment = z.infer<typeof attachmentSchema>
export interface ParsedAttachment {
  key: string
  name: string
  attachment: Attachment
}
export function useContact() {
  const isLoading = ref(false)
  const attachment = ref<ParsedAttachment | null>(null)

  function createTicket(ticket: unknown) {
    const parseResult = contactTicketSchema.safeParse(ticket)

    if (!parseResult.success) {
      throw parseResult.error
    }

    return parseResult.data
  }

  function createAttachmentKey(data: unknown) {
    const parseResult = attachmentSchema.safeParse(data)

    if (!parseResult.success) {
      throw parseResult.error
    }

    const lastDot = parseResult.data.name.lastIndexOf('.')
    const extension = parseResult.data.name.substring(lastDot + 1)

    return {
      key: `attachments/att_${moment(new Date()).unix()}_${generateUUID()}${extension ? `.${extension}` : ''}`,
      name: parseResult.data.name,
    }
  }

  function addAttachment(data: unknown) {
    try {
      attachment.value = null
      const { key, name } = createAttachmentKey(data)

      attachment.value = { key, name, attachment: data as Attachment }
      return { key, name }
    } catch (e: any) {
      attachment.value = null
      console.error(e.message)
      throw e
    }
  }

  async function uploadAttachment() {
    try {
      const client = await s3(process.env.VUE_APP_ATTACHMENTS_S3)
      return await client.putObject({
        Bucket: process.env.VUE_APP_ATTACHMENTS_S3,
        Key: attachment.value?.key,
        Body: attachment.value?.attachment,
      })
    } catch (e) {
      throw new Error('Error while uploading attachment')
    }
  }

  async function sendTicket(ticket: ContactTicket) {
    try {
      isLoading.value = true
      const parsedTicket = createTicket(ticket)

      if (attachment.value) {
        await uploadAttachment()
      }

      const response = await marketingApi.createTicket(parsedTicket)
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      isLoading.value = false
    }
  }

  return {
    loading: isLoading,
    addAttachment,
    sendTicket,
  }
}
