<template>
  <th class='b-r-1' v-if="isHeader">
      {{title}}
  </th>
  <td class='b-r-1 column-500' v-else>
		<v-row no-gutters v-if="rowData">
			<v-col class="col-12 p-l-15 p-r-15 text-left">
				<span class="f-s-14">
					<router-link  class="color-gray-dark" :to="{name: 'User', params: {userId: rowData.customer.id}}">
                    <strong> {{getCustomerName(rowData.customer)}}</strong>
            </router-link>
        </span>
			</v-col>
		</v-row>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
      rowData: {
        type: Object,
        default: () => {
          return { customer: {id: null , detail: null}};
        }
      }
  },
  methods:{
    getCustomerName(customer){
      let fullname = "";
      if (customer.detail ){
        fullname = JSON.parse(customer.detail).Name + ' ' + JSON.parse(customer.detail).Surname;
      }
      return fullname;
    }
  },
  name: 'Client'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>


