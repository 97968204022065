<template>
  <div class="device-container">
    <h3>{{ `${vehicle.name || vehicle.model} (${vehicle.registrationNumber})` }}</h3>

    <div class="container-details">
      <dl class="vehicle-details">
        <dt>{{ $t('users.vehicle.vin') }}</dt>
        <dd>{{ vehicle.vin || '-' }}</dd>

        <dt>{{ $t('users.vehicle.sales-date') }}</dt>
        <dd>{{ vehicle.saleDate | formatDate }}</dd>

        <dt>{{ $t('users.device.mapit') }}</dt>
        <dd>{{ deviceImei || $t('users.device.no-device') }}</dd>

        <dt>{{ $t('users.device.state') }}</dt>
        <dd class="device-status">
          <v-icon x-small :color="deviceStatus.color">fa fa-circle</v-icon
          ><span>{{ deviceStatus.title }}</span>
        </dd>
      </dl>

      <div class="services-details">
        <h3>{{ $t('users.services.contracted') }}</h3>

        <div class="services-products">
          <service-checkbox
            v-if="!hasHondaPlusGo"
            :checkValue="hasHondaPlus"
            :disabled="!hasHondaPlus"
            :isSelectable="true"
            data-tooltip="Honda Plus"
            title="Honda Plus"
            typeStyled="reduced"
            variant="honda-plus"
          />

          <service-checkbox
            v-if="hasHondaPlusGo"
            :checkValue="hasHondaPlusGo"
            :disabled="!hasHondaPlusGo"
            :isSelectable="true"
            data-tooltip="Honda Plus Go"
            title="Honda Plus Go"
            typeStyled="reduced"
            variant="honda-plus-go"
          />

          <service-checkbox
            :checkValue="hasHondaMapit"
            :disabled="!hasHondaMapit"
            :isSelectable="true"
            data-tooltip="Honda Mapit"
            title="Honda Mapit"
            typeStyled="reduced"
            variant="honda-mapit"
          />

          <service-checkbox
            :checkValue="hasInsurance"
            :data-tooltip="$t('users.services.insurance')"
            :disabled="!hasInsurance"
            :isSelectable="true"
            title="Honda Seguro"
            typeStyled="reduced"
            variant="honda-seguro-normal"
          />

          <service-checkbox
            :checkValue="hasInsuranceExpanded"
            :data-tooltip="$t('users.services.insurance-expanded')"
            :disabled="!hasInsuranceExpanded"
            :isSelectable="true"
            title="Honda Seguro Ampliado"
            typeStyled="reduced"
            variant="honda-seguro-plus"
          />

          <service-checkbox
            :checkValue="hasFinance"
            :data-tooltip="$t('users.services.financing')"
            :disabled="!hasFinance"
            :isSelectable="true"
            title="Honda Financial Services"
            typeStyled="reduced"
            variant="honda-finance"
          />
        </div>

        <div class="actions">
          <v-btn v-if="deviceImei" color="secondary" @click="handleDetachDevice">
            {{ $t('users.actions.change-device') }}
          </v-btn>

          <v-btn v-else color="yellow" @click="handleAttachDevice">
            {{ $t('users.actions.install-device') }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="!changeDevice && loading"
            color="secondary"
            @click="handleDownloadContracts"
          >
            {{ $t('users.actions.download-contracts') }}
          </v-btn>
        </div>
      </div>
    </div>

    <mapit-dialog :dialog="dialog.status.value" custom-width="610px" @close="dialog.close">
      <template v-slot:header-icon>
        <v-icon :color="dialog.content.value.iconColor">{{ dialog.content.value.icon }}</v-icon>
      </template>

      <template v-slot:header-text>
        <p>{{ dialog.content.value.title }}</p>
      </template>

      <template v-slot:body>
        <p>{{ dialog.content.value.content }}</p>

        <div
          v-if="!changeDevice && contractsDownloaded && hasContracts"
          class="d-flex justify-center"
        >
          <div v-if="contractHP">
            <a :href="contractHP.documentUrl" download>
              <v-img width="130" src="/images/print-hp.png" />
            </a>
          </div>

          <div v-if="contractSEPA">
            <a :href="contractSEPA.documentUrl" download>
              <v-img width="130" src="/images/print-sepa.png" />
            </a>
          </div>

          <div v-if="contractHM">
            <a :href="contractHM.documentUrl" download>
              <v-img width="130" src="/images/print-hm.png" />
            </a>
          </div>
        </div>
      </template>

      <template v-slot:footer-center>
        <v-btn class="btn-gray" @click="dialog.close">{{ $t('buttons.cancel') }}</v-btn>
        <v-btn
          :disabled="loading"
          :loading="changeDevice && loading"
          @click="dialog.confirm.value"
          class="btn-primary"
          >{{ dialog.content.value.confirm }}
        </v-btn>
      </template>
    </mapit-dialog>
  </div>
</template>

<script>
import { ref, computed, defineComponent, watch } from '@vue/composition-api'
import { useI18n } from '@/i18n'

import coreApi from '@/api/core.api'
import dealersApi from '@/api/dealers.api'

import { DetachmentReason } from '@mapit/core-dynamodb/dist/types'
import { getDeviceStatusConfig } from '@/utils/devices'
import { useDialog } from '@/composables/dialog'
import { Product } from '@/types/offers'

import ServiceCheckbox from '@/components/registrations/HondaRegistration/services/ServiceCheckbox.vue'
import MapitDialog from '@/components/dialog/MapitDialog.vue'

export default defineComponent({
  name: 'ContainerDetail',
  components: {
    MapitDialog,
    ServiceCheckbox,
  },
  props: {
    vehicle: {
      type: Object,
      required: false,
    },
  },
  setup(props, { root }) {
    const { t } = useI18n()
    const { id: vehicleId, products, device, vin } = props.vehicle

    const deviceImei = ref(device?.imei)
    const changeDevice = ref(false)

    const dialog = useDialog()
    const loading = ref(false)
    const error = ref(false)

    const contracts = ref([])
    const contractsDownloaded = ref(false)

    const registrationId = computed(() => {
      return props.vehicle?.registrationId || props.vehicle?.registration?.id
    })

    const hasContracts = computed(() => {
      return contracts.value.filter((contract) => contract.s3Key).length > 0
    })

    const hasHondaPlus = computed(() => {
      return products.includes(Product.HondaPlus)
    })

    const hasHondaPlusGo = computed(() => {
      return products.includes(Product.HondaPlusGo)
    })

    const hasHondaMapit = computed(() => {
      return products.includes(Product.HondaMapit)
    })

    const hasInsurance = computed(() => {
      return products.includes(Product.Insurance)
    })

    const hasInsuranceExpanded = computed(() => {
      return products.includes('INS_COM')
    })

    const hasFinance = computed(() => {
      return products.includes(Product.Finance)
    })

    const deviceStatus = computed(() => {
      const status = getDeviceStatusConfig(device?.state?.status)
      return { title: t(status.title), color: status.color }
    })

    const contractHM = computed(() => {
      return contracts?.value.find(
        (contract) => !!contract.s3Key && contract.name === Product.HondaMapit,
      )
    })

    const contractHP = computed(() => {
      return contracts?.value.find(
        (contract) =>
          !!contract.s3Key &&
          (contract.name === Product.HondaPlus || contract.name === Product.HondaPlusGo),
      )
    })

    const contractSEPA = computed(() => {
      return contracts?.value.find((contract) => !!contract.s3Key && contract.name === 'SEPA')
    })

    function handleAttachDevice() {
      root.$router.push({ name: 'RegistrationOnlyMapit', query: { vin } })
    }

    function handleDetachDevice() {
      changeDevice.value = true

      dialog.setContent('users.dialog-change-device')
      dialog.setConfirm(detachDevice)
      dialog.open()
    }

    async function handleDownloadContracts() {
      changeDevice.value = false

      if (!contractsDownloaded.value) {
        await downloadContracts()
      }

      const content = hasContracts.value ? 'content' : 'no-content'

      dialog.setContent('users.dialog-download-contracts', content)
      dialog.setConfirm(dialog.close)
      dialog.open()
    }

    async function detachDevice() {
      loading.value = true
      error.value = false

      try {
        await dealersApi.replaceDevice(device.id, {
          vehicleId,
          reason: DetachmentReason.NoWarranty,
        })
        root.$store.dispatch('showAlertSuccess', t('alerts.success.detach'))
        deviceImei.value = ''
      } catch (e) {
        console.error(e)
        error.value = true
      }

      loading.value = false
      dialog.close()
    }

    async function downloadContracts() {
      loading.value = true
      error.value = false

      try {
        const response = await coreApi.getRegistrationContracts(registrationId.value)
        contracts.value = response.data
        contractsDownloaded.value = true
      } catch (e) {
        console.error(e)
        error.value = true
      }

      loading.value = false
    }

    watch(error, (newError) => {
      if (newError) {
        root.$store.dispatch('showAlertError', root.$t('alerts.errors.action'))
      }
    })

    return {
      changeDevice,
      contractHM,
      contractHP,
      contracts,
      contractsDownloaded,
      contractSEPA,
      deviceImei,
      deviceStatus,
      dialog,
      downloadContracts,
      handleAttachDevice,
      handleDetachDevice,
      handleDownloadContracts,
      hasContracts,
      hasFinance,
      hasHondaMapit,
      hasHondaPlus,
      hasHondaPlusGo,
      hasInsurance,
      hasInsuranceExpanded,
      loading,
    }
  },
})
</script>

<style scoped>
h3 {
  font-family: 'ProximaNovaSemibold', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  color: #000;
}

dt {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  color: #777;
}

dd {
  font-family: 'ProximaNovaSemibold', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.6em;
  color: #000;
}

.device-container {
  background-color: white;
  margin-bottom: 1.6em;
}

.device-container > h3 {
  background-color: var(--v-primary-base);
  color: white;
  padding: 0.7em 1em;
  border-radius: 5px 5px 0 0;
}

.container-details {
  display: flex;
  gap: 1em;
  padding: 1em;

  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

.vehicle-details {
  flex: 1 1 auto;
}

.vehicle-details .device-status {
  display: flex;
  align-items: center;
  gap: 0.4em;
}

.services-details {
  flex: 0 0 auto;
}

.services-details > h3 {
  margin-bottom: 0.4em;
}

.services-details .actions {
  display: flex;
  align-items: center;
  gap: 1em;
}

.services-products {
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
}

@media (max-width: 1000px) {
  .actions,
  .services-products {
    flex-wrap: wrap;
  }
}

@media (max-width: 1200px) {
  .container-details {
    flex-direction: column;
  }
}
</style>
