import { render, staticRenderFns } from "./CardBars.vue?vue&type=template&id=710d6a91&scoped=true&"
import script from "./CardBars.vue?vue&type=script&lang=ts&"
export * from "./CardBars.vue?vue&type=script&lang=ts&"
import style0 from "./CardBars.vue?vue&type=style&index=0&lang=css&"
import style1 from "./CardBars.vue?vue&type=style&index=1&id=710d6a91&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710d6a91",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardText,VSkeletonLoader})
