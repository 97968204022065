





























































import { defineComponent, ref, toRefs, onUnmounted, computed } from '@vue/composition-api'

import { s3 } from '@/api/lib/s3-client'
import { sanitizeForS3 } from '@/utils/utils'
import { mapGetters } from '@/utils/map-store'
import { parseImportResponse } from '@/helpers/import'

import CustomInputFile from '@/components/import/CustomInputFile.vue'
import MapitDialog from '@/components/dialog/MapitDialog.vue'

export default defineComponent({
  name: 'ImportTargets',
  components: { CustomInputFile, MapitDialog },
  props: {
    selectedBranch: {
      type: String,
      required: true,
    },
  },
  setup(props, { root }) {
    const { getUser } = mapGetters(root.$store.getters)
    const { selectedBranch } = toRefs(props)

    const loading = ref<boolean>(false)
    const interval = ref<any>(null)
    const retries = ref<number>(1)

    const resourceKey = ref<number>(Date.now())
    const resource = computed(() => {
      return `kpi-targets/${selectedBranch.value}/${resourceKey.value}`
    })

    const dialog = ref<
      Partial<{
        open: boolean
        title: string
        body: string
        error: boolean
        info: boolean
        showCancel: boolean
        click: () => void
        continue: () => void
        close: () => void
      }>
    >({
      open: false,
      error: false,
      info: false,
      showCancel: false,
      title: '',
      body: '',
      click: () => closeDialog(),
      continue: () => ({}),
      close: () => closeDialog(),
    })

    function renderDialog({
      message,
      messageData,
      error,
      next = () => ({}),
      cancel = false,
    }: {
      message: string
      messageData?: any
      next?: any
      error?: boolean
      cancel?: boolean
    }) {
      loading.value = false

      dialog.value = {
        open: true,
        error,
        showCancel: cancel,
        title: root.$t('import.import-title') as string,
        body: root.$t(message, messageData) as string,
        continue: () => {
          loading.value = false
          closeDialog()
          next()
        },
        close: () => {
          loading.value = false
          closeDialog()
        },
      }
    }

    function closeDialog() {
      dialog.value.open = false
      resourceKey.value = Date.now()
    }

    async function handleUpload(data: any) {
      loading.value = true
      const response = await uploadFileToS3(data)

      if (response.status > 300 || response.error) {
        const responseParsed = parseImportResponse(response)
        renderDialog(responseParsed)

        loading.value = false
        return
      }

      interval.value = setInterval(handleCheckStatus, 5000)
    }

    async function uploadFileToS3(data: any) {
      const client = await s3(process.env.VUE_APP_S3_OFFER_DATA_BUCKET)
      let response

      try {
        const result = await client.putObject({
          Bucket: process.env.VUE_APP_S3_OFFER_DATA_BUCKET,
          Key: `${resource.value}.xlsx`,
          Body: data,
          Metadata: {
            'created-by-name': sanitizeForS3(root.$store.state.fullUserName),
            'created-by-email': getUser.value.login,
          },
        })

        response = {
          status: result.$response.httpResponse.statusCode,
        }
      } catch (err: any) {
        console.error(err)
        response = { status: err.statusCode, error: err.message }
      }

      return response
    }

    async function handleCheckStatus() {
      loading.value = true
      const response = await checkUploadStatus()

      if (response.status > 300 && retries.value < 3) {
        retries.value++
        return
      }

      const responseParsed = parseImportResponse(response)
      renderDialog(responseParsed)
      loading.value = false

      clearInterval(interval.value)
      interval.value = null
    }

    async function checkUploadStatus() {
      const client = await s3(process.env.VUE_APP_S3_OFFER_DATA_BUCKET)
      let response

      try {
        const result = await client
          .getObject({
            Bucket: process.env.VUE_APP_S3_OFFER_DATA_BUCKET,
            Key: `${resource.value}.RESULT.json`,
          })
          .promise()

        if (!result.Body) {
          throw new Error('the resource does not exist')
        }

        response = {
          status: result.$response.httpResponse.statusCode,
          ...JSON.parse(result.Body.toString()),
        }
      } catch (err: any) {
        console.error(err)
        response = { status: err.statusCode, error: err.message }
      }

      return response
    }

    async function importFile(data: any) {
      renderDialog({
        cancel: true,
        message: 'import.targets.confirmation',
        next: () => handleUpload(data),
      })
    }

    onUnmounted(() => {
      clearInterval(interval.value)
      interval.value = null
    })

    return {
      dialog: dialog as any,
      importFile,
      loading,
      resourceKey,
    }
  },
})
