import { DeviceStatus, DeviceStatusColor } from '@/types/devices'

export function getDeviceStatusConfig(status?: DeviceStatus) {
  const scope = 'devices.status'

  if (status === DeviceStatus.AtRest) {
    return {
      title: `${scope}.${DeviceStatus.AtRest}`,
      color: DeviceStatusColor.Orange,
    }
  }

  if (status === DeviceStatus.Buzz) {
    return {
      title: `${scope}.${DeviceStatus.Buzz}`,
      color: DeviceStatusColor.Orange,
    }
  }

  if (status === DeviceStatus.Moving) {
    return {
      title: `${scope}.${DeviceStatus.Moving}`,
      color: DeviceStatusColor.Green,
    }
  }

  return {
    title: `${scope}.${DeviceStatus.NoComms}`,
    color: DeviceStatusColor.Red,
  }
}
