<template>
  <card-wrapper
    class="honda-registration-wrapper"
    id="mapit-wizard-transferred"
    :title="$t('honda.registration.transferred.title')"
    :hide-close-btn="false"
    @openCloseCancelDialog="$router.push('/registrations/mapit-only')"
  >
    <v-card-text class="description px-6">{{ $t('honda.registration.transferred.description') }}</v-card-text>
    <v-card-text class="px-6">
      <div class="d-flex flex-inline justify-center">
        <v-text-field
          v-model="search"
          class="search-transferred"
          data-cy="search-transferred"
          :placeholder="$t('honda.registration.transferred.search_placeholder')"
          dense
          hide-details
          clearable
          clear-icon="z"
          outlined />
        <v-btn class="btn-primary px-10 search-btn">?</v-btn>
      </div>

      <v-alert
        class="warning-info-box"
        type="warning"
        icon="T"
        v-if="!!search && results === 0"
      >
        {{ $t('honda.registration.transferred.no_data_info1') }}<br />
        {{ $t('honda.registration.transferred.no_data_info2') }}
      </v-alert>

      <v-alert
        class="success-info-box"
        type="success"
        icon="R"
        v-else-if="!!search && results > 0"
      >
        {{
          $t(results > 1 ? 'honda.registration.transferred.data_info_plural' : 'honda.registration.transferred.data_info_singular', {results})
        }}
      </v-alert>
    </v-card-text>
    <v-card-text class="px-6">
      <transferred-table @changeSelected="changeSelected" :search="search" @changeResults="changeResults" />
    </v-card-text>

    <v-card-actions class="px-6 pt-4 pb-10">
      <v-btn data-cy="honda-mapit-transferred-btn-back"
             class="btn-gray mr-2"
             @click="$router.push({ name: 'RegistrationOnlyMapit' })">
        {{ $t('buttons.back') }}
      </v-btn>
      <v-btn
        data-cy="honda-mapit-transferred-btn-continue"
        class="btn-primary ml-auto"
        :disabled="!selected"
        @click="continueToRegistration"
      >
        {{ $t('buttons.next') }}
      </v-btn>
    </v-card-actions>
    <dialog-exit-process
      :dialog="cancelDialog"
      @exit="exit"
      @openClose="openCloseCancelDialog"
    />
  </card-wrapper>
</template>

<script>
import DialogExitProcess from '@/components/dialog/DialogExitProcess'
import TransferredTable from './TransferredTable.vue'
import {RegistrationTypes} from '@/utils/constants'
import CardWrapper from "@/components/general/CardWrapper.vue";

export default {
  name: 'TransferredVehicles',
  components: {CardWrapper, DialogExitProcess, TransferredTable},
  props: [],
  data() {
    return {
      cancelDialog: false,
      selected: null,
      search: '',
      results: 0
    }
  },
  methods: {
    continueToRegistration() {
      if (this.selected) {
        this.$store.dispatch('setSelectedTransfer', this.selected)
        this.$router.push({name: 'HondaRegistration', query: {forcedType: RegistrationTypes.HONDA_MAPIT_TRANSFERRED}})
      }
    },
    changeResults(results) {
      this.results = results
    },
    openCloseCancelDialog() {
      this.cancelDialog = !this.cancelDialog
    },
    exit() {
      this.openCloseCancelDialog()
      this.$router.push('/')
      this.$store.dispatch('resetRegistration')
    },
    changeSelected(item) {
      this.selected = item
    }
  }
}
</script>

<style lang="less">
#mapit-wizard-transferred {
  .subtitle {
    text-align: center;
    font: normal normal 600 26px/28px 'ProximaNovaExCnSBold' !important;
    letter-spacing: -0.48px;
    color: var(--v-primary-base);
    text-transform: uppercase;
    opacity: 1;
  }

  .description {
    color: var(--v-gray-darken4);
    text-align: center;
    font: normal normal normal 16px/22px 'ProximaNovaRegular';
    letter-spacing: 0px;
    opacity: 1;
  }

  .search-transferred {
    fieldset {
      border-radius: 0;
      border: 1px solid #BDBDBD;
      height: 55px;
    }

    .v-text-field__slot {
      height: 50px;
    }

    max-width: 400px;

    ::placeholder {
      text-align: left;
      font: normal normal normal 16px/22px 'ProximaNovaRegular';
      letter-spacing: 0px;
      color: #BFBFBF;
      opacity: 1;
    }

    .v-input__icon--clear button {
      font-family: 'mapitfont-v1' !important;
      text-transform: none;
      height: 50px;
      margin-top: 8px;
      color: #BFBFBF !important;
    }
  }

  .search-btn {
    height: 50px !important;
    font-family: 'mapitfont-v1' !important;
  }

  .warning-info-box {
    background-color: rgba(252, 164, 0, 0.12) !important;
    max-width: 625px;
    margin: 15px auto;

    i {
      font-family: 'mapitfont-v1' !important;
      text-transform: none;
      font-style: inherit;
      align-self: auto;
      color: #FCA400;
    }

    .v-alert__content {
      text-align: left;
      font: normal normal 600 14px/18px 'ProximaNovaRegular';
      letter-spacing: 0px;
      color: #FCA400;
      opacity: 1;
    }
  }

  .success-info-box {
    background-color: rgba(0, 128, 62, 0.12) !important;
    max-width: 625px;
    margin: 15px auto;

    i {
      font-family: 'mapitfont-v1' !important;
      text-transform: none;
      font-style: inherit;
      align-self: auto;
      color: var(--v-secondary-base);
    }

    .v-alert__content {
      text-align: left;
      font: normal normal 600 14px/18px 'ProximaNovaRegular';
      letter-spacing: 0px;
      color: var(--v-secondary-base);
      opacity: 1;
    }
  }
}
</style>
