import { LogItem } from '@/types'

export function mapDataToComments(data: LogItem[] = []): LogItem[] {
  return data.map((item) => mapItemToComment(item))
}

export function mapItemToComment(comment: LogItem): LogItem {
  return {
    dueDate: comment.dueDate,
    text: comment.text,
    timestamp: comment.timestamp,
  }
}

export function mapDealerDataToComments(data: LogItem[] = []): LogItem[] {
  return data.map((item) => mapDealerItemToComment(item))
}

export function mapDealerItemToComment(comment: any): LogItem {
  return {
    dueDate: undefined,
    text: comment.message,
    timestamp: comment.createdAt,
  }
}
