


































import { defineComponent, computed, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'CardAppointments',
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    current: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const { current } = toRefs(props)

    const isEmpty = computed(() => {
      return !current?.value && current?.value !== 0
    })

    return {
      isEmpty,
    }
  },
})
