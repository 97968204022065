import validator from "validator"
import moment from 'moment'
import { formatNumberToCurrency } from "@mapit/common-utils";
import i18n from "@/i18n"

export const isRequired = value => value !== undefined && value !== null && value !== '' || i18n.t('error.required')

export const isMinAmount = (value, min) => {
    if (!value || !min) return false

    const epsilon = 0.001;

    const valueParsed = typeof value === 'number' ? value : parseFloat(value.replace('.', '').replace('-', '').replace(',', '.'))
    const minimum = parseFloat(min)

    const isEq = (a, b) => Math.abs(a - b) < epsilon;

    return valueParsed
        && !isNaN(valueParsed)
        && isEq(valueParsed, minimum)
        || valueParsed > minimum
        || i18n.t('error.offer-min-amount', {amount: formatNumberToCurrency(min)})
}

export const isRequiredTrueCheck = value => !!value || i18n.t('error.required')

export const isPercentage = value => !value || (parseFloat(value) >= 0 && parseFloat(value) <= 100) || i18n.t('error.invalid-percentage')

export const isDiscountPercentage = value => !value || (parseFloat(value.replace('.', '').replace('-', '').replace(',', '.')) >= 0 && parseFloat(value.replace('.', '').replace('-', '').replace(',', '.')) <= 100) || i18n.t('error.invalid-percentage')

export const isMinText = (value, min = 3) => !value || value.length >= min || i18n.t('error.min-text', { min })

export const isMaxText = (value, max = 255) => !value || value.length <= max || i18n.t('error.max-text', { max })

export const isEmpty = (value, textError = "honda.validation.required") => !!value && !validator.isEmpty(typeof value === 'string' ? value.trim() : value) || i18n.t(textError)

export const isAlphanumeric = (value, textError = "honda.validation.alphaNum") => validator.isAlphanumeric(value) || i18n.t(textError)

export const isNumeric = (value, textError = "honda.validation.num") => !isNaN(value) || i18n.t(textError)

export const isLength = (value, length) => !validator.isLength(value, length) || i18n.t("honda.validation.maxLength")

export const lengthEqualTo = (value, length, field = "honda.validation.fields.default") => value.length == length || i18n.t("honda.validation.equalLengthCustom", {
    field: i18n.t(field),
    length
})

export const isNoRequiredAlphanumeric = value => (validator.isEmpty(value) || validator.isAlphanumeric(value)) || i18n.t("honda.validation.alphaNum")

export const isEmail = (value, textError = "honda.validation.email") => (value && validator.isEmail(value)) || i18n.t(textError)

export const isEmailOptional = (value, textError = "honda.validation.email") => !value || value && validator.isEmail(value) || i18n.t(textError)

export const isPhone = (value, textError = "honda.validation.phone") => !value || value && validator.isMobilePhone(value) || i18n.t(textError)

export const isTimestamp = (value) => !isNaN(parseFloat(value)) && isFinite(value)

export const isPhoneNumberEvent = evt => {
    if (evt.key == '+') return
    return isNumberEvent(evt)
}

export const isNumberEvent = evt => {
    if (!validator.isInt(evt.key))
        evt.preventDefault()
    return true
}

export const isNumberFloatEvent = evt => {
    if (evt.key == '.') return
    if (!validator.isFloat(evt.key))
        evt.preventDefault()
    return true
}

export const isValidBirth = (value, textError = "honda.validation.date", inputFormat = 'DD/MM/YYYY') => {
    const date = moment(value, inputFormat)
    return date.isValid() && date.isBefore(moment()) && date.isAfter('1900-01-01') || i18n.t(textError)
}

export const isValidIban = (value, textError = "honda.validation.iban") => validator.isIban(value) || i18n.t(textError)

export const isMobilePhone = number => {
    try {
        if (!validator.isMobilePhone(number)) {
            return false
        }
        if (number.length < 9) {
            return false
        }
        const countPlus = number.split('+').length - 1
        if (countPlus > 1) {
            return false
        }
        const intPhone = parseInt(number.replace('+', ''))
        return !!intPhone
    } catch (e) {
        return false
    }
}

export function inRange(value, max, min = 0) {
  return value >= min && value <= max || i18n.t("honda.validation.in-range", { min, max })
}
