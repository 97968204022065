<template>
  <message>
    <template slot="icon">
      <i class="far fa-calendar-check text-success" style="font-size: 100px"/>
    </template>
    <template slot="text">
      <h3 class="text-success p-t-20">CITA ACEPTADA CORRECTAMENTE</h3>
    </template>
    <template slot="bottom">
      <i class="fa fa-mobile-alt pr-2"/>Se notificará al cliente en su móvil
      <div class='column col-12 col-lg-12'>
        <div class="modified-alert">
          <i class="fa fa-exclamation-circle pr-2"/>Se ha programado una nueva Reparación, para gestionarla acceder al apartado 'Reparaciones'
        </div>
      </div>
    </template>
  </message>
</template>
<script>
  import Message from '@/components/dialog/Message';

  export default {
    name: 'RevisionConfirm',
    components: {
      Message
    }
  }
</script>
