<template>
  <DealerDashboard />
</template>

<script>
import { mapGetters } from 'vuex'
import DealerDashboard from '@/components/metrics/DealerDashboard'

export default {
  name: 'HomeDashboard',
  components: { DealerDashboard },
  computed: {
    ...mapGetters({
      branch: 'getBranch',
      capabilities: 'getCapabilities',
    }),
    branchLowerCase() {
      return this.branch?.toLowerCase()
    },
    branchPreferences() {
      return this.capabilities ?? this.$preferences(this.branch)
    },
  },
}
</script>
