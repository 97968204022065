import { render, staticRenderFns } from "./RegistrationsTable.vue?vue&type=template&id=4bd9d1ea&scoped=true&"
import script from "./RegistrationsTable.vue?vue&type=script&lang=ts&"
export * from "./RegistrationsTable.vue?vue&type=script&lang=ts&"
import style0 from "./RegistrationsTable.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./RegistrationsTable.vue?vue&type=style&index=1&id=4bd9d1ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd9d1ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VChip,VCol,VDataTable,VIcon,VList,VListItem,VListItemTitle,VMenu,VRow,VSpacer,VTooltip})
