import { DateTime } from 'luxon'

import {
  DealerBike,
  DealerBikeActions,
  DealerBikeStatusPortal,
  UsageType,
} from '../types/dealer-bikes'

export function getDealerBikeActions(dealerBike: DealerBike): DealerBikeActions[] {
  if (
    dealerBike.loan ||
    dealerBike.status === DealerBikeStatusPortal.HandedOver ||
    dealerBike.status === DealerBikeStatusPortal.Retired
  ) {
    return [DealerBikeActions.Detail]
  }

  if (dealerBike.status === DealerBikeStatusPortal.Candidate) {
    return [DealerBikeActions.Register, DealerBikeActions.RemoveBike]
  }

  if (
    dealerBike.status === DealerBikeStatusPortal.Available &&
    dealerBike.usageType === UsageType.Demo
  ) {
    return [
      DealerBikeActions.Unavailable,
      DealerBikeActions.Transfer,
      DealerBikeActions.Courtesy,
      DealerBikeActions.Unregister,
      DealerBikeActions.Detail,
    ]
  }

  if (dealerBike.status === DealerBikeStatusPortal.Available) {
    return [
      DealerBikeActions.Unavailable,
      DealerBikeActions.Transfer,
      DealerBikeActions.Unregister,
      DealerBikeActions.Detail,
    ]
  }

  if (dealerBike.status === DealerBikeStatusPortal.Unavailable) {
    return [DealerBikeActions.Available, DealerBikeActions.Unregister, DealerBikeActions.Detail]
  }

  if (dealerBike.status === DealerBikeStatusPortal.Expired) {
    return [DealerBikeActions.Transfer, DealerBikeActions.Unregister, DealerBikeActions.Detail]
  }

  return []
}

export function isExpiredBike(createDate: string) {
  const today = DateTime.now()
  const date = DateTime.fromISO(createDate)

  // check if the difference is more than 1 year
  return today.diff(date, 'years').years > 1
}
