<template>
  <div id="file-input">
    <v-btn
      elevation="0"
      v-if="type === 'button'"
      :loading="loading"
      :disabled="loading"
      @click="$emit('btnAction')"
    >
      {{ label || $t('import.download-file') }}
    </v-btn>
    <v-file-input
      v-if="type !== 'button'"
      @change="loadFromFile"
      hide-input
      :accept="accept"
      :prepend-icon="label || $t('import.select-file')"
      :loading="loading"
      :disabled="loading"
    />
    <v-progress-linear
      v-if="loading && type !== 'button'"
      indeterminate
      color="primary"
    />
  </div>
</template>

<script>
/**
 * The custom HTML `<file-reader />` component.
 *
 * @author Julià Soler
 * @license Mapit IoT S.L.
 */
export default {
  props: {
    /**
     * Type of file buffer/text/png....
     */
    type: { type: String, required: false, default: 'buffer' },
    /**
     * Is start import file
     */
    loading: { type: Boolean, required: false, default: false },
    /**
     * Label for imput-file
     */
    label: { type: String, required: false, default: '' },
    accept: { type: String, required: false, default: '' },
  },
  methods: {
    /**
     * Function to read file and emit `load` event with data result
     * @param {Object} file - Event data emited from input-file
     */
    loadFromFile(file) {
      if(this.type === 's3-attachment') {
        this.$emit('reading', false)
        this.$emit('load', file)
      } else if (this.type === 's3') {
        this.$emit('reading', true)
        this.$emit('load', file)
      } else {
        this.$emit('reading', true)
        const reader = new FileReader()
        reader.onload = e => {
          this.$emit('load', e.target.result)
        }
        this.type === 'buffer' || this.type === 'csv'
          ? reader.readAsArrayBuffer(file)
          : reader.readAsText(file)
      }
    },
  },
}
</script>
<style lang="scss">
#file-input {
  margin: 0;
  padding: 0;
  .v-input {
    margin: 0;
    padding: 0;
  }
  .v-input__prepend-outer {
    margin: 0;
  }
  .v-input__icon {
    width: auto !important;
    button {
      min-width: 200px;
      padding: 10px 18px;
      border: 1px solid var(--v-gray-lighten3);
      border-radius: 4px;
      color: var(--v-gray-darken3) !important;
      font: normal normal normal 14px/24px 'ProximaNovaRegular' !important;
      letter-spacing: 0px;
      text-transform: none !important;
      &::after {
        opacity: 0 !important;
      }
    }
  }
  .v-progress-linear {
    height: 2px !important;
    bottom: -5px;
    border-radius: 0 0 4px 4px;
    margin: 0 1px 0 1px;
    width: 99%;
  }
  .v-btn {
    background: white;
    min-width: 200px;
    padding: 10px 18px;
    border: 1px solid var(--v-gray-lighten3);
    border-radius: 4px;
    color: var(--v-gray-darken3) !important;
    font: normal normal normal 14px/24px 'ProximaNovaRegular' !important;
    letter-spacing: 0px;
    text-transform: none !important;
    &::after {
      opacity: 0 !important;
    }
  }
}
</style>
