<template>
  <div class="revisions form-card ultra-high-size ">

    <h3 class="align-left p-t-0 p-b-20 honda-plus-title" style='border-bottom: 1px solid black'>GESTIÓN DE CITAS</h3>

    <v-row no-gutters>

      <div class="d-flex flex-nowrap flex-sm-wrap justify-space-between full-width">
        <div class="d-flex flex-wrap">
          <button class='btn btn-block btn-honda-plus mr-2 text-no-wrap mb-1' aria-hidden="true" v-bind:class="{'btn-disabled': !request_data.pending }" @click="request_data.pending = !request_data.pending;" :disabled='loading'>
            <i class='fas fa-exclamation-circle'></i> CITAS SOLICITADAS
          </button>
          <button class='btn btn-block btn-honda-plus mr-2 text-no-wrap mb-1' v-bind:class="{'btn-disabled': !request_data.accepted }"  @click="request_data.accepted = !request_data.accepted" :disabled='loading'>
            <i class='fas fa-check-circle' aria-hidden="true"></i> CITAS ACEPTADAS
          </button>
          <button class="btn btn-block btn-honda-plus mr-2 text-no-wrap mb-1" aria-hidden="true" v-bind:class="{'btn-disabled': !request_data.cancelled }" @click="request_data.cancelled = !request_data.cancelled" :disabled='loading'>
            <i class='fas fa-times-circle'></i> CITAS CANCELADAS
          </button>
        </div>
        <div class="text-no-wrap align-self-end mb-1 ml-auto flex-grow-1">
          <v-row no-gutters>
            <m-text-field
              class="custom-search flex-grow-1"
              @keydown.enter="load()"
              hide-details
              outlined
              :placeholder="$t('honda.registration.list.placeholder_search')"
              v-model="searchQuery"
            >
            </m-text-field>
            <m-btn class="btn-primary" @click="load()">
              <v-icon class="icon-mapit">?</v-icon>
            </m-btn>
          </v-row>
        </div>
      </div>

      <v-col class="d-flex justify-center align-center" style="min-height: 200px" v-if='loadingModal'>
        <loading-spinner></loading-spinner>
      </v-col>
      <v-col class='col-12 p-t-20' v-else-if='!loadingModal && (!loading && important_services.length == 0) || (important_services.length > 0)'>
        <table-base id="appointments" :headers="columns" :rows="important_services" :key="Math.random()"  @openModal="openModal"></table-base>
      </v-col>
      <v-col class="d-flex justify-center align-center" v-if='loading'>
        <loading-spinner></loading-spinner>
      </v-col>
      <revision-modal v-if="activeModal" :request="modalData.request" :container="modalData.container" :customer="modalData.customer" :active="activeModal"
                      @closePopup="closeModal" :step="step"> </revision-modal>

    </v-row>

  </div>
</template>
<script>
  import api from '@/api';
  import RevisionModal from './RevisionModal.vue';
  import TableBase from '@/components/general/TableBase';
  import {eventBus} from '@/main';
  import LoadingSpinner from '@/components/other/LoadingSpinner';
  import {mapGetters} from "vuex";
  import {toDateFormat} from "@/utils/date-helper";

  export default {
    name: 'RevisionsList',
    components: {
      TableBase,
      RevisionModal,
      LoadingSpinner
    },
    data() {

      var sortOrders = {};
      var columns = [
        {
          component: 'model',
          field: 'model',
          caption: "Modelo",
          class: "col-1 f-s-16 text-center p-t-10 p-b-10",
          class2: "col-1 f-s-11 text-center p-t-10 p-b-10"
        },
        {component: 'plate', field: 'platel', caption: "Matrícula", class: "col-2 p-10 p-l-20 text-left", class2: "col-3 p-10"},
        {
          component: 'client',
          field: 'ownerDetail.Name',
          caption: "Nombre",
          class: "col-2 p-10 text-left p-l-20",
          class2: "col-3 p-10 text-center"
        },
        {
          component: 'appointmentStatus',
          caption: "Estado",
          class: "col-2 p-10 text-left p-l-20",
          class2: "col-3 p-10 text-center"
        },
        {
          component: 'appointmentReason',
          caption: "Motivo",
          class: "col-2 p-10 text-left p-l-20",
          class2: "col-3 p-10 text-center"
        },
        {
          component: 'kmnextrevision',
          field: 'kmnextrevision',
          caption: "Km próxima revisión",
          class: "col-1 f-s-16 text-center p-t-10 p-b-10",
          class2: "col-1 f-s-11 text-center p-t-10 p-b-10"
        },
        {component: 'appointmentDay', caption: "Fecha", class: "col-2 p-10 text-left p-l-20", class2: "col-3 p-10 text-center"},
        {component: 'appointmentTime', caption: "Hora", class: "col-2 p-10 text-left p-l-20", class2: "col-3 p-10 text-center"},
        {component: 'appointmentButton', caption: "Cita", class: "col-2 p-10 text-left p-l-20", class2: "col-3 p-10 text-center"},
        {
          component: 'appointmentHasContainer',
          caption: "Moto cortesía",
          class: "col-2 p-10 text-left p-l-20",
          class2: "col-3 p-10 text-center"
        }
      ];
      columns.forEach(function (key) {
        sortOrders[key.field] = 1
      });
      sortOrders['requestPetitionDate'] = 1;

      let now = new Date();
      let minDate = new Date();
      let maxDate = new Date();
      let firstDate = new Date();
      minDate.setDate(now.getDate() + 7);
      maxDate.setDate(now.getDate() + 120);
      if (now.getDay() === 6)
        firstDate.setDate(now.getDate() + 9);
      else
        firstDate.setDate(now.getDate() + 8);

      return {
        activeModal: false,
        modalData: {},
        sortKey: 'prevision',
        sortOrders: sortOrders,
        all_services: [],
        important_services: [],
        searchQuery: '',
        columns: columns,
        editMode: null,
        defaultDate: firstDate,
        testEdit: {
          dateSelected: firstDate,
          schedule: "Mañana",
          comment: ""
        },
        disabledDates: {
          to: minDate, // Disable all dates up to specific date
          from: maxDate, // Disable all dates after specific date
          days: [0] // Disable Saturday's and Sunday's
        },
        request_data: {
          accepted: true,
          cancelled: true,
          pending: true
        },
        page: 1,
        max: 20,
        bottom: false,
        noMoreResults: false,
        loading: false,
        loadingModal: false,
        ORDERBY: "PETITION_DESC",
        step: 0
      }
    },
    props: ["id"],
    computed: {
      ...mapGetters({
        locale: 'getLocale'
      }),
      isFiltered() {
        return Object.values(this.request_data).filter(Boolean).length>0;
      },
      token() {
        return this.$store.getters.getToken;
      },
      user() {
        return this.$store.getters.getUser;
      }
    },
    watch: {
      bottom(bottom) {
        if (bottom) {
          this.loadMoreRevisions();
        }
      },
      request_data: {
        deep: true,
        handler() {
          this.load();
        }
      }
    },
    methods: {
      openModal({payload: {request: {id}}}) {
        this.$router.push('/appointments/' + id);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        self.loadingModal = true;
        api.appointment(id, (err, request) => {
          let step = 0
          if (err) {
            request = {};
            if (err._status >=500){
              step = 5;
            }else{
              step = 4;
            }
          }
          self.modalData = request;
          self.loadingModal = false;
          self.activeModal = true;
          self.step = step;
          eventBus.$emit("refresh-counters");
        });
      },
      closeModal(){
        this.activeModal = false;
        if ('id' in this.$route.params){
          this.load(true);
          this.$router.push('/appointments');
        }
      },
      load() {
        this.page = 1;
        this.max = 50;
        this.noMoreResults = false;
        this.loading = true;


        //PARCHE FOR SEARCH PARAM
        let body = {
          groupId: this.user.groupId,
          priority: false,
          status: 0,
          page: 1,
          max: 50,
          order: this.ORDERBY
        };

        if(this.isFiltered) {
          body = {...body, request_data: this.request_data};
        }

        if (this.searchQuery && this.searchQuery.length > 0) {
          body = {...body, search: this.searchQuery}
        }

        //todas
        api.services(body, (err, resp) => {
          this.loading = false;
          if (err) return; //TODO
          this.important_services = resp;
          this.page++;

          this.important_services = resp.map(r => {
            const appointment = r.request.appointment
            const pickup = r.request.pickup

            if (appointment) {
              appointment.day = appointment.day ? toDateFormat(appointment.day, this.locale) : ''
            }

            if (pickup) {
              pickup.day = pickup.day ? toDateFormat(pickup.day, this.locale) : ''
            }

            r.id = r.inspection.id
            return r
          })
        });
      },
      reload() {
        this.load(true);
      },
      //COMPROVAR SI ESTEM AL FINAL DE LA PAGINA
      bottomVisible() {
        const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
        const visible = document.documentElement.clientHeight
        const pageHeight = document.documentElement.scrollHeight
        const bottomOfPage = visible + scrollY >= pageHeight

        return bottomOfPage || pageHeight < visible
      },
      loadMoreRevisions() {

        if (!this.noMoreResults) {
          this.loading = true;

          let body = {};
          if (this.searchQuery && this.searchQuery.length > 0) {
            body = {
              groupId: this.user.groupId,
              priority: false,
              request_data: this.request_data,
              search: this.searchQuery,
              status: 0,
              page: this.page,
              max: this.max,
              order: this.ORDERBY
            }
          } else {
            body = {
              groupId: this.user.groupId,
              priority: false,
              request_data: this.request_data,
              status: 0,
              page: this.page,
              max: this.max,
              order: this.ORDERBY
            }
          }

          api.services(body, (err, resp) => {
            if (!err) {
              if (resp.length === 0) {
                this.noMoreResults = true;
              }

              resp.forEach((service) => {
                this.important_services.push(service);
              });

              this.page++;
              this.loading = false;
            }
          });
        }
      }

    },
    created() {
      window.addEventListener('scroll', () => {
        if (!this.loading) {
          this.bottom = this.bottomVisible();
        }
      });
      eventBus.$on("refresh-list", () => {
        this.load(true);
      })
      if('id' in this.$route.params){
        this.openModal({payload: {request: {id:this.$route.params.id}}});
      }
      else{
        this.load(true);
      }
    },
    destroyed () {
      eventBus.$off("refresh-list");
    }
  };
</script>
<style>

</style>
