import { Moment } from 'moment'

export enum AppointmentStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED_USER',
  Requested = 'PENDING',
}

export enum AppointmentCourtesyStatus {
  Accepted = 'ACCEPTED',
  NotAvailable = 'NOT_AVAILABLE',
  NotRequested = 'NOT_REQUESTED',
}

export enum AppointmentService {
  Failure = 'failure',
  Other = 'other',
  Paint = 'paint',
  Service = 'service',
  Tyres = 'tyres',
}

export interface AppointmentServiceRequest {
  failure: boolean
  other: string
  paint: boolean
  service: boolean
  tyres: boolean
}

export interface Appointment {
  comments: string
  courtesyRequested: boolean
  courtesyStatus: AppointmentCourtesyStatus
  createdAt: Moment
  email: string
  id: string
  model: string
  name: string
  phone: string
  pickupDate: string
  pickupTime: string
  registrationNumber: string
  requestDate: string
  requested: AppointmentServiceRequest
  requestTime: string
  status: AppointmentStatus
}

export enum RequestError {
  Default = 'DEFAULT',
  NotFound = 'NOT_FOUND',
}
