import ApiGatewayClient from "./aws/aws-gateway-client"

export class GeoApi {

  constructor() {
    this.client = new ApiGatewayClient()
  }

  getRoutes(vehicleId,from,includeInProgress) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_GEO_API,
      method: 'GET',
      path: `/v1/routes/`,
      queryParams: {
        vehicleId,
        includeInProgress,
        from
      }
    })
  }

}

export default new GeoApi()
