import AWS from 'aws-sdk'
import { Auth } from 'aws-amplify'

export default class S3Client {
  constructor(bucketName, opts) {
    this.s3 = new AWS.S3({ Bucket: bucketName, ...opts })
  }

  getObject(params) {
    return this.s3.getObject(params)
  }

  putObject(params) {
    return this.s3.putObject(params).promise()
  }

  getSignedUrl(params) {
    return this.s3.getSignedUrl('getObject', params)
  }
}

export const s3 = async (bucketName) => {
  return Auth.currentAuthenticatedUser()
    .then(() => Auth.currentSession())
    .then(() => Auth.currentCredentials())
    .then((credentials) => {
      return new S3Client(bucketName, {
        credentials: Auth.essentialCredentials(credentials),
      })
    })
}
