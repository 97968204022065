import ApiGatewayClient from "./aws/aws-gateway-client"
import { LogItem, Lead } from '@/types'
import { LeadSummary, LeadStatus } from "@mapit/leads-dynamodb/dist/types";
import { AxiosResponse } from "axios";

export class RidoApi {
  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  getLeads(): Promise<AxiosResponse<{ data: Array<LeadSummary> }>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_RIDO_API,
      method: 'GET',
      path: `/v1/iam/leads`,
    })
  }

  getLead(id: string): Promise<AxiosResponse<Lead>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_RIDO_API,
      method: 'GET',
      path: `/v1/iam/leads/${id}`
    })
  }

  addOffer(id: string, offerId: string): Promise<AxiosResponse<any>>  {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_RIDO_API,
      method: 'PATCH',
      path: `/v1/iam/leads/${id}`,
      data: { offerId }
    })
  }

  markAsRead(id: string, read: boolean): Promise<AxiosResponse<any>>  {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_RIDO_API,
      method: 'PATCH',
      path: `/v1/iam/leads/${id}`,
      data: { read }
    })
  }

  updateLeadStatus(id: string, status: LeadStatus): Promise<AxiosResponse<any>>  {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_RIDO_API,
      method: 'PATCH',
      path: `/v1/iam/leads/${id}`,
      data: { status }
    })
  }

  updateComments(id: string, commentLog: Array<LogItem>): Promise<AxiosResponse<any>>  {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_RIDO_API,
      method: 'PATCH',
      path: `/v1/iam/leads/${id}`,
      data: { commentLog }
    })
  }

  updateState(id: string, status: LeadStatus): Promise<AxiosResponse<any>>  {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_RIDO_API,
      method: 'PATCH',
      path: `/v1/iam/leads/${id}`,
      data: { status }
    })
  }
}

export default new RidoApi()
