<template>
  <message>
    <template slot="icon">
      <i class="far fa-calendar-times text-error" style="font-size: 100px"/>
    </template>
    <template slot="text">
      <h3 class="text-error p-t-20">DEBES ELIMINAR LA CITA ASOCIADA</h3>
    </template>
    <template slot="bottom">
        <div> Para eliminar la reparación debes eliminar la cita asociada desde el apartado CITAS.</div>
        <button class='btn col-auto btn-success m-t-20 m-r-10' @click="$emit('redirect')"><i class="fas fa-angle-right"></i>
            Ver cita
        </button>  
        <button class='btn col-auto btn-error m-t-20' @click="$emit('close')"><i class="fas fa-angle-right"></i>
            Cerrar
        </button>
    </template>
    
  </message>
</template>
<script>
  import Message from '@/components/dialog/Message';

  export default {
    components: {
      Message
    }
  }
</script>