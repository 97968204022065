import Amplify from 'aws-amplify'
// @ts-ignore
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import sigV4Client from './sigV4Client'

export default class ApiGatewayClient {
  private readonly client: AxiosInstance

  constructor(axiosOpts?: AxiosRequestConfig) {
    this.client = axios.create(axiosOpts)
  }

  async sendRequest(req: ApiGatewayClientRequest): Promise<AxiosResponse<any>> {
    const {endpoint = 'https://localhost:9099', method, path, queryParams, data, signal} = req
    const creds = await Amplify.Auth.currentCredentials()
    const session = await Amplify.Auth.currentSession()

    console.log('Preparing request to', endpoint, path, method, queryParams, data, signal)

    if (!endpoint) {
      throw Error(`Endpoint not provided for request: ${req.path}`)
    }

    const headers = {...req.headers}

    const signedRequest = await sigV4Client
      .newClient({
        accessKey: creds.accessKeyId,
        secretKey: creds.secretAccessKey,
        sessionToken: creds.sessionToken,
        region: 'eu-west-1',
        endpoint,
        clockDrift: session.clockDrift
      })
      .signRequest({
        method,
        path,
        headers,
        queryParams,
        body: data,
      })

    try {
      return await this.client.request({
          method,
          url: signedRequest.url,
          data,
          headers: { ...signedRequest.headers, 'X-Id-Token': session.getIdToken().getJwtToken() },
          signal
        })
    } catch(e) {
      if (!signal?.aborted) throw e
      return Promise.reject()
    }
  }
}

interface ApiGatewayClientRequest {
  endpoint?: string
  method: Method
  path: string
  headers?: Record<string,string>
  queryParams?: Record<string,any>
  data?: any,
  signal?: AbortSignal
}
