<template>
  <th class='align-center' v-if="isHeader">
    {{title}}
  </th>
  <td class="align-center column-500" v-else>
    <div  v-if="rowData">
      <div class="col-12 p-l-15 p-r-15">
        <button data-cy="repair-btn" class='btn btn-honda-plus-light-mini' @click="$emit('vuetable:field-event', {payload: rowData})"><i class="fas fa-wrench"></i></button>
      </div>
    </div>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';


  export default {
    mixins: [VuetableFieldMixin],
    props: ["rowData"],
    name: 'Repair'
  }
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
