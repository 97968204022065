


















































import { DateTime } from 'luxon'
import { useI18n } from '@/i18n'
import { computed, defineComponent, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'CardBarsStack',
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    sales: {
      type: Number,
      required: false,
    },
    salesTarget100: {
      type: Number,
      required: false,
    },
    salesTarget105: {
      type: Number,
      required: false,
    },
    weight: {
      type: Number,
      required: false,
    },
    weightTarget: {
      type: Number,
      required: false,
    },
    status: {
      type: String,
      required: false,
    },
    lastDelivery: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const { i18n } = useI18n()
    const { sales, salesTarget100, salesTarget105, weight, weightTarget, lastDelivery } =
      toRefs(props)

    const isEmpty = computed(() => {
      return (
        (!sales?.value && sales?.value !== 0) ||
        (!salesTarget100?.value && salesTarget100?.value !== 0)
      )
    })

    const lastData = computed(() => {
      const date = DateTime.fromISO(lastDelivery.value)

      if (!date.isValid) {
        return
      }

      return date.toLocaleString(DateTime.DATE_FULL, { locale: i18n.locale })
    })

    const options = computed(() => {
      const value = sales?.value || 0
      const limit = salesTarget100?.value ? salesTarget100.value * 2 : 0
      const overload = value >= limit

      const bar1 = overload ? 1 : value / limit
      const bar2 = overload ? 0 : (limit - value) / limit

      return {
        color: ['#dc192d', '#eee', '#eee'],
        grid: {
          top: 'center',
          left: 'center',
          width: '100%',
        },
        xAxis: {
          type: 'value',
          show: false,
        },
        yAxis: {
          type: 'category',
          data: ['completed'],
          inverse: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
        },
        series: [
          {
            type: 'bar',
            stack: 'total',
            silent: true,
            barWidth: 25,
            data: [bar1],
            label: { show: false },
          },
          {
            type: 'bar',
            stack: 'total',
            silent: true,
            barWidth: 25,
            data: [bar2],
            label: { show: false },
            markLine: {
              silent: true,
              symbol: 'none',
              lineStyle: {
                color: '#000',
                type: 'solid',
              },
              data: [
                {
                  xAxis: 1 / 2,
                  label: {
                    position: 'start',
                    align: 'right',
                    formatter: () => `${salesTarget100?.value} (100%)`,
                  },
                },
                salesTarget105?.value
                  ? {
                      xAxis: 1.05 / 2,
                      label: {
                        position: 'end',
                        align: 'left',
                        formatter: () => `${salesTarget105.value} (105%)`,
                      },
                    }
                  : null,
              ].filter((axis) => axis),
            },
          },
        ],
      }
    })

    const optionsWeight = computed(() => {
      const value = weight?.value || 0
      const limit = weightTarget?.value ? weightTarget.value * 2 : 0
      const overload = value >= limit

      const bar1 = overload ? 1 : value / limit
      const bar2 = overload ? 0 : (limit - value) / limit

      return {
        color: ['#dc192d', '#eee', '#eee'],
        grid: {
          top: 'center',
          left: 'center',
          width: '100%',
        },
        xAxis: {
          type: 'value',
          show: false,
        },
        yAxis: {
          type: 'category',
          data: ['completed'],
          inverse: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
        },
        series: [
          {
            type: 'bar',
            stack: 'total',
            silent: true,
            barWidth: 25,
            data: [bar1],
            label: { show: false },
          },
          {
            type: 'bar',
            stack: 'total',
            silent: true,
            barWidth: 25,
            data: [bar2],
            label: { show: false },
            markLine: {
              symbol: 'none',
              lineStyle: {
                color: '#000',
                type: 'solid',
              },
              data: [
                {
                  xAxis: 0.5,
                  label: {
                    show: true,
                    formatter: () => `${weightTarget?.value || 0}`,
                  },
                },
              ],
            },
          },
        ],
      }
    })

    return {
      isEmpty,
      lastData,
      options,
      optionsWeight,
    }
  },
})
