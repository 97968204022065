
import {computed, defineComponent, onMounted, Ref, ref} from '@vue/composition-api';
import CardWrapper from '@/components/general/CardWrapper.vue';
import RegistrationCard from '@/components/general/RegistrationCard.vue';
import DialogVinRegistration from '../dialog/DialogVinRegistration.vue'
import DialogMapitRegistration from '../dialog/DialogMapitRegistration.vue'
import DialogResult from '../dialog/DialogResult.vue'
import {mapGetters} from '@/utils/map-store';
import {RegistrationPlatform, RegistrationVehicleStatus, ResultStatus} from '@/types';
import {RegistrationTypes, VinRegistrationStates} from '@/utils/constants';
import {AttachmentReason} from '@mapit/core-dynamodb/dist/types';
import coreApi from '@/api/core.api'

interface RegistrationCardData {
  name: string;
  image: string;
  imageHover: string;
  title: string;
  subtitle: string;
  description: string;
  actionText: string;
  status: RegistrationVehicleStatus;
  platform: RegistrationPlatform;
}

export default defineComponent({
  name: 'RegistrationOnlyMapit',
  components: {
    CardWrapper,
    RegistrationCard,
    DialogVinRegistration,
    DialogMapitRegistration,
    DialogResult
  },
  setup(props, {root}) {

    const {
      getCapabilities: capabilities
    } = mapGetters(root.$store.getters)

    const registrationCards = ref<Array<RegistrationCardData>>([])

    const isTransferredAvailable = computed<boolean>(() => {
      return capabilities.value?.dealerDeviceTransfer?.mode === 'FULL'
    })

    const cancelDialog = ref(false)
    const dialogVinRegistration = ref(false)
    const dialogMapitRegistration = ref(false)
    const vinData = ref(null) as Ref<any>
    const dialogResult = ref(false)
    const dialogResultStatus = ref(null) as Ref<ResultStatus | null>
    const dialogVinLoading = ref(false)
    const statusSuccessStrings = ref({
      title: root.$t('honda.registration.mapit-wizard.used.confirm_popup.title'),
      body: root.$t('honda.registration.mapit-wizard.used.confirm_popup.body')
    })
    const statusErrorStrings = ref({
      title: root.$t('honda.registration.mapit-wizard.used.error_popup.title'),
      body: root.$t('honda.registration.mapit-wizard.used.error_popup.body')
    })
    const resultLoading = ref(false)
    const queryVin = ref('')

    const getResultStrings = computed(() => {
      return dialogResultStatus.value === ResultStatus.SUCCESS ? statusSuccessStrings.value : statusErrorStrings.value
    })

    const buttonStyles = computed(() => {
      return `
        text-transform: uppercase !important;
        padding: 8px 24px !important;
        font-family: 'ProximaNovaExCnSbold', sans-serif !important;
        letter-spacing: 0.01em !important;
     `
    })

    onMounted(async () => {
      await root.$store.dispatch('resetRegistration')
      const availableCards = [
        {
          key: 'new',
          status: 'NEW',
          platform: 'HONDA_MAPIT'
        },
        {
          key: 'used-demo-bike',
          status: 'USED',
          platform: 'HONDA_MAPIT'
        },
        isTransferredAvailable.value ? {
          key: 'transferred',
          status: 'TRANSFERRED',
          platform: 'HONDA_MAPIT'
        } : undefined,
        {
          key: 'other',
          status: 'NEW',
          platform: 'MAPIT_CONNECT'
        }
      ].filter(card => card !== undefined) as Array<{key: string, status: RegistrationVehicleStatus, platform: RegistrationPlatform}>

      registrationCards.value = availableCards.map(({key, status, platform}) => ({
        name: key,
        image: `/images/registrations/${key}.svg`,
        imageHover: `/images/registrations/${key}-light.svg`,
        title: `honda.registration.mapit-wizard.${key}.title`,
        subtitle: `honda.registration.mapit-wizard.${key}.subtitle`,
        description: `honda.registration.mapit-wizard.${key}.description`,
        actionText: `honda.registration.mapit-wizard.${key}.action`,
        status,
        platform
      }))

      if(root.$route.query.vin){
        queryVin.value = root.$route.query.vin as string
        dialogVinRegistration.value = true
      }
    })

    function handleActionClick(card: RegistrationCardData) {
      if (card.status === 'TRANSFERRED') root.$router.push({name: 'TransferredVehicles'})
      else if (card.status === 'USED') dialogVinRegistration.value = true
      else if (card.platform === 'MAPIT_CONNECT') root.$router.push({name: 'RegistrationMapitConnect'})
      else root.$router.push({name: 'HondaRegistration', query: {forcedType: card.platform}})
    }

    function closeDialogVinRegistration() {
      dialogVinRegistration.value = false
      vinData.value = null
    }

    async function continueDialogVinRegistration(vData: any) {
      if (vData.status === VinRegistrationStates.NOT_FOUND) {
        vinData.value = null
        dialogVinLoading.value = true
        await root.$router.push({
          name: 'HondaRegistration',
          query: {forcedType: RegistrationTypes.HONDA_MAPIT, vin: vData.vin}
        })
        dialogVinRegistration.value = false
      } else {
        dialogVinRegistration.value = false
        dialogMapitRegistration.value = true
        vinData.value = vData
      }
    }

    function closeDialogMapitRegistration() {
      dialogMapitRegistration.value = false
      vinData.value = null
    }

    function continueDialogMapitRegistration(mapit: any) {
      if (!resultLoading.value) {
        resultLoading.value = true
        const body = {
          vehicle: {
            id: vinData.value?.vehicleId
          },
          reason: AttachmentReason.AfterRegistrationAuthorised
        }
        coreApi.addDeviceToVehicle(mapit.id, body)
          .then(res => {
            dialogResultStatus.value = ResultStatus.SUCCESS
          })
          .catch(err => {
            dialogResultStatus.value = ResultStatus.ERROR
          })
          .finally(() => {
            closeDialogMapitRegistration()
            resultLoading.value = false
            dialogResult.value = true
          })
      }
    }

    function closeDialogResult() {
      dialogResult.value = false
    }

    function initRegistration() {
      if (root.$route.query.vin) {
        queryVin.value = root.$route.query.vin as string
        dialogVinRegistration.value = true
      }
    }

    return {
      registrationCards,
      isTransferredAvailable,
      cancelDialog,
      dialogVinRegistration,
      dialogMapitRegistration,
      vinData,
      dialogResult,
      dialogResultStatus,
      dialogVinLoading,
      statusSuccessStrings,
      statusErrorStrings,
      resultLoading,
      queryVin,
      getResultStrings,
      buttonStyles,
      handleActionClick,
      closeDialogVinRegistration,
      continueDialogVinRegistration,
      closeDialogMapitRegistration,
      continueDialogMapitRegistration,
      closeDialogResult,
      initRegistration
    }
  },
})
