<template>
  <v-row>
    <v-col class="col-5 col-md-5 col-sm-12 bold-900">
      COSTE DE LA REPARACIÓN
    </v-col>
    <v-col class="col-7 col-md-7 col-sm-12">
      <v-row no-gutters>
        <v-col class="col-12 subtitle">
          PRECIO CON IMPUESTOS INCLUIDOS
        </v-col>
        <v-col class="col-12">
          <div class="form-group has-error">
            <label class="form-label" for="price"></label>
            <input class="form-input" :disabled="$attrs.disabled" type="number" id="price" v-model="request.price"
                   placeholder="Precio"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import api from '@/api';


export default {
   props: ["request"]
}
</script>
<style scoped>
.redbg{
  color: red !important;
}
.subtitle{
  font-family: 'ProximaNovaExCnLight','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  color: #888;
}
</style>
