import {onUnmounted, ref} from "@vue/composition-api";

interface Props<T> {
  url: string;
  token: string;
  firstMessageTimeout?: number // Time that will be considered loading before showing the data
  onOpen?: (e: Event) => void;
  onMessage?: (e: T) => void;
  onError?: (e: Event) => void;
  onClose?: (e: CloseEvent) => void;
}

export const useWebSocket = <T>() => {
  const WEBSOCKET_TIMEOUT = 5000
  const wsClient = ref<WebSocket | null>(null)
  const loading = ref(true)

  function init({url, token, firstMessageTimeout, onOpen, onClose, onMessage, onError}: Props<T>) {
    if (wsClient.value) return

    const ws = new WebSocket(url,[token])

    ws.onopen = (e: Event) => {
      setTimeout(() => loading.value = false, firstMessageTimeout || WEBSOCKET_TIMEOUT)
      onOpen && onOpen(e)
    }

    ws.onmessage = (e: MessageEvent<string>) => {
      loading.value = false
      const payload = JSON.parse(e.data)
      onMessage && onMessage(payload)
    }
    ws.onerror = (e: Event) => {
      loading.value = false
      onError && onError(e) || throwError(e)
    }

    ws.onclose = (e: CloseEvent) => {
      loading.value = false
      onClose && onClose(e)
    }

    wsClient.value = ws
  }

  onUnmounted(() => {
    wsClient.value?.close()
  })

  function throwError(e: Event) {
    console.error("WebSocket error observed:", {cause: e})
    throw new Error(e.type)
  }

  return {
    loading,
    init
  }
}
