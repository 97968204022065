








































































import DiscountField from '@/components/general/DiscountField.vue'
import {computed, defineComponent, ref, watch} from '@vue/composition-api'
import {CustomPacks, PackItem} from '@/types'

// @ts-ignore
import {complementDiscountRules, complementDiscountRulesPercentage} from '@/helpers/offer-manager-rules'
import {mapGetters} from '@/utils/map-store'
import {useI18n} from '@/i18n'
import PriceField from '@/components/general/PriceField.vue'

export default defineComponent({
  name: 'Packs',
  components: { DiscountField, PriceField },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {root, emit}){

    const { t } = useI18n()
    const refreshKey = ref(0)

    const {
      getComplements: complementsList,
      getVehicleModelPacks: availablePacks,
      isLoadingPacks,
      getPacks: packs
    } = mapGetters(root.$store.getters)

    const selectablePacks = computed(() => {
      const filteredPacks = availablePacks.value.filter((item: PackItem) => !item.removed)

      return props.isEditing &&
        filteredPacks.length === 0 &&
        availablePacks.value && availablePacks.value.length === 0 ? selectedPacks.value : filteredPacks
    })

    const arePacksAvailable = computed(() => {
      return availablePacks.value.length > 0
    })

    const selectedPacks = computed({
      get: (): CustomPacks | undefined => packs.value,
      set: (value: CustomPacks | undefined) => root.$store.commit('SET_PACKS', value)
    })

    watch(arePacksAvailable, () => {
      refreshKey.value++
    })

    function validateForm() {
      emit('validateForm')
    }

    return {
      t,
      refreshKey,
      complementDiscountRules,
      complementDiscountRulesPercentage,
      complementsList,
      selectablePacks,
      arePacksAvailable,
      availablePacks,
      isLoadingPacks,
      selectedPacks,
      packs,
      commit: root.$store.commit,
      validateForm
    }
  }
})
