import { Ref, ref } from '@vue/composition-api'
import { DashboardStatResponse, Stats } from '@/types'

interface Props<T> {
  selectedMonth: Ref<number>
  selectedYear: Ref<number>
  onFetchData?: () => Promise<Array<T>>
  onStatsRender: (stats: T | any) => Stats[] | any
}

export const useDealerStats = <T extends DashboardStatResponse>({
  selectedMonth,
  selectedYear,
  onFetchData,
  onStatsRender,
}: Props<T>) => {
  const stats = ref<Stats[] | undefined>(undefined) as Ref<Stats[] | undefined>
  const loading = ref(false)

  async function fetchData() {
    loading.value = true

    try {
      if (!onFetchData) {
        return
      }

      const data = await onFetchData()
      const dataFiltered = data.find(
        (item: T) => item.year === selectedYear.value && item.month === selectedMonth.value,
      ) as T

      stats.value = onStatsRender(dataFiltered)
    } catch (e) {
      console.error(`An error ocurred trying to fetch dealer stats data`, e)
    } finally {
      loading.value = false
    }
  }

  return {
    stats,
    loading,
    refetch: async () => await fetchData(),
  }
}
