import ApiGatewayClient from "./aws/aws-gateway-client"
import {AxiosResponse} from "axios";

export class LeviathanApi {

  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  sellDevice(dealerId: string, deviceId: string, products: string[]): Promise<AxiosResponse<any>> {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MUVIO_LEVIATHAN_API,
      method: 'POST',
      path: `/v1/devices/${deviceId}/sell`,
      data: {
        source: {
          id: dealerId
        },
        products
      }
    })
  }
}

export default new LeviathanApi()
