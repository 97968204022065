<template id="repair-modal">
  <modal :active="active" @close="closeModal" :title="title">
    <template slot="body">
      <customer-info v-if="mStep==0" :customer='customer' :container='container'></customer-info>
      <repair-detail  v-if="mStep==0" :request="cloned" :container="container" :inspection="inspection" @accept="acceptRepair" @reject="rejectRepair"/>
      <repair-confirm v-else-if="mStep==1"/>
      <!--<repair-delete v-else-if="mStep==2" :deleteRevision="deleteRevision" :isRevision="request.requested.service" @confirm="deleteRepair"/>-->
      <repair-delete-confirm v-else-if="mStep==3" @confirm="confirmDeleteRepair"></repair-delete-confirm>
      <repair-delete-message  v-else-if="mStep==2"  @close="closeDeleteMessage"  @redirect="goToAppointment"></repair-delete-message>
      <request-modified v-if="mStep==4" />
      <error-message v-if="mStep==5" />
    </template>
  </modal>
</template>

<script>
  import api from '@/api/'
  import Modal from '@/components/Modal';
  import RepairDetail from './RepairDetail.vue';
  import RepairDelete from './RepairDelete.vue';
  import RepairConfirm from './RepairConfirm.vue';
  import RepairDeleteConfirm from './RepairDeleteConfirm';
  import RepairDeleteMessage from '@/components/dialog/RepairDeleteMessage';
  import {eventBus} from "@/main";
  import CustomerInfo from '@/components/dialog/CustomerInfo'
  import RequestModified from '@/components/dialog/RequestModified';
  import ErrorMessage from '@/components/dialog/ErrorMessage';

  const RepairModal = {
    props: {
      active:{
          type: Boolean,
          default: () => {
              return false
          }
      },
      inspection:{
          type: Object,
          default: null
      },
      request:{
          type: Object,
          default: null
      },
      container:{
          type: Object,
          default: null
      },
      customer: {
           type: Object,
           default:null
      },
      step:{
        type: Number,
        default: ()=>{
          return 0;
        }
      }
    },
    components: {Modal, RepairDetail, RepairDelete, RepairConfirm, RepairDeleteConfirm, CustomerInfo, RequestModified, ErrorMessage, RepairDeleteMessage},
    template: "#repair-modal",
    data() {
      const location = {kind: '', description: ''};
      const currentRevision = {km: null, day: new Date()};
      const amount = 0;
      let cloned = JSON.parse(JSON.stringify({location, currentRevision, amount, ...this.request})) //TODO: Hack to avoid v-model aliasing with parent rowData
      cloned.pickup = cloned.pickup || { day: null, hour: null }
      cloned = {...cloned, notifyUser: false};
      return {
        mStep: 0,
        deleteRevision: { kind: 'NO_CLIENT', comment:null, nextService:null},
        cloned

      }
    },
    computed:{
      title() {
        switch(this.mStep) {
          case 0: return "DETALLE DE LA REPARACIÓN";
          case 1: return "";
          case 2: return "ELIMINAR REPARACIÓN";
        }
      },
      user() {
        return this.$store.getters.getUser;
      }
    },
    created(){
      this.mStep = this.step;
    },
    methods: {
      closeModal() {
        eventBus.$emit("refresh-list");
        this.emitClose();
      },
      emitClose() {
        eventBus.$emit("refresh-counters");
        this.$emit('close');
      },
      goToAppointment(){
        const route = '/appointments/' + this.request.id;
        this.$router.push(route);
      },
      acceptRepair() {
        let {cloned: {price: amount, requested, notifyUser, currentRevision, pickup: {day: pickupDate, hour: pickupHour}, location}} = this;
        let {cloned: {id}} = this;
        let {km, day} = currentRevision;

        id = parseInt(id);
        amount = Number(amount||0);

        let request = {requested, notifyUser};
        let service = {};

        if(pickupDate) {
          request = {...request, pickupDate};
        }
        if(pickupHour) {
          request = {...request, pickupHour};
        }
        if(amount && amount>0) {
          request = {...request, amount};
        }
        
        service = {
          ...service,
          nextService: km
            ? Number(km)
            : this.inspection.lastService
              ? this.inspection.lastService + 1
              : 0,
          updateDate: day ? day : new Date(),
        }
        
        if(this.cloned.location) {
          let {kind, description} = location;
          description = kind == "OTHER" ? description : null;
          request = {...request, location: {kind, description}};
        }

        let body = {request};

        if(Object.keys(service).length>0) {
          const {id} = this.inspection;
          service = {...service, id, containerId: this.container.id, groupId: this.user.groupId, status: 2};
          body = {...body, service};
        }

        api.acceptRepair(id, body, (err) => {
          let step = 1;
          if (err) {
            if (err._status >=500){
              step = 5;
            }else{
              step = 4;
            }
          }
          this.mStep = step;
        });

      },
      rejectRepair() {
        this.mStep = 2;
      },
      closeDeleteMessage() {
        this.closeModal();
      },

      confirmDeleteRepair() {
        const {cloned: {id}} = this;
        const {inspection} = this;
        let body = {};
        if(inspection && inspection.id) {
          let {kind, comment} = this.deleteRevision;
          const inspectionId = inspection.id;
          switch (kind) {
            case "REV_ERROR":
              comment = "Cálculo de revisión erróneo";
              break;
            case "NO_CLIENT":
              comment = "El cliente no se ha presentado";
              break;
          }
          body = {
            service: {
              id: inspectionId,
              comment,
              containerId: this.container.id,
              nextService: Number(this.deleteRevision.nextService || this.inspection.nextService),
              scheduled: 0,
              status: 3
            }
          }
        }
        api.removeRequestRevisionAdmin(id, body, (err) => {
          if (err) {
            let step = 1;
            if (err._status >=500){
              step = 5;
            }else{
              step = 4;
            }
            this.mStep = step;
          }
          this.closeModal();
        });
      },
      deleteRepair() {
        this.mStep = 3;
      },
    }
  }


  export default RepairModal;

</script>


