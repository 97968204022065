import ApiGatewayClient from './aws/aws-gateway-client'

export class DealersApi {
  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  replaceDevice(deviceId: string, data: any) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_DEALERS_API,
      method: 'POST',
      path: `/v1/devices/${deviceId}/replace`,
      data,
    })
  }
}

export default new DealersApi()
