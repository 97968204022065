<template>
  <div class="Solicitudes col-12">
    <div class="form-card mobilestyle ultra-high-size">
      <div class="form form-horizontal centered" @submit.prevent="">
        <revisions-list></revisions-list>
      </div>
    </div>
  </div>
</template>

<script>
import RevisionsList from '@/components/revisions/RevisionsList'

export default {
  name: 'Revisions',
  components: {
    RevisionsList,
  },
  data() {
    return {
      isLoading: false,
      /*
        test:
          [
            {
              id: 11111, //draft id
              created: "....", // TIMESTAMP sets on insert
              containerDetail: "{....}",
              mapitCode: "", // mapit code Number
              supervisor: {
                id: 222222, // 		1)
                detail: "{....}", // 	2)
                state: "...", // 	3)
                login: "..." // 		1)
              },
	            registerPendingEmail: "" // Only if user not registred
            },
            {
              groupId: 258,
              containerDetail: "{}",
              mapitCode: "",
              supervisorId: "", // Opcional  (if already registred)
              supervisorDetail: "{}", // Opcional (If we create it)
              registerPendingEmail: "" // Opcional (We need him to register)
            }

          ]
*/
    }
  },
  props: ['id'],
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUser
    },
  },
}
</script>
