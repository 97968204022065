import ApiGatewayClient from "./aws/aws-gateway-client"

export class StocksApi {

  constructor() {
    this.client = new ApiGatewayClient()
  }

  createOrder(data) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_STOCKS_API,
        method: 'POST',
        path: `/v1/orders`,
        data
      })
  }
  getOrders() {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_STOCKS_API,
        method: 'GET',
        path: `/v1/orders`
      })
  }
  updateOrder(id, newStatus) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_STOCKS_API,
        method: 'PATCH',
        path: `/v1/orders/${id}/status`,
        data: { status: newStatus }
      })
  }

}

export const createOrderData = (dealerId, solicitantName, amount) => ({
  applicant: {
    dealer: {
      id: dealerId
    },
    name: solicitantName
  },
  product: 'MAPIT_DEVICE',
  quantity: amount
})

export default new StocksApi()
