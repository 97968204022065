interface ImportSuccess {
  total: number
}

interface ImportError {
  type: 'WrongImportHeader' | 'WrongImportInput'
  column: string
  row?: number
}

interface ImportData {
  success?: ImportSuccess
  error?: ImportError
}

export function parseImportResponse({ success, error }: ImportData) {
  if (success) {
    return {
      message: 'import.targets.success',
      messageData: { total: success.total },
      error: false,
    }
  }

  if (!error) {
    return {
      message: 'import.success-import-text',
      error: false,
    }
  }

  if (error.type === 'WrongImportHeader') {
    return {
      message: 'import.errors.wrong-column',
      messageData: { column: error.column },
      error: true,
    }
  }

  if (error.type === 'WrongImportInput') {
    return {
      message: 'import.errors.wrong-row-column',
      messageData: { column: error.column, row: error.row },
      error: true,
    }
  }

  return {
    message: 'import.error-import-server',
    error: true,
  }
}
