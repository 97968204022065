import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import provincias from '@/assets/json/provincias.json'

const BASE_PRICE_CCAA_KEY = "PRECIO_BASE"

export const reverseItemInArray = (element, array) => {
    array.includes(element) ?
        array.splice(array.indexOf(element), 1)
        :
        array.push(element)
    return array
}

export const inRange = (x, min, max) =>
    ((x - min) * (x - max) <= 0)

export const makeVehicleModelName = (model) => {
    if(model.modelName !== model.commercialName) {
        return model.modelName + (model.commercialName ? ` ${model.commercialName}` : '')
    }
    return model.modelName
}
export const makeVehicleModelSubname = model =>
    model.year
    + (model.color ? (' ' + model.color) : '')



export const defaultPriceMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    integerLimit: 5,
    decimalLimit: 2,
    includeThousandsSeparator: true,
    allowNegative: false,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ','
});
export const negativePriceMask = createNumberMask({
    prefix: '-',
    allowDecimal: true,
    integerLimit: 5,
    decimalLimit: 2,
    includeThousandsSeparator: true,
    allowNegative: false,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ','
});

export const defaultPercentageMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    integerLimit: 3,
    decimalLimit: 2,
    includeThousandsSeparator: true,
    allowNegative: false,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ','
});
export const negativePercentageMask = createNumberMask({
    prefix: '-',
    allowDecimal: true,
    integerLimit: 3,
    decimalLimit: 2,
    includeThousandsSeparator: true,
    allowNegative: false,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ','
});

export const getProvinciaIdByName = (name) => {
  let res = '';
  provincias.forEach(function (value) {
    if(value.nm === name){
      res = value.id;
    }
  });
  return res;
}


export const getCCAAByProvinceCode = provinceCode =>
 provincias.find(p => p.id === provinceCode)?.ccaa || BASE_PRICE_CCAA_KEY

const capitalize = str => {
    return str.substring(0,1).toUpperCase() + str.substring(1, str.length).toLowerCase();
}

export const toCapitalize = (str) => {
    const arr = str.split(' ')
    let res = ''
    arr.forEach((newStr, index) => {
        res += capitalize(newStr) + (index < arr.length-1 ? ' ' : '')
    })
    return res
}
