<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4">
        <m-month-picker
          v-model="month"
          :locale="locale"
          hide-details
        />
      </v-col>
      <v-col cols="12" lg="8">
        <mapit-input
          uid="loansSearchFilter"
          :value="search"
          :placeholder="$t('dealer-bike.loans.table.lbl-search-finished')"
          @changeValue="value => search = value"
          :clearable="true"
        />
      </v-col>
    </v-row>
    <v-data-table
      id="dealer-bike-finished-loans-table-list"
      :loading="loading"
      :loading-text="$t('dealer-bike.loans.table.loading')"
      :headers="headers"
      :items="filteredLoans"
      sort-by="scheduledFor"
      :sort-desc="[true]"
      class="elevation-0 pt-4"
      :no-data-text="
        $t (
          filteredLoans && filteredLoans.length > 0
            ? 'dealer-bike.loans.table.no-data-search'
            : 'dealer-bike.loans.table.no-data',
          { search },
        )
      "
      :no-results-text="$t('dealer-bike.loans.table.no-data-search')"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right',
        'items-per-page-text': $t('dealer-bike.loans.table.footer_num_x_page'),
        'items-per-page-options': [10, 25, 50, -1],
      }"
    >
      <template #footer.page-text="props">
        {{ props.pageStart }}-{{ props.pageStop }} {{ $t('honda.registration.list.of') }} {{ props.itemsLength }}
      </template>

      <template v-slot:item.usageType="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="ma-2" outlined label text-color="black" v-bind="attrs" v-on="on">
              {{ $t(`dealer-bike.loans.type.${item.usageType.toLowerCase()}`) }}
            </v-chip>
          </template>
          <span>
            {{ $t(`dealer-bike.loans.table.tooltips.${item.usageType.toLowerCase()}`) }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.contact.firstName="{ item }">
        <div class="vehicle">
          <span class="vehicle-model">
            {{ `${ item.contact.firstName } ${ item.contact.lastName }` }}
          </span>
        </div>
      </template>

      <template v-slot:item.contact.phone="{ item }">
        <div class="vehicle">
          <m-phone-label :value="item.contact.phone" class="vehicle-color" />
        </div>
      </template>

      <template v-slot:item.scheduledFor="{ item }">
        <span class="text-item">{{ toDateTimeFormat(item.scheduledFor) }}</span>
      </template>

      <template v-slot:item.returnedAt="{ item }">
        <span class="text-item" v-if="item.returnedAt">{{ toDateTimeFormat(item.returnedAt)  }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu transition="slide-x-transition" bottom right :data-menu="item.id">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-icon class="icon-mapit">)</v-icon>
            </div>
          </template>
          <v-list dense class="list-menu-actions">
            <download-loan-list-item :data-cy="`download-loan-document-${item.id}`" :loan="item" />
            <v-list-item
              :data-cy="`show-loan-feedback-${item.id}`"
              @click="toggleDialogFeedback(item)"
            >
              <v-list-item-title>
                {{ $t('dealer-bike.detail.actions.show-return-comments') }}
              </v-list-item-title>

            </v-list-item>
          </v-list>
        </v-menu>
      </template>

    </v-data-table>

    <mapit-dialog :dialog="dialogFeedback" @close="dialogFeedback = false">
      <template v-slot:body>
        <div class="align-left pt-4">
          <span class="icon-mapit color-gray" style="font-size: 45px;">&#xe01f;</span>
        </div>
        <div class="label-title mt-4">{{ $t('dealer-bike.return.feedback.lbl-probability') }}</div>
        <p class="label-text py-2" data-cy="dialog-loan-feedback-probability-of-sale">
          <b v-if="selectedLoan && selectedLoan.return.assessment">
            {{ $t(`dealer-bike.return.feedback.lbl-probability-${selectedLoan.return.assessment.probabilityOfSale.toLowerCase()}`) }}
          </b>
        </p>
        <div class="label-title">{{ $t('dealer-bike.return.feedback.lbl-comments') }}</div>
        <p class="py-2" data-cy="dialog-loan-feedback-comment" v-if="selectedLoan && selectedLoan.return.assessment.comment">
          <b>{{ (selectedLoan.returnedBy || selectedLoan.return.attendedBy).split('<')[0] }}<span class="color-gray"> - {{ toDateTimeFormat(selectedLoan.returnedAt) }}</span></b><br/>
          {{ selectedLoan.return.assessment.comment }}
        </p>
        <p class="label-text py-2" data-cy="dialog-loan-feedback-no-comment" v-else>
          <b>{{ $t('dealer-bike.return.feedback.lbl-no-comments') }}</b>
        </p>
      </template>
    </mapit-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {UsageType} from '@mapit/dealer-bikes-dynamodb/dist/types'
import MapitInput from '@/components/general/MapitInput.vue'
import DownloadLoanListItem from '@/components/atom/DownloadLoanListItem'
import MapitDialog from '@/components/dialog/MapitDialog'
import {toDateTimeFormat} from "@/utils/date-helper";
import {DateTime} from "luxon";

export default {
  name: 'FinishedLoansTable',
  components: { MapitDialog, DownloadLoanListItem, MapitInput },
  props: [ 'dealerBikeId' ],
  data() {
    return {
      toDateTimeFormat,
      UsageType,
      month: DateTime.now().toFormat('yyyy-MM'),
      search: '',
      headers: [
        {
          text: this.$t('dealer-bike.detail.history-loans.type'),
          align: 'left',
          value: 'usageType',
          class: 'text-header',
          sortable: false,
        },
        {
          text: this.$t('dealer-bike.detail.history-loans.customer'),
          align: 'left',
          value: 'contact.firstName',
          class: 'text-header',
          sortable: false,
        },
        {
          text: this.$t('dealer-bike.detail.history-loans.phone'),
          align: 'left',
          value: 'contact.phone',
          class: 'text-header',
          sortable: false,
        },
        {
          text: this.$t('dealer-bike.detail.history-loans.delivery-date'),
          align: 'left',
          value: 'scheduledFor',
          class: 'text-header',
          sortable: true,
        },
        {
          text: this.$t('dealer-bike.detail.history-loans.return-date'),
          align: 'left',
          value: 'returnedAt',
          class: 'text-header',
          sortable: false,
        },
        {
          text: this.$t('dealer-bike.detail.history-loans.actions'),
          align: 'center',
          class: 'text-header',
          sortable: false,
          value: 'actions',
        },
      ],
      loansList: [],
      listLoading: false,
      dialogFeedback: false,
      selectedLoan: null
    }
  },
  watch: {
    month() {
      this.loadLoans()
    }
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    loading() {
      return this.listLoading
    },
    filteredLoans() {
      return this.loansList.filter(item => this.search ?
        JSON.stringify([
          item.dealerBike.vehicle.registrationNumber,
          item.dealerBike.vehicle.model,
          item.dealerBike.vehicle.color,
          item.dealerBike.vehicle.vin,
          item.contact.phone,
          item.createdBy,
          `${ item.contact.firstName } ${ item.contact.lastName }`,
        ]).toLowerCase().includes(this.search.toLowerCase())
        : true)
    },
  },
  methods: {
    ...mapActions({
      loadLoansFinishedByMonth: 'loadLoansFinishedByMonth',
    }),
    async loadLoans() {
      this.listLoading = true
      this.loansList = await this.loadLoansFinishedByMonth({ dealerBikeId: this.dealerBikeId, month: this.month })
      this.listLoading = false
    },
    toggleDialogFeedback(item){
      this.selectedLoan = item
      this.dialogFeedback = !this.dialogFeedback
    }
  },
  async created() {
    await this.loadLoans()
  },
}
</script>

<style lang="scss" scoped>
.label-title{
  font-family: "ProximaNovaExCnSBold" !important;
  font-size: 24px !important;
  color: var(--v-primary-base);
  text-transform: uppercase !important;
}
.label-text{
  font: normal normal normal 16px/26px "ProximaNovaRegular" !important;
  color: var(--v-gray-darken4);
  white-space: nowrap;
  overflow: hidden;
  line-height: 26px;
}
</style>
