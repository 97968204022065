import { LogItem } from '@/types'

export function getClosestCommentToToday(comments: LogItem[] = []): LogItem | undefined {
  // only consider comments with due dates (follow ups)
  const commentsFiltered = comments.filter((comment) => comment.dueDate)

  if (!commentsFiltered?.length) {
    return
  }

  // reset the day
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const todayTS = today.getTime()

  // filter by due date and add distance from today to the comments
  const commentsWithDistances = commentsFiltered.map((comment: any) => {
    const time = new Date(comment.dueDate).getTime()
    return { ...comment, distance: time - todayTS }
  })

  // sort comments by distance to today
  const commentsByDistance = commentsWithDistances.sort((a, b) => {
    return a.distance < b.distance ? -1 : 1
  })

  // get the closest comment after and before today
  const closestAfter = commentsByDistance.find((comment) => comment.distance >= 0)
  const closestBefore = commentsByDistance[commentsWithDistances.length - 1]

  // return the follow up object
  const closest = closestAfter || closestBefore

  return {
    dueDate: closest.dueDate,
    text: closest.text,
    timestamp: closest.timestamp,
  }
}
