import { getClosestCommentToToday } from '@/utils/comments'
import { isExpiredOffer } from '@/utils/offers'

export function mapResponseDataToOffers(data: any) {
  return data.map((item: any) => ({
    ...item,
    expired: isExpiredOffer(item),
    followUp: getClosestCommentToToday(item.commentLog),
  }))
}
