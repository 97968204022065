import { DateTime } from 'luxon'
import { s3 } from '@/api/lib/s3-client'

export const APP_VERSION_KEY = 'app-version'

export async function fetchAppVersion() {
  try {
    const s3Client = await s3(process.env.VUE_APP_S3_PUBLIC_DATA_BUCKET)
    const response = await s3Client
      .getObject({
        Bucket: process.env.VUE_APP_S3_PUBLIC_DATA_BUCKET,
        Key: 'version.json',
        ResponseCacheControl: 'no-cache',
      })
      .promise()

    if (!response.Body) {
      return
    }

    const { version } = JSON.parse(response.Body.toString('utf-8'))
    return cleanVersionTag(version)
  } catch (error) {
    console.error(error)
    return
  }
}

export async function checkAppVersion() {
  const itemParsed = getAppVersionFromStore()

  if (itemParsed.expireDate) {
    const expireDate = DateTime.fromISO(itemParsed.expireDate)
    const hasExpired = expireDate < DateTime.now()

    if (!hasExpired) {
      return
    }
  }

  const currentVersion = cleanVersionTag(process.env.VUE_APP_GIT_TAG || 'no-tag')
  const lastAppVersion = await fetchAppVersion()

  if (currentVersion === lastAppVersion) {
    setAppVersionToStore(lastAppVersion, 5)
    return
  }

  if (!isValidVersion(lastAppVersion)) {
    setAppVersionToStore(currentVersion, 30)
    return
  }

  return lastAppVersion
}

export function getAppVersionFromStore() {
  const storedItem = localStorage.getItem(APP_VERSION_KEY) || '{}'
  return JSON.parse(storedItem)
}

export function setAppVersionToStore(version: string, minutes: number) {
  const expireDate = DateTime.now().plus({ minutes })
  const storeValue = JSON.stringify({ expireDate, version })

  localStorage.setItem(APP_VERSION_KEY, storeValue)
}

export function cleanVersionTag(tag: string): string {
  return tag?.replace(/v/, '')?.split('-')[0]
}

export function isValidVersion(tag: string | undefined): boolean {
  return !!tag && /^\d+\.\d+\.\d+$/.test(tag)
}
