import { MapitDevice, PhysicalState } from "@mapit/core-dynamodb/dist/types"
import { Status } from "@mapit/honda-offers-builder/dist/types";

export class RegistrationHelper {

  public canDeviceBeInstalled(device?: MapitDevice | null): CanDeviceBeInstalledResult {
    if (device == null) {
      return {
        canBeInstalled: false,
        reason: PreventInstallReason.NotFound
      }
    } else if (device.vehicle?.id != null) {
      return {
        canBeInstalled: false,
        reason: PreventInstallReason.AlreadyAttached
      }
    } else if (device.physicalState && device.physicalState !== PhysicalState.NotInstalled) {
      return {
        canBeInstalled: false,
        reason: PreventInstallReason.NotInstallable
      }
    }
    return {
      canBeInstalled: true
    }
  }

}

export enum PreventInstallReason {
  AlreadyAttached = "ALREADY_ATTACHED",
  NotInstallable = "NOT_INSTALLABLE",
  NotFound = "NOT_FOUND"
}

interface CanDeviceBeInstalledResult {
  canBeInstalled: boolean
  reason?: PreventInstallReason
}

export const STATES_ALLOWED_FOR_REGISTRATION = [
  Status.Accepted,
  Status.COICreated,
  Status.Quote,
  Status.Ordered,
  Status.Invoiced,
  Status.Completed,
]

export function isRegistrationAllowed(status: Status) {
  return STATES_ALLOWED_FOR_REGISTRATION.includes(status)
}