import { OfferDoc } from '@mapit/opensearch-client/dist/types'
import {
  Client,
  LogItem,
  Offer,
  OfferType,
  Pack,
  Packs,
  Product,
  Status,
} from '@mapit/honda-offers-builder/dist/types'

export { Client, LogItem, Offer, OfferType, Pack, Packs, Product, Status, OfferDoc }

// TODO: update new types in @/types/offers
export const OfferTypePortal = {
  customer: OfferType.customer,
  subagent: OfferType.subagent,
  demobike: 'DEMO_BIKE',
  fleet: 'FLEET',
}

export const offerTypes = [
  OfferTypePortal.customer,
  OfferTypePortal.subagent,
  OfferTypePortal.demobike,
  OfferTypePortal.fleet,
]

export interface Pagination {
  page?: number
  itemsPerPage?: number
}
