import {BrowserDetection, DeviceDetection, OSDetection} from '@/types'
import { Material, MaterialModel, Model } from '@mapit/honda-catalog-dynamodb/dist/types';
import KSUID from "ksuid";

export const getIdMaterial = (str: string): any => {
  return str?.match(/\d{6}-/)?.shift()?.substring(0, 6)
}

const firstNonZeroValue = (p: number, n: number) => {
  return p ? p : n
}

export const fieldSorter = (fields: string[]): any => {
  return (a: any, b: any) => {
    return fields.map((o: string) => {
      let dir = 1
      if (o[0] === '-') { // - => DESC sort
        dir = -1
        o = o.substring(1)
      }
      if (a[o] > b[o]) return dir
      if (a[o] < b[o]) return -(dir)
      return 0
    })
      .reduce(firstNonZeroValue, 0)
  }
}

export const capitalize = (str: string | null | undefined): string | null | undefined => {
  if (str === null || str === undefined) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const toCapitalize = (str: string) => {
  const arr = str.split(' ')
  let res = ''
  arr.forEach((newStr, index) => {
    res += capitalize(newStr) + (index < arr.length-1 ? ' ' : '')
  })
  return res
}
export const sanitizeForS3 = (str: string) => str.replace(/[^a-zA-Z\d:]/gm, '_')

function getOS(): OSDetection {
  const userAgent = navigator.userAgent
  // @ts-ignore
  const platform = Object.hasOwn(navigator, 'userAgentData') ? navigator?.userAgentData?.platform : window.navigator.platform

  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']

  let os: OSDetection = 'unknown';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'ios';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'windows';
  } else if (/Android/.test(userAgent)) {
    os = 'android';
  } else if (/Linux/.test(platform)) {
    os = 'linux';
  }

  return os;
}

export function detectDevice(): DeviceDetection {

  const userAgent = navigator.userAgent;
  const os = getOS()

  const isMobile = /iOS|Android/i.test(os ?? '')
  const isDesktop = !isMobile;

  let browser: BrowserDetection = 'unknown';

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browser = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browser = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browser = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browser = "opera";
  } else if (userAgent.match(/edg/i)) {
    browser = "edge";
  }

  return {
    isDesktop,
    isMobile,
    browser,
    os
  };
}

export function compactObject(data?: Record<string, any>): Record<string, any> | undefined {
  if (!data && typeof data !== 'object') {
    return data;
  }

  return Object.keys(data).reduce(function (accumulator, key) {
    const isObject = data[key] !== null && typeof data[key] === "object";
    let value: any = isObject ? compactObject(data[key]) : data[key];

    if (value === undefined) {  // || isEmptyObject
      return accumulator;
    }
    if (Array.isArray(data[key])) {
      value = Object.values(value);
    }

    return Object.assign(accumulator, { [key]: value });
  }, {});
}

export function generateUUID() {
  return KSUID.randomSync().string
}

export function createMaterialDescription(material: Material | undefined): string | undefined {
  if (!material) return undefined
  const year = material.model.y || material.model.ym || ''
  const color = material.color ? `${material.idMat}-${material.color.general}_${material.color.code}_${material.model.type}` : ''
  return `${year} ${color}`.trim()
}

export function createModelName(model: Model | MaterialModel | undefined): string | undefined {
  if (!model) return undefined
  const commercialName = model.name !== model.commercialName? `${model.commercialName}` : ''
  return `${model.name} ${commercialName}`.trim()
}
