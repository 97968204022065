var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","xl":"6"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"stats-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t(_vm.title)))]),(_vm.tooltip)?_c('v-tooltip',{attrs:{"bottom":"","max-width":400,"nudge-right":"100%","transition":"fade-transition","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"fa-light fa-circle-info click",attrs:{"color":"tertiary","dense":""}},'v-icon',attrs,false),on))]}}],null,false,1711189788)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.tooltip))}})]):_vm._e()],1)]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('CardTrend',{attrs:{"loading":_vm.loading,"title1":_vm.$t('honda.dashboard.stats.offers.created'),"value1":_vm.data.offers,"percent":_vm.data.offersYoy,"year":_vm.data.year}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('CardTrend',{attrs:{"loading":_vm.loading,"title1":_vm.$t('honda.dashboard.stats.offers.closed-sale'),"value1":_vm.data.coiCreated,"subtitle":_vm.$t('honda.dashboard.stats.offers.coi-created'),"percent":_vm.data.coiCreatedYoy,"year":_vm.data.year}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('CardBars',{attrs:{"loading":_vm.loading,"title":_vm.$t('honda.dashboard.stats.offers.pending-delivery'),"current":_vm.data.toBeDelivered,"labels":[
            _vm.$t('honda.dashboard.stats.offers.invoiced'),
            _vm.$t('honda.dashboard.stats.offers.confirmed'),
            _vm.$t('honda.dashboard.stats.offers.quoted'),
            _vm.$t('honda.dashboard.stats.offers.coi-created'),
            _vm.$t('honda.dashboard.stats.offers.accepted') ],"data":[
            _vm.data.currentInvoiced,
            _vm.data.currentConfirmed,
            _vm.data.currentQuote,
            _vm.data.currentCoiAccepted,
            _vm.data.currentAccepted ]}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }