import coreApi from '@/api/core.api'
import { showAlert } from '@/vuex/modules/ui'

const state = {
  list: [],
  loading: false
}

const getters = {
    getUserCredential: state => state.list,
    getLoadingUsersCredentialList: state => state.loading
}

const actions = {
  loadUsersCredentialList: ({ commit }, dealerId) => {
    commit('SET_LOADING_USERS_CREDENTIAL_LIST', true)
    return new Promise((resolve, reject) => {
      coreApi
        .getUserCredential(dealerId)
        .then(({data}) => {
          commit('SET_USERS_CREDENTIAL_LIST', data.users)
          commit('SET_LOADING_USERS_CREDENTIAL_LIST', false)
          resolve(data.data)
        })
        .catch(e => {
          commit('SET_USERS_CREDENTIAL_LIST', [])
          commit('SET_LOADING_USERS_CREDENTIAL_LIST', false)
          reject(e)
        })
    })
  },
  resendApprovalEmail: async ({ dispatch, commit }, {userId, dealerId}) => {
    commit('SET_LOADING_USERS_CREDENTIAL_LIST', true)
    try {
      await coreApi
        .resendApprovalEmail(dealerId, userId)
      showAlert(dispatch, 'success', 'users-manager.lbl-resend-approval-success')
    } catch (e) {
      console.log(e)
      showAlert(dispatch, 'error', 'users-manager.lbl-resend-approval-error')
    }
    commit('SET_LOADING_USERS_CREDENTIAL_LIST', false)

  }
}

const mutations = {
  SET_USERS_CREDENTIAL_LIST: (state, users) => {
    state.list = users
  },
  SET_LOADING_USERS_CREDENTIAL_LIST: (state, loading) => {
    state.loading = loading
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
