









import RegistrationsTable from "@/components/registrations/HondaRegistration/RegistrationsTable.vue";
import {defineComponent} from "@vue/composition-api";
import {RegistrationArchivedFilters, RegistrationFilters} from "@/types";
import CardWrapper from "@/components/general/CardWrapper.vue";
import {mapGetters} from "@/utils/map-store";

export default defineComponent({
  name: 'Registrations',
  components: {
    RegistrationsTable,
    CardWrapper
  },
  setup(props, {root}) {
    const {getPermissions: permissions} = mapGetters(root.$store.getters)

    const filters = [...Object.values(RegistrationFilters), RegistrationArchivedFilters.ArchivedPending]
    const defaultFilters = [RegistrationFilters.PendingSign, RegistrationFilters.PendingRegister, RegistrationFilters.PendingCommunication] as RegistrationFilters[]

    if (permissions.value.registrationArchivedFilters.canSee) {
      filters.push(RegistrationArchivedFilters.ArchivedTotal)
    }

    return {
      filters,
      defaultFilters
    };
  }
})
