// COMMON
export const SET_LOADING = 'SET_LOADING'

// REGISTRATIONS
export const SET_EX_DEALER_BIKE = 'SET_EX_DEALER_BIKE'

// DEALER BIKES
export const SET_DEALER_BIKE_RETURN_SALE_PROBABILITY = 'SET_DEALER_BIKE_RETURN_SALE_PROBABILITY'
export const SET_DEALER_BIKE_RETURN_COMMENTS = 'SET_DEALER_BIKE_RETURN_COMMENTS'
export const SET_DEALER_BIKE_LOAN_RETURN_DATE = 'SET_DEALER_BIKE_LOAN_RETURN_DATE'
export const SET_DEALER_BIKE_LOAN_RETURN_TIME = 'SET_DEALER_BIKE_LOAN_RETURN_TIME'
export const SET_DEALER_BIKE_LOAN_CONTACT = 'SET_DEALER_BIKE_LOAN_CONTACT'
export const SET_DEALER_BIKE_LOAN_DATA_DATE = 'SET_DEALER_BIKE_LOAN_DATA_DATE'
export const SET_DEALER_BIKE_LOAN_DATA_TIME = 'SET_DEALER_BIKE_LOAN_DATA_TIME'
export const SET_DEALER_BIKE_LOAN_HANDOVER_DAMAGE_LOG = 'SET_DEALER_BIKE_LOAN_HANDOVER_DAMAGE_LOG'
export const SET_DEALER_BIKE_LOAN_LICENSE_TYPE = 'SET_DEALER_BIKE_LOAN_LICENSE_TYPE'
export const SET_DEALER_BIKE_LOAN_LICENSE_EXPEDITION_DATE = 'SET_DEALER_BIKE_LOAN_LICENSE_EXPEDITION_DATE'
export const SET_DEALER_BIKE_LOAN_USAGE_TYPE = 'SET_DEALER_BIKE_LOAN_USAGE_TYPE'
export const SET_DEALER_BIKE_LOAN_RETURN_DAMAGE_LOG = 'SET_DEALER_BIKE_LOAN_RETURN_DAMAGE_LOG'
export const SET_DEALER_BIKE = 'SET_DEALER_BIKE'
export const SET_DEALER_BIKE_LIST = 'SET_DEALER_BIKE_LIST'
export const SET_DEALER_BIKE_LOANS_LIST = 'SET_DEALER_BIKE_LOANS_LIST'
export const SET_DEALER_BIKE_SELECTED = 'SET_DEALER_BIKE_SELECTED'
export const SET_LOAN_DISTANCE = 'SET_LOAN_DISTANCE'
export const SET_VEHICLE_KM = 'SET_VEHICLE_KM'
export const SET_DEALER_BIKE_SELECTED_STATUS = 'SET_DEALER_BIKE_SELECTED_STATUS'
export const SET_LOAN_DATA = 'SET_LOAN_DATA'
export const RESET_DEALER_BIKE_LOAN_RETURN_DATA = 'RESET_DEALER_BIKE_LOAN_RETURN_DATA'
export const SET_IS_MIGRATION_DEALER_BIKE = 'SET_IS_MIGRATION_DEALER_BIKE'
export const SET_DEALER_BIKE_REGISTRATION_NUMBER = 'SET_DEALER_BIKE_REGISTRATION_NUMBER'
export const SET_DEALER_BIKE_MAPIT = 'SET_DEALER_BIKE_MAPIT'
export const SET_IS_VALID_DEALER_BIKE_MAPIT = 'SET_IS_VALID_DEALER_BIKE_MAPIT'
export const SET_MIGRATION_DEMO_BIKE_DATA = 'SET_MIGRATION_DEMO_BIKE_DATA'
export const SET_DEALER_BIKE_MODEL_NAME = 'SET_DEALER_BIKE_MODEL_NAME'
export const SET_DEALER_BIKE_MODEL_SUBNAME = 'SET_DEALER_BIKE_MODEL_SUBNAME'
export const SET_DEALER_BIKE_MODEL_ID = 'SET_DEALER_BIKE_MODEL_ID'
export const SET_DEALER_BIKE_REGISTRATION_PANELS = 'SET_DEALER_BIKE_REGISTRATION_PANELS'
export const SET_DEALER_BIKE_LOAN_PANELS = 'SET_DEALER_BIKE_LOAN_PANELS'
export const SET_DEALER_BIKE_LICENSE = 'SET_DEALER_BIKE_LICENSE'
export const SET_DEALER_BIKE_VIN = 'SET_DEALER_BIKE_VIN'
export const SET_DEALER_BIKE_REGISTRATION_DATE = 'SET_DEALER_BIKE_REGISTRATION_DATE'
export const SET_DEALER_BIKE_USAGE_TYPE = 'SET_DEALER_BIKE_USAGE_TYPE'
export const SET_LOAN_ID = 'SET_LOAN_ID'
export const SET_DIGITAL_SIGNATURE_STATUS = 'SET_DIGITAL_SIGNATURE_STATUS'
export const RESET_DEALER_BIKE_STORE = 'RESET_DEALER_BIKE_STORE'
export const SET_IS_DEALER_BIKE_MAPIT_LOADING = 'SET_IS_DEALER_BIKE_MAPIT_LOADING'

//OFFERS
export const SET_ATTENDED_BY = 'SET_ATTENDED_BY'
