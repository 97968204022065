






































import {computed, defineComponent, onMounted, PropType, ref} from "@vue/composition-api";
import LabelTooltip from "@/components/text/LabelTooltip.vue";
import {Country} from "@mapit/common-utils/dist/types";
import {toDateFormat} from "@/utils/date-helper";
import {mapGetters} from "@/utils/map-store";
import {TranslateResult} from "vue-i18n";

interface LeadDetailItem {
  text?: string;
  value?: any;
  id?: string;
  type?: "text" | "phone" | "date" | "link";
  loading?: boolean;
}

export default defineComponent({
  name: "LeadDetailCard",
  components: {LabelTooltip},
  props: {
    items: {
      type: Array as PropType<LeadDetailItem[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    title: {
      type: String as PropType<string | TranslateResult>,
      required: false
    },
    variant: {
      type: String as PropType<"outline" | "filled">,
      default: "filled"
    },
    country: {
      type: String as PropType<Country>,
      required: false,
      default: Country.Spain
    }
  },
  setup(props, {root}) {

    const windowWidth = ref(0)

    const {
      getLocale: locale
    } = mapGetters(root.$store.getters)

    const filteredItems = computed(() => {
      return props.items
        .filter(item => item.value !== undefined)
    })

    onMounted(() => {
      windowWidth.value = window.innerWidth

      root.$nextTick(() => {
        window.addEventListener('resize', onResize)
      })
    })

    function onResize() {
      windowWidth.value = window.innerWidth
    }

    function getCreatedAtDate(created: any) {
      return toDateFormat(created, locale.value)
    }

    async function downloadFile(url: string, name: string) {
      const pdf = await fetch(url)
      const pdfBlob = await pdf.blob()
      const pdfUrl = URL.createObjectURL(pdfBlob)

      const link = document.createElement('a')
      link.href = pdfUrl
      link.download = name

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
    }

    return {
      filteredItems,
      windowWidth,
      getCreatedAtDate,
      downloadFile
    }
  }
})
