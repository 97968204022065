export enum DeviceStatus {
  NoComms = 'NO_COMMS',
  AtRest = 'AT_REST',
  Buzz = 'BUZZ',
  Moving = 'MOVING',
}

export enum DeviceStatusColor {
  Red = '#dc182d',
  Green = '#00803e',
  Orange = '#fca400',
}
