<template>
  <div>
      <search :step="step" @nextStep="nextStep" @backStep="backStep" :class="{'d-none' : part !== 1}"/>
      <form-data :step="step" @nextStep="nextStep" @backStep="backStep" :class="{'d-none' : part !== 2}"/>
  </div>
</template>

<script>
import FormData from './FormData'
import Search from './Search.vue'
import coreApi from '@/api/core.api'
import { accountRegistrationModel, parseRegisteredAccount } from '@/vuex/models'

export default {
  name: 'Account',
  components: { FormData, Search },
  props: ['step'],
  data() {
    return {
      part: 1
    }
  },
  watch: {
    "vehicle.forcedDemoBike": function(val) {
      this.$store.dispatch('setDemoBike', val)
      if(val) {
        this.part = 2
        coreApi
          .getAccountByEmail(this.user.login)
          .then(({data}) => {
            this.$store.dispatch('addAccount', parseRegisteredAccount(data[0]))
          })
      } else {
        this.part = 1
        const newAccount = accountRegistrationModel();
        newAccount.email.value = ''
        newAccount.withoutEmail = false
        this.$store.dispatch('addAccount', newAccount)
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    vehicle() {
      return this.$store.getters.getVehicle
    }
  },
  methods: {
    backStep() {
      if(this.part > 1 && !this.vehicle.forcedDemoBike) this.part = 1
      else this.$emit('backStep')
    },
    nextStep() {
      if(this.part === 1) this.part = 2
      else this.$emit('nextStep')
    },
  },
}
</script>


