






import { defineComponent, computed, watch } from '@vue/composition-api'
import { CurrencyInputOptions, CurrencyDisplay, useCurrencyInput } from 'vue-currency-input'

const DEFAULT_OPTIONS: CurrencyInputOptions = {
  accountingSign: false,
  autoDecimalDigits: false,
  autoSign: true,
  currency: 'EUR',
  currencyDisplay: CurrencyDisplay.hidden,
  hideCurrencySymbolOnFocus: false,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: true,
  locale: 'es-ES',
  precision: 2,
  useGrouping: true,
}

interface CurrencyIcons {
  [key: string]: string
}

const CURRENCY_ICONS: CurrencyIcons = {
  EUR: 'fak fa-light fa-m-euro',
  USD: 'fa-light fa-dollar-sign',
}

export default defineComponent({
  name: 'PriceField',
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const options = computed((): CurrencyInputOptions => {
      let valueRange = {}

      if (props.min) {
        valueRange = { ...valueRange, min: props.min }
      }

      if (props.max) {
        valueRange = { ...valueRange, max: props.max }
      }

      if (Object.keys(valueRange).length > 0) {
        return { ...DEFAULT_OPTIONS, valueRange }
      }

      return DEFAULT_OPTIONS
    })

    const { inputRef, setValue, formattedValue } = useCurrencyInput(options.value)

    watch(
      () => props.value,
      (value: any) => setValue(value),
    )

    return {
      inputRef,
      currencyIcons: CURRENCY_ICONS[options.value.currency],
      formattedValue,
    }
  },
})
