




































import { computed, defineComponent, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'CardGauge',
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    current: {
      type: Number,
      required: false,
    },
    percent: {
      type: Number,
      required: false,
    },
    target: {
      type: Number,
      required: false,
      default: 0,
    },
    status: {
      type: String,
      required: false,
    },
    semi: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const { current, percent, target } = toRefs(props)

    const config = {
      angleStart: 180,
      angleEnd: 0,
      fontColor: '#000',
      fontFamily: 'ProximaNovaRegular',
      lineWidth: 23,
      position: ['50%', '60%'],
    }

    const isEmpty = computed(() => {
      return !current?.value && current?.value !== 0 && !percent?.value && percent?.value !== 0
    })

    const targetDegrees = computed(() => {
      return (target.value / 400) * -360 + config.angleStart
    })

    const options = computed(() => {
      return {
        series: [
          {
            type: 'gauge',
            center: config.position,
            startAngle: config.angleStart,
            endAngle: config.angleEnd,
            silent: true,
            progress: {
              show: true,
              width: config.lineWidth,
              itemStyle: {
                color: '#dc192d',
              },
            },
            axisLine: {
              lineStyle: {
                width: config.lineWidth,
                color: [[1, '#eee']],
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            pointer: {
              show: false,
            },
            detail: {
              fontSize: 18,
              fontFamily: config.fontFamily,
              formatter: `${percent?.value}%`,
              color: config.fontColor,
              offsetCenter: [0, 0],
            },
            data: [{ value: percent?.value ? percent?.value / 2 : 0 }],
          },
          {
            type: 'gauge',
            center: config.position,
            splitNumber: 1,
            z: 3,
            startAngle: config.angleStart,
            endAngle: targetDegrees.value,
            min: 0,
            max: target.value,
            axisLine: {
              lineStyle: {
                width: 0,
              },
            },
            splitLine: {
              distance: 0,
              length: config.lineWidth,
              lineStyle: {
                width: 1,
                color: config.fontColor,
              },
            },
            axisLabel: {
              formatter: (value: number) => (value === 0 ? '' : `${value}%`),
              distance: -45,
              color: config.fontColor,
            },
            axisTick: {
              show: false,
            },
            pointer: {
              show: false,
            },
          },
        ],
      }
    })

    return {
      isEmpty,
      options,
    }
  },
})
