<template>
  <div class="data-card">
    <h2>{{ $t('users.vehicle.title') }}</h2>

    <div v-if="vehicles.length > 0">
      <template v-for="vehicle in vehicles">
        <container-detail :vehicle="vehicle" :key="vehicle.id" />
      </template>
    </div>

    <div v-else>
      <p class="mb-0">{{ $t('users.loading.no-vehicles') }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import ContainerDetail from '@/components/users/ContainerDetail'

export default defineComponent({
  name: 'ContainersList',
  components: {
    ContainerDetail,
  },
  props: {
    vehicles: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
})
</script>
