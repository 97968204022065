<template>
  <date-picker v-bind="$attrs" :default-value="initValue" :clearable="clearable" :disabled="disabled" input-class='form-input col spectre'
               :bootstrap-styling="true" :placeholder="$attrs.placeholder || 'Selecciona día'" format="DD/MM/YYYY"
               @input="onInput" v-model="valueAsDate" :disabled-days="disableSundays"
               :disabled-date="isDateDisabled"
               />

</template>
<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import moment from 'moment';

  export default {
    components: {DatePicker},
    props: {
      "value": String,
      "clearable": {
        type: Boolean,
        default: true
      },
      "disabled": {
        type: Boolean,
        default: false
      },
      "notBefore": Date,
      "notAfter": Date,
      "initValue": Date
    },
    computed: {
      valueAsDate: {
        get() {
          return moment.utc(this.value).toDate()
        },
        set(val) {
          const newValue = moment(val).format("YYYY-MM-DD")
          this.$emit("input", newValue)
        },
      },
    },
    methods: {
      onInput(value) {
        // You can conditionally do this after validation
        const day = moment(value).startOf('day').format("YYYY-MM-DD");
        this.$emit('input', day);
      },
      disableSundays(date) {
        return moment(date).day() === 0;
      },
      isDateDisabled(date) {
        const isBefore = this.notBefore ? moment(date) < moment(this.notBefore) : false
        const isAfter = this.notAfter ? moment(date) > moment(this.notAfter) : false
        return isBefore || isAfter
      }
    }
  }
</script>
