var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"id":"transferred-table-list","loading":_vm.loading,"loading-text":_vm.$t('honda.registration.list.loading'),"headers":_vm.headers,"items":_vm.filteredTransfers,"items-per-page":10,"sort-by":['createdAt'],"sort-desc":[true],"single-select":"","footer-props":{
    showFirstLastPage: true,
    firstIcon: 'fas fa-angle-double-left',
    lastIcon: 'fas fa-angle-double-right',
    prevIcon: 'fas fa-angle-left',
    nextIcon: 'fas fa-angle-right',
    'items-per-page-text': _vm.$t('honda.registration.list.footer_num_x_page'),
    'items-per-page-options': [10, 25, 50, -1],
  },"no-data-text":_vm.$t(_vm.transfersList && _vm.transfersList.length > 0 ? 'honda.registration.transferred.no_data' : 'honda.registration.list.no_data', {search: _vm.search}),"no-results-text":_vm.$t('honda.registration.list.no_data'),"data-cy":"list-transferred-devices"},on:{"click:row":_vm.selectTransfer},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" "+_vm._s(_vm.$t('honda.registration.list.of'))+" "+_vm._s(props.itemsLength)+" ")]}},{key:"item.createdAt",fn:function(ref){
  var item = ref.item;
return [_c('v-icon',{staticClass:"icon-mapit",class:_vm.isSelected(item)  ? 'text-secondary' : ''},[_vm._v(" "+_vm._s(_vm.isSelected(item) ? 'O' : 'M'))])]}},{key:"item.vehicle.model",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-item"},[_vm._v(_vm._s(item.vehicle.model))])]}},{key:"item.vehicle.vin",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-item"},[_vm._v(_vm._s(item.vehicle.vin))])]}},{key:"item.vehicle.registrationNumber",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-item"},[_vm._v(_vm._s(item.vehicle.registrationNumber))])]}},{key:"item.device.imei",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-item"},[_vm._v(_vm._s(item.device.imei))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }