
































































import { defineComponent } from '@vue/composition-api'
import CardTrend from '@/components/metrics/CardTrend.vue'
import CardGauge from './CardGauge.vue'

export default defineComponent({
  name: 'StatsLeads',
  components: {
    CardTrend,
    CardGauge,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
})
