<template>
  <dealer-bikes-table @refreshList="refreshDealerBikeList" />
</template>

<script>
import DealerBikesTable from './DealerBikesTable.vue'

export default {
  name: 'DealerBikesList',
  components: {DealerBikesTable },
  data() {
    return {}
  },
  methods: {
    refreshDealerBikeList() {
      this.$store.dispatch('loadDealerBikeList')
    },
  },
  created() {
    this.refreshDealerBikeList()
  },
}
</script>
