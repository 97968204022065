<template>
  <div class="service-card">
    <div
      data-cy="service-card"
      @click="changeCheckValue"
      :class="[
        serviceClass, /** Basic class */
        isSelectable ? (checkValue ? 'selected' : '') : 'noSelectable', /** Status class (selected or unselected) */
        typeStyled ? typeStyled: '' /** Extra/Other class styles */
      ]"
    >
      <i
        v-if="isSelectable"
        aria-hidden="true"
        class="checkbox icon-mapit"
        :class="{ 'icon-blue': checkValue }"
      >{{ checkValue ? 'O' : 'M'}}</i>
    </div>
    <p v-if="isSelectable && error" id="error-lbl" class="color-red" v-html="error" />
    <p v-if="isSelectable && info" id="info-lbl" class="color-green" v-html="info" />
  </div>
</template>

<script>
export default {
  name: 'ServiceCheckbox',
  components: {},
  props: [
    'checkValue', // status value (true/false)
    'variant', // honda-plus, honda-plus-go, honda-mapit, honda-seguro-normal, honda-seguro-plus, honda-finance
    'error', // text for errors (red)
    'info', // extra text info or success messages (green)
    'disabled',
    'isSelectable', // Define if selectable component like checkbox or readonly component (and shows only service card box)
    'typeStyled', // custom styles
    'forcedSelected', // force state selected
    'hideInfoButton' // force hide (i) button to show info
  ],
  data() {
    return {}
  },
  computed: {
    serviceClass() {
      return !this.error && !this.isDisabled
        ? `service-box ${this.variant}`
        : `service-box-disabled ${this.variant}-disabled${this.getStyleForcedSelected}`
    },
    getStyleForcedSelected(){
      return this.forcedSelected ? '-forced-selected' : ''
    },
    isDisabled() {
      return this.disabled || this.forcedSelected
    }
  },
  methods: {
    changeCheckValue() {
      if(!this.isDisabled) this.$emit('changeCheckValue', this.variant)
    },
  },
}
</script>

<style lang="scss">
  #info-lbl, #error-lbl {
    font-family: 'ProximaNovaRegular' !important;
    font-size: 14px;
  }
  .fs-10 {
    font-size: 10px !important;
  }
  .fs-12 {
      font-size: 12px !important;
  }
</style>

<style lang="scss" scoped>
.service-card {
  .service-box {
    &:not(.selected){
      &:hover {
        border: 2px solid var(--v-primary-base);
        cursor: pointer;
      }
    }
  }
  .noSelectable {
    border: 2px solid var(--v-primary-base) !important;
    margin: 5px !important;
    user-select: none !important;
    cursor: default !important;
  }

  .service-box,
  .service-box-disabled {
    height: 170px;
    width: 170px;
    border: 2px solid var(--v-gray-lighten2);
    margin: auto;
    color: #333;
    border-radius: 6px;
    transform: perspective(1px) translateZ(0);

    &.reduced {
      height: 100px;
      width: 100px;

      &.honda-plus {
        background-size: 100px;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }
      &.honda-plus-go {
        background-size: 100px;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      &.honda-mapit {
        background-size: 100px;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      &.honda-seguro-normal {
        background-size: 100px;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      &.honda-seguro-plus {
        background-size: 100px;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      &.honda-finance {
        background-size: 100px;
        &.selected {
          background-size: 100px;
        }
        &-disabled {
          background-size: 100px;
          border: 2px solid var(--v-gray-lighten2) !important;
          pointer-events: none;
        }
      }

      i.fa {
        position: absolute;
        top: 2.5px;
        left: 2.5px;
        font-size: 0.8em;
      }
    }
    &-disabled {
      border: 2px solid var(--v-gray-lighten2) !important;
    }

    &.honda-plus {
      background: url(/images/services/HP.svg);
      background-size: 170px;
      &.selected, &-disabled.selected {
        background: url(/images/services/HP_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
      }
      &-disabled {
        background: url(/images/services/HP_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
        &-forced-selected {
          background: url(/images/services/HP_selected.svg);
          pointer-events: none;
          background-color: #ECF3FF !important;
        }
      }
    }
    &.honda-plus-go {
      background: url(/images/services/HPGo.svg);
      background-size: 170px;
      &.selected, &-disabled.selected {
        background: url(/images/services/HPGo_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
      }
      &-disabled {
        background: url(/images/services/HPGo_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
        &-forced-selected {
          background: url(/images/services/HPGo_selected.svg);
          pointer-events: none;
          background-color: var(--v-primary-base);
          opacity: 0.6;
        }
      }
    }

    &.honda-mapit {
      background: url(/images/services/HM.svg);
      background-size: 170px;
      &.selected, &-disabled.selected{
        background: url(/images/services/HM_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
      }
      &-disabled {
        background: url(/images/services/HM_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
        &-forced-selected {
          background: url(/images/services/HM_selected.svg);
          pointer-events: none;
          background-color: var(--v-primary-base);
          opacity: 0.6;
        }
      }
    }

    &.honda-seguro-normal {
      background: url(/images/services/HS.svg);
      background-size: 170px;
      &.selected, &-disabled.selected {
        background: url(/images/services/HS_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
      }
      &-disabled {
        background: url(/images/services/HS_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
        &-forced-selected {
          background: url(/images/services/HS_selected.svg);
          pointer-events: none;
          background-color: var(--v-primary-base);
          opacity: 0.6;
        }
      }
    }

    &.honda-seguro-plus {
      background: url(/images/services/HSA.svg);
      background-size: 170px;
      &.selected, &-disabled.selected {
        background: url(/images/services/HSA_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
      }
      &-disabled {
        background: url(/images/services/HSA_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
        &-forced-selected {
          background: url(/images/services/HSA_selected.svg);
          pointer-events: none;
          background-color: var(--v-primary-base);
          opacity: 0.6;
        }
      }
    }

    &.honda-finance {
      background: url(/images/services/HFS.svg);
      background-size: 170px;
      &.selected, &-disabled.selected {
        background: url(/images/services/HFS_selected.svg);
        background-size: 170px;
        background-color: var(--v-primary-base);
      }
      &-disabled {
        background: url(/images/services/HFS_disabled.svg);
        background-size: 170px;
        pointer-events: none;
        background-color: var(--v-gray-lighten1);
        border: 2px solid var(--v-gray-lighten2) !important;
        &-forced-selected {
          background: url(/images/services/HFS_selected.svg);
          pointer-events: none;
          background-color: var(--v-primary-base);
          opacity: 0.6;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: var(--v-primary-base);
      color: white;
    }

    &:not(.selected) {
      &:not(-disabled) {
        i.checkbox {
          color: var(--v-gray-lighten2);
        }
      }
    }
    i.checkbox {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 24px;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 60px;
      text-shadow: 2px 2px #aaaaaa;
    }
  }

  .icon-blue{
    color: #ffffff;
  }
}
</style>
