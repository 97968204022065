import { Status } from '@/types/offers'
import { isValidDate, isPastToday, addDaysToDate } from '@/utils/date-helper'

export const OFFER_EXPIRE_DAYS = 30

export function isExpiredOffer(offer: any) {
  if (offer.status !== Status.Accepted && offer.status !== Status.Pending) {
    return false
  }

  if (!isValidDate(offer.updatedAt)) {
    return false
  }

  // is expired after 30 days of the last update date
  const date = addDaysToDate(offer.updatedAt, OFFER_EXPIRE_DAYS)

  return isPastToday(date)
}
