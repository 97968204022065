<template>
  <th  class='b-l-1 p-l-20 p-r-20' v-if="isHeader">
    {{title}}
  </th>
  <td class='b-l-1 column-500' v-else>
    <div class="align-left " v-if="rowData">
      <div class="col-12 p-l-20 p-r-20">
          <button class='btn btn-honda-plus-light-mini m-r-5 tooltip' data-tooltip="Pasar revisión" @click="openModal"><i class="fas fa-wrench"></i></button>
          <button class='btn btn-honda-plus-light-mini m-r-5 tooltip' data-tooltip="Crear cita" @click="openCreateAppointmentModal"><i class="fas fa-calendar-plus"></i></button>
          <button class='btn btn-honda-plus-light-mini-delete tooltip' data-tooltip="Ignorar"  @click="openIgnoreModal"><i class="fas fa-times"></i></button>
      </div>
    </div>

  </td>
</template>

<script>
  import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

  export default {
    mixins: [VuetableFieldMixin],
    props: ["rowData"],
    methods: {
      openModal() {
        const {rowData: {inspection: {id}}} = this;
        const modalId = 'PASS';
        this.$emit('vuetable:field-event', {payload: {id, modalId}})
      },
      openCreateAppointmentModal() {
        const {rowData: {inspection: {id}}} = this;
        const modalId = 'CREATE_APPOINTMENT';
        this.$emit('vuetable:field-event', {payload: {id, modalId}})
      },
      openIgnoreModal() {
        const {rowData: {inspection: {id}}} = this;
        const modalId = 'IGNORE';
        this.$emit('vuetable:field-event', {payload: {id, modalId}})
      }
    },
    name: 'Revision'
  }
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>

