






























































import ResultInfoBox from '@/components/general/ResultInfoBox.vue'
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import {mapGetters} from '@/utils/map-store'
import {computed, defineComponent, ref, watch} from '@vue/composition-api'
import {ResultStatus} from '@/types'
import {DateTime, Duration, Interval} from 'luxon'
import {customFormatDateTimeOutput} from '@/utils/constants'
import { humanizeDuration, timeUnitsBetween } from '@/utils/date-helper'

interface InfoError {
  text: string,
  type: ResultStatus,
  params?: any
}

export default defineComponent({
  name: 'ReturnData',
  components: {ExpansionPanel, ResultInfoBox},
  props: {
    showValidations: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {root}) {
    const {
      getScheduledFor: scheduledFor,
      getDistance: distance,
      isLoadingDealerBikes: loading,
      getLocale: locale
    } = mapGetters(root.$store.getters)

    const dealerBikeLoanReturnDate = ref(DateTime.now().toFormat('yyyy-MM-dd'))
    const dealerBikeLoanReturnTime = ref(DateTime.now().toFormat('HH:mm'))
    const timeBetween = ref('')
    const error = ref<InfoError | undefined>(undefined)

    function checkReturnLoanDateStatus(): InfoError | undefined {
      if(!dealerBikeLoanReturnDate.value || !dealerBikeLoanReturnTime.value) return undefined
      const returnDate = DateTime.fromISO(`${dealerBikeLoanReturnDate.value}T${dealerBikeLoanReturnTime.value}:00.000`).toUTC()
      const scheduledForDate = DateTime.fromISO(scheduledFor.value, {zone: 'utc'})
      const nowDate = DateTime.now().toUTC().plus({ minutes: 10 })
      if(returnDate && (scheduledForDate > returnDate)){ // scheduled date is after return date
        timeBetween.value = ''
        return {
          type: ResultStatus.ERROR,
          text: 'dealer-bike.return.return-data.errors.min-time-required',
          params: {
            date: scheduledForDate.toLocal().toLocaleString(customFormatDateTimeOutput)
          }
        }
      }else if(returnDate && returnDate > nowDate){ // return date is after now (+10 mins)
        timeBetween.value = ''
        return {
          type: ResultStatus.ERROR,
          text: 'dealer-bike.return.return-data.errors.max-time-required',
          params: {
            date: scheduledForDate.toLocal().toLocaleString(customFormatDateTimeOutput)
          }
        }
      } else if(returnDate && Interval.fromDateTimes(scheduledForDate, nowDate).contains(returnDate)) { // return date is between now (+10 mins) and scheduled date
        const timeBetweenDates = timeUnitsBetween(scheduledForDate.toJSDate(), returnDate.toJSDate())
        timeBetween.value = root.$t('dealer-bike.return.return-data.loan-duration',{time: humanizeDuration(timeBetweenDates, locale.value)}).toString()
        if (returnDate.diff(scheduledForDate).as('milliseconds') >= Duration.fromObject({ hour: 2 }).as('milliseconds')) {
          return {
            type: ResultStatus.WARNING,
            text: 'dealer-bike.return.return-data.errors.suspicious-durations'
          }
        }
      }
      return undefined
    }
    const minDateScheduledFor = computed(() => {
      return DateTime.fromISO(scheduledFor.value, {zone: 'utc'}).toFormat('yyyy-MM-dd')
    })

    watch(dealerBikeLoanReturnDate, (value) => {
      root.$store.dispatch('setDealerBikeLoanReturnDate', value)
      error.value = checkReturnLoanDateStatus()
    }, { immediate: true })
    watch(dealerBikeLoanReturnTime, (value) => {
      root.$store.dispatch('setDealerBikeLoanReturnTime', value)
      error.value = checkReturnLoanDateStatus()
    })
    watch(scheduledFor, () => {
      error.value = checkReturnLoanDateStatus()
    })

    function isTimeFormatCorrect(value: string | undefined): boolean {
      return !!value && value.length === 5
    }

    return {
      ResultStatus,
      dealerBikeLoanReturnDate,
      dealerBikeLoanReturnTime,
      minDateScheduledFor,
      maxValidDate: DateTime.now().toFormat('yyyy-MM-dd'),
      distance,
      loading,
      locale,
      error,
      timeBetween,
      isTimeFormatCorrect
    }
  }
})
