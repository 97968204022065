import ContactsApi from '@/api/contacts.api'
import { showAlert } from '@/vuex/modules/ui'
import { isEmpty } from 'lodash'

const state = {
  isSearchedContact: false,
  loading: false
}

const getters = {
  isSearchedContact: state => state.isSearchedContact,
  isContactsLoading: state => state.loading
}

const actions = {
  loadContactByPhone: ({ commit, dispatch }, phone) => {
    commit('SET_LOADING', true)
    return ContactsApi.getContactByPhone(phone)
    .then(({ data: { data } }) => {
        const hasContact = !isEmpty(data)
        hasContact ?
          showAlert(dispatch, 'success', 'dealer-bike.loan.contact.lbl-search-found') :
          showAlert(dispatch, 'warning', 'dealer-bike.loan.contact.lbl-search-not-found')
        hasContact && commit('SET_DEALER_BIKE_LOAN_CONTACT', data[0])
        return
      })
      .catch(e => {
        showAlert(dispatch, 'error', 'dealer-bike.loan.contact.lbl-search-error')
        return e
      })
      .finally(() => {
        commit('SET_LOADING', false)
        commit('SET_SEARCHED_CONTACT', true)
      })
  },
  setSearchedContact: ({commit, isSearched}) => {
    commit('SET_SEARCHED_CONTACT', isSearched)
  }
}

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading
  },
  SET_SEARCHED_CONTACT: (state, isSearched) => {
    state.isSearchedContact = isSearched
  },

}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
