<template>
  <div>
    <v-overlay :value="importing">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <h3 class="pt-2">{{ $t('import.loading-import') }}</h3>
    </v-overlay>
    <file-reader
      @load="importData"
      @reading="readingFileEvent"
      :type="type"
      :loading="importing || loading"
      :label="label"
      :accept="accept"
      @btnAction="$emit('btnAction')"
    />
    <mapit-dialog :dialog="dialog.open" @close="dialog.open = !dialog.open">
      <template v-slot:header-icon>
        <v-icon class="text-orange" v-if="dialog.error">
          fa fa-exclamation-triangle
        </v-icon>
        <v-icon class="text-secondary" v-else>fa fa-check</v-icon>
      </template>
      <template v-slot:header-text>
        <div class="text-center">{{ dialog.title }}</div>
      </template>
      <template v-slot:body>
        <div v-html="dialog.body" class="text-center">
        </div>
      </template>
      <template v-slot:footer-center>
        <v-btn class="btn-primary" @click="dialog.open = !dialog.open">{{
          $t('buttons.next')
        }}</v-btn>
      </template>
    </mapit-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import FileReader from './FileReader'
import MapitDialog from '@/components/dialog/MapitDialog'

/**
 * The custom HTML `<custom-input-file />` component.
 *
 * @author Julià Soler
 * @license Mapit IoT S.L.
 */
export default {
  components: { FileReader, MapitDialog },
  props: {
    /**
     * Workbook name
     */
    sheet: { type: String, required: false },
    /**
     * Handler to upload document/file data
     */
    onImport: { type: Function, required: false },
    /**
     * Type of input button or file type buffer/text/png....
     */
    type: { type: String, required: false, default: 'buffer' },
    /**
     * Label for imput-file
     */
    label: { type: String, required: false, default: '' },
    /**
     * Handler to use button action if we use type === 'button'
     */
    btnAction: { type: Function, required: false },
    /**
     * Handler to use custom import action if we use type !== 'csv'
     */
    importAction: { type: Function, required: false },
    /**
     * Loading for custom actions
     */
    loading: { type: Boolean, required: false, default: false },
    accept: { type: String, required: false, default: '' },
  },
  data: () => ({
    /**
     * Loading import/upload file data
     */
    importing: false,
    reading: false,
    /**
     * Dialog options and strings
     */
    dialog: {
      open: false,
      error: false,
      title: '',
      body: '',
    },
  }),
  methods: {
    /**
     * Callback to know when is reading a file
     * @syntax readingFileEvent(reading: Boolean)
     * @param {Boolean} reading - Reading state
     */
    readingFileEvent(reading) {
      this.importing = reading
    },
    /**
     * Function to read and upload file to server or emit action to import
     * @syntax importData(data: Object)
     * @param {Object} data - data from file-input
     */
    importData(data) {
      if (this.type === 'csv') {
        return this.importCsv(data)
      } else {
        return this.$emit('importAction', data)
      }
    },
    /**
     * Function to read and upload CSV file to server
     * @syntax importCsv(data: Object)
     * @param {Object} data - CSV data from file-input
     *
     * ```typescript
     * //<template>
     *    <input type="file" @change="load" />
     *
     * //<script>
     * load(event) {
     *   const reader = new FileReader();
     *   reader.onload = e => importCsv(e.target.result)
     *   reader.readAsArrayBuffer(event.target.files[0])
     * }
     * ```
     */
    importCsv(data) {
      this.importing = true

      return this.$nextTick(() => {
        const workbook = XLSX.read(data, { type: 'array' })

        const sheet = workbook.Sheets[this.sheet]
        const csv = XLSX.utils.sheet_to_csv(sheet)

        if (csv.length === 0) {
          this.importing = false
          this.dialog = {
            open: true,
            error: true,
            title: this.$t('import.import-title'),
            body: this.$t('import.error-import-not-found'),
          }
        } else {
          this.onImport(csv)
            .then(() => {
              this.dialog = {
                open: true,
                error: false,
                title: this.$t('import.import-title'),
                body: this.$t('import.success-import-text'),
              }
            })
            .catch(() => {
              this.dialog = {
                open: true,
                error: true,
                title: this.$t('import.import-title'),
                body: this.$t('import.error-import-server'),
              }
            })
            .finally(() => {
              this.importing = false
            })
        }
      })
    },
  },
}
</script>
