<template>
  <expansion-panel
    uid="expansion-panel-mapit-data"
    :title="$t('dealer-bike.registration.mapit-data-title')"
    :readonly="false"
  >
    <template v-slot:panel-content>
      <m-form-row :label="$t('dealer-bike.registration.lbl-mapit-code')">
        <v-form v-model="validMapitCode">
          <available-device-lookup
            :disabled="isMigrationDealerBike"
            @is-loading="handleMapitLoading"
            data-cy="dealerBikeMapitCode"
            required
            use-ref-model
            v-model="device"
          />
        </v-form>
      </m-form-row>
    </template>
  </expansion-panel>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SET_DEALER_BIKE_MAPIT, SET_IS_VALID_DEALER_BIKE_MAPIT } from '@/vuex/mutation-types'
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import AvailableDeviceLookup from '@/components/general/AvailableDeviceLookup.vue'

export default {
  name: 'MapitData',
  components: { AvailableDeviceLookup, ExpansionPanel },
  data() {
    return {
      validMapitCode: false,
      device: null,
    }
  },
  watch: {
    validMapitCode(isValid) {
      this.commitMapitValid(isValid)
      this.$emit('validateForm')
    },
    device(val) {
      this.commitMapitData(val)
    },
    getDealerBikeMapit(val) {
      this.device = val
    },
  },
  methods: {
    ...mapMutations({
      commitMapitData: SET_DEALER_BIKE_MAPIT,
      commitMapitValid: SET_IS_VALID_DEALER_BIKE_MAPIT,
    }),
    ...mapActions({
      commitMapitLoading: 'setIsDealerBikeMapitLoading',
    }),
    handleMapitLoading(isLoading) {
      this.commitMapitLoading(isLoading)
    },
  },
  computed: {
    ...mapGetters(['getDealerBikeMapit', 'isMigrationDealerBike']),
  },
}
</script>
