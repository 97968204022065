<template>

	<div style="width: 100%;height: 100%;" class="routes-check">
		<google-map :name="name"></google-map>
	</div>

</template>

<script>

  import GoogleMap from '@/components/other/GoogleMap';

  export default {
    components:{
      GoogleMap
    },
    name: 'RoutesCheck',
    data() {
      return {
        name: "MAPA1"
      };
    },
    computed: {
    },
    mounted() {

    },
    methods: {

    }
  };
</script>