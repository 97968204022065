<template>
  <div>
    <v-stepper v-model="currentStep" alt-labels class="stepper-wrapper">
      <v-stepper-header class="header-stepper-wrapper">
        <v-divider class="first_divider_stepper" :style="dividerStyle(0)" />

        <v-stepper-step :complete="currentStep > 1" class="offer-step1" step="1">
          {{ $t('offer-manager.create.stepper.lbl-step-client-data') }}
        </v-stepper-step>

        <v-divider class="divider_stepper" :style="dividerStyle(1)" />
        <v-stepper-step :complete="currentStep > 2" class="offer-step2" step="2">
          {{ $t('offer-manager.create.stepper.lbl-step-vehicle-data') }}
        </v-stepper-step>

        <v-divider class="divider_stepper" :style="dividerStyle(2)" />
        <v-stepper-step :complete="currentStep > 3" class="offer-step3" step="3">
          {{ $t('offer-manager.create.stepper.lbl-step-accessories-and-services') }}
        </v-stepper-step>

        <v-divider class="divider_stepper" :style="dividerStyle(3)" />
        <v-stepper-step :complete="currentStep > 4" class="offer-step4" step="4">
          {{ $t('offer-manager.create.stepper.lbl-step-total-price') }}
        </v-stepper-step>

        <v-divider class="divider_stepper" :style="dividerStyle(4)" />
        <v-stepper-step :complete="currentStep > 5" class="offer-step5" step="5">
          {{ $t('offer-manager.create.stepper.lbl-step-resume-offer') }}
        </v-stepper-step>

        <v-divider class="last_divider_stepper" :style="dividerStyle(0)" />
      </v-stepper-header>
    </v-stepper>

    <div class="mx-0 my-0 mb-8">
      <v-expansion-panels v-model="panels" multiple class="px-12 py-4" :readonly="true">
        <div
          :class="`py-8 px-10 items-stepper-wrapper full-width ${
            currentStep !== 1 ? 'd-none' : ''
          }`"
        >
          <v-form
            id="mapit-input-form"
            v-model="validForms.step1"
            ref="InputForm1"
            v-focusNextOnEnter
          >
            <offer-data
              :panelReadonly="true"
              :disableAttach="true"
              :disabled="disabled"
            />

            <customer-data :panelReadonly="true" :panelHideDivider="true" :disabled="disabled" />
          </v-form>
        </div>

        <div
          :class="`py-8 px-10 items-stepper-wrapper full-width ${
            currentStep !== 2 ? 'd-none' : ''
          }`"
        >
          <v-form id="mapit-input-form" ref="InputForm2" v-focusNextOnEnter>
            <motorbike-data
              :disabled="disabled"
              :hideDetails="hideDetails"
              :panelHideDivider="true"
              :panelReadonly="true"
              @validateForm="validateForm"
            />
            <campaign
              :disabled="disabled"
              :hide-details="hideDetails"
              :panel-readonly="true"
              @validate-form="validateForm"
            />
          </v-form>
        </div>

        <div
          :class="`py-8 px-10 items-stepper-wrapper full-width ${
            currentStep !== 3 ? 'd-none' : ''
          }`"
        >
          <v-form id="mapit-input-form" ref="InputForm3" v-focusNextOnEnter>
            <vehicle-registration
              :disabled="disabled"
              :hideDetails="hideDetails"
              :panelHideDivider="true"
              @validateForm="validateForm"
            />
            <accessories
              :disabled="disabled"
              :hideDetails="hideDetails"
              @validateForm="validateForm"
            />
            <services
              :disabled="disabled"
              :hideDetails="hideDetails"
              :safePaddingBottom="true"
              @validateForm="validateForm"
            />
          </v-form>
        </div>

        <div
          :class="`py-8 px-10 items-stepper-wrapper full-width ${
            currentStep !== 4 ? 'd-none' : ''
          }`"
        >
          <v-form id="mapit-input-form" ref="InputForm4" v-focusNextOnEnter>
            <delivered-vehicle
              :disabled="disabled"
              :hideDetails="hideDetails"
              :panelHideDivider="true"
              @validateForm="validateForm"
            />
            <total-price-data
              :disabled="disabled"
              :hideDetails="hideDetails"
              :panelReadonly="true"
              @validateForm="validateForm"
            />
            <financial-services-section
              v-if="availableFinancialServicesSection"
              :disabled="loading || disabled"
              :hide-details="hideDetails"
              :loading="loading"
            />
            <customer-notes
              :disabled="disabled"
              :hideDetails="hideDetails"
              @validateForm="validateForm"
            />
            <status-data
              :disabled="disabled"
              :hideDetails="hideDetails"
              @validateForm="validateForm"
            />
          </v-form>
        </div>

        <div
          :class="`py-8 px-10 items-stepper-wrapper full-width ${
            currentStep !== 5 ? 'd-none' : ''
          }`"
        >
          <v-form id="mapit-input-form" ref="InputForm5" v-focusNextOnEnter v-if="resumeOffer">
            <resume-offer :resumeOffer="resumeOffer" :loading="resumeLoading">
              <template v-slot:extra-content>
                <v-expansion-panels v-model="panelsResume" multiple flat>
                  <dealer-notes
                    :offerId="resumeOffer.id"
                    :panelHideDivider="true"
                    @setLoading="setLoading"
                    fromPage="OffersForm"
                  />
                </v-expansion-panels>
              </template>
            </resume-offer>
          </v-form>
        </div>
      </v-expansion-panels>
    </div>

    <v-row class="px-2 py-4" no-gutters>
      <v-col class="col-6">
        <v-btn
          v-if="currentStep < lastStep"
          @click="backStep"
          class="btn-gray"
          data-cy="btn-back"
          tabindex="-1"
        >
          {{ $t('buttons.back') }}
        </v-btn>
      </v-col>

      <v-col class="text-right col-6">
        <v-btn
          :disabled="!validFormInStep || !validStep || disabled"
          :loading="loading || isOfferCreating"
          @click="createEditOffer"
          class="btn-primary"
          data-cy="btn-next"
        >
          {{
            currentStep === lastStep - 1
              ? $t('buttons.create-offer')
              : currentStep === lastStep
              ? $t('buttons.finish')
              : $t('buttons.next')
          }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Accessories from '../sections/Accessories.vue'
import Campaign from '@/components/offer-manager/sections/Campaign.vue'
import CustomerData from '../sections/CustomerData.vue'
import CustomerNotes from '../sections/CustomerNotes.vue'
import DealerNotes from '../sections/DealerNotes.vue'
import DeliveredVehicle from '../sections/DeliveredVehicle.vue'
import FinancialServicesSection from '@/components/offer-manager/sections/FinancialServicesSection'
import MotorbikeData from '../sections/MotorbikeData.vue'
import OfferData from '../sections/OfferData.vue'
import ResumeOffer from '../sections/ResumeOffer.vue'
import Services from '../sections/Services.vue'
import StatusData from '../sections/StatusData.vue'
import TotalPriceData from '../sections/TotalPriceData.vue'
import VehicleRegistration from '../sections/VehicleRegistration.vue'

export default {
  name: 'StepperOfferForm',
  components: {
    Accessories,
    Campaign,
    CustomerData,
    CustomerNotes,
    DealerNotes,
    DeliveredVehicle,
    FinancialServicesSection,
    MotorbikeData,
    OfferData,
    ResumeOffer,
    Services,
    StatusData,
    TotalPriceData,
    VehicleRegistration,
  },
  props: ['loading', 'resumeOffer', 'disabled', 'isOfferCreating'],
  data() {
    return {
      panels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // by default all panels are open
      panelsResume: [0, 1],
      lastStep: 5,
      currentStep: 1,
      validationLoading: false,
      hideDetails: true,
      validStep: false,
      resumeLoading: false,
      validForms: {
        step1: false,
      },
    }
  },
  inject: ['leadId'],
  computed: {
    ...mapGetters({
      group: 'getGroup',
      country: 'getCountry',
      capabilities: 'getCapabilities',
    }),
    availableFinancialServicesSection() {
      return (
        this.capabilities?.finance?.supported ?? this.$preferences(this.country).finance.supported
      )
    },
    validFormInStep() {
      if (this.currentStep === 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.validStep = true
        return this.validForms.step1
      }
      return true
    },
  },
  methods: {
    setLoading(loading) {
      this.resumeLoading = loading
    },
    validateForm(hideDetails) {
      if (this.currentStep === 1) {
        return this.validFormInStep
      }
      this.$nextTick(() => {
        if (this.$refs[`InputForm${this.currentStep}`]) {
          this.validStep = this.$refs[`InputForm${this.currentStep}`].validate()
        } else {
          this.validStep = false
        }
        this.hideDetails = !!hideDetails
      })
    },
    dividerStyle(step) {
      return this.currentStep > step
        ? 'border: 1px solid var(--v-primary-base) !important; background-color:var(--v-primary-base);'
        : 'border: 1px solid var(--v-white-base) !important;'
    },
    backStep() {
      if (this.currentStep > 1) {
        this.currentStep--
        this.validateForm(true)
        this.scrollUp()
      } else {
        this.$router.back()
      }
    },
    sendGAEvent(action, label) {
      this.$gtm.trackEvent({ category: 'Offers', action, label })
    },
    createEditOffer() {
      this.validationLoading = true
      this.hideDetails = false

      if (!this.$refs[`InputForm${this.currentStep}`].validate()) {
        this.validationLoading = false

        for (const key of Object.keys(this.$refs[`InputForm${this.currentStep}`].inputs).sort()) {
          if (!this.$refs[`InputForm${this.currentStep}`].inputs[key].valid) {
            this.scrollUp()
            return
          }
        }
      } else {
        this.validationLoading = false
        this.hideDetails = true

        if (this.currentStep < this.lastStep) {
          this.sendGAEvent(
            { stepInit: this.currentStep, stepEnd: this.currentStep + 1 },
            'Continuar',
          )
          this.scrollUp()
          if (this.currentStep === this.lastStep - 1) {
            this.$emit('createOffer', false, () => {
              this.currentStep++
            })
          } else {
            this.currentStep++
            this.validateForm(true)
          }
        } else {
          this.sendGAEvent(this.currentStep, 'Finalizar')
          if (this.leadId) {
            this.$router.push({ name: 'Leads' })
          } else {
            this.$router.push({ name: 'Offers' })
          }
        }
      }
      this.$gtm.trackEvent({
        category: 'Offers',
        action: 'OfferNavigation',
        label:
          this.currentStep === this.lastStep ? this.$t('buttons.finish') : this.$t('buttons.next'),
      })
    },
    scrollUp() {
      window.document.getElementById('offerFormTitle').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.hideDetails = true
    })
  },
}
</script>

<style scoped>
.v-expansion-panel:before,
.v-expansion-panel:after {
  box-shadow: none;
  border: 0;
}
.stepper-wrapper {
  box-shadow: none;
  border: 0;
}
.header-stepper-wrapper {
  box-shadow: none;
  border-top: 1px solid var(--v-gray-base);
}
.items-stepper-wrapper {
  box-shadow: 3px 3px 18px #00000029;
  border: 1px solid #bbbbbb;
  border-radius: 12px;
}
.divider_stepper {
  margin: 50px -80px !important;
}
.first_divider_stepper {
  margin: 50px -60px 0px 2% !important;
}
.last_divider_stepper {
  margin: 50px -30px !important;
  visibility: hidden;
}
</style>
