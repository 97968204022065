<template>
  <v-form id="mapit-input-form" v-model="validForm" ref="InputForm" v-focusNextOnEnter>
    <v-card elevation="5" class="mx-0 mx-md-12 px-0 px-md-6 py-6">
      <v-expansion-panels v-model="decollapsedPanels" multiple flat>
        <offer-data
          :disabled="isFinished || isAcceptedOrRejected || disabled"
          :hideDetails="hideDetails"
          @validateForm="validateForm"
        />

        <customer-data
          :disabled="isFinished || isAcceptedOrRejected || disabled"
          :focus="focus"
          @validateForm="validateForm"
        />

        <motorbike-data
          :disabled="isFinished || isAcceptedOrRejected || disabled"
          :hideDetails="hideDetails"
          @validateForm="validateForm"
        />

        <campaign
          :disabled="isFinished || isAcceptedOrRejected || loading || disabled"
          :hide-details="hideDetails"
          @validateForm="validateForm"
        />

        <vehicle-registration
          :disabled="isFinished || isAcceptedOrRejected || disabled"
          :hideDetails="hideDetails"
          @validateForm="validateForm"
        />

        <accessories
          :disabled="isFinished || disabled"
          :hideDetails="hideDetails"
          @validateForm="validateForm"
        />

        <services
          :disabled="isFinished || disabled"
          :hideDetails="hideDetails"
          @validateForm="validateForm"
        />

        <delivered-vehicle
          :disabled="isFinished || isAcceptedOrRejected || disabled"
          :hideDetails="hideDetails"
          @validateForm="validateForm"
        />

        <total-price-data
          :disabled="isFinished || isAcceptedOrRejected || disabled"
          :hideDetails="hideDetails"
          @validateForm="validateForm"
        />

        <financial-services-section
          v-if="availableFinancialServicesSection"
          :disabled="isFinished || loading || disabled"
          :hide-details="hideDetails"
          :loading="loading"
        />

        <customer-notes :hideDetails="hideDetails" @validateForm="validateForm" />

        <status-data
          :force-status="offer.status === 'pending' && containsModifications ? 'pending' : null"
          :hideDetails="hideDetails"
          @validateForm="validateForm"
        />
      </v-expansion-panels>
    </v-card>

    <v-card-actions class="px-6 py-10">
      <v-btn
        v-if="isNewOffer"
        class="btn-gray"
        data-cy="offer-manager-create-motorbike-button-back"
        @click="handleBackClick"
      >
        {{ $t('buttons.back') }}
      </v-btn>
      <div class="my-spacer"></div>

      <v-btn
        :disabled="loading || !validForm || disabled"
        :loading="isOfferCreating"
        @click="createEditOffer"
        class="btn-primary"
        data-cy="offer-manager-create-motorbike-button-continue"
      >
        {{
          isNewOffer
            ? $t('buttons.create-offer')
            : containsModifications
            ? $t('buttons.update-offer')
            : $t('buttons.close')
        }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { Status } from '@mapit/honda-offers-builder/dist/types'

import Accessories from '../sections/Accessories.vue'
import Campaign from '@/components/offer-manager/sections/Campaign.vue'
import CustomerData from '../sections/CustomerData.vue'
import CustomerNotes from '../sections/CustomerNotes.vue'
import DeliveredVehicle from '../sections/DeliveredVehicle.vue'
import FinancialServicesSection from '@/components/offer-manager/sections/FinancialServicesSection'
import MotorbikeData from '../sections/MotorbikeData.vue'
import OfferData from '../sections/OfferData.vue'
import Services from '../sections/Services.vue'
import StatusData from '../sections/StatusData.vue'
import TotalPriceData from '../sections/TotalPriceData.vue'
import VehicleRegistration from '../sections/VehicleRegistration.vue'

export default {
  name: 'SimpleOfferForm',
  components: {
    Accessories,
    Campaign,
    CustomerData,
    CustomerNotes,
    DeliveredVehicle,
    FinancialServicesSection,
    MotorbikeData,
    OfferData,
    Services,
    StatusData,
    TotalPriceData,
    VehicleRegistration,
  },
  props: ['id', 'loading', 'disabled', 'isOfferCreating', 'focus'],
  data() {
    return {
      hideDetails: true,
      validationLoading: false,
      validForm: false,
      originalOffer: null,
      containsModifications: false,
    }
  },
  methods: {
    validateForm() {
      if (this.$refs.InputForm) this.$refs.InputForm.validate()
    },
    createEditOffer() {
      this.validationLoading = true

      if (this.$refs.InputForm.validate()) {
        this.validationLoading = false
        this.$emit('createOffer', true)
      } else {
        this.hideDetails = false

        try {
          for (const key of Object.keys(this.$refs.InputForm.inputs).sort()) {
            if (!this.$refs.InputForm.inputs[key].valid && this.$refs.InputForm.inputs[key].$el) {
              this.scrollUp()
              this.validationLoading = false
              return
            }
          }
        } catch (err) {
          console.error(err)
        }
        this.validationLoading = false
      }
    },
    scrollUp() {
      window.document.getElementById('offerFormTitle').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    },
    handleBackClick() {
      this.$store.dispatch('clearOfferContact')
      this.$router.push({ name: 'InitOffer' })
    },
  },
  created() {
    this.$store.dispatch('setStatus', Status.Accepted)
  },
  mounted() {
    this.$store.dispatch('setClientCountry', this.country)
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
      initialStatus: 'getInitialStatus',
      capabilities: 'getCapabilities',
      offer: 'getOffer',
      offerServices: 'getServicesProducts',
      dealerId: 'getDealerId',
    }),
    isAcceptedOrRejected() {
      return (
        this.$route.params.id &&
        (this.initialStatus === Status.Accepted || this.initialStatus === Status.Refused)
      )
    },
    isFinished() {
      return this.$route.params.id && this.initialStatus === Status.Completed
    },
    availableFinancialServicesSection() {
      return (
        this.capabilities?.finance?.supported ?? this.$preferences(this.country).finance.supported
      )
    },
    decollapsedPanels: {
      get() {
        return this.$store.getters.getDecollapsedPanels
      },
      set(panels) {
        this.$store.dispatch('setDecollapsedPanels', panels)
      },
    },
    isNewOffer() {
      return !this.$route.params.id
    },
  },
  watch: {
    offer: {
      handler(offerBuilder) {
        if (!this.offer.id) {
          return
        }

        const offer = offerBuilder.toOffer()

        if (!this.originalOffer) {
          this.originalOffer = _.cloneDeep(offer)
          return
        }

        if (!_.isEqual(offer, this.originalOffer)) {
          const objectDiff = (a, b) =>
            _.fromPairs(_.differenceWith(_.toPairs(a), _.toPairs(b), _.isEqual))

          const diff = objectDiff(offer, this.originalOffer)
          console.log('Offer diff', JSON.stringify(diff, null, 2))

          if (this.initialStatus === Status.Accepted || this.initialStatus === Status.Refused) {
            if (
              (this.initialStatus === Status.Accepted && offer.status === Status.Refused) ||
              (this.initialStatus === Status.Refused && offer.status === Status.Accepted)
            ) {
              this.containsModifications = true
              return
            }

            // an offer that has not been modified still contains of changes due to the nature of how it is loaded,
            // so we don't take the packs diff into account in that case
            const emptyPacks = _.isEmpty(diff.packs?.packs)
            if (emptyPacks) {
              delete diff.packs
            }

            //ignore the attendedBy and annotation
            delete diff.attendedBy
            delete diff.annotation

            if (_.isEqual(diff, { status: Status.Pending })) {
              // only change is the pending status
              this.containsModifications = false
              this.$store.dispatch('setStatus', this.initialStatus)
              this.$store.dispatch('setProbabilityOfSale', this.originalOffer.probabilityOfSale)
            } else if (Object.keys(diff)?.length > 0) {
              // other changes
              this.containsModifications = true
              this.$store.dispatch('setStatus', Status.Pending)
              this.$store.dispatch('setProbabilityOfSale', this.originalOffer.probabilityOfSale)
            }
          } else if (this.initialStatus === Status.Pending && offer.status !== Status.Pending) {
            this.containsModifications = true
            this.$store.dispatch('setProbabilityOfSale', this.originalOffer.probabilityOfSale)
          } else {
            this.containsModifications = false
          }
        }
      },
      deep: true,
    },
  },
}
</script>
