<template>
  <div v-if="contact">
    <m-form-row :label="$t('contact.form.lbl-name') + `${firstNameRequired ? '*' : ''}`">
      <m-text-field
        v-model="contact.firstName"
        :placeholder="$t('contact.form.lbl-name-placeholder')"
        :rules="firstNameRules"
        maxlength="50"
        :autofocus="contact && !contact.firstName && firstNameRequired"
        data-cy="first-name"
        :disabled="disabled"
      />
    </m-form-row>

    <m-form-row :label="$t('contact.form.lbl-lastname') + `${lastNameRequired ? '*' : ''}`">
      <m-text-field
        v-model="contact.lastName"
        :placeholder="$t('contact.form.lbl-lastname-placeholder')"
        :rules="lastNameRules"
        maxlength="50"
        data-cy="last-name"
        :disabled="disabled"
      />
    </m-form-row>

    <m-form-row :label="$t('contact.form.lbl-email') + `${emailRequired ? '*' : ''}`">
      <m-text-field
        v-model="contact.email"
        :errorMessages="errorMessageInvalidEmail"
        :placeholder="$t('contact.form.lbl-email-placeholder')"
        :rules="emailRules"
        maxlength="50"
        data-cy="email"
        :disabled="disabled"
        :autofocus="focus === 'email'"
      />
    </m-form-row>

    <m-form-row :label="$t('contact.form.lbl-phone')  + `${phoneNumberRequired ? '*' : ''}`">
      <m-phone-field
        v-model="contact.phone"
        :placeholder="$t('contact.form.lbl-phone-placeholder')"
        :maxlength="20"
        :readonly="phoneReadonly"
        :tabindex="phoneReadonly ? -1 : undefined"
        :required="phoneNumberRequired"
        :error-invalid-phone="$t('honda.validation.phone')"
        :country="country"
        :disabled="disabled"
      />
    </m-form-row>

    <m-form-row :label="$t('contact.form.lbl-province')">
      <m-region-select
        data-cy="contactRegion"
        :placeholder="$t('contact.form.lbl-province-placeholder')"
        v-model="selectedRegion"
        required
        :rules="[
          r => isRequired(r),
          r => isRegionValid(r)
        ]"
        :country="contact.address.addressCountry"
        :level="2"
        :disabled="disabled"
      />
    </m-form-row>

    <m-form-row :label="$t('contact.form.lbl-country')">
      <m-select
        v-model="contact.address.addressCountry"
        :items="countries"
        item-text="label"
        item-value="code"
        readonly
        :disabled="disabled"
      />
    </m-form-row>

    <m-form-row :label="$t('contact.form.lbl-privacity') + `${requiredTrueCheck ? '*' : ''}`">
      <mapit-input
        uid="contactConsentAgreement"
        v-model="contact.gdprConsent"
        :type="InputTypes.CHECKBOX"
        :rules="trueCheckRules"
        :disabled="disabled"
      >
        <template v-slot:label>
          <span class="text-small">{{ $t('contact.form.lbl-consent-checkbox') }}</span>
        </template>
      </mapit-input>

      <mapit-input
        uid="contactPrivacyAgreement"
        v-model="contact.privacyTermsAccepted"
        :type="InputTypes.CHECKBOX"
        :rules="trueCheckRules"
        :disabled="disabled"
      >
        <template v-slot:label>
            <span class="text-small">
            {{ $t('contact.form.lbl-privacity-checkbox') }}&nbsp;
            <a
              :href="privacyPolicyUrl"
              @click.stop
              target="_blank"
              tabindex="-1"
            >{{ $t('contact.form.lbl-privacity') }}</a>
            </span>
        </template>
      </mapit-input>
    </m-form-row>
  </div>
</template>

<script>
import MapitInput from '@/components/general/MapitInput.vue'
import {InputTypes} from '@/utils/constants'
import {isEmail, isEmailOptional, isEmpty, isPhone, isRequired, isRequiredTrueCheck} from '@/helpers/validations'
import {toCapitalize} from "@/helpers/utils"
import {Country} from '@mapit/common-utils/dist/types'
import {findSecondLevelRegionByName} from "@/utils/administrative-region-helper";
import {mapGetters} from "vuex";

export default {
  name: 'ContactForm',
  components: {MapitInput},
  props: {
    value: Object,
    showValidations: Boolean,
    firstNameRequired: Boolean,
    lastNameRequired: Boolean,
    emailRequired: Boolean,
    phoneNumberRequired: Boolean,
    phoneReadonly: Boolean,
    countryDisabled: Boolean,
    requiredTrueCheck: Boolean,
    country: {
      type: String,
      default: Country.Spain
    },
    invalidEmails: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: String,
      default: null,
      required: false
    },
  },
  data() {
    return {
      InputTypes,
      isRequired,
      isEmpty,
      toCapitalize,
      requiredRules: [isRequired, isEmpty],
      contact: null,
      selectedRegion: this.value?.address?.addressRegion ? findSecondLevelRegionByName(this.value.address.addressRegion, this.value.address.addressCountry) : undefined,
      countries: [
        {code: Country.Spain, label: this.$t(`countries.${Country.Spain}`)},
        {code: Country.Portugal, label: this.$t(`countries.${Country.Portugal}`)},
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          if (val == null) {
            this.contact = null
            this.selectedRegion = undefined
          } else {
            this.contact = JSON.parse(JSON.stringify(val))
          }
        }
        if (this.contact && !this.contact.address?.addressCountry && this.country) {
          this.contact.address = {...this.contact.address, addressCountry: this.country}
        }
        if (this.contact?.address?.addressRegion) {
          this.selectedRegion = findSecondLevelRegionByName(this.contact.address.addressRegion, this.contact.address.addressCountry)
        }
      },
      deep: true
    },
    contact: {
      handler(contact) {
        if (JSON.stringify(contact) !== JSON.stringify(this.value)) {
          this.$emit('input', contact)
        }
      },
      deep: true
    },
    selectedRegion: {
      handler(region) {
        if (region && region.name) {
          this.contact.address.addressRegion = region.name
          this.contact.address.addressCountry = this.country
          this.$store.dispatch('setClientProvince', region.name)
          this.$store.dispatch('setClientCountry', this.country)
        }
      },
      deep: true
    },
    'contact.firstName'(val) {
      if (this.contact) {
        this.contact.firstName = toCapitalize(val)
      }
    },
    'contact.lastName'(val) {
      if (this.contact) {
        this.contact.lastName = toCapitalize(val)
      }
    },
    'contact.email'(val) {
      if (this.contact) {
        this.contact.email = val?.toLowerCase()?.trim()
        this.$emit('validateForm')
      }
    },
    'contact.privacyTermsAccepted'(val, oldVal) {
      if (val !== oldVal && val != null) {
        this.$store.dispatch('setPrivacyAgreement', val)
        this.contact.privacyTermsAccepted = val
      }
    },
    'contact.gdprConsent'(val, oldVal) {
      if (val !== oldVal && val != null) {
        this.$store.dispatch('setConsentAgreement', val)
        this.contact.gdprConsent = val
      }
    }
  },
  computed: {
    ...mapGetters({
      branch: 'getBranch',
      capabilities: 'getCapabilities'
    }),
    errorMessageInvalidEmail() {
      return this.invalidEmails.find(email => email === this.contact.email)
        ? this.$t('contact.form.invalid-email')
        : ''
    },
    privacyPolicyUrl() {
      return this.capabilities?.externalLinks?.privacyPolicyUrl ?? this.$preferences(this.branch).externalLinks.privacyPolicyUrl
    },
    firstNameRules() {
      return this.firstNameRequired ? [isRequired, isEmpty] : []
    },
    lastNameRules() {
      return this.lastNameRequired ? [isRequired, isEmpty] : []
    },
    emailRules() {
      return this.emailRequired ? [isRequired, (value) => isEmail(value?.trim())] : [(value) => isEmailOptional(value?.trim())]
    },
    phoneRules() {
      return this.phoneNumberRequired ? [isRequired, isPhone] : [isPhone]
    },
    trueCheckRules() {
      return this.requiredTrueCheck ? [isRequiredTrueCheck] : []
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.contact) {
        this.$store.dispatch('setPrivacyAgreement', this.contact.privacyTermsAccepted)
        this.$store.dispatch('setConsentAgreement', this.contact.gdprConsent)
      }
    })
  },
  methods: {
    validateForm() {
      this.$emit('validateForm')
    },
    isRegionValid(val) {
      return val && isEmpty(val.name)
    },
    isEmptyEmail(val) {
      const isEmpty = !this.contact.email
      return isEmpty || isRequiredTrueCheck(val)
    },
  }
}
</script>

<style>
#mapit-input .v-input--is-focused .v-input--selection-controls__input {
  border: 1px solid #0089DE !important;
}
.text-small {
  font-size: 14px;
}
</style>
