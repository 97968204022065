<template>
    <div>
        <div class="align-center">
            <img src="/images/orderDevices.png" height="200" class="mt-6"/>
            <div class="description pb-6">{{ $t('stocks-manager.create.lbl-description') }}</div>
        </div>
        <v-form id="mapit-input-form" ref="InputForm" class="create-form" v-focusNextOnEnter>
            <m-form-row :label="$t('stocks-manager.create.lbl-ordered-amount')">
                <mapit-input
                    uid="orderedAmount"
                    :type="InputTypes.SELECT"
                    :value="amount"
                    :rules="[]"
                    :placeholder="$t('stocks-manager.create.lbl-ordered-amount-placeholder')"
                    :listItems="Array.from({length: 20}, (_, i) => (++i)*15)"
                    @changeValue="(value) => amount = value"
                    :hideDetails="true"
                    :disableAttach="true"
                />
            </m-form-row>
            <m-form-row :label="$t('stocks-manager.create.lbl-ordered-by')">
                <mapit-input
                    uid="orderedBy"
                    :type="InputTypes.TEXT_FIELD_WITH_SUGGESTIONS"
                    :value="name"
                    :rules="[]"
                    :placeholder="$t('stocks-manager.create.lbl-ordered-by-placeholder')"
                    :listItems="orderBySuggestions"
                    @changeValue="(value) => name = value"
                    :maxlength="100"
                    :hideDetails="true"
                    :disableAttach="true"
                />
            </m-form-row>

            <div class="align-center">
                <v-btn class="btn-primary mt-10 mb-16" @click="handlerDialogConfirm" :loading="loading" :disabled="loading || !name || !amount">
                    {{ $t('buttons.create-order') }}
                </v-btn>
            </div>
        </v-form>
        <dialog-result
            :dialog="dialogConfirmCreateOrder"
            :status="ResultStatus.SUCCESS"
            :textTitle="$t('stocks-manager.create.lbl-popup-confirm-title')"
            :textBody="$t('stocks-manager.create.lbl-popup-confirm-body')"
            :textConfirmButton="$t('buttons.confirm')"
            @nextHandler="createOrder"
            @closeHandler="handlerDialogConfirm"
            :cancellable="true"
            :loading="loading"
        />
        <dialog-result
            :dialog="dialogError"
            :status="ResultStatus.ERROR"
            :textTitle="$t('stocks-manager.create.lbl-popup-error-title')"
            :textBody="$t('stocks-manager.create.lbl-popup-error-body')"
            @nextHandler="handlerDialogError"
            @closeHandler="handlerDialogError"
        />
    </div>
</template>

<script>
import MapitInput from '@/components/general/MapitInput.vue'
import { InputTypes } from '@/utils/constants'
import {ResultStatus} from '@/types'
import DialogResult from '../../dialog/DialogResult.vue'
import apiStocks, { createOrderData } from '@/api/stocks.api'

export default {
    name: 'CreateOrder',
    components: { MapitInput, DialogResult },
    props: {},
    data() {
        return {
            InputTypes,
            ResultStatus,
            amount: 15,
            name: '',
            dialogConfirmCreateOrder: false,
            dialogError: false,
            loading: false
        }
    },
    methods:{
        createOrder() {
            this.loading = true;
            apiStocks.createOrder(createOrderData(this.group.dealerId, this.name, this.amount))
                .then(() => {
                    this.$emit('goTab', 'tab-2')
                    this.$store.dispatch('addOrderBySuggestion', this.name)
                })
                .catch(err => this.handlerDialogError())
                .finally(()=> {
                    this.handlerDialogConfirm()
                    this.resetState()
                })
        },
        handlerDialogConfirm() {
            this.dialogConfirmCreateOrder = !this.dialogConfirmCreateOrder
        },
        handlerDialogError() {
            this.dialogError = !this.dialogError
        },
        resetState() {
            this.amount = 15
            this.name = ''
            this.dialogConfirmCreateOrder = false
            this.dialogError = false
            this.loading = false
        }
    },
    computed:{
        orderBySuggestions() {
            return this.$store.getters.getOrderBySuggestions
        },
        group() {
            return this.$store.getters.getGroup
        },
    }
}
</script>

<style lang="scss" src="../stocks-manager-styles.scss" />
