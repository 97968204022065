<template>
  <th v-if="isHeader">
      {{title}}
  </th>
  <td class="align-left column-500" v-else>
		<div  v-if="rowData">
			<div class="col-12 p-l-15 p-r-15">
				<span class="f-s-14">
          <b>{{getTime(rowData.request)}}</b>
        </span><br/>
			</div>
		</div>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
      rowData: {
        type: Object,
        default: () => {
          return { request: { appointment: { hour: '-'} } };
        }
      }
  },
  methods:{
    getTime : (request) => {
      return  (request && request.appointment)? request.appointment.hour:'';
    }
  },
  name: 'Time'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
