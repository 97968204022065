<template>
  <div class="text-center grey--text">Ver. {{ semver }}</div>
</template>

<script>
export default {
  name: 'Version',
  computed: {
    semver() {
      return process.env.VUE_APP_GIT_SEMVER
    },
  },
}
</script>
