interface Address {
  addressLocality?: string
  addressRegion?: string
  postalCode?: string
  streetAddress?: string
}

export function buildAddress(addressObj: Address): string {
  const {
    addressLocality = '',
    addressRegion = '',
    postalCode = '',
    streetAddress = '',
  } = addressObj || {}

  let address = ''

  if (streetAddress) {
    address = streetAddress
  }

  if (postalCode) {
    address = address ? `${address}, ${postalCode}` : postalCode
  }

  if (addressLocality) {
    address = address ? `${address}, ${addressLocality}` : addressLocality
  }

  if (addressLocality) {
    address = address ? `${address}, ${addressRegion}` : addressRegion
  }

  return address.trim() || '-'
}
