
































































































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'

import { isEmpty, isMaxText, isMinText, isRequired } from '@/helpers/validations'
import { useContact } from '@/composables/contact'
import router from '@/router'

import CustomInputFile from '@/components/import/CustomInputFile.vue'
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import ToolbarCard from '@/components/registrations/HondaRegistration/ToolbarCard.vue'

export default defineComponent({
  methods: { isMaxText, isMinText },
  components: {
    CustomInputFile,
    MapitDialog,
    ToolbarCard,
  },
  setup(props, { root }) {
    const refreshKey = ref(0)

    const formEmail = ref('')
    const formSubject = ref('')
    const formMessage = ref('')
    const formAttachment = ref('')
    const fileName = ref<string | null>(null)
    const validForm = ref(false)

    const isDialogOpen = ref(false)
    const serverError = ref(false)

    const { loading, sendTicket, addAttachment } = useContact()

    const user = computed(() => {
      return root.$store.getters.getUser
    })

    onMounted(() => {
      formEmail.value = user.value.login
    })

    async function handleUploadAttachment(data: any) {
      try {
        const { key, name } = addAttachment(data)
        formAttachment.value = key
        fileName.value = name
      } catch (e) {
        console.log(e)
      }

      refreshKey.value++
    }

    async function handleCreateTicket() {
      serverError.value = false

      try {
        await sendTicket({
          email: formEmail.value,
          subject: formSubject.value,
          record: formMessage.value,
          attachment: formAttachment.value,
        })

      } catch (e) {
        console.error(e)
        serverError.value = true
      }

      isDialogOpen.value = true
    }

    function handleCloseDialog() {
      isDialogOpen.value = false

      if (!serverError.value) {
        router.push({ name: 'Dashboard' })
      }
    }

    return {
      fileName,
      formAttachment,
      formEmail,
      formMessage,
      formSubject,
      handleCloseDialog,
      handleCreateTicket,
      handleUploadAttachment,
      isDialogOpen,
      isEmpty,
      isRequired,
      loading,
      refreshKey,
      serverError,
      validForm,
    }
  },
})
