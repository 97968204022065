<template>
  <div>
    <v-row no-gutters>
      <v-col class="col-12">
        <delete-reason :deleteRevision='deleteRevision' :isRevision='isRevision' ></delete-reason>
      </v-col>
      <v-col class='p-t-40'>
        <div class="col-12 text-center">
          <button class='btn col-auto btn-error' @click="$emit('confirm')"><i class="fas fa-angle-right"></i> Eliminar Reparación</button>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Vue from 'vue';
  import DeleteReason from '@/components/dialog/DeleteReason';

  export default {
    props: ['deleteRevision', 'isRevision'],
    components: {
      DeleteReason
    }
  }
</script>
