<template>
  <v-form ref="ServicesForm" lazy-validation>
    <v-stepper-content :step="step" class="py-0 px-10">
      <v-form ref="AccountForm" lazy-validation>
        <v-row no-gutters>
          <v-col class="text-center pt-8" cols="12">
            <h1 class="title-signature">{{ t('title') }}</h1>
          </v-col>
          <v-col class="text-center my-2" cols="12">
            <p class="body-text-signature">{{ t('sub_title') }}</p>
          </v-col>
          <v-col class="py-4 px-4" cols="12" md="6">
            <v-card
              data-cy="btn-digital-signature"
              :disabled="getAccount.withoutEmail || !hasContracts"
              class="full-height sign-card"
              elevation="5"
              @click="openCloseDialogSendEmailDocuments"
            >
              <v-card-title class="icon-card-signature pt-8">
                <v-progress-circular
                  v-if="!hasContracts"
                  :size="50"
                  class="m-auto"
                  color="primary"
                  indeterminate
                />
                <v-icon v-else class="m-auto">fa-light fa-signature</v-icon>
              </v-card-title>
              <v-card-text>
                <h1 class="title-card-signature">{{ t('digital_title') }}</h1>
                <p class="body-card-signature">{{ t('digital_desc') }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="py-4 px-4" cols="12" md="6">
            <v-card
              data-cy="btn-physical-signature"
              :disabled="!hasContracts"
              class="full-height sign-card"
              elevation="5"
              @click="openCloseDialogUploadDocuments"
            >
              <v-card-title class="icon-card-signature pt-8">
                <v-progress-circular
                  v-if="!hasContracts"
                  :size="50"
                  class="m-auto"
                  color="primary"
                  indeterminate
                />
                <v-icon v-else class="m-auto">fa-light fa-file-signature</v-icon>
              </v-card-title>
              <v-card-text>
                <h1 class="title-card-signature">{{ t('physical_title') }}</h1>
                <p class="body-card-signature">{{ t('physical_desc') }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-stepper-content>

    <mapit-dialog :dialog="dialogSendEmailDocuments" @close="openCloseDialogSendEmailDocuments">
      <template v-slot:header-icon>
        <v-icon class="text-secondary">fa fa-signature</v-icon>
      </template>
      <template v-slot:header-text>
        <span> {{ t('send_documents_title') }}</span>  
      </template>
      <template v-slot:body>
        <div
          class="body-text-signature"
          v-html="t('send_documents_description')"
        />
      </template>
      <template v-slot:footer-center>
        <v-btn
          data-cy="btn-send-documents-digital-signature"
          :key="buttonKey"
          :loading="loadingApi"
          class="btn-primary"
          @click.once="sendEmailSignature"
          text
        >
          {{ $t('buttons.send') }}
        </v-btn>
      </template>
    </mapit-dialog>

    <upload-registration-docs-dialog
      :contracts="contracts"
      :dialog="dialogUploadDocuments"
      :loading="loadingApi"
      @close="openCloseDialogUploadDocuments"
      @continue="signDocuments"
    />

    <footer-actions :step="step">
      <template v-if="services && services['honda-mapit'].selected" v-slot:left>
        <v-btn class="btn-gray" dark tabindex="-1" @click="backStep()"> {{ $t('buttons.back') }}</v-btn>
      </template>
    </footer-actions>

    <mapit-dialog
      :dialog="isOpenDialogError"
      :hideCloseButton="true"
      @close="closeDialogError()"
    >
      <template v-slot:header-icon>
        <v-icon class="text-orange">fa fa-exclamation-triangle</v-icon>
      </template>
      <template v-slot:header-text>
        <span>{{ $t('honda.registration.after.download.sendmail_popup.title_fail') }}</span>
      </template>
      <template v-slot:body>
        <div
          class="body-text-signature"
          v-html="$t('honda.registration.after.download.sendmail_popup.description_fail')"
        />
      </template>
      <template v-slot:footer-center>
        <v-btn class="btn-primary" @click="closeDialogError()">{{ $t('buttons.ok') }}</v-btn>
      </template>
    </mapit-dialog>
  </v-form>
</template>

<script>
import FooterActions from '../FooterActions'
import MapitDialog from '@/components/dialog/MapitDialog'
import UploadRegistrationDocsDialog from '@/components/dialog/UploadRegistrationDocsDialog'
import coreApi from '@/api/core.api'
import { mapGetters } from 'vuex'
import {DateTime} from 'luxon'

export default {
  name: 'Signature',
  components: {
    FooterActions,
    MapitDialog,
    UploadRegistrationDocsDialog,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dialogUploadDocuments: false,
      dialogSendEmailDocuments: false,
      isOpenDialogError: false,
      contracts: [],
      interval: null,
      loadingApi: false,
      buttonKey: 1,
      expiresAt: null,
      urlInterval: null
    }
  },
  watch: {
    getId: function ( val ) {
      coreApi
        .getRegistrationContracts(val)
        .then(( { data } ) => {
          this.contracts = data || []
          if ( this.contracts.length && !this.hasContracts ) {
            this.interval = setInterval(this.getContracts, 2000, val)
          }
        })
        .catch(( e ) => {
          console.error('Error download contract files: ', e)
        })
    },
  },
  computed: {
    ...mapGetters({
      services: 'getServices',
      getId: 'getId',
      getVehicle: 'getVehicle',
      getAccount: 'getAccount',
    }),
    hasContracts() {
      return this.contracts.filter(contract => Object.hasOwn(contract, 's3Key')).length === this.contracts.length
    },
    isExpired() {
      return this.expiresAt ? DateTime.fromSeconds(this.expiresAt).diffNow().milliseconds < 0 : false
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval)
    this.urlInterval = clearInterval(this.urlInterval)
  },
  methods: {
    getContracts( id ) {
      coreApi
        .getRegistrationContracts(id)
        .then(( { data } ) => {
          this.contracts = data || []
          if ( !this.contracts.length || this.contracts.length && this.hasContracts ) {
            this.interval = clearInterval(this.interval)
            this.expiresAt = this.parseExpirationDateFromContracts(this.contracts)

            this.urlInterval = setInterval(() => {
              if (this.isExpired) {
                this.urlInterval = clearInterval(this.urlInterval)
                this.interval = setInterval(this.getContracts, 2000, this.getId)
              }
            }, 1000)
          }
        })
        .catch(( e ) => {
          console.log('Error download contract files: ', e)
          this.interval = clearInterval(this.interval)
        })
    },
    parseExpirationDateFromContracts(contracts) {
      const DEFAULT_EXPIRATION_TIME = DateTime.now().plus({ minutes: 10 }).toSeconds()
      if (!contracts.length) return DEFAULT_EXPIRATION_TIME

      const contract = contracts[0]
      const expirationDateRaw = new URL(contract.documentUrl).searchParams.get('Expires')
      try {
        return Number(expirationDateRaw) || DEFAULT_EXPIRATION_TIME
      } catch(e) {
        return DEFAULT_EXPIRATION_TIME
      }
    },
    signDocuments() {
      if ( !this.loadingApi ) {
        this.loadingApi = true
        coreApi
          .signRegistration(this.getId)
          .then(( res ) => {
            this.nextStep()
          })
          .catch(( e ) => {
            console.log('Error signing docs: ', e)
          })
          .finally(() => {
            this.openCloseDialogUploadDocuments()
            this.loadingApi = false
            this.buttonKey++
          })
      } else {
        this.buttonKey++
      }
    },
    t( string ) {
      return this.$t(`honda.registration.after.signature.${ string }`)
    },
    backStep() {
      this.$emit('backStep')
    },
    nextStep() {
      this.$emit('nextStep')
    },
    openCloseDialogSendEmailDocuments() {
      this.dialogSendEmailDocuments = !this.dialogSendEmailDocuments
    },
    openCloseDialogUploadDocuments() {
      this.dialogUploadDocuments = !this.dialogUploadDocuments
    },
    sendEmailSignature() {
      if ( !this.loadingApi ) {
        this.loadingApi = true
        coreApi
          .sendEmailContractHondaPlus(this.getId)
          .then(( res ) => {
            this.nextStep()
          })
          .catch(( e ) => {
            this.isOpenDialogError = true
            console.log('Error sending email: ', e)
          })
          .finally(() => {
            this.openCloseDialogSendEmailDocuments()
            this.loadingApi = false
            this.buttonKey++
          })
      } else {
        this.buttonKey++
      }
    },
    closeDialogError() {
      this.isOpenDialogError = false
    },
  },
}
</script>

<style lang="scss" scoped>

.title-signature {
  text-align: center;
  color: var(--v-primary-base);
  text-transform: uppercase;
  font-size: 28px;
  font-family: 'ProximaNovaExCnSBold', sans-serif;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 1;
}

.body-text-signature {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 15px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--v-gray-darken4);
  opacity: 1;
}

.sign-card {
  cursor: pointer;
  border-radius: 8px !important;

  .icon-card-signature i {
    width: 110px;
    height: 110px;
    /* UI Properties */
    background: var(--v-gray-lighten3) 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 110px;
    color: white;
    font-size: 48px;
    text-align: center;
    padding: auto;
    padding-left: 15px;
  }

  .title-card-signature {
    letter-spacing: 0px;
    text-align: center;
    font-size: 24px;
    font-family: 'ProximaNovaExCnSBold', sans-serif;
    color: var(--v-gray-darken4);
    text-transform: uppercase;
    opacity: 1;
  }

  .body-card-signature {
    font-family: 'ProximaNovaRegular', sans-serif;
    font-size: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--v-gray-darken4);
    opacity: 1;
  }

  .info-label {
    font-family: 'ProximaNovaRegular', sans-serif;
    font-size: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--v-gray-darken4);
    opacity: 1;
  }

  &:before {
    background: white;
  }

  &:hover {
    background: var(--v-gray-lighten1);

    .icon-card-signature i {
      background: var(--v-secondary-base) 0% 0% no-repeat padding-box;
    }
  }
}

.btn-icon {
  color: var(--v-gray-base);
  margin: 0 0 2px 2px;
  font-size: 20px;

  &:hover {
    cursor: pointer;
  }
}

</style>
