import registration from "./registration"
import cloneDeep from 'lodash/cloneDeep'
import { RegistrationOrigin } from '@mapit/core-dynamodb/dist/types'

export const newRegistrationModel = () => cloneDeep(registration)
export const vehicleRegistrationModel = () => cloneDeep(registration.vehicle)
export const servicesRegistrationModel = () => cloneDeep(registration.services)
export const accountRegistrationModel = () => cloneDeep(registration.account)
export const subagentRegistrationModel = () => cloneDeep(registration.subagent)


export const parseRegisterData = (data, group) => {
  const vehicle = {
    registrationNumber: data.vehicle.registrationNumber.value.toUpperCase(),
    model: data.vehicle.model.value.toUpperCase(),
    modelId: data.vehicle.modelId,
    vin: data.vehicle.vin.value.toUpperCase(),
    key: data.vehicle.key.value || undefined,
    demoBike: data.vehicle.demoBike
  }

  const account = {
    email: data.account.withoutEmail ? undefined : data.account.email.value.toLowerCase(),
    firstName: data.account.firstName.value,
    lastName: data.account.lastName.value,
    address: {
      addressNumber: data.account.address.addressNumber.value,
      addressLocality: data.account.address.addressLocality.value,
      addressRegion: data.account.address.addressRegion.value,
      streetAddress: data.account.address.streetAddress.value,
      postalCode: data.account.address.postalCode.value,
      country: group.country
    },
    document: {
      type: data.account.documentType.value,
      number: data.account.document.value.toUpperCase()
    },
    phone: data.account.phone.value,
    dob: data.account.dob.value
  }

  if(data.account.id) {
    account.id = data.account.id
  }

  const exDealerBike = data.exDealerBike ? {
    id: data.exDealerBike.id,
    device: {
      id: data.exDealerBike.device.id,
      imei: data.exDealerBike.device.imei,
    }
  } : undefined

  const result = {
    subtype: data.subtype,
    branch: group.branch,
    vehicle,
    offerNumber: data.offerNumber.value || undefined,
    account,
    products: [],
    subagent: data.subagent.exist ?
    { name: data.subagent.name } : undefined,
    exDealerBike
  }
  if (data.services['honda-mapit'].selected) {
    result.device = data.device
  }

  if (group){
    result.dealer = {
      id: group.id,
      code: group.code,
      name: group.name,
      address: Object.keys(group.profile?.shop?.address || {}).length > 0 ? group.profile?.shop?.address : group.profile?.garage?.address,
      phone: group.profile?.shop?.telephone || group.profile?.garage?.telephone
    }
  }

  if (data.account.paymentMethod) {
   result.account.originalPaymentMethod = {
    stripeSourceId: data.account.paymentMethod.id
   }
  }

  if (data.origin === RegistrationOrigin.Resale) {
    result.origin = data.origin
    result.deviceTransfer = data.deviceTransfer
   }

  switch (data.subtype) {
    case 'Honda':
      Object.keys(data.services).forEach(key => {
        if (data.services[key].selected) {
          result.products.push(data.services[key].key)
        }
      });
      if (data.services['honda-plus-go'].selected) { //BackEnd uses HPG just like HP
        result.products.push('HP')
      }
      break;
    case 'Connect':
      result.products.push('MC')
      break
  }

  return result
}

export const parseRegisteredAccount = account => {
  const newAccount = accountRegistrationModel()

  newAccount.id = account.id
  newAccount.email.value = account.email
  newAccount.stripeId = account.stripeId

  newAccount.firstName.value = `${account.firstName || ''}`
  newAccount.lastName.value = `${account.lastName || ''}`
  newAccount.dob.value = `${account.dob || ''}`
  if(account.address) {
    newAccount.address.streetAddress.value = `${account.address.streetAddress || ''}`
    newAccount.address.addressNumber.value = `${account.address.addressNumber || ''}`
    newAccount.address.postalCode.value = `${account.address.postalCode || ''}`
    newAccount.address.addressLocality.value = account.address.addressLocality
    newAccount.address.addressRegion.value = account.address.addressRegion
  }
  newAccount.paymentMethods = account.paymentMethods || []
  newAccount.phone.value = `${account.phone || ''}`
  if(account.document) {
    newAccount.document.value = `${account.document.number || ''}`
    newAccount.documentType.value = `${account.document.type || ''}`
  }

  return newAccount
}
