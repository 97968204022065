<template>
<div>
  <v-row class="flex justify-start" no-gutters>
    <v-col class="col-lg-3 col-sm-6 col-xs-12">
      <m-select
        data-cy="loansStatusFilter"
        :value="selectedStatus"
        @input="value => selectedStatus = value"
        :placeholder="$t('dealer-bike.loans.table.lbl-filter-status')"
        :items="filterList"
        :showSelectedCounter="true"
        :multiple="true"
      >
        <template v-slot:prepend-inner>
          <i class="icon-mapit icon-filter-status"></i>
        </template>
      </m-select>
    </v-col>
    <v-col class="col-lg-3 col-sm-6 col-xs-12 pl-2 pl-xs-0">
      <m-select
        data-cy="loansTypeFilter"
        :value="selectedType"
        @input="value => selectedType = value"
        :placeholder="$t('dealer-bike.loans.table.lbl-filter-type')"
        :items="typeList"
        :showSelectedCounter="true"
        :multiple="true"
      >
        <template v-slot:prepend-inner>
          <i class="icon-mapit icon-filter-status"></i>
        </template>
      </m-select>
    </v-col>
    <v-spacer/>
    <v-col class="col-lg-5 col-sm-12">
      <mapit-input
        uid="loansSearchFilter"
        :value="search"
        :placeholder="$t('dealer-bike.loans.table.lbl-search')"
        @changeValue="value => search = value"
        :clearable="true"
      />
    </v-col>
  </v-row>
  <v-data-table
    id="dealer-bike-loans-table-list"
    :loading="loading"
    :loading-text="$t('dealer-bike.loans.table.loading')"
    :headers="headers"
    :items="filteredLoans"
    sort-by="scheduledFor"
    :sort-desc="[true]"
    class="elevation-0 pt-4"
    :no-data-text="
      $t (
        filteredLoans && filteredLoans.length > 0
          ? 'dealer-bike.loans.table.no-data-search'
          : 'dealer-bike.loans.table.no-data',
        { search },
      )
    "
    :no-results-text="$t('dealer-bike.loans.table.no-data-search')"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'fas fa-angle-double-left',
      lastIcon: 'fas fa-angle-double-right',
      prevIcon: 'fas fa-angle-left',
      nextIcon: 'fas fa-angle-right',
      'items-per-page-text': $t('dealer-bike.loans.table.footer_num_x_page'),
      'items-per-page-options': [10, 25, 50, -1],
    }"
  >
    <template #footer.page-text="props">
      {{ props.pageStart }}-{{ props.pageStop }} {{ $t('honda.registration.list.of') }} {{ props.itemsLength }}
    </template>

    <template v-slot:item.dealerBike.vehicle.model="{ item }">
      <div class="vehicle  py-4">
        <span class="vehicle-model" data-hover>{{ item.dealerBike.vehicle.model }}</span
        ><br />
        <span class="vehicle-color">{{ item.dealerBike.vehicle.registrationNumber }}</span>
      </div>
    </template>

    <template v-slot:item.usageType="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip class="ma-2" outlined label text-color="black" v-bind="attrs" v-on="on">
            {{ item.usageType === UsageType.Demo ? $t('dealer-bike.loans.type.demo') : $t('dealer-bike.loans.type.courtesy') }}
          </v-chip>
        </template>
        <span>{{ item.usageType === UsageType.Demo ? $t('dealer-bike.loans.table.tooltips.demo') : $t('dealer-bike.loans.table.tooltips.courtesy') }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.contact.phone="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="vehicle text-overflow" v-bind="attrs" v-on="on">
              <span class="vehicle-model">{{ `${item.contact.firstName} ${item.contact.lastName}` }}</span>
              <br />
              <m-phone-label :value="item.contact.phone" class="vehicle-color" />
            </div>
          </template>
          <span class="vehicle-model">{{ `${item.contact.firstName} ${item.contact.lastName}` }}</span>
        </v-tooltip>
    </template>

    <template v-slot:item.scheduledFor="{ item }">
      <span class="text-item">{{ toDateTimeFormat(item.scheduledFor) }}</span>
    </template>

    <template v-slot:item.returnedAt="{ item }">
      <span class="text-item" v-if="item.returnedAt">{{ toDateTimeFormat(item.returnedAt) }}</span>
    </template>

    <template v-slot:item.createdBy="{ item }">
      <div class="text-overflow">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="text-item" v-if="item.return ? item.return.attendedBy || item.createdBy : item.handover? item.handover.attendedBy || item.createdBy : item.createdBy">{{ (item.return ? item.return.attendedBy || item.createdBy : item.handover? item.handover.attendedBy || item.createdBy : item.createdBy).slice(0, (item.return ? item.return.attendedBy || item.createdBy : item.handover? item.handover.attendedBy || item.createdBy : item.createdBy).indexOf('<')) }}</span>
          </template>
          <span class="text-item" v-if="item.return ? item.return.attendedBy || item.createdBy : item.handover? item.handover.attendedBy || item.createdBy : item.createdBy">{{ (item.return ? item.return.attendedBy || item.createdBy : item.handover? item.handover.attendedBy || item.createdBy : item.createdBy).slice(0, (item.return ? item.return.attendedBy || item.createdBy : item.handover? item.handover.attendedBy || item.createdBy : item.createdBy).indexOf('<')) }}</span>
        </v-tooltip>
      </div>
    </template>

    <template v-slot:item.status="{ item }">
      <div>
        <v-icon
          :class="
            `icon-status ${
              item.status === LoanStatus.Completed
                ? 'green-dot'
                : item.status === LoanStatus.HandedOver
                ? 'yellow-dot'
                : item.status === LoanStatus.Booked
                ? 'blue-dot'
                : 'red-dot'
            }`
"
          >&#xe025;
        </v-icon>
        <span class="text-item">
          {{
            item.status === LoanStatus.Completed
              ? $t('dealer-bike.loans.status.completed')
              : item.status === LoanStatus.HandedOver
              ? $t('dealer-bike.loans.status.handed-over')
              : item.status === LoanStatus.Returned
              ? $t('dealer-bike.loans.status.returned')
              : item.status === LoanStatus.Started
              ? $t('dealer-bike.loans.status.started')
              : $t('dealer-bike.loans.status.booked')
          }}
        </span>
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-menu transition="slide-x-transition" bottom right :data-menu="item.id">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" :data-cy="`actionsMenu${dealerBikeLoansList.indexOf(item)}`">
            <v-icon class="icon-mapit">)</v-icon>
          </div>
        </template>
        <v-list  dense class="list-menu-actions">
          <v-list-item :data-cy="`resendSignHandoverEmailAction${dealerBikeLoansList.indexOf(item)}`" @click="resendSignHandoverEmail(item.id)" :disabled="item.status !== LoanStatus.Started">
            <v-list-item-title>
              {{ $t('dealer-bike.loans.actions.resend-sign-handover-email') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :data-cy="`signHandOverLoanAction${dealerBikeLoansList.indexOf(item)}`" @click="markHandOverLoanAsSigned(item.id)" :disabled="item.status !== LoanStatus.Started">
            <v-list-item-title>
              {{ $t('dealer-bike.loans.actions.sign-handover-loan-paper') }}
            </v-list-item-title>
          </v-list-item>
          <v-divider class="drawer-divider"></v-divider>
          <v-list-item :data-cy="`makeReturnAction${dealerBikeLoansList.indexOf(item)}`" @click="makeReturn(item)" :disabled="item.status !== LoanStatus.HandedOver">
            <v-list-item-title>
              {{ $t('dealer-bike.loans.actions.make-return') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :data-cy="`resendSignReturnEmailAction${dealerBikeLoansList.indexOf(item)}`" @click="resendSignReturnEmail(item.id)" :disabled="item.status !== LoanStatus.Returned">
            <v-list-item-title>
              {{ $t('dealer-bike.loans.actions.resend-sign-return-email') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :data-cy="`signReturnLoanAction${dealerBikeLoansList.indexOf(item)}`" @click="markReturnLoanAsSigned(item.id)" :disabled="item.status !== LoanStatus.Returned">
            <v-list-item-title>
              {{ $t('dealer-bike.loans.actions.sign-return-loan-paper') }}
            </v-list-item-title>
          </v-list-item>
          <v-divider class="drawer-divider"></v-divider>
          <download-loan-list-item
            :data-cy="`download-handover-document-${item.id}`"
            :loan="item"
            :label="$t('dealer-bike.loans.actions.download-handover-document')"
            @toggleDialog="toggleDialogWaitingDocs"
          />
          <v-divider class="drawer-divider"></v-divider>
          <v-list-item :data-cy="`cancelled-${item.id}`" @click="markLoanAsCancelled(item.id)" >
            <v-list-item-title>
              {{ $t('dealer-bike.loans.actions.cancelled-loan') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>

  <mapit-dialog
    :dialog="dialogMarkHandOverLoanSigned"
    :hideCloseButton="true"
  >
    <template v-slot:header-icon>
      <v-icon class="icon-mapit" color="secondary">O</v-icon>
    </template>
    <template v-slot:header-text>
      <div class="text-center" v-balance-text>{{ $t("dealer-bike.loans.sign-handover-loan-paper.title") }}</div>
    </template>
    <template v-slot:body>
      <div class="text-center" v-balance-text>
         {{ $t("dealer-bike.loans.sign-handover-loan-paper.body") }}
      </div>
    </template>
    <template v-slot:footer-center>
        <v-btn
          :loading="loading"
          :disabled="loading"
          class="btn-primary"
          @click="signLoan"
          >{{ $t('buttons.confirm') }}</v-btn
        >
        <v-btn
          data-cy="btn-cancel-dialog-mark-hand-over-loan-signed"
          class="btn-gray"
          @click="dialogMarkHandOverLoanSigned = !dialogMarkHandOverLoanSigned"
          >{{ $t('buttons.cancel') }}</v-btn
        >
    </template>
  </mapit-dialog>

  <mapit-dialog
    :dialog="dialogMarkReturnLoanSigned"
    :hideCloseButton="true"
  >
    <template v-slot:header-icon>
      <v-icon class="icon-mapit" color="secondary">O</v-icon>
    </template>
    <template v-slot:header-text>
      <div class="text-center" v-balance-text>{{ $t("dealer-bike.loans.sign-return-loan-paper.title") }}</div>
    </template>
    <template v-slot:body>
      <div class="text-center" v-balance-text>
        {{ $t("dealer-bike.loans.sign-return-loan-paper.body") }}
      </div>
    </template>
    <template v-slot:footer-center>
        <v-btn
          :loading="loading"
          :disabled="loading"
          class="btn-primary"
          @click="signReturn"
          :data-cy="`btn-mark-return-signed-${loanId}`"
          >{{ $t('buttons.confirm') }}</v-btn
        >
        <v-btn
          class="btn-gray"
          @click="dialogMarkReturnLoanSigned = !dialogMarkReturnLoanSigned"
          :data-cy="`btn-cancel-${loanId}`"
        >{{ $t('buttons.cancel') }}</v-btn
        >
    </template>
  </mapit-dialog>

    <mapit-dialog
    :dialog="dialogCancelledLoan"
    :hideCloseButton="true"
  >
    <template v-slot:header-icon>
      <v-icon class="text-orange"> fa fa-exclamation-triangle</v-icon>
    </template>
    <template v-slot:header-text>
      <div class="text-center" v-balance-text>{{ $t("dealer-bike.loans.cancelled.title") }}</div>
    </template>
    <template v-slot:body>
      <div class="text-center">
        <div v-balance-text> {{ $t("dealer-bike.loans.cancelled.body-top") }} </div>
      </div>
    </template>
    <template v-slot:footer-center>
        <v-btn
          :loading="loading"
          :disabled="loading"
          class="btn-primary"
          @click="doCancelledLoan(loanId)"
          >{{ $t('buttons.confirm') }}</v-btn
        >
        <v-btn
          class="btn-gray"
          @click="dialogCancelledLoan = !dialogCancelledLoan"
          >{{ $t('buttons.cancel') }}</v-btn
        >
    </template>
  </mapit-dialog>

  <mapit-dialog :dialog="isOpenDialogWaitingDocs" :hideCloseButton="true" @close="toggleDialogWaitingDocs()">
    <template v-slot:header-icon>
      <v-icon color="warning">fa-solid fa-warning</v-icon>
    </template>
    <template v-slot:header-text>
      <div class="text-center" v-balance-text>
        {{ $t('dealer-bike.loans.popups.waiting-docs.title') }}
      </div>
    </template>
    <template v-slot:body>
      <div
        v-balance-text.children
        class="dialog-body mt-5"
        v-html="$t('dealer-bike.loans.popups.waiting-docs.body', {
            type: selectedLoan && selectedLoan.returnedAt
              ? $t('dealer-bike.loans.popups.waiting-docs.type-return')
              : $t('dealer-bike.loans.popups.waiting-docs.type-handover')
          })"
      />
    </template>
    <template v-slot:footer-center>
      <v-btn data-cy="btnDialogWaitingDocs" class="btn-primary" @click="toggleDialogWaitingDocs()">
        {{ $t('buttons.accept') }}
      </v-btn>
    </template>
  </mapit-dialog>

</div>
</template>

<script>
import {toDateTimeFormat} from "@/utils/date-helper";
import { UsageType, LoanStatus } from '@mapit/dealer-bikes-dynamodb/dist/types'
import MapitDialog from '@/components/dialog/MapitDialog'
import MapitInput from '../general/MapitInput.vue'
import { InputTypes } from "@/utils/constants";
import { mapActions, mapGetters } from 'vuex'
import DownloadLoanListItem from '@/components/atom/DownloadLoanListItem'

export default {
  name: 'LoansTable',
  components: {
    DownloadLoanListItem,
    MapitDialog,
    MapitInput,
  },
  data() {
    return {
      InputTypes,
      toDateTimeFormat,
      UsageType,
      LoanStatus,
      headers: [
        {
          text: this.$t('dealer-bike.loans.table.model-plate'),
          align: 'left',
          value: 'dealerBike.vehicle.model',
          class: 'text-header',
          sortable: true,
        },
        {
          text: this.$t('dealer-bike.loans.table.created-by'),
          align: 'left',
          value: 'createdBy',
          class: 'text-header',
          sortable: true,
        },
        {
          text: this.$t('dealer-bike.loans.table.type'),
          align: 'left',
          value: 'usageType',
          class: 'text-header',
          sortable: true,
        },
        {
          text: this.$t('dealer-bike.loans.table.user-phone'),
          align: 'left',
          value: 'contact.phone',
          class: 'text-header',
          sortable: false,
        },
        {
          text: this.$t('dealer-bike.loans.table.delivery-date'),
          align: 'left',
          value: 'scheduledFor',
          class: 'text-header',
          sortable: true,
        },
        {
          text: this.$t('dealer-bike.loans.table.return-date'),
          align: 'left',
          value: 'returnedAt',
          class: 'text-header',
          sortable: true,
        },
        {
          text: this.$t('dealer-bike.loans.table.state'),
          align: 'left',
          class: 'text-header',
          value: 'status',
          sortable: true,
          sort: (a, b) => ((a, b) ? a.localeCompare(b) : b.localeCompare(a)),
        },
        {
          text: this.$t('dealer-bike.loans.table.actions'),
          align: 'center',
          class: 'text-header',
          sortable: false,
          value: 'actions',
        },
      ],
      search: '',
      selectedStatus: [],
      selectedType: [],
      filterList: Object.values(LoanStatus).filter(ls => [LoanStatus.HandedOver,LoanStatus.Started,LoanStatus.Returned].includes(ls)).map(item => {
        return {text: this.$t(`dealer-bike.loans.status.${item.toLowerCase().replace('_','-')}`), value: item }
      }),
      typeList: Object.values(UsageType).map(item => {
        return {text: this.$t(`dealer-bike.loans.table.tooltips.${item.toLowerCase()}`), value: item}
      }),
      dialogMarkHandOverLoanSigned: false,
      dialogMarkReturnLoanSigned: false,
      dialogCancelledLoan: false,
      isOpenDialogWaitingDocs: false,
      loanId: null,
      selectedLoan: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'isLoadingDealerBikes',
      dealerBikeLoansList: 'getDealerBikeLoansList',
    }),
    filteredLoans() {
      return this.dealerBikeLoansList
        .filter(item => {
          const filterValidStatus = item.status !== LoanStatus.Completed && item.status !== LoanStatus.Cancelled
          const filterSelectedStatus = this.selectedStatus.length > 0 ? this.selectedStatus.includes(item.status) : true
          const filterSelectedType = this.selectedType.length > 0 ? this.selectedType.includes(item.usageType) : true
          const filterSearchString = this.search ?
            JSON.stringify([
              item.dealerBike.vehicle.registrationNumber,
              item.dealerBike.vehicle.model,
              item.dealerBike.vehicle.color,
              item.dealerBike.vehicle.vin,
              item.contact.phone,
              item.createdBy,
              `${item.contact.firstName} ${item.contact.lastName}`
            ]).toLowerCase().includes(this.search.toLowerCase())
            : true
          return filterValidStatus && filterSelectedStatus && filterSelectedType && filterSearchString
        })
    },
  },
  methods: {
    ...mapActions([
      'resendSignHandoverEmail',
      'resendSignReturnEmail',
      'signHandOverLoan',
      'signReturnLoan',
      'cancelledLoan'
    ]),
    async toggleDialogWaitingDocs(loan) {
      this.isOpenDialogWaitingDocs = !this.isOpenDialogWaitingDocs
      if(loan) {
        this.selectedLoan = loan
      }else{
        this.$emit("refreshList")
      }
    },
    makeReturn(dbLoanSelected) {
      this.$store.commit('SET_DEALER_BIKE_LOAN_CONTACT', dbLoanSelected.contact)
      this.$router.push({ name: 'ReturnDealerBike', params: {loanId: dbLoanSelected.id} })
    },
    markHandOverLoanAsSigned(loanId) {
      this.dialogMarkHandOverLoanSigned = true
      this.loanId = loanId
    },
    markReturnLoanAsSigned(loanId) {
      this.dialogMarkReturnLoanSigned = true
      this.loanId = loanId
    },
    markLoanAsCancelled(loanId) {
      this.dialogCancelledLoan = true
      this.loanId = loanId
    },
    async signLoan() {
      try {
        await this.signHandOverLoan(this.loanId)
        this.dialogMarkHandOverLoanSigned = !this.dialogMarkHandOverLoanSigned
        this.$emit("refreshList")
      } catch(err) {
        console.error(err)
      }
    },
    async signReturn() {
      try {
        await this.signReturnLoan(this.loanId)
        this.dialogMarkReturnLoanSigned = !this.dialogMarkReturnLoanSigned
        this.$emit("refreshList")
      } catch(err) {
        console.error(err)
      }
    },
    async doCancelledLoan() {
       try {
         await this.cancelledLoan(this.loanId)
         this.dialogCancelledLoan = !this.dialogCancelledLoan
         this.$emit("refreshList")
       } catch(err) {
         console.error(err)
       }
    },
  },
}
</script>
<style>
.text-overflow {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
