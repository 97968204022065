import { Variant, Theme } from './types'

export const themes: Theme = {
  [Variant.Success]: {
    colorIcon: '#00803f',
    colorText: '#00803f',
    colorBackground: '#d9ece2',
  },
  [Variant.Warning]: {
    colorIcon: '#d18901',
    colorText: '#d18901',
    colorBackground: '#fff1d9',
  },
  [Variant.Error]: {
    colorIcon: '#cc0000',
    colorText: '#cc0000',
    colorBackground: '#fadcdf',
  },
  [Variant.Disabled]: {
    colorIcon: '#9a9a9a',
    colorText: '#9a9a9a',
    colorBackground: '#ededed',
  },
  [Variant.Info]: {
    colorIcon: '#0075eb',
    colorText: '#0075eb',
    colorBackground: '#d9eafc',
  },
}
