import { onMounted, ref } from '@vue/composition-api'

import serviceApi from '@/api/service.api'
import { Appointment, RequestError } from '@/types/appointments'
import { mapDataToAppointment } from '@/mappers/appointments'

export const useAppointment = (id?: string) => {
  const appointment = ref<Appointment | undefined>()
  const isLoading = ref<boolean>(false)
  const error = ref<RequestError>()

  onMounted(async () => {
    if (id) {
      await fetchAppointmentById(id)
    }
  })

  async function fetchAppointmentById(id: string) {
    error.value = undefined

    try {
      isLoading.value = true
      const response = await serviceApi.getAppointmentById(id)
      appointment.value = mapDataToAppointment(response.data)
    } catch (e: any) {
      console.error('Error fetching the appointment', e)
      error.value = RequestError.NotFound
    } finally {
      isLoading.value = false
    }
  }

  async function updateAppointment(appointment: Appointment) {
    error.value = undefined

    try {
      isLoading.value = true
      await serviceApi.updateAppointment(appointment)
    } catch (e) {
      console.error('Error updating the appointment', e)
      error.value = RequestError.Default
    } finally {
      isLoading.value = false
    }
  }

  async function deleteAppointment(id: string) {
    error.value = undefined

    try {
      isLoading.value = true
      await serviceApi.deleteAppointment(id)
    } catch (e) {
      console.error('Error deleting the appointment', e)
      error.value = RequestError.Default
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    error,
    appointment,
    updateAppointment,
    deleteAppointment,
  }
}
