<template>
  <v-form ref="detailForm">
    <v-stepper-content :step="step" class="py-0 px-10">
      <v-divider class="divider mb-2" />
      <div class="section_wrapper">
        <h4 class="title-search-user column col-3">
          {{ t('customer.title') }}
        </h4>
        <div class="data-wrapper">
          <div class="input-wrapper">
            <div class="text-wrapper">
              <div class="text-title-section">
                {{ t('customer.email') }}
              </div>
              <div class="text-description-section">
                {{ account.email.value === '' ? '-' : account.email.value }}
              </div>
            </div>
            <v-divider class="text-divider" />
          </div>
          <div class="input-wrapper">
            <div class="text-wrapper">
              <div class="text-title-section">
                {{ t('customer.fullname') }}
              </div>
              <div class="text-description-section">
                {{ account.firstName.value + ' ' + account.lastName.value }}
              </div>
            </div>
            <v-divider class="text-divider" />
          </div>
          <div class="input-wrapper">
            <div class="text-wrapper">
              <div class="text-title-section">
                {{ t('customer.document') }}
              </div>
              <div class="text-description-section">
                {{ account.document.value }}
              </div>
            </div>
            <v-divider class="text-divider" />
          </div>
          <div class="input-wrapper">
            <div class="text-wrapper">
              <div class="text-title-section">
                {{ t('customer.address') }}
              </div>
              <div class="text-description-section">
                {{
                  `${
                    account.address.streetAddress.value
                      ? account.address.streetAddress.value + ', '
                      : ''
                  }
                  ${
                    account.address.addressNumber.value
                      ? account.address.addressNumber.value + ', '
                      : ''
                  }
                  ${account.address.postalCode.value ? account.address.postalCode.value + ' ' : ''}
                  ${
                    account.address.addressLocality.value
                      ? account.address.addressLocality.value + ', '
                      : ''
                  }
                  ${
                    account.address.addressRegion.value
                      ? account.address.addressRegion.value + ', '
                      : ''
                  }
                  ${$t(`countries.${country}`)}
                  `
                }}
              </div>
            </div>
            <v-divider class="text-divider" />
          </div>
          <div class="input-wrapper">
            <div class="text-wrapper">
              <div class="text-title-section">
                {{ t('customer.phone') }}
              </div>
              <m-phone-label :value="account.phone.value" class="text-description-section" />
            </div>
            <v-divider class="text-divider" />
          </div>

          <div class="input-wrapper">
            <div class="text-wrapper">
              <div class="text-title-section">
                {{ t('customer.payment_method') }}
              </div>

              <div :class="`text-description-section ${paymentMethodSection.style || ''}`">
                {{ paymentMethodSection.message }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-divider class="divider mb-2" />

      <div class="section_wrapper">
        <h4 class="title-search-user column col-3">
          {{ t('vehicle.title') }}
        </h4>
        <div class="data-wrapper">
          <div class="input-wrapper">
            <div class="text-wrapper">
              <div class="text-title-section">
                {{ t('vehicle.vin') }}
              </div>
              <div class="text-description-section">
                {{ vehicle.vin.value }}
              </div>
            </div>
            <v-divider class="text-divider" />
          </div>
          <div class="input-wrapper">
            <div class="text-wrapper">
              <div class="text-title-section">
                {{ t('vehicle.plate') }}
              </div>
              <div class="text-description-section">
                {{ vehicle.registrationNumber.value }}
              </div>
            </div>
            <v-divider class="text-divider" />
          </div>
          <div class="input-wrapper">
            <div class="text-wrapper">
              <div class="text-title-section">
                {{ t('vehicle.model') }}
              </div>
              <div class="text-description-section">
                {{ vehicle.model.value }}
              </div>
            </div>
          </div>
          <div class="input-wrapper mb-2" v-if="vehicle.demoBike">
            <v-divider class="text-divider" />
            <div class="text-wrapper">
              <div class="text-title-section" style="width: 100%">
                {{ $t('honda.registration.vehicle.is_demobike') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-divider class="divider mb-5" />

      <div class="section_wrapper">
        <h4 class="title-search-user column col-3">
          {{ t('services.title') }}
        </h4>

        <div class="data-wrapper">
          <div class="input-wrapper">
            <div class="section_wrapper">
              <service-checkbox
                v-if="!isHondaMapitRegistration && services['honda-plus-go'].selected"
                title="Honda Plus Go"
                :error="''"
                variant="honda-plus-go"
                typeStyled="reduced"
                :checkValue="services['honda-plus-go'].selected"
                :isSelectable="true"
              />

              <service-checkbox
                v-if="!isHondaMapitRegistration && !services['honda-plus-go'].selected"
                title="Honda Plus"
                :error="''"
                :disabled="!services['honda-plus'].selected"
                :checkValue="services['honda-plus'].selected"
                :isSelectable="true"
                variant="honda-plus"
                typeStyled="reduced"
              />

              <service-checkbox
                title="Honda Mapit"
                :class="!isHondaMapitRegistration ? 'ml-5' : ''"
                :error="''"
                :disabled="!services['honda-mapit'].selected"
                :checkValue="services['honda-mapit'].selected"
                :isSelectable="true"
                variant="honda-mapit"
                :typeStyled="services['honda-plus-go'].selected ? 'reduced go' : 'reduced'"
              />

              <service-checkbox
                v-if="!isHondaMapitRegistration"
                title="Honda Seguro"
                class="ml-5"
                :error="''"
                :disabled="!services['honda-seguro-normal'].selected"
                :checkValue="services['honda-seguro-normal'].selected"
                :isSelectable="true"
                variant="honda-seguro-normal"
                :typeStyled="services['honda-plus-go'].selected ? 'reduced go' : 'reduced'"
              />

              <service-checkbox
                v-if="!isHondaMapitRegistration"
                title="Honda Seguro Ampliado"
                class="ml-5"
                :error="''"
                :disabled="!services['honda-seguro-plus'].selected"
                :checkValue="services['honda-seguro-plus'].selected"
                :isSelectable="true"
                variant="honda-seguro-plus"
                :typeStyled="services['honda-plus-go'].selected ? 'reduced go' : 'reduced'"
              />

              <service-checkbox
                v-if="!isHondaMapitRegistration"
                title="Honda Financial Services"
                class="ml-5"
                :error="''"
                :disabled="!services['honda-finance'].selected"
                :checkValue="services['honda-finance'].selected"
                :isSelectable="true"
                variant="honda-finance"
                :typeStyled="services['honda-plus-go'].selected ? 'reduced go' : 'reduced'"
              />
            </div>

            <v-divider class="text-divider" />
            <div v-if="services['honda-mapit'].selected">
              <div class="text-wrapper">
                <div class="text-title-section">
                  {{ t('services.honda_mapit') + ':' }}
                </div>
                <div class="text-description-section">
                  <span
                    v-for="(part, index) in services['honda-mapit'].value.match(/.{5}/g)"
                    :key="part"
                    >{{ part }}{{ index !== 2 ? '-' : '' }}</span
                  >
                </div>
              </div>
              <v-divider class="text-divider mb-10" />
            </div>
            <p class="label-proxima">{{ t('services.check_box_text') }}</p>
            <v-checkbox
              class="label-proxima"
              data-cy="chk-confirm"
              :label="t('services.check_box_text2')"
              v-model="isCheckBoxSelected"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </v-stepper-content>

    <mapit-dialog :dialog="dialog" @close="nextStep" :hideCloseButton="true">
      <template v-slot:header-icon>
        <v-icon class="text-secondary">fa fa-check</v-icon>
      </template>
      <template v-slot:header-text>
        <span>{{ t('confirm_popup.title') }}</span>
      </template>
      <template v-slot:body>
        <div
          :class="{ 'text-center': vehicle.demoBike }"
          v-html="t(`confirm_popup.${vehicle.demoBike ? 'body_demobike' : 'body'}`)"
        />
      </template>
      <template v-slot:footer-center>
        <v-btn
          class="btn-primary"
          :key="buttonKey"
          text
          data-cy="btn-next-confirm-popup"
          @click.once="nextStep"
          >{{ $t('buttons.next') }}</v-btn
        >
      </template>
    </mapit-dialog>

    <mapit-dialog :dialog="dialogError" @close="openCloseErrorDialog">
      <template v-slot:header-icon>
        <v-icon class="text-orange">fa fa-exclamation-triangle</v-icon>
      </template>
      <template v-slot:header-text>
        <span>{{ t('error_popup.title') }}</span>
      </template>
      <template v-slot:body>
        <div class="text-center">
          {{ t('error_popup.body') }}
        </div>
      </template>
      <template v-slot:footer-center>
        <v-btn class="btn-primary" text @click="openCloseErrorDialog">{{
          $t('buttons.cancel')
        }}</v-btn>
      </template>
    </mapit-dialog>

    <footer-actions :step="step">
      <template v-slot:left v-if="step > 1">
        <v-btn class="btn-gray" @click="backStep()" dark tabindex="-1">
          {{ $t('buttons.back') }}
        </v-btn>
      </template>
      <template v-slot:right v-if="step < 7">
        <v-btn
          class="btn-primary"
          data-cy="btn-confirm"
          :key="buttonKey"
          @click.once="createRegistration()"
          :disabled="!isCheckBoxSelected || loadingCreateRegistration"
          :loading="loadingCreateRegistration"
        >
          {{ $t('buttons.confirm-data') }}
        </v-btn>
      </template>
    </footer-actions>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseRegisterData } from '@/vuex/models'

import coreApi from '@/api/core.api'
import { RegistrationTypes } from '@/utils/constants'

import FooterActions from '../FooterActions'
import MapitDialog from '@/components/dialog/MapitDialog'
import ServiceCheckbox from '../services/ServiceCheckbox.vue'

export default {
  name: 'DetailHondaRegistration',
  components: { FooterActions, ServiceCheckbox, MapitDialog },
  props: ['step'],
  data() {
    return {
      isCheckBoxSelected: false,
      dialog: false,
      dialogError: false,
      loadingCreateRegistration: false,
      buttonKey: 1,
    }
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
    }),
    services() {
      return this.$store.getters.getServices
    },
    vehicle() {
      return this.$store.getters.getVehicle
    },
    account() {
      return this.$store.getters.getAccount
    },
    getRegistration() {
      return this.$store.getters.getRegistration
    },
    getGroup() {
      return this.$store.getters.getGroup
    },
    suggestions() {
      return this.$store.getters.getSubagentSuggestions
    },
    isHondaMapitRegistration() {
      return (
        this.$route.query.forcedType === RegistrationTypes.HONDA_MAPIT ||
        this.$route.query.forcedType === RegistrationTypes.HONDA_MAPIT_TRANSFERRED
      )
    },
    hasHondaMapit() {
      return this.services['honda-mapit'].selected
    },
    hasInsurance() {
      return (
        this.services['honda-seguro-normal'].selected || this.services['honda-seguro-plus'].selected
      )
    },
    hasValidPaymentMethod() {
      return this.account.paymentMethod?.id || this.hasPreviousPaymentMethods
    },
    hasPreviousPaymentMethods() {
      return this.account.stripeId && this.account.paymentMethods?.length
    },
    paymentMethodSection() {
      const { paymentMethod } = this.account || {}

      if (paymentMethod) {
        return {
          message: `${paymentMethod.sepa_debit.country}** **** **** **** **** ${paymentMethod.sepa_debit.last4}`,
        }
      }

      if (this.hasPreviousPaymentMethods) {
        return { message: this.t('customer.payment_method_added') }
      }

      if (this.hasHondaMapit) {
        return { message: this.t('customer.no_payment_method'), style: 'color-red' }
      }

      return { message: '-' }
    },
  },
  methods: {
    t(id) {
      return this.$t(`honda.registration.detail.${id}`)
    },
    openCloseErrorDialog() {
      this.dialogError = !this.dialogError
    },
    backStep() {
      this.$emit('backStep')
    },
    nextStep() {
      this.dialog = false
      this.$emit('nextStep')
      this.buttonKey++
    },
    async createRegistration() {
      this.$store.dispatch('setDealerId', this.getGroup.dealerId)

      if (this.loadingCreateRegistration) {
        this.buttonKey++
        return
      }

      this.loadingCreateRegistration = true

      try {
        const payload = parseRegisterData(this.getRegistration, this.getGroup)
        const response = await coreApi.createRegistration(payload)

        this.$store.dispatch('setId', response.headers.location)
        this.$store.dispatch('setPendingRegistrations', this.getGroup.dealerId)

        if (this.getRegistration.subagent.exist) {
          this.$store.dispatch('addSubagentSuggestion', this.getRegistration.subagent.name)
        }

        this.openCloseInfo()
      } catch (error) {
        console.log('Error in registration: ', error)
        this.openCloseErrorDialog()
      }

      this.loadingCreateRegistration = false
      this.buttonKey++
    },
    openCloseInfo() {
      this.dialog = !this.dialog
    },
  },
}
</script>

<style lang="scss" scoped>
.label-proxima {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 16px !important;
}
.text-title-section {
  color: black;
  font-size: 16px !important;
  letter-spacing: 0.48px;
  line-height: 20px;
  font-family: 'ProximaNovaExCnSbold', sans-serif;
  width: 25%;
}
.text-wrapper {
  display: flex;
  margin-top: 5px;
}

.input-wrapper {
  width: 100%;
}

.data-wrapper {
  width: 100%;
}

.section_wrapper {
  display: flex;
}

.text-description-section {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 16px !important;
  letter-spacing: 0px;
}
.text-divider {
  border-color: var(--v-gray-lighten2) !important;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
