
import {defineComponent, PropType, ref, toRefs, watch} from "@vue/composition-api";
import {TranslateResult} from "vue-i18n";

export default defineComponent({
  name: 'Tooltip',
  props: {
    text: {
      type: String as PropType<string | TranslateResult>,
      required: true,
    },
    position: {
      type: String as PropType<'top' | 'bottom' | 'left' | 'right'>,
      default: 'top',
    },
    width: {
      type: Number,
      default: 200,
    },
    attach: {
      type: String,
      default: undefined
    }
  },
  setup(props, {refs}) {
    const attachmentStyles = ref('')
    const isHovering = ref(false)

    const {attach: attachLocation} = toRefs(props)

    watch(isHovering, getAttachmentStyles)

    function getAttachmentStyles(isHovering: boolean) {
      if (!attachLocation.value || !isHovering) {
        attachmentStyles.value = ''
        return
      }

      const content = refs.content as HTMLDivElement
      const {top, left, right, bottom, height, width} = content.getBoundingClientRect()

      const horizontalOffset = () => {
        const padding = 12

        if (props.position === 'right') {
          return `left: ${left + padding + width}px;`
        }
        if (props.position === 'left') {
          return `right: ${right + padding + width}px;`
        }
        return ''
      }
      const verticalOffset = () => {
        if (props.position === 'top') {
          return `bottom: ${bottom + (height * 0.25)}px;`
        }
        if (props.position === 'bottom') {
          return `top: ${top + (height * 0.25)}px;`
        }
        if (props.position === 'left' || props.position === 'right') {
          return `top: ${top}px;`
        }
        return ''
      }

      attachmentStyles.value = `
        position: absolute;
        ${verticalOffset()}
        ${horizontalOffset()}
        --position: ${props.position};
        --reverse-position: ${reversePosition()};
        z-index: 1;
      `
    }

    function reversePosition() {
      if (props.position === 'top') {
        return 'bottom'
      }
      if (props.position === 'bottom') {
        return 'top'
      }
      if (props.position === 'left') {
        return 'right'
      }
      if (props.position === 'right') {
        return 'left'
      }
      return ''
    }

    return {attachmentStyles, isHovering, attachLocation, reversePosition}
  }
})
