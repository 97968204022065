





















































































































































































































































import printJS from 'print-js'
import { DateTime } from 'luxon'
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api'

import apiOffers from '@/api/offers.api'
import HondaOfferBuilder from '@mapit/honda-offers-builder'
import { OfferDoc, Offer, OfferType, Status, Pagination } from '@/types/offers'

import LabelTooltip from '@/components/text/LabelTooltip.vue'
import MChip from '@/components/atom/MChip/index.vue'
import MCopy from '@/components/atom/MCopy.vue'

import { downloadFile } from '@/helpers/downloadFile'
import { useGtm } from '@/plugins/gtm'
import { useOffersList } from '@/composables/offers-list'

import { customFormatDateOutput, RegistrationTypes } from '@/utils/constants'
import { InputTypes } from '@/utils/constants'
import { isRegistrationAllowed } from '@/utils/registration-helper'
import { mapGetters } from '@/utils/map-store'
import { isPastToday } from '@/utils/date-helper'
import { Variant } from '@/components/atom/MChip/types'

export default defineComponent({
  name: 'OfferTable',
  components: {
    LabelTooltip,
    MChip,
    MCopy,
  },
  setup(props, { root, emit }) {
    const gtm = useGtm()

    const showExpiredOffers = ref(false)
    const tablePagination = ref<Pagination>({ page: 1, itemsPerPage: 10 })

    const { getGroup: group, getSortBy: sortBy } = mapGetters(root.$store.getters)

    const {
      data: offerList,
      debounceRefetch,
      isLoading: offerListLoading,
      pagination,
      queryParams,
      refetch,
      text,
      range,
      total: totalOffers,
    } = useOffersList<OfferDoc>({
      dealerId: group.value.dealerId,
      dealerCode: group.value.code,
      pagination: {
        from: calculateFrom(tablePagination.value),
        size: tablePagination.value.itemsPerPage,
      },
      queryParams: {
        statuses: [
          Status.Pending,
          Status.Accepted,
          Status.COICreated,
          Status.Quote,
          Status.Ordered,
          Status.Invoiced,
        ],
      },
    })

    const headers = ref([
      {
        text: root.$t('offer-manager.offers-list.lbl-creation'),
        align: 'center',
        value: 'createDate',
        class: 'text-header px-0',
        width: '10%',
        sortable: true,
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-name-email'),
        align: 'left',
        value: 'client',
        class: 'text-header',
        width: '20%',
        sortable: true,
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-model'),
        align: 'left',
        value: 'vehicle.model',
        class: 'text-header',
        width: '10%',
        sortable: true,
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-num-offer'),
        align: 'center',
        value: 'offerCode',
        class: 'text-header',
        width: '10%',
        sortable: true,
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-attended-by'),
        align: 'left',
        value: 'attendedBy',
        class: 'text-header',
        width: '20%',
        sortable: true,
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-status'),
        align: 'left',
        value: 'status',
        class: 'text-header',
        width: '15%',
        sortable: true,
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-follow-up'),
        align: 'center',
        value: 'followUp',
        class: 'text-header',
        width: '10%',
        sortable: true,
      },
      {
        align: 'center',
        value: 'actions',
        class: 'text-header',
        width: '5%',
        sortable: false,
      },
    ])

    const MAX_HOLD_DURATION = 50
    const keyStopper = ref(false)
    const activeKeys = ref<Record<string, any>>({})
    const loading = ref(false)

    const filterStatus = ref<Array<{ text: string; value: Status }>>(
      Object.values(Status).map((status) => {
        return {
          text: root.$t(`offers.status.${status}`).toString(),
          value: status,
        }
      }),
    )

    const total = computed(() => {
      return totalOffers.value || 0
    })

    onMounted(() => {
      nextTick(() => {
        window.document.querySelector('.dashboard-body')?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      })
    })

    function getModel(model = ''): string {
      return model.split(' ')[0]
    }

    function onCopyClick() {
      root.$store.dispatch('showAlertSuccess', 'common.copy-success')
    }

    function canChangeToAccepted(status: Status) {
      return status === Status.Pending
    }

    function canChangeToRefused(status: Status) {
      return status === Status.Pending || status === Status.Accepted
    }

    function canCreateRegistration(status: Status) {
      return isRegistrationAllowed(status)
    }

    function viewOfferSummary(offer: Offer) {
      if (!offer?.id) {
        console.error('This offer does not exists', offer)
        return
      }

      gtm?.trackEvent({
        category: 'Offers',
        action: 'ViewOfferSummary',
        label: 'FromOffersList',
      })

      root.$router.push({ name: 'ResumeOfferMotorbike', params: { id: offer.id } })
    }

    async function editOffer(offerId: string) {
      gtm?.trackEvent({
        category: 'Offers',
        action: 'EditOffer',
        label: 'FromOffersList',
      })

      root.$store.commit('RESET_OFFER')
      await root.$store.dispatch('setGroupToOffer', group.value)
      await root.$store.dispatch('loadOfferById', offerId)

      root.$router.push({ name: 'EditOfferMotorbike', params: { id: offerId } })
    }

    function createRegistration(offerId: string) {
      gtm?.trackEvent({
        category: 'Offers',
        action: 'CreateRegistrationFromOffer',
        label: 'FromOffersList',
      })
      root.$router.push({
        name: 'HondaRegistration',
        query: { forcedType: RegistrationTypes.OFFER_REGISTRATION, offerId },
      })
    }

    function getFullName(item: OfferDoc) {
      return `${item.client?.name || ''} ${item.client?.surname || ''}`
    }

    function printPdf(offerId: string) {
      gtm?.trackEvent({
        category: 'Offers',
        action: 'PrintOffer',
        label: 'FromOffersList',
      })

      root.$store
        .dispatch('loadOfferSummaryById', { id: offerId, dealerId: group.value.dealerId })
        .then(({ pdf, code }) => {
          printJS({
            printable: pdf,
            type: 'pdf',
            documentTitle: `${group.value.code}-${code?.split('-')[1] ?? offerId}.pdf`,
          })
        })
    }

    function downloadPdf(offer: Offer) {
      gtm?.trackEvent({
        category: 'Offers',
        action: 'DownloadOffer',
        label: 'FromOffersList',
      })

      root.$store
        .dispatch('loadOfferSummaryById', { id: offer.id, dealerId: group.value.dealerId })
        .then(({ pdf, code }) => {
          downloadFile(pdf, `${group.value.code}-${code?.split('-')[1] ?? offer.id}.pdf`)
        })
    }

    async function changeStatus(offer: Offer, status: Status) {
      if (!loading.value) {
        gtm?.trackEvent({
          category: 'Offers',
          action: `EditStatusOffer`,
          label: `${offer.id}-${status}`,
        })

        try {
          loading.value = true
          const fullOffer = await apiOffers.getOfferById(offer.id || '', group.value.dealerId)
          const newOffer = new HondaOfferBuilder().fromOffer(fullOffer.data)
          newOffer.status = status

          await apiOffers.editOffer(offer.id || '', newOffer.toOffer(), group.value.dealerId)

          emit('refreshList')
          loading.value = false

          nextTick(() => {
            // Visual change if not refreshed
            offer.status = status
            offer.updatedStatusFromEmail = false
          })
        } catch (err) {
          console.log(err) /* Show error popup */
          loading.value = false
        }
      }
    }

    function calculateFrom({ page, itemsPerPage }: Pagination) {
      return !page ? 0 : (page - 1) * (itemsPerPage || 10)
    }

    function updatePagination({ page, itemsPerPage }: Pagination) {
      if (page) {
        pagination.value.from = calculateFrom({ page, itemsPerPage: pagination.value.size })
        tablePagination.value.page = page
      }

      if (itemsPerPage) {
        pagination.value.size = itemsPerPage
        tablePagination.value.itemsPerPage = itemsPerPage
      }
    }

    function onPageChange(page: any) {
      updatePagination({ page })
    }

    function onItemsPerPageChange(itemsPerPage: any) {
      updatePagination({ itemsPerPage })
    }

    function onSortByChange(options: any) {
      let [column] = options.sortBy

      if (column === 'client') {
        column = 'user'
      }

      if (column === 'vehicle.model') {
        column = 'model'
      }

      if (column === 'followUp') {
        options.sortDesc = [true]
      }

      // update request params
      const sort = options.sortDesc[0] === false ? 'asc' : 'desc'
      pagination.value.column = column
      pagination.value.sort = sort

      // call the API
      debounceRefetch()

      // update store
      root.$store.dispatch('setSortBy', { column, sort })
    }

    function onTextKeydown(e: KeyboardEvent) {
      if (!activeKeys.value[e.code] && e.code !== 'Backspace') {
        activeKeys.value[e.code] = Date.now()
      }

      if (keyStopper.value) {
        e.preventDefault()
        return
      }

      if (Date.now() - activeKeys.value[e.code] > MAX_HOLD_DURATION) {
        e.preventDefault()
        keyStopper.value = true
      }

      if (e.key === 'Enter') {
        e.preventDefault()
        refetch()
      }
    }

    function onTextKeyup({ code }: KeyboardEvent) {
      keyStopper.value = false
      delete activeKeys.value[code]
    }

    function getRowClass(item: OfferDoc) {
      return `click row-${item.id}`
    }

    function hasBeenResponded(offer: OfferDoc) {
      return offer.updatedAtStatusAccepted || offer.updatedAtStatusRefused
    }

    function statusChip(offer: any) {
      const text = root.$t(`offers.status.${offer.status}`)
      const variant = offer.expired ? Variant.Disabled : undefined

      if (offer.status === Status.Pending && hasBeenResponded(offer)) {
        return {
          icon: 'fa-pencil',
          text,
          variant: variant || Variant.Warning,
          tooltip: root.$t(`offers.tooltip.pending-updated`),
        }
      }

      if (offer.status === Status.Pending && !hasBeenResponded(offer)) {
        return {
          icon: 'fa-hourglass',
          text,
          variant: variant || Variant.Warning,
        }
      }

      if (offer.status === Status.Accepted && offer.updatedStatusFromEmail) {
        return {
          icon: 'fa-envelope',
          text,
          variant: variant || Variant.Info,
          tooltip: root.$t('offers.tooltip.accepted-email'),
        }
      }

      if (offer.status === Status.Refused && offer.updatedStatusFromEmail) {
        return {
          icon: 'fa-envelope',
          text,
          variant: Variant.Error,
          tooltip: root.$t('offers.tooltip.rejected-email'),
        }
      }

      if (offer.status === Status.Refused) {
        return {
          icon: 'fa-xmark',
          text,
          variant: Variant.Error,
        }
      }

      if (offer.status === Status.Completed) {
        return {
          text,
          variant: Variant.Success,
        }
      }

      return {
        text,
        variant: variant || Variant.Info,
      }
    }

    watch(
      [text, range, queryParams],
      () => {
        updatePagination({ page: 1 }) // reset pagination
      },
      { deep: true },
    )

    return {
      activeKeys,
      canChangeToAccepted,
      canChangeToRefused,
      canCreateRegistration,
      changeStatus,
      createRegistration,
      downloadPdf,
      editOffer,
      filterStatus,
      getFullName,
      getModel,
      getRowClass,
      hasBeenResponded,
      headers,
      InputTypes,
      isPastToday,
      keyStopper,
      loading,
      offerList,
      offerListLoading,
      OfferType,
      onCopyClick,
      onItemsPerPageChange,
      onPageChange,
      onSortByChange,
      onTextKeydown,
      onTextKeyup,
      pagination,
      printPdf,
      queryParams,
      range,
      showExpiredOffers,
      sortBy,
      Status,
      statusChip,
      tablePagination,
      text,
      total,
      viewOfferSummary,
    }
  },
})
