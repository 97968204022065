import ridoApi from '@/api/rido.api'
import {ContactsApi} from '@/api/contacts.api'
import {findSecondLevelRegionById} from '@/utils/administrative-region-helper'
import { ContactAddress, ContactWithExtras, Lead } from '@/types'

export const searchContactAndLeadByLeadId = async (leadId: string): Promise<{contact: ContactWithExtras, lead: Lead}> => {
  const {data: lead} = await ridoApi.getLead(leadId)
  let contact = {
    gdprConsent: true,
    privacyTermsAccepted: true,
  } as ContactWithExtras
  if(lead.prospect.phone) {
    try {
      const { data: { data } } = await new ContactsApi().getContactByPhone(lead.prospect.phone)
      if ( data.length > 0 ) {
        contact = {
          ...contact,
          ...data[ 0 ]
        }
      }
    } catch(e) {
      console.error("Contact not found for this lead")
    }
  }
  if(lead.prospect.firstName) contact.firstName = lead.prospect.firstName
  if(lead.prospect.lastName) contact.lastName = lead.prospect.lastName
  if(lead.prospect.dob) contact.dob = lead.prospect.dob
  if(lead.prospect.phone) contact.phone = lead.prospect.phone
  if(lead.prospect.email) contact.email = lead.prospect.email
  if(!contact.address) contact.address = {} as ContactAddress
  if(lead.prospect.address?.streetAddress) contact.address.streetAddress = lead.prospect.address.streetAddress
  if(lead.prospect.address?.addressNumber) contact.address.addressNumber = lead.prospect.address.addressNumber
  if(lead.prospect.address?.postalCode) contact.address.postalCode = lead.prospect.address.postalCode
  if(lead.prospect.address?.addressLocality) contact.address.addressLocality = lead.prospect.address.addressLocality
  if(lead.prospect.address?.addressRegion) contact.address.addressRegion = findSecondLevelRegionById(lead.prospect.address.addressRegion)?.name
  if(lead.prospect.address?.country) contact.address.country = lead.prospect.address.country

  return {
    contact,
    lead
  }
}
