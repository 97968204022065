<template>
  <loans-table @refreshList="refreshLoansList" />
</template>

<script>
import LoansTable from './LoansTable.vue'

export default {
  name: 'LoansList',
  components: { LoansTable },
  data() {
    return {}
  },
  methods: {
    refreshLoansList() {
      this.$store.dispatch('loadOnGoingDealerBikeLoansList')
    },
  },
  created() {
    this.refreshLoansList()
  },
}
</script>
