<template>
  <v-dialog :value="active" max-width="940px" >
    <v-card>
      <v-card-title>
        <div class="modal-title h4">{{title}}</div>
        <v-spacer/>
        <i @click="$emit('close')" data-cy="close-modal" class="fa fa-times fa-lg float-right" aria-label="Close"></i>
      </v-card-title>
      <v-card-text>
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "Modal",
    props: {
      active: {
        type: Boolean,
        required: true
      },
      title: {
        type: String
      }
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
  .modal-style{
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
  }

  .v-card__text {
    color: black !important;
    font-size: 15px;
  }
</style>
