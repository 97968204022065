<template>
  <th v-if="isHeader">
      {{title}}
  </th>
  <td class='column-500' v-else>
		<div class="align-left" v-if="rowData">
			<div class="col-12 p-l-15 p-r-15 ">
				<span class="f-s-14">
          {{getDay(rowData.request)}}
        </span><br/>
			</div>
		</div>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
      rowData: {
        type: Object,
        default: () => {
          return { request: { pickup: { day: '-'} } };
        }
      }
  },
  methods:{
    getDay: (request) => {
      return (request && request.pickup)? request.pickup.day:'';
    }
  },
  name: 'Day'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>

