<template>
    <v-row>
        <v-col class='column col-6 col-md-6 col-sm-12'>
            <v-row no-gutters>
                <div class='col-12 subtitle'>
                    {{title1}}
                </div>
                <div class='col-12'>
                   <date-picker :clearable="$attrs.clearable"
                                v-model='interval.day'
                                :disabled="$attrs.disabled"
                                @input='getTime(containerId, interval.day, apiFunction)'
                                :notBefore='notBefore'
                                :init-value="initValue"
                                />
                </div>

            </v-row>
        </v-col>
        <v-col class='column col-6 col-md-6 col-sm-12'>
            <v-row no-gutters>
                <v-col class='col-12 subtitle'>
                    {{title2}}
                </v-col>
                <v-col class='col-12'>
                    <select :disabled="$attrs.disabled" v-model='interval.hour' class='form-select'>
                        <option v-for="option in hours" :key='option.key' :value="option.value" :selected='option.selected'>{{option.value}}</option>
                    </select>
                    <div v-if="!found">
                        <small style='color:red;'>{{getTimeScheduleString()}}</small>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>

import DatePicker from '@/components/general/DatePicker';
import moment from 'moment';


export default {
    name: 'TimeIntervalForm',
    components:{
        DatePicker
    },
    props: {
      interval: {
        type: Object,
        default: () => ({})
      },
      day: {
        type: Object,
        default: () => {
          return { request: { appointment: { day: null} } };
        }
      },
      title1:{
          type: String,
          default: 'Fecha de recogida'
      },
      title2:{
          type: String,
          default: 'Hora de recogida'
      },
      containerId:{
          type:String
      },
      apiFunction: {
          type: Function,
          default: () =>{

          }
      },
      notBefore: Date,
      initValue: Date,
    },
    data: () => {
        return { hours:[], time: null, found: true, intervals: null};
    },
    created() {
      this.getTime(this.containerId, this.interval.day, this.apiFunction);
    },
    methods:{
        getTime: function (containerId, changed, apiFunction) {
            if (!changed){
                this.hours = [];
                this.time = null;
                this.found = true;
            }
            else{
                const self = this;
                apiFunction(containerId, moment(changed).format('YYYYMMDD'), (err, result) => {
                    if (err) {
                        //alert(err);
                    }
                    else{
                        this.hours = result.schedule.map((item, index)=>{
                            if (index == 0){
                                this.time = {key:index, value:item};
                            }
                            return {key:index, value:item};
                        });

                        const found = self.hours.find((elem)=>{
                            return elem.value === self.interval.hour;
                        });

                        self.found = !self.interval.hour ||  self.interval.hour && found? true: false;

                        if (!self.found && self.interval.hour){
                            const intervals = this.generateIntervals(this.hours);
                            this.hours.unshift({key:0, value:self.interval.hour});
                            this.intervals = intervals;
                        }
                    }
                });
            }
        },
        generateIntervals: function(hours){
            let intervals = [];

            for(let i = 0; hours && i < hours.length; i++){
                let x = hours[i].value;
                let t0 = moment(x,"HH:mm");
                let j = i + 1;
                let y ='';

                for (; j < hours.length; j++){
                    y = hours[j].value;
                    const t1 = moment(y, "HH:mm");
                    if (!(t0.get('hour') == t1.get('hour') || t0.get('hour') + 1  == t1.get('hour'))){
                        y = hours[j - 1].value;
                        break;
                    }
                    t0 = t1;
                }
                i = j - 1;
                intervals.push(x + ' a ' + y)
            }

            return intervals
        },

        getTimeScheduleString: function (){
            let ret = 'El horario anteriormente asignado no es válido';

            if (this.intervals && this.intervals.length > 0){
                ret = ret + ". El horario es de " + this.intervals[0];
                if(this.intervals.length == 2){
                    ret = ret + ' y de ' + this.intervals[1];
                }
            }

            return ret;
        }
    }
}
</script>
<style scoped>
    .mx-datepicker{
        width:100%;
    }

    .subtitle{
        font-family: 'ProximaNovaExCnLight','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 14px;
        color: #888;
    }
</style>
