import apiStocks from '@/api/stocks.api'

const state = {
  orders: [],
}

const getters = {
    getOrders: state => state.orders,
}

const actions = {
  loadOrdersList: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apiStocks
        .getOrders()
        .then(({data}) => {
          commit('SET_ORDERS_LIST', data.data)
          resolve(data.data)
        })
        .catch(e => {
          commit('SET_ORDERS_LIST', [])
          reject(e)
        })
    })
  },
}

const mutations = {
  SET_ORDERS_LIST: (state, orders) => {
    state.orders = orders
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
