import { RegistrationOrigin } from '@mapit/core-dynamodb/dist/types'
import { isEmpty, isNumeric, isAlphanumeric, isLength, isNoRequiredAlphanumeric, lengthEqualTo, isEmail, isValidBirth, isValidDocument, isRequired } from '../../helpers/validations'

export default {
    id: null,
    dealerId: null,
    subtype: 'Honda',
    hasGoModel: false,
    origin: RegistrationOrigin.Sale,
    deviceTransfer: {
        id: ''
    },
    currentStep: 1,
    vehicle: {
        vin: {
            value: "",
            rules: [
                e => isEmpty(e, "honda.registration.vehicle.validations.vin_empty"),
                e => isAlphanumeric(e, "honda.registration.vehicle.validations.vin_incorrect_format"),
                e => lengthEqualTo(e, 17, 'honda.validation.fields.vin')
            ],
            status: null,
        },
        registrationNumber: {
            value: "",
            rules: [
                isEmpty,
                e => isAlphanumeric(e, "honda.registration.vehicle.validations.registration_number_incorrect_format"),
            ]
        },
        model: {
            value: "",
            rules: [
                e => isRequired(e)
            ]
        },
        key: {
            value: "",
            rules: [
                isNoRequiredAlphanumeric,
                e => isLength(e, 101)
            ]
        },
        modelId: null,
        demoBike: false,
        forcedDemoBike: false
    },
    offerNumber: {
        value: "",
        rules: [
            isNumeric,
            e => lengthEqualTo(e, 6)
        ]
    },
    subagent: {
        name: "",
        exist: undefined,
        rules: [
            e => isEmpty(e, "honda.registration.vehicle.validations.subagent_empty"),
            e => isLength(e, 101)
        ]
    },
    device: {
        id: null,
        imei: null
    },
    account: {
        id: null,
        stripeId: null,
        withoutEmail: false,
        email: {
            value: "",
            rules: [
                isEmail,
            ]
        },
        firstName: {
            value: "",
            rules: [
                e => isEmpty(e, "honda.registration.account.account_form.error.name_empty"),
                e => isLength(e, 101)
            ]
        },
        lastName: {
            value: "",
            rules: [
                e => isEmpty(e, "honda.registration.account.account_form.error.lastName_empty"),
                e => isLength(e, 101)
            ]
        },
        dob: {
            value: '',
            rules: [
                e => isEmpty(e, "honda.registration.account.account_form.error.dob_empty"),
                e => isValidBirth(e, "honda.registration.account.account_form.error.dob_empty")
            ]
        },
        phone: {
            value: '',
            rules: [
                e => isEmpty(e, "honda.registration.account.account_form.error.phone_empty"),
                e => isLength(e, 21)
            ]
        },
        document: {
            value: '',
            rules: [
                e => isEmpty(e, "honda.registration.account.account_form.error.document_empty"),
                e => isLength(e, 21)
            ],
            rulesOther: [
                e => isEmpty(e, "honda.registration.account.account_form.error.document_empty")
            ]
        },
        address: {
            addressNumber: {
                value: '',
                rules: [
                    e => isLength(e, 51)
                ]
            },
            addressLocality: {
                value: undefined,
                rules: [
                    e => isRequired(e)
                ]
            },
            addressRegion: {
                value: undefined,
                rules: [
                    e => isRequired(e)
                ]
            },
            streetAddress: {
                value: '',
                rules: [
                    e => isEmpty(e, "honda.registration.account.account_form.error.address_empty"),
                    e => isLength(e, 151)
                ]
            },
            postalCode: {
                value: '',
                rules: [
                    e => isEmpty(e, "honda.registration.account.account_form.error.postal_code_empty"),
                ],
            },
        },
        documentType: {
            value: 'NIF',
            rules: []
        },
        iban: {
            value: undefined,
            rules: [
                e => isEmpty(e, "honda.registration.account.account_form.error.iban_empty"),
                e => isLength(e, 35)
            ]
        },
        paymentMethod: null,
        paymentMethods: [],
    },
    services: {
        "honda-plus": {
            key: 'HP',
            selected: false,
        },
        "honda-plus-go": {
            key: 'HPG',
            selected: false,
        },
        "honda-mapit": {
            key: 'HM',
            selected: true,
            value: '',
            rules: [
            ]
        },
        "honda-seguro-normal": {
            key: 'INS',
            selected: false
        },
        "honda-seguro-plus": {
            key: 'INS_COM',
            selected: false
        },
        "honda-finance": {
            key: 'FIN',
            selected: false
        },
    },
    exDealerBike: null
}

