import {Document} from "@mapit/dealer-bikes-dynamodb/dist/types"
import MapitS3Client from "@/helpers/mapit-s3-client"
import {Credentials} from "@aws-sdk/types"

export class DealerBikeHelper {
  private readonly credentials?: Credentials

  constructor(credentials?: Credentials) {
    this.credentials = credentials
  }
  async fetchLoanDocument(document: Document): Promise<{ blob: Blob }> {
    const docKey = document?.key
    const s3Client = new MapitS3Client(this.credentials)
    const res = await s3Client.getObjectAsBlob({
      Bucket: process.env.VUE_APP_S3_DEALER_DATA_BUCKET,
      Key: docKey
    })
    return {
      blob: res.blob
    }
  }
}
