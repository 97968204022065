export default {
  externalLinks: {
    privacyPolicyUrl: "https://www.honda.es/motorcycles/useful-links/privacy-policy.html"
  },
  finance: {
    supported: true
  },
  hondaPlus: {
    supported: true
  },
  maintenance: {
    supported: true
  },
  transfer: {
    supported: true
  }
}
