<template>
  <v-data-table id="transferred-table-list"
    :loading="loading"
    :loading-text="$t('honda.registration.list.loading')"
    :headers="headers"
    :items="filteredTransfers"
    :items-per-page="10"
    :sort-by="['createdAt']"
    :sort-desc="[true]"
    v-model="selected"
    single-select
    class="elevation-0"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'fas fa-angle-double-left',
      lastIcon: 'fas fa-angle-double-right',
      prevIcon: 'fas fa-angle-left',
      nextIcon: 'fas fa-angle-right',
      'items-per-page-text': $t('honda.registration.list.footer_num_x_page'),
      'items-per-page-options': [10, 25, 50, -1],
    }"
    :no-data-text="$t(transfersList && transfersList.length > 0 ? 'honda.registration.transferred.no_data' : 'honda.registration.list.no_data', {search})"
    :no-results-text="$t('honda.registration.list.no_data')"
    @click:row="selectTransfer"
    data-cy="list-transferred-devices"
  >
    <template #footer.page-text="props">
      {{props.pageStart}}-{{props.pageStop}} {{$t('honda.registration.list.of')}} {{props.itemsLength}}
    </template>
    <template v-slot:item.createdAt="{ item }">
      <v-icon class="icon-mapit" :class="isSelected(item)  ? 'text-secondary' : ''"> {{ isSelected(item) ? 'O' : 'M' }}</v-icon>
    </template>
    <template v-slot:item.vehicle.model="{ item }">
      <span class="text-item">{{item.vehicle.model}}</span>
    </template>
    <template v-slot:item.vehicle.vin="{ item }">
      <span class="text-item">{{item.vehicle.vin}}</span>
    </template>
    <template v-slot:item.vehicle.registrationNumber="{ item }">
      <span class="text-item">{{item.vehicle.registrationNumber}}</span>
    </template>
    <template v-slot:item.device.imei="{ item }">
      <span class="text-item">{{item.device.imei}}</span>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment'
import coreApi from '@/api/core.api'
import { DeviceTransferStatus } from '@mapit/core-dynamodb/dist/types'

export default {
  name: 'TransferredTable',
  components: {},
  props: ['search'],
  data() {
    return {
      loading: false,
      headers: [
        {
          text: '',
          value: 'createdAt',
          align: 'center',
          width: '5%',
          sortable: false
        },
        {
          text: this.$t('honda.registration.transferred.header_registration_number'),
          align: 'start',
          value: 'vehicle.registrationNumber',
          class: 'text-header',
          width: '25%',
          sortable: false
        },
        {
          text: this.$t('honda.registration.transferred.header_vin'),
          align: 'start',
          value: 'vehicle.vin',
          class: 'text-header',
          width: '25%',
          sortable: false
        },
        {
          text: this.$t('honda.registration.transferred.header_model'),
          align: 'start',
          value: 'vehicle.model',
          class: 'text-header',
          width: '20%',
          sortable: false
        },
        {
          text: this.$t('honda.registration.transferred.header_mapit_device'),
          align: 'start',
          value: 'device.imei',
          class: 'text-header',
          width: '25%',
          sortable: false
        },
      ],
      selected: [],
      transfersList: []
    }
  },
  watch: {
    filteredTransfers: function(val) {
      this.$emit('changeResults', val.length)
    }
  },
  computed: {
    filteredTransfers() {
      return this.search ? this.transfersList.filter(
        ({ vehicle: { model, vin, registrationNumber }, device: { imei } }) =>
          model.includes(this.search) ||
          vin.includes(this.search) ||
          registrationNumber.includes(this.search) ||
          imei.includes(this.search),
      ) : this.transfersList
    },
    group() {
      return this.$store.getters.getGroup;
    },
  },
  created() {
    this.loading = true
    coreApi.listVehiclesTransferredByDealer(this.group.dealerId, DeviceTransferStatus.Pending)
      .then(res => {
        this.transfersList = res.data.filter(item => !item.registration?.id)
        this.loading = false
      })

  },
  methods: {
    isSelected(item){
      return this.selected && this.selected.length > 0 && this.selected[0].id === item.id
    },
    selectTransfer(item) {
      if(this.isSelected(item)){
        this.selected = []
        this.$emit('changeSelected', null)
      } else {
        this.selected = [item]
        this.$emit('changeSelected', item)
      }
    }
  }
}
</script>

<style lang="scss">
#transferred-table-list{
  td {
    height: 80px !important;
  }
  .text-header {
    letter-spacing: 0;
    color: var(--v-gray-lighten5);
    text-align: left;
    font: normal normal 600 16px/20px 'ProximaNovaExCnSBold';
    text-transform: uppercase;
    opacity: 1;
  }
  .text-item {
    letter-spacing: 0;
    text-align: left;
    font: normal normal normal 16px/26px 'ProximaNovaRegular';
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .icon-mapit{
    text-align: center;
    letter-spacing: 0px;
    color: var(--v-gray-base);
    opacity: 1;
  }
  .text-secondary {
    color: var(--v-secondary-base) !important;
  }
  .v-data-table__empty-wrapper {
    font: normal normal normal 16px/26px 'ProximaNovaRegular';
    letter-spacing: 0px;
    color: black;
    opacity: 1;
  }
}
</style>
