<template>
  <v-navigation-drawer
    v-model="drawer"
    @input="updateDrawerState"
    fixed
    right
    temporary
    id="user-drawer"
  >
    <div class="full-height d-flex align-content-space-between flex-column">
    <v-list nav dense>
      <v-list-item two-line v-if="user" data-cy="drawer-user">
        <v-list-item-avatar id="user-avatar">
          <v-icon dense color="primary">fa-light fa-user</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="bold-title">{{ fullname }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.login }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2" />

      <v-list-item data-cy="drawer-editAccount" v-if="permissions.dealerProfile.canSee" to="/profile">
        <v-list-item-icon>
          <v-icon dense color="primary">fa-light fa-pen-to-square</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.edit-account")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'Solicitudes' }" data-cy="drawer-logout" v-if="permissions.requests.canSee">
        <v-list-item-icon>
          <v-icon dense color="primary">fa-light fa-message-lines</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.requests")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'DataImport' }" data-cy="drawer-logout" v-if="permissions.imports.canSee">
        <v-list-item-icon>
          <v-icon class="icon-mapit" color="primary" >&</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.data-import")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'UsersManager' }" v-if="permissions.userCredentials.canSee">
        <v-list-item-icon>
          <v-icon dense color="primary">fa-light fa-users-gear</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
              $t("menu.users-manager")
            }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'StocksManager' }" v-if="permissions.stocks.canSee">
        <v-list-item-icon>
          <v-icon dense color="primary">fa-light fa-hand-holding-box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
              $t('menu.stocks')
            }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click.prevent="exit" data-cy="drawer-logout" v-if="user">
        <v-list-item-icon>
          <v-icon dense color="primary">fa-light fa-arrow-right-from-bracket</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.sign-out")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <version class="mt-auto ma-2"/>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {Auth} from 'aws-amplify'
import {mapState} from "vuex";
import Version from "@/components/Version"

export default {
  name: 'DrawerUserMenu',
  components: {Version},
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['permissions']),
    drawer: {
      get () {
        return this.$store.getters.getUserDrawerState
      },
      set (val) {
        this.$store.dispatch('setUserDrawerState', val)
      }
    },
    fullname(){
        return this.group && this.group.name ? this.group.name : '';
    },
    group() {
      return this.$store.getters.getGroup;
    },
    user() {
      return this.$store.getters.getUser;
    },
    allowImport() {
      return this.$store.getters.isImportUser
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isDealerAdmin() {
      return this.$store.getters.isDealerAdmin
    }
  },
  methods: {
    updateDrawerState(state){
        this.$store.dispatch('setUserDrawerState', state)
    },
    exit() {
      return Auth.signOut().then(() => {
        this.$store.dispatch('resetStore');
        this.$store.dispatch('setUserDrawerState', false)
        this.$store.dispatch('logout');
        this.$router.push({name: 'Login'});
      })
    },
  }
};
</script>

<style lang="less">
#user-drawer {
  background-color: white;
  * {
    text-decoration: none !important;
  }
  i {
    width: 28px;
    height: 28px;
  }
  #user-avatar{
    width: auto !important;
    min-width: 0 !important;
    margin-right: 15px;
  }
  .v-subheader {
    color: #333333 !important;
  }
  .v-list--nav {
    .v-list-item{
      padding: 0 6px;
    }
  }
  .v-list-item__avatar {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .v-list-item__icon {
    margin-right: 10px;
    margin-left: 10px;
  }
  .v-list-item__title {
    font-size: 14px;
    line-height: 1.5rem;
    color: #333333 !important;
    &.bold-title {
      font-family: 'ProximaNovaSemibold', sans-serif !important;
    }
  }
  .v-list-item__content{
    padding: 0;
    .v-list-item__title{
      color: black;
      font-family: 'ProximaNovaRegular', sans-serif;
    }
    .v-list-item__subtitle {
      font-size: 12px;
      color: #555555 !important;
      font-family: 'ProximaNovaRegular', sans-serif;
    }
  }
  .v-divider {
      background-color: rgba(255,255,255, 0.4);
  }
}
</style>
