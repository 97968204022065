<template>

	<div class="Solicitudes col-12" >
		<div class="form-card mobilestyle ultra-high-size" >
			<div class="form form-horizontal centered" @submit.prevent="">
			    <repairs-list></repairs-list>
			</div>
		</div>
	</div>

</template>

<script>
  import RepairsList from '@/components/repairs/RepairsList';


  export default {
    components: {
      RepairsList
    }
  };
</script>


