
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  name: 'RegistrationStatusCard',
  props: {
    valid: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
