

















































import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import { InputTypes } from '@/utils/constants'
import MapitInput from '@/components/general/MapitInput.vue'
import {defineComponent, computed} from "@vue/composition-api";
import {mapGetters} from "@/utils/map-store";

export default defineComponent({
  name: 'DeliveredVehicle',
  components: { ExpansionPanel, MapitInput },
  props: {
    panelReadonly: {
      type: Boolean,
      default: false
    },
    panelHideDivider: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root, emit }) {

    const {
      getDeliveredBrand: deliveredBrand,
      getDeliveredModel: deliveredModel,
      getDeliveredAmount: deliveredAmount
    } = mapGetters(root.$store.getters)

    const deliveredVehicleRules = computed( () => [
      () =>
        deliveredBrand.value  && deliveredModel.value && deliveredAmount.value
        || !deliveredBrand.value && !deliveredModel.value && !deliveredAmount.value
        || 'Error rules'
    ])

    const validDeliveredBike = computed(() =>
      deliveredBrand.value && deliveredModel.value && deliveredAmount.value
      || !deliveredBrand.value && !deliveredModel.value && !deliveredAmount.value
    )

    function updateOffer(action: string, value: any){
      root.$store.dispatch(action, value)
      emit('validateForm')
    }

    return {
      InputTypes,
      deliveredVehicleRules,
      validDeliveredBike,
      deliveredBrand,
      deliveredModel,
      deliveredAmount,
      updateOffer
    }
  }
})
