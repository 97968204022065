<template>
  <v-toolbar class="wrapper-toolbar-card">
    <v-toolbar-title class="title-toolbar-card">
      {{ title }}
    </v-toolbar-title>

    <v-btn icon @click="$emit('openCloseCancelDialog')" v-if="!hideCloseBtn" class="close-button" data-cy="toolbar-card-btn-exit">
      <v-icon class="icon-mapit">x</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  props: ['title', 'hideCloseBtn' ]
}
</script>

<style scoped>
.close-button {
  position: absolute !important;
  right: 12px;
}
.wrapper-toolbar-card {
  box-shadow: none !important;
}
.title-toolbar-card {
  flex: 1;
  text-align: center;
  color: var(--v-primary-base);
  font-size: 28px !important;
  text-transform: uppercase;
  font-family: 'ProximaNovaExCnSbold', sans-serif;
  letter-spacing: -0.01em;
  line-height: 1.8;
}
</style>
