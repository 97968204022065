







































































































































































































































































































































































import moment from "moment/moment";
import { computed, defineComponent, PropType, Ref, ref, watch } from "@vue/composition-api";

import coreApi from '@/api/core.api.js';
import registrationsApi from "@/api/registrations.api";

import { Product, RegistrationResolutionStatus } from "@mapit/core-dynamodb/dist/types";
import { RegistrationArchivedFilters, RegistrationDoc, RegistrationFilters } from "@/types";

import { downloadSignedUrlFiles } from "@/helpers/downloadFile.js";
import { generateUUID } from "@/utils/utils";
import { mapGetters } from "@/utils/map-store";
import { useTableList } from "@/composables/table-list";

import MapitDialog from "@/components/dialog/MapitDialog.vue";

export default defineComponent({
  name: "RegistrationsTable",
  components: {MapitDialog},
  props: {
    filterList: {
      type: Array as PropType<Array<RegistrationFilters | RegistrationArchivedFilters>>,
      required: true
    },
    defaultFilters: {
      type: Array as PropType<Array<RegistrationFilters | RegistrationArchivedFilters>>,
      required: false,
      default: () => []
    }
  },
  setup(props, {root}) {
    const {
      getGroup: group,
      getLocale: locale
    } = mapGetters(root.$store.getters)

    const filters = ref(props.defaultFilters) as Ref<Array<RegistrationFilters | RegistrationArchivedFilters>>
    const loading = ref(false)
    const currentPage = ref(1)

    const {
      data: registrationsList,
      total: totalRegistrations,
      isLoading: registrationListLoading,
      text,
      queryParams,
      pagination,
      refetch,
      debounceRefetch
    } = useTableList<RegistrationDoc>({
      dealerId: group.value.dealerId,
      dealerCode: group.value.code,
      pagination: {
        from: 0,
        size: 10
      },
      queryParams: {
        archived: false,
        accountRegistrationCompleted: false,
        contractsSigned: false,
        deviceHealthChecked: false,
        resolutionStatus: RegistrationResolutionStatus.ActionsNeeded,
      },
      fetchFn: (payload) => registrationsApi.getRegistrations(payload)
    })
    const loadingFinishRegistration = ref(false)
    const loadingDeletingRegistration = ref(false)
    const headers = ref([
      {
        text: '',
        align: 'center',
        sortable: false,
        value: 'star',
        width: '50px',
        class: 'text-header no-padding-right'
      },
      {
        text: root.$t('honda.registration.list.table.header-date'),
        align: 'left',
        value: 'createdAt',
        class: 'text-header',
        sortable: false
      },
      {
        text: root.$t('honda.registration.list.table.header-client'),
        align: 'left',
        value: 'account.firstName',
        class: 'text-header',
        sortable: false
      },
      {
        text: root.$t('honda.registration.list.table.header-model'),
        align: 'left',
        value: 'vehicle.model',
        class: 'text-header',
        sortable: false
      },
      {
        text: root.$t('honda.registration.list.table.header-matricula'),
        align: 'left',
        value: 'vehicle.registrationNumber',
        class: 'text-header',
        sortable: false
      },
      {
        text: root.$t('honda.registration.list.table.header-services'),
        align: 'center',
        value: 'products',
        class: 'text-header',
        sortable: false
      },
      {
        text: root.$t('honda.registration.list.table.header-signature'),
        align: 'center',
        value: 'contractsSigned',
        class: 'text-header',
        width: '85px',
        sortable: false
      },
      {
        text: root.$t('honda.registration.list.table.header-registration'),
        align: 'center',
        value: 'accountRegistrationCompleted',
        class: 'text-header',
        width: '85px',
        sortable: false
      },
      {
        text: root.$t('honda.registration.list.table.header-comms'),
        align: 'center',
        value: 'hasComms',
        class: 'text-header no-padding-left',
        width: '85px',
        sortable: false
      },
      {
        text: root.$t('leads.list.action'),
        align: 'center',
        value: 'actions',
        class: 'text-header',
        sortable: false,
      },
    ])
    const MAX_HOLD_DURATION = 50
    const keyStopper = ref(false);
    const activeKeys = ref<Record<string, any>>({});
    const resultStringPopup = ref("error")
    const dialogResult = ref(false)
    const dialogArchived = ref(false)
    const dialogConfirm = ref(false)
    const selectedToFinish = ref(null) as Ref<RegistrationDoc | null>
    const itemToUnarchive = ref(null) as Ref<RegistrationDoc | null>
    const pendingToDelete = ref(null) as Ref<RegistrationDoc | null>
    const dialogConfirmDelete = ref(false)

    const total = computed(() => {
      return totalRegistrations.value || 0
    })

    const filterListRef = ref<Array<{ text: string, value: string }>>(props.filterList.map(item => {
      return {
        text: root.$t(`honda.registration.list.btn_${item}`).toString(),
        value: item
      }
    }))

    watch(filters, () => {
      if (filters.value.some(f => [RegistrationArchivedFilters.ArchivedTotal, RegistrationArchivedFilters.ArchivedPending].includes(f as RegistrationArchivedFilters))) {
        queryParams.value.resolutionStatus = filters.value.includes(RegistrationArchivedFilters.ArchivedTotal) ?
          RegistrationResolutionStatus.Finished : RegistrationResolutionStatus.ActionsNeeded
        queryParams.value.archived = true
        queryParams.value.accountRegistrationCompleted = undefined
        queryParams.value.contractsSigned = undefined
        queryParams.value.deviceHealthChecked = undefined
      } else {
        queryParams.value.resolutionStatus = RegistrationResolutionStatus.ActionsNeeded
        queryParams.value.archived = false
        queryParams.value.accountRegistrationCompleted = filters.value.includes(RegistrationFilters.PendingRegister) ? false : undefined
        queryParams.value.contractsSigned = filters.value.includes(RegistrationFilters.PendingSign) ? false : undefined
        queryParams.value.deviceHealthChecked = filters.value.includes(RegistrationFilters.PendingCommunication) ? false : undefined
      }

      pagination.value.from = 0
      currentPage.value = 1
    })

    function onTextKeydown(e: KeyboardEvent) {
      if (!activeKeys.value[e.code] && e.code !== 'Backspace') {
        activeKeys.value[e.code] = Date.now()
      }

      if (keyStopper.value) {
        e.preventDefault()
        return
      }

      if ((Date.now() - activeKeys.value[e.code]) > MAX_HOLD_DURATION) {
        e.preventDefault()
        keyStopper.value = true
      }

      if (e.key === 'Enter') {
        e.preventDefault()
        refetch()
      }
    }

    function onTextKeyup({code}: KeyboardEvent) {
      keyStopper.value = false
      delete activeKeys.value[code]
    }

    function openCloseDialogArchive() {
      dialogArchived.value = !dialogArchived.value
      if (itemToUnarchive.value) itemToUnarchive.value = null
    }

    function openCloseDialogConfirmDelete() {
      if (dialogConfirmDelete.value) refetch()
      dialogConfirmDelete.value = !dialogConfirmDelete.value
      pendingToDelete.value = null
    }

    function openCloseDialogConfirm() {
      dialogConfirm.value = !dialogConfirm.value
      if (selectedToFinish.value) selectedToFinish.value = null
    }

    function openCloseDialogResult() {
      if (dialogResult.value) refetch()
      dialogResult.value = !dialogResult.value
    }

    function deleteRegistration() {
      if (!pendingToDelete.value?.contractsSigned) {
        loadingDeletingRegistration.value = true
        coreApi
          .deleteRegistration(pendingToDelete.value?.id)
          .then(() => {
            resultStringPopup.value = "success_delete"
          })
          .catch((e: any) => {
            resultStringPopup.value = "error"
            console.log('Error deleting registration: ', e)
          })
          .finally(() => {
            openCloseDialogConfirmDelete()
            dialogResult.value = true
            loadingDeletingRegistration.value = false
          })
      }
    }

    function getProductChips(products: Product[]) {
      if (!products) return {}
      let productChips = [...products]
      if (productChips.includes(Product.InsuranceComprehensive) && productChips.includes(Product.Insurance)) {
        productChips.splice(productChips.indexOf(Product.Insurance), 1)
      }
      if (productChips.includes(Product.HondaPlusGo)) {
        productChips.splice(productChips.indexOf(Product.HondaPlus), 1)
      }
      return productChips.map(product => {
        switch (product) {
          case 'MC':
            return ({
              value: product,
              tooltip: "Mapit Connect"
            })
          case 'HP':
            return ({
              value: product,
              tooltip: 'Honda Plus'
            })
          case 'HPG':
            return ({
              value: product,
              tooltip: 'Honda Plus Go'
            })
          case 'HM':
            return ({
              value: product,
              tooltip: 'Honda Mapit'
            })
          case 'FIN':
            return ({
              value: "HF",
              tooltip: 'Honda Financiación'
            })
          case 'INS':
            return ({
              value: "HS",
              tooltip: 'Honda Seguro básico'
            })
          case 'INS_COM':
            return ({
              value: "HS+",
              tooltip: 'Honda Seguro Ampliado'
            })
        }
      })

    }

    function archiveRegistration(item: RegistrationDoc) {
      if (!loadingFinishRegistration.value) {
        loadingFinishRegistration.value = true
        coreApi
          .archiveRegistration(item.id)
          .then(() => {
            resultStringPopup.value = "success_archive"
          })
          .catch((e: any) => {
            resultStringPopup.value = "error"
            console.log('Error mark registration as completed: ', e)
          })
          .finally(() => {
            openCloseDialogConfirm()
            dialogResult.value = true
            loadingFinishRegistration.value = false
          })
      }
    }

    function confirmDeleteRegistration(item: RegistrationDoc) {
      pendingToDelete.value = item
      dialogConfirmDelete.value = true
    }

    function markDocsSigned(item: RegistrationDoc) {
      if (!loading.value) {
        loading.value = true
        coreApi
          .signRegistration(item.id)
          .then(() => {
            resultStringPopup.value = "success_sign_docs"
            item.contractsSigned = true
          })
          .catch((e: any) => {
            resultStringPopup.value = "error"
            console.log('Error signing docs: ', e)
          })
          .finally(() => {
            dialogResult.value = true
            loading.value = false
          })
      }
    }

    function getRegistrationContracts(item: RegistrationDoc) {
      if (!loading.value) {
        loading.value = true
        registrationsApi
          .getRegistrationContracts(item.id)
          .then(({data}) => {
            let docs: any[] = []
            data.forEach((contract: any) => {
              if (contract.s3Key) {
                docs.push({url: contract.documentUrl, name: `${item.vehicle.vin}_${contract.name}.pdf`})
              }
            })
            downloadSignedUrlFiles(docs)
            resultStringPopup.value = "success_download"
          })
          .catch((e) => {
            resultStringPopup.value = "error"
            console.log('Error download contract files: ', e)
          })
          .finally(() => {
            dialogResult.value = true
            loading.value = false
          })
      }
    }

    function sendEmailSignDocs(item: RegistrationDoc) {
      if (!loading.value) {
        loading.value = true
        coreApi
          .sendEmailContractHondaPlus(item.id)
          .then(() => {
            resultStringPopup.value = "success_send_docs"
          })
          .catch((e: any) => {
            resultStringPopup.value = "error"
            console.log('Error sending email: ', e)
          })
          .finally(() => {
            dialogResult.value = true
            loading.value = false
          })
      }
    }

    function sendEmailRegisterApp(item: RegistrationDoc) {
      if (!loading.value) {
        loading.value = true
        coreApi
          .sendEmailDownloadApp(item)
          .then(() => {
            resultStringPopup.value = "success_send_invite"
          })
          .catch((e: any) => {
            resultStringPopup.value = "error"
            console.log('Error sending email: ', e)
          })
          .finally(() => {
            dialogResult.value = true
            loading.value = false
          })
      }
    }

    function confirmFinish(item: RegistrationDoc) {
      dialogConfirm.value = true
      selectedToFinish.value = item
    }

    function confirmUnarchive(item: RegistrationDoc) {
      dialogArchived.value = true
      itemToUnarchive.value = item
    }

    function hasHondaMapit(item: RegistrationDoc) {
      return item.products.includes(Product.HondaMapit) || item.products.includes(Product.MapitConnect)
    }

    function hasHondaPlusOrInsurance(item: RegistrationDoc) {
      return item.products.includes(Product.HondaPlus) || item.products.includes(Product.Insurance) || item.products.includes(Product.HondaPlusGo)
    }

    function hasComms(item: RegistrationDoc) {
      return hasHondaMapit(item) && item.deviceHealthChecked !== undefined && item.deviceHealthChecked
    }

    function trimName(name: string, max: number) {
      return name.length >= max ? name.substring(0, max) + '...' : name
    }

    function getFullName(item: RegistrationDoc) {
      return `${item.account.firstName} ${item.account.lastName}`
    }

    function isRecent(item: RegistrationDoc) {
      const a = moment(item.createdAt)
      const b = moment(new Date()).add(-1, 'hours')
      return a.isAfter(b);
    }

    function onPageChange(page: any) {
      pagination.value.from = (page - 1) * (pagination.value.size || 10)
      refetch()
    }

    function onItemsPerPageChange(itemsPerPage: any) {
      pagination.value.size = itemsPerPage
      refetch()
    }

    function onSortByChange(column?: string) {
      switch (column) {
        case 'createdAt':
          pagination.value.column = 'createDate'
          break;
        case 'account.firstName':
          pagination.value.column = 'user'
          break;
        case 'vehicle.model':
          pagination.value.column = 'model'
          break;
        case 'vehicle.registrationNumber':
          pagination.value.column = 'registrationNumber'
          break;
        case 'products':
          pagination.value.column = 'products'
          break;
        case 'contractsSigned':
          pagination.value.column = 'contractSigned'
          break;
        case 'accountRegistrationCompleted':
          pagination.value.column = 'accountRegistrationCompleted'
          break;
        case 'hasComms':
          pagination.value.column = 'deviceHealthChecked'
          break;
        default:
          pagination.value.column = 'createDate'
      }
      pagination.value.sort = 'asc'
      debounceRefetch()
    }

    function onSortDescChange(desc: Array<boolean | undefined>) {
      const [isDesc] = desc
      pagination.value.sort = 'desc'

      if (desc.length > 0 && !isDesc) {
        pagination.value.sort = 'asc'
      }
      debounceRefetch()
    }

    function getRowClass() {
      return 'registrations-table__row'
    }

    function getDocumentNumber(item: RegistrationDoc) {
      //@ts-ignore
      return item.account.document ? item.account.document.number : ''
    }

    function unarchiveRegistration(item: RegistrationDoc) {
      if (!loadingFinishRegistration.value) {
        loadingFinishRegistration.value = true
        registrationsApi
          .unarchiveRegistration(item.id)
          .then(() => {
            resultStringPopup.value = "success_unarchive"
          })
          .catch((e: any) => {
            resultStringPopup.value = "error"
            console.log('Error unarchiving registration: ', e)
          })
          .finally(() => {
            openCloseDialogArchive()
            dialogResult.value = true
            loadingFinishRegistration.value = false
          })
      }
    }

    return {
      group,
      loading,
      registrationsList,
      total,
      registrationListLoading,
      text,
      queryParams,
      pagination,
      refetch,
      debounceRefetch,
      headers,
      filterListRef,
      dialogArchived,
      dialogResult,
      resultStringPopup,
      selectedToFinish,
      dialogConfirm,
      dialogConfirmDelete,
      loadingFinishRegistration,
      loadingDeletingRegistration,
      filters,
      currentPage,
      itemToUnarchive,
      onTextKeydown,
      onTextKeyup,
      unarchiveRegistration,
      onPageChange,
      onItemsPerPageChange,
      onSortByChange,
      onSortDescChange,
      getRowClass,
      getProductChips,
      isRecent,
      getFullName,
      trimName,
      hasHondaMapit,
      hasHondaPlusOrInsurance,
      confirmFinish,
      sendEmailRegisterApp,
      sendEmailSignDocs,
      getRegistrationContracts,
      markDocsSigned,
      confirmDeleteRegistration,
      openCloseDialogArchive,
      openCloseDialogResult,
      openCloseDialogConfirm,
      openCloseDialogConfirmDelete,
      finishRegistration: archiveRegistration,
      deleteRegistration,
      hasComms,
      getDocumentNumber,
      generateUUID,
      confirmUnarchive
    }
  }
})
