
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'RegistrationCard',
  setup() {

    const isHovering = ref(false)
    return {
      isHovering,
    }
  },
})
