
var interval;

export const downloadFile = async (url, name) => {
  const pdf = await fetch(url)
  const pdfBlob = await pdf.blob()
  const pdfUrl = URL.createObjectURL(pdfBlob)

  const link = document.createElement('a')
  link.href = pdfUrl
  link.download = name

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link);
  URL.revokeObjectURL(pdfUrl);
}
export const downloadSignedUrlFile = async (url, name) => {
  const link = document.createElement('a')
  link.href = url
  link.download = name

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link);
}
export const downloadBlob = (blob, name) => {
  const objUtl = URL.createObjectURL(blob);
  const link = document.createElement('a')
  link.id = name
  link.href = objUtl
  link.download = name
  link.click()
  URL.revokeObjectURL(objUtl);
}

function download(docs){
  var doc = docs.pop();

  downloadFile(doc.url, doc.name)

  if (docs.length === 0) {
    clearInterval(interval);
  }
}

function downloadSigned(docs) {
  var doc = docs.pop();

  downloadSignedUrlFile(doc.url, doc.name)

  if (docs.length === 0) {
    clearInterval(interval);
  }
}

export const downloadFiles = (docs) => {
  interval = setInterval(download, 300, docs);
}
export const downloadSignedUrlFiles = (docs) => {
  interval = setInterval(downloadSigned, 300, docs);
}
export default downloadFile
