<template>
  <v-stepper-content :step="step" class="py-8 px-10">
    <v-row no-gutter>
      <v-col class="stepper-button-spacing-left" :class="{'col-6': $slots.right, 'col-12': !$slots.right}" v-if="$slots.left">
        <slot name="left"></slot>
      </v-col>
      <v-col class="text-right stepper-button-spacing-right" :class="{'col-6': $slots.left, 'col-12': !$slots.left}" v-if="$slots.right">
        <slot name="right"></slot>
      </v-col>
    </v-row>
  </v-stepper-content>
</template>

<script>

export default {
  name: "FooterActions",
  components: { },
  props: ['step'],
  data() {
    return {
    }
  },
  computed: {},
  methods: {}
}
</script>
<style>
.stepper-button-spacing-left button {
  margin-left: 3px !important;
}
.stepper-button-spacing-right button {
  margin-right: 3px !important;
}
</style>
