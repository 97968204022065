import Container from '@/components/tablecolumns/Container';
import Client from '@/components/tablecolumns/Client';
import AppointmentStatus from '@/components/tablecolumns/appointment/Status';
import AppointmentButton from '@/components/tablecolumns/appointment/Button';
import AppointmentDay from '@/components/tablecolumns/appointment/Day';
import AppointmentReason from '@/components/tablecolumns/appointment/Reason';
import AppointmentTime from '@/components/tablecolumns/appointment/Time';
import AppointmentHasContainer from '@/components/tablecolumns/appointment/HasContainer';
import AppointmentDelete from '@/components/tablecolumns/appointment/Delete';
import Repair from '@/components/tablecolumns/repair/Repair';
import RepairPickupDay from '@/components/tablecolumns/repair/Day';
import RepairPickupTime from '@/components/tablecolumns/repair/Time';
import Plate from '@/components/tablecolumns/Plate';
import Model from '@/components/tablecolumns/Model';
import Prevision from '@/components/tablecolumns/nextrevision/Prevision';
import KmNextRevision from '@/components/tablecolumns/nextrevision/KmNextRevision';
import SubAgent from '@/components/tablecolumns/nextrevision/SubAgent';
import Revision from '@/components/tablecolumns/nextrevision/Revision';

const hashMap = {
    'prevision' : Prevision,
    'container': Container,
    'client': Client,
    'appointmentStatus': AppointmentStatus,
    'appointmentButton': AppointmentButton,
    'appointmentDay': AppointmentDay,
    'appointmentReason': AppointmentReason,
    'appointmentTime': AppointmentTime,
    'appointmentHasContainer': AppointmentHasContainer,
    'appointmentDelete': AppointmentDelete,
    'repair': Repair,
    'pickupDay': RepairPickupDay,
    'pickupTime': RepairPickupTime,
    'plate': Plate,
    'model': Model,
    'kmnextrevision': KmNextRevision,
    'subagent': SubAgent,
    'revision': Revision
}

export default (key) => {
    return hashMap[key];
};
