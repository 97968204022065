











































































































































































































































import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { DateTime } from 'luxon'

import { DealerBikeStatus } from '@mapit/dealer-bikes-dynamodb/dist/types'
import { GoogleMapsPosition, MapitDeviceStatus, WebSocketDeviceState } from '@/types'
import { Subscription, SubscriptionStatus } from '@mapit/core-dynamodb/dist/types'

import { mapGetters } from '@/utils/map-store'
import { toDateFormat } from '@/utils/date-helper'
import { useWebSocket } from '@/composables/websocket'

import coreApi from '@/api/core.api.js'
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import FinishedLoansTable from '@/components/dealer-bike/sections/FinishedLoansTable.vue'
import Imei from '../general/Imei.vue'
import LabelTooltip from '@/components/text/LabelTooltip.vue'

export default defineComponent({
  name: 'DealerBikeDetail',
  components: {
    LabelTooltip,
    FinishedLoansTable,
    ExpansionPanel,
    Imei,
  },
  setup(props, { root, refs }) {
    const {
      getIdToken: token,
      isLoadingDealerBikes: loading,
      getDealerBikeSelected: dealerBikeSelected,
      getVehicleTotalKm: vehicleTotalKm,
      getLocale: locale,
      getPermissions: permissions,
    } = mapGetters(root.$store.getters)

    const subscription = ref<Subscription>()
    const panels = ref([0, 1, 2, 3, 4])
    const damageLogLoading = ref(false)
    const dealerBikeLoading = ref(true)

    const currentPositionStatus = ref('')
    const currentPositionDate = ref('')
    const currentPositionBattery = ref(0)
    const lastPositionStatus = ref('')
    const lastPositionBattery = ref(0)

    const formattedBattery = computed(() => {
      return `${currentPositionBattery.value}%`
    })

    const currentMapCenter = ref<GoogleMapsPosition | null>(null)
    const lastMapCenter = ref<GoogleMapsPosition | null>(null)

    const dealerBikeId = computed(() => {
      return root.$route.params.dealerBikeId
    })

    const damageLog = computed({
      get: () => {
        return dealerBikeSelected.value?.damageLog
      },
      set: (newVal) => {
        damageLogLoading.value = true
        root.$store
          .dispatch('updateDealerBike', { damageLog: newVal })
          .finally(() => (damageLogLoading.value = false))
      },
    })

    const computedErrorMessage = computed(() => {
      if (dealerBikeSelected.value && dealerBikeSelected.value.status === DealerBikeStatus.Retired)
        return root.$t('dealer-bike.detail.fleet-location.retired')
      if (
        dealerBikeSelected.value &&
        dealerBikeSelected.value.status === DealerBikeStatus.Unavailable
      )
        return root.$t('dealer-bike.detail.fleet-location.unavailable')
      if (!subscription.value || subscription.value.status === SubscriptionStatus.Cancelled) {
        return root.$t('dealer-bike.detail.fleet-location.no-subscription')
      }
      if (!currentMapCenter.value) return root.$t('dealer-bike.detail.fleet-location.no-data')

      return root.$t('dealer-bike.detail.fleet-location.unknown-error')
    })

    const { init: initWebsocket, loading: loadingWebsocket } = useWebSocket<WebSocketDeviceState>()

    watch(dealerBikeSelected, (newVal) => {
      if (newVal && newVal.device.id && permissions.value.fleetManagement.canSee) {
        initWebsocket({
          url: `${process.env.VUE_APP_MAPIT_WS_URL}/devicestate/${newVal.device.id}`,
          token: token.value,
          firstMessageTimeout: 10000,
          onMessage: async (message) => {
            lastMapCenter.value = { lat: message.lat, lng: message.lng }
            currentMapCenter.value =
              lastMapCenter.value.lat && lastMapCenter.value.lng
                ? lastMapCenter.value
                : currentMapCenter.value

            const lastTs = DateTime.fromMillis(message.lastTs || DateTime.now().toMillis())
            currentPositionDate.value = `${lastTs.toLocaleString(
              DateTime.TIME_24_SIMPLE,
            )} - ${toDateFormat(lastTs, locale.value)}`

            lastPositionStatus.value = message.status
            lastPositionBattery.value = message.battery

            currentPositionStatus.value =
              lastPositionStatus.value || currentPositionStatus.value || 'NO_COMMS'
            currentPositionBattery.value =
              lastPositionBattery.value || currentPositionBattery.value || 0

            currentMapCenter.value && (await centerPosition(currentMapCenter.value))
          },
        })
      }
    })

    watch(loadingWebsocket, async (newVal) => {
      if (!newVal && currentMapCenter.value) {
        await centerPosition(currentMapCenter.value)
      }
    })

    onMounted(async () => {
      await loadDealerBike()
    })

    async function centerPosition(position: GoogleMapsPosition) {
      currentMapCenter.value = position
      const mapRef = refs.mapRef as any

      if (!mapRef) return

      const map = await mapRef.$mapPromise

      map.panTo(currentMapCenter.value)
      map.panBy(0, -48)
    }

    async function loadDealerBike() {
      try {
        await root.$store.dispatch('loadDealerBike', dealerBikeId.value)
        const vehicleId = dealerBikeSelected.value.vehicle.id

        const { data: vehicle } = await coreApi.getVehicle(vehicleId)
        root.$store.dispatch('setVehicleKms', vehicle.km || 0)
        subscription.value = vehicle.subscription
      } catch (e: any) {
        const errorKey = 'dealer-bike.detail.error.default'
        console.error(root.$t(errorKey), { cause: e })

        if (e.response?.status === 404) {
          return // not display the error if the motorbike is retired (not found)
        }

        root.$store.dispatch('setAlertResult', {
          type: 'error',
          stringId: errorKey,
          open: true,
        })
      } finally {
        dealerBikeLoading.value = false
      }
    }

    function goToDealerBikesList() {
      root.$router.push({ name: 'DealerBikesList' })
    }

    function getDataFormatted(data: any) {
      return toDateFormat(data, locale.value)
    }

    function hasActiveSubscription() {
      return subscription.value && subscription.value.status === SubscriptionStatus.Active
    }

    function isAvailable() {
      return (
        dealerBikeSelected.value && dealerBikeSelected.value.status === DealerBikeStatus.Available
      )
    }

    function getDeviceStatus(status: MapitDeviceStatus | string) {
      switch (status) {
        case 'AT_REST':
          return root.$t('dealer-bike.detail.fleet-location.at-rest')
        case 'MOVING':
          return root.$t('dealer-bike.detail.fleet-location.moving')
        case 'BUZZ':
          return root.$t('dealer-bike.detail.fleet-location.buzz')
        case 'NO_COMMS':
          return root.$t('dealer-bike.detail.fleet-location.no-comms')
      }
    }

    function getBatteryIcon(battery: number) {
      if (battery >= 80) return 'battery-full'
      if (battery >= 60) return 'battery-three-quarters'
      if (battery >= 40) return 'battery-half'
      if (battery >= 20) return 'battery-quarter'
      if (battery > 0) return 'battery-low'
      return 'battery-slash'
    }

    return {
      centerPosition,
      computedErrorMessage,
      currentMapCenter: currentMapCenter,
      currentPositionBattery: currentPositionBattery,
      currentPositionDate: currentPositionDate,
      currentPositionStatus,
      damageLog,
      damageLogLoading,
      dealerBikeId,
      dealerBikeLoading,
      dealerBikeSelected,
      DealerBikeStatus,
      formattedBattery,
      getBatteryIcon,
      getDataFormatted,
      getDeviceStatus,
      goToDealerBikesList,
      hasActiveSubscription,
      isAvailable,
      loadDealerBike,
      loading,
      loadingWebsocket,
      panels,
      permissions,
      subscription,
      vehicleTotalKm,
    }
  },
})
