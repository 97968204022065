import Vue from 'vue'
// @ts-ignore
import App from './App.vue'
import router from './router'
import vuex from './vuex'
// @ts-ignore
import JsonExcel from 'vue-json-excel'
import VTooltip from 'v-tooltip'
// @ts-ignore
import VCalendar from 'v-calendar'
import VueScrollTo from 'vue-scrollto'
import VeeValidate from 'vee-validate'
// @ts-ignore
import validationMessagesES from 'vee-validate/dist/locale/es'
// @ts-ignore
import validationMessagesEN from 'vee-validate/dist/locale/en'
// @ts-ignore
import { VueMaskDirective } from 'v-mask'
// @ts-ignore
import VueBalanceText from 'vue-balance-text'
import i18n from './i18n'
import vuetify from './vuetify'
import '@aws-amplify/ui-vue'
import './api/aws/config-amplify'
// @ts-ignore
import VueMoment from 'vue-moment'
import './plugins/muvue'
import { Country } from '@mapit/common-utils/dist/types'
import preferences from '@/utils/preferences'
import CountryFlag from 'vue-country-flag'
import VueCompositionAPI from '@vue/composition-api'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { BarChart, GaugeChart, LineChart, PieChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components'
import VueGtm from './plugins/gtm'

import { LabelLayout, UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
// @ts-ignore
import * as VueGoogleMaps from 'vue2-google-maps'
import { createPinia, PiniaVuePlugin } from 'pinia'
import PortalVue from 'portal-vue'
import * as Sentry from '@sentry/vue'

import { formatDate } from '@/utils/date-helper'
import { formatPhone } from '@/utils/phone'

Vue.use(PortalVue)

Vue.directive('balance-text', VueBalanceText)
Vue.directive('mask', VueMaskDirective)

Vue.use(VeeValidate, {
  i18nRootKey: 'validations', // customize the root path for validation messages.
  fieldsBagName: 'validateFields',
  i18n,
  dictionary: {
    en: validationMessagesEN,
    es: validationMessagesES,
  },
})

Vue.use(VueCompositionAPI)

Vue.prototype.$scrollToElement = (id: string, params: any) =>
  document.getElementById(id)?.scrollIntoView(
    params || {
      behavior: 'smooth',
      block: 'center',
    },
  )

Vue.filter('formatPhone', (value: any): string => {
  return formatPhone(value)
})

Vue.filter('formatDate', (value: any): string => {
  return formatDate(value)
})

Vue.prototype.$preferences = (country: Country = Country.Spain) => preferences[country]

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
})

Vue.use(VTooltip)

Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

Vue.config.productionTip = false

Vue.directive('clickoutside', {
  // @ts-ignore
  bind: function (el, binding, vNode) {
    // Provided expression must evaluate to a function.
    if (typeof binding.value !== 'function') {
      // @ts-ignore
      const compName = vNode.context.name
      let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
      if (compName) {
        warn += `Found in component '${compName}'`
      }

      console.warn(warn)
    }
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble
    const handler = (e: any) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    // @ts-ignore
    el.__vueClickOutside__ = handler

    // add Event Listeners
    document.addEventListener('click', handler)
  },

  unbind: function (el) {
    // Remove Event Listeners
    // @ts-ignore
    document.removeEventListener('click', el.__vueClickOutside__)
    // @ts-ignore
    el.__vueClickOutside__ = null
  },
})

Vue.component('downloadExcel', JsonExcel)

Vue.directive('focusNextOnEnter', {
  inserted: function (el, binding, vnode) {
    el.addEventListener('keyup', (ev) => {
      // @ts-ignore
      const index = [...vnode.elm.elements].indexOf(ev.target)

      if (
        ev.keyCode === 13 && // @ts-ignore
        index < vnode.elm.length - 1 && // @ts-ignore
        !`${vnode.elm[index]}`.includes('HTMLTextAreaElement') &&
        index !== -1
      ) {
        // @ts-ignore
        vnode.elm[index + 1].focus()
      }
    })
  },
})

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  queryParams: {
    gtm_auth: process.env.VUE_APP_GTM_AUTH,
    gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
    gtm_cookies_win: process.env.VUE_APP_GTM_COOKIES_WIN,
  },
  compatibility: false,
  enabled: Boolean(process.env.VUE_APP_GTM_ENABLED),
  debug: process.env.VUE_APP_ENV_MODE === 'development',
  vueRouter: router,
  loadScript: true,
})

if (process.env.VUE_APP_ENV_MODE === 'staging') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./api/mocks/browser')
  worker.start()
}

Vue.use(VueMoment)
Vue.component('country-flag', CountryFlag)

/* ECHARTS INIT */
use([
  TransformComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  BarChart,
  GaugeChart,
  PieChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
])

Vue.component('v-chart', ECharts)

export const eventBus = new Vue()

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  },
  installComponents: true,
})

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

if (
  process.env.VUE_APP_SENTRY_ENVIRONMENT &&
  ['staging', 'production'].includes(process.env.VUE_APP_SENTRY_ENVIRONMENT)
) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_ID,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 0.1% of all sessions,
    // plus for 1% of sessions with an error
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 0.01,

    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  })
}

new Vue({
  el: '#app',
  router,
  store: vuex,
  i18n: i18n,
  vuetify,
  pinia,
  render: (h) => h(App),
})
