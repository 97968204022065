<template>
  <v-form id="dealer-bike-return-form" ref="DealerBikeReturnForm" v-focusNextOnEnter v-model="isValidForm">
    <v-card elevation="5" class="mx-0 mx-md-12 px-0 px-md-6 py-6">
      <v-expansion-panels v-model="panels" multiple flat>
        <return-data :showValidations="showValidations" />
        <expansion-panel :title="$t('dealer-bike.detail.observations-title')" data-panel="damage-log">
          <template v-slot:panel-content>
            <m-form-row>
              <m-note-log
                v-model="damageLog"
                :placeholder="$t('dealer-bike.detail.observations-placeholder')"
                :add-button-text="$t('dealer-bike.detail.observations-btn-add')"
                :cancel-text="$t('buttons.cancel')"
                :remove-dialog-title="$t('dealer-bike.detail.observations-remove-dialog-title')"
                :remove-dialog-text="$t('dealer-bike.detail.observations-remove-dialog-text')"
                allow-remove
                :loading="loading"
              />
            </m-form-row>
          </template>
        </expansion-panel>
        <feedback-data :showValidations="showValidations" />
      </v-expansion-panels>
    </v-card>

    <v-card-actions class="px-12 pt-10 pb-6">
      <div class="my-spacer"></div>
      <v-btn
        class="btn-primary"
        data-cy="btnConfirmReturnLoanDealerBike"
        @click.once="confirmReturnLoanDealerBike"
        :key="buttonKey"
        :loading="loading"
        :disabled="!isValidForm || loading"
      >
        {{ $t('buttons.next') }}
      </v-btn>
    </v-card-actions>

    <mapit-dialog
      id="confirmReturnLoanDealerBikeDialog"
      :dialog="isOpenDialog"
      :hideCloseButton="true"
      @close="closeDialog()"
      custom-width="590px"
    >
      <template v-slot:header-icon>
        <v-icon color="#DBDBDB">fa-solid fa-signature</v-icon>
      </template>

      <template v-slot:header-text>
        <div class="text-center">
          {{ $t(digitalSignatureTextsPopup.title) }}
        </div>
      </template>

      <template v-slot:body>
        <result-info-box
          class="mt-5"
          :type="digitalSignatureTextsPopup[getDigitalSignatureStatus].color"
          :text="$t(digitalSignatureTextsPopup[getDigitalSignatureStatus].description)"
        />

        <div
          class="dialog-body mt-5"
          v-html="$t(digitalSignatureTextsPopup[getDigitalSignatureStatus].subDescription, { email })"
        ></div>

        <div
          style="text-align: -webkit-center"
          class="mt-5"
          v-if="getDigitalSignatureStatus == digitalSignatureStatus.waiting"
        >
          <v-progress-circular :size="50" color="gray" indeterminate />
          <div class="dialog-body mt-5" v-html="$t(digitalSignatureTextsPopup[getDigitalSignatureStatus].status)" />
        </div>
      </template>

      <template v-slot:footer-center>
        <v-btn
          data-cy="dialogSignReturnLoan"
          class="btn-gray"
          @click="closeDialog()"
        >{{
          $t(digitalSignatureTextsPopup[getDigitalSignatureStatus].action)
        }}</v-btn>
      </template>
    </mapit-dialog>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showAlert } from '@/vuex/modules/ui'
import { isEqual } from 'lodash'

import ReturnData from './sections/ReturnData'
import MapitDialog from '@/components/dialog/MapitDialog'
import FeedbackData from '@/components/dealer-bike/sections/FeedbackData'
import {digitalSignatureStatus} from '@/utils/constants'
import ResultInfoBox from '@/components/general/ResultInfoBox.vue'
import ExpansionPanel from '@/components/general/ExpansionPanel'

export default {
  name: 'ReturnDealerBike',
  components: { ReturnData, MapitDialog, ResultInfoBox, FeedbackData, ExpansionPanel },
  data() {
    return {
      showValidations: false,
      isValidForm: false,
      buttonKey: 1,
      panels: [0, 1, 2],
      isOpenDialog: false,
      interval: null,
      timeoutInterval: null,
      digitalSignatureStatus,
      digitalSignatureTextsPopup: {
        title: 'dealer-bike.return.popups.digital-signature.title',
        [digitalSignatureStatus.waiting]: {
          color: 'WARNING',
          description: 'dealer-bike.return.popups.digital-signature.waiting-popup.body-top',
          subDescription: 'dealer-bike.return.popups.digital-signature.waiting-popup.body-bottom',
          status: 'dealer-bike.return.popups.digital-signature.waiting-popup.status',
          action: 'buttons.close',
        },
        [digitalSignatureStatus.success]: {
          color: 'SUCCESS',
          description: 'dealer-bike.return.popups.digital-signature.success-popup.body-top',
          subDescription: 'dealer-bike.return.popups.digital-signature.success-popup.body-bottom',
          action: 'buttons.close',
        },
        [digitalSignatureStatus.error]: {
          color: 'ERROR',
          description: 'dealer-bike.return.popups.digital-signature.error-popup.body-top',
          subDescription: 'dealer-bike.return.popups.digital-signature.error-popup.body-bottom',
          action: 'buttons.close',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      returnedAt: 'getReturnedAt',
      getDigitalSignatureStatus: 'getDigitalSignatureStatus',
      getLoanContact: 'getDealerBikeLoanContact',
      dealerBikeLoanReturnDate: 'getDealerBikeLoanReturnDate',
      dealerBikeLoanReturnTime: 'getDealerBikeLoanReturnTime',
      scheduledFor: 'getScheduledFor',
      loading: 'isLoadingDealerBikes'
    }),
    damageLog: {
      ...mapGetters({ get: 'getDealerBikeLoanReturnDamageLog' }),
      ...mapMutations({ set: 'SET_DEALER_BIKE_LOAN_RETURN_DAMAGE_LOG' }),
    },
    email() {
      return this.getLoanContact?.email
    }
  },
  methods: {
    ...mapActions(['processDealerBikeLoanReturn']),
    confirmReturnLoanDealerBike() {
      if (!this.email) {
        showAlert(this.$store.dispatch, 'error', 'dealer-bike.return.alert-create-error')
        this.$router.push({ name: 'LoansList' })
        return
      }

      if (this.isValidForm) {
        this.processDealerBikeLoanReturn(this.$route.params.loanId)
          .then((res) => {
            this.$store.commit('SET_LOAN_ID', res.headers.location)
            this.isOpenDialog = true
            this.interval = setInterval(this.checkStatusLoan, 5000)
            this.timeoutInterval = setInterval(() => {
              this.$store.commit('SET_DIGITAL_SIGNATURE_STATUS', digitalSignatureStatus.error)
              this.clearIntervals()
            }, 300000)
          })
          .catch(() => {
            this.showValidations = true
          })
          .finally(() => {
            this.buttonKey++
          })
      } else {
        this.showValidations = true
        this.buttonKey++
      }
    },
    closeDialog() {
      if (isEqual(this.getDigitalSignatureStatus, digitalSignatureStatus.waiting)) {
        this.clearIntervals()
        this.$store.commit('SET_DIGITAL_SIGNATURE_STATUS', digitalSignatureStatus.error)
      } else {
        this.isOpenDialog = false
        this.$router.push({ name: 'LoansList' })
      }
    },
    checkStatusLoan() {
      this.$store
        .dispatch('loadLoanById', this.$route.params.loanId)
        .then((data) => {
          if (data.return.signed) {
            this.clearIntervals()
            this.$store.commit('SET_DIGITAL_SIGNATURE_STATUS', digitalSignatureStatus.success)
          }
        })
        .catch((error) => {
          console.error(error)
          this.clearIntervals()
          showAlert(this.$store.dispatch, 'error', 'dealer-bike.loans.popups.error')
        })
    },
    clearIntervals() {
      clearInterval(this.interval)
      clearInterval(this.timeoutInterval)
    },
  },
  async created() {
    await this.$store.dispatch('startLoanReturnProcess', this.$route.params.loanId)
  },
  mounted() {
    this.$store.commit('SET_DIGITAL_SIGNATURE_STATUS', digitalSignatureStatus.waiting)
  },
  destroyed() {
    this.clearIntervals()
  },
}
</script>
