<template>
  <div>
    <div class="text-center m-t-40" >
      <slot name="icon" style="font-size: 100px"></slot>
      <slot name="text" class="p-t-20"></slot>
    </div>
    <div class='column col-12 col-lg-12 m-t-40'>
      <div class="bottom-message">
        <slot name="bottom"></slot>
      </div>
    </div>
  </div>
</template>
<script>
    export default {}
</script>

<style>
  .bottom-message {
    margin-top: 20px;
    text-align: center;
  }
</style>
