import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export const themes = {
  light: {
    primary: {
      base: '#DC182D',
      darken1: '#8F101E',
      lighten1: '#F1A2AB'
    },
    secondary: {
      base: '#00803E',
      darken1: '#005A2C',
      lighten1: '#7FBF9E'
    },
    accent: '#DC182D',
    error: '#DC182D',
    warning: '#FCA400',
    info: '#00bcd4',
    success: '#00803E',
    background: '#EEEEEE',
    gray: {
      base: '#BDBDBD',
      darken1: '#888888',
      darken2: '#666666',
      darken3: '#434343',
      darken4: '#212121',
      darken5: '#333333',
      lighten1: '#F2F2F2',
      lighten2: '#DDDDDD',
      lighten3: '#BBBBBB',
      lighten4: '#A0A0A0',
      lighten5: '#8B8B8B'
    },
    white: '#FFFFFF',
    green: '#00803E',
    tertiary: {
      base: '#0075EB',
      lighten1: '#3797F0'
    },
    negative: {
      base: '#666666',
      lighten1: '#888888'
    },
    soft: {
      base: '#ecf3ff'
    }
  },
}

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    options: { customProperties: true },
    themes
  }
})
