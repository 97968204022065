<template>
    <v-row v-if="request.comment || editable">
      <v-col class="col-5 m-t-15 col-md-5 col-sm-12 bold-900">
        COMENTARIOS DEL CLIENTE
      </v-col>
      <v-col class="col-7 col-md-7 col-sm-12">
        <div class="form-group pt-2">
          <label class="form-label" for="general-comments"></label>
          <m-textarea v-if="editable" v-model="request.comment"
                    id="general-comments"
                    data-cy="general-comments"
                    rows="3" auto-grow solo
          ></m-textarea>
          <div class="customer-comments" v-else>
            {{ request.comment }}
          </div>
        </div>
      </v-col>
    </v-row>
</template>
<script>
export default {

  props: {
    request: Object,
    editable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
