<template>
  <div class="columns custom-text-field">
    <div class="column col-12 py-1" :class="{ 'd-none': hasPaymentMethod }">
      <label>{{ t('section_payment.label_iban') }}</label>
    </div>

    <div class="column col-12 px-0" :class="{ 'd-none': hasPaymentMethod }">
      <div id="iban-element"
        class="iban-field"
        :class="{'field-error': error || ibanError, 'field-success': !error && !ibanError && success}"
        :disabled="hasPaymentMethod">
        <!-- A Stripe Element will be inserted here. -->
      </div>

      <!-- Used to display form errors. -->
      <div id="error-message" role="alert" class="f-s-12 color-red" v-if="!hasPaymentMethod && !error && ibanError">
        {{ ibanError }}
      </div>

      <p class="f-s-12 color-red" v-if="!hasPaymentMethod && error ">{{ error }}</p>
      <p class="info-text" v-if="info">{{ info }}</p>
    </div>

    <div class="column col-12 px-0" :class="{ 'd-none': !hasPaymentMethod }">
      <v-radio-group value="0" class="py-0 my-0" readonly>
        <v-radio color="secondary" class="payment-radio"
          :label="t('section_payment.label_already_has_payment')"
          value="0"
        ></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js/pure'
import { mapGetters } from "vuex";
import { Country } from "@mapit/common-utils/dist/types";

export default {
  name: 'IbanStripe',
  props: ['account', 'info', 'error'],
  data() {
    return {
      iban: null,
      ibanError: '',
      stripe: undefined,
      success: false,
    }
  },
  watch: {
    account: function() {
      if (this.iban) {
        this.iban.unmount('#iban-element')
      }

      this.createIbanField()
    }
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
      locale: 'getLocale'
    }),
    hasPaymentMethod() {
      return this.account.stripeId && this.account.paymentMethods.length > 0
    },
  },
  created() {
    this.createIbanField()
  },
  methods: {
    t(id) {
      return this.$t(`honda.registration.account.account_form.${id}`)
    },
    async createIbanField(){
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY, { locale: this.locale.substring(0, 2) || 'es' })

      const elements = this.stripe.elements()

      this.iban = elements.create('iban', {
        supportedCountries: ['SEPA'],
        placeholderCountry: this.country || Country.Spain,
      })

      this.iban.mount('#iban-element')

      this.iban.on('change', (event) => {
        this.success = !event.empty && event.complete

        if (event.error) {
          this.ibanError = event.error.message
        } else {
          this.ibanError = ''
          this.submitIban(event.complete)
        }
      })
    },
    submitIban(complete) {
      if (complete) {
        this.$emit('setIban', { iban: this.iban, stripe: this.stripe })
      } else {
        this.$emit('setIban', { iban: null, stripe: undefined })
      }
    },
  }
}
</script>

<style lang="scss">
.payment-radio {
  pointer-events: none;
  label.v-label {
    font-family: 'ProximaNovaRegular', sans-serif;
    font-size: 14px !important;
    line-height: 18px;
    letter-spacing: 0;
    color: var(--v-gray-darken4) !important;
  }
  .v-input--selection-controls__input {
    .v-icon, .v-input--selection-controls__ripple {
      font-size: 20px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.label-field {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 13px;
}

.iban-field {
  padding: 15px;
  font-size: 15px;
  width: 100%;
  background: #fff;
  color: var(--v-gray-darken4) !important;
  font: normal normal normal 16px/26px 'ProximaNovaRegular';
  box-shadow: none;
  border: 1px solid var(--v-gray-lighten3) !important;
  border-radius: 4px;

  input {
    text-align: left;
    font-family: 'ProximaNovaRegular', sans-serif;
    :active, :focus {
      border: 1px solid #0075eb !important;
      box-shadow: none;
    }
  }

  &:not(.is-error) {
  border: 1px solid var(--v-gray-lighten3) !important;
  }
  &:active, &:focus {
    border: 1px solid #0075eb !important;
    box-shadow: none;
  }
  &::placeholder{
    font-family: 'ProximaNovaRegular', sans-serif;
  }
  &.field-error {
    border: 1px solid var(--v-primary-base) !important;
    box-shadow: none;
  }
  &.field-success {
    border: 1px solid var(--v-gray-lighten3);
    background-color: #ecf3ff !important;
    font: normal normal normal 16px/26px 'ProximaNovaSemibold';
  }
}

.info-text {
  font-size: 14px;
  margin-top: 10px;
}
</style>
