
import { DateTime } from 'luxon'
import { ref, computed, defineComponent, watch, onMounted } from '@vue/composition-api'

import router from '@/router'
import coreApi from '@/api/core.api'

import { useI18n } from '@/i18n'
import { mapGetters, mapState } from '@/utils/map-store'
import { isRequired, isEmpty, isEmail, inRange } from '@/helpers/validations'

import CardWrapper from '@/components/general/CardWrapper.vue'
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import LocationFormGroup from '@/components/repairshop/LocationFormGroup.vue'

export default defineComponent({
  name: 'RepairShopProfile',
  components: {
    CardWrapper,
    ExpansionPanel,
    LocationFormGroup,
  },
  setup(props, { root, refs }) {
    const scope = 'honda.repair.repairshop'
    const { getLocale: locale } = mapGetters(root.$store.getters)
    const { permissions, dealerMid } = mapState(root.$store.state)
    const { t } = useI18n(scope)

    const panels = ref([0])
    const loading = ref(false)

    const dealer = ref()
    const holidays = ref([])

    onMounted(async () => {
      loading.value = true

      try {
        const response = await coreApi.getDealer(dealerMid.value)
        dealer.value = response.data

        holidays.value = dealer.value.profile?.appointmentConditions?.holidays?.map(
          (date: string) => date,
        )
      } catch (error: any) {
        console.error(error)

        if (error.response.status === 401) {
          logout()
        }
      }

      loading.value = false
    })

    const rules = computed(() => {
      const marginDays = 45
      const today = DateTime.now()

      return {
        email: [isEmpty, isEmail],
        marginDays: [isRequired, (value: number) => inRange(value, marginDays)],
        name: [isRequired],
        dates: {
          min: today.toISO(),
          max: today.plus({ year: 1 }).toISO(),
        },
      }
    })

    function allowedDates(date: string) {
      const day = new Date(date).getDay()
      const excludeSundays = day !== 0
      const excludeSaturdays = day !== 6

      const { garage } = dealer.value.profile
      const openSaturdays = garage?.openingHours?.length === 3

      if (openSaturdays) {
        return excludeSundays
      }

      return excludeSaturdays && excludeSundays
    }

    function handleClose() {
      router.push({ name: 'Dashboard' })
    }

    async function handleSubmit() {
      loading.value = true

      // @ts-ignore
      const isValid = await refs.dealerContactForm.validate()

      if (!isValid) {
        loading.value = false
        return
      }

      const payload = {
        name: dealer.value.name,
        profile: dealer.value.profile,
      }

      try {
        await coreApi.updateDealer(dealerMid.value, payload)
        await root.$store.dispatch('showAlertSuccess', 'honda.repair.updated-data')
      } catch (error: any) {
        console.error(error)

        if (error.response.status === 401) {
          logout()
          return
        }
      }

      loading.value = false
    }

    function logout() {
      loading.value = false
      root.$store.dispatch('logout')
      router.push({ name: 'Login' })
    }

    watch(holidays, (newHolidays) => {
      dealer.value.profile.appointmentConditions.holidays = newHolidays.map((date: string) => date)
    })

    return {
      allowedDates,
      dealer,
      handleClose,
      handleSubmit,
      holidays,
      loading,
      locale,
      panels,
      permissions,
      rules,
      t,
    }
  },
})
