

















import {computed, defineComponent, PropType} from '@vue/composition-api'

enum LayoutAlign {
  center = 'center',
  end = 'end',
  start = 'start'
}
export default defineComponent({
  name: 'MCopy',
  props: {
    hideCopy: {
      type: Boolean,
      default: true
    },
    textToCopy: {
      type: String,
      required: true
    },
    label: String,
    align: {
      type: String as PropType<LayoutAlign>,
      default: LayoutAlign.start
    }
  },
  setup(props, {root, emit}) {

    function onCopyClick() {
      navigator.clipboard.writeText(props.textToCopy).then(() => {
        emit('onCopy')
      })
    }

    const justifyAlign = computed(() => {
      return Object.values(LayoutAlign).includes(props.align) ? props.align : LayoutAlign.start
    })

    return {
      justifyAlign,
      onCopyClick,
      emit
    }
  }
})
