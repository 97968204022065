<template>
  <v-form ref="ServicesForm" lazy-validation>
    <v-stepper-content :step="step" class="py-0 px-10 pb-12">
      <div class="d-flex justify-center">
        <v-img src="/images/downloadApp.png" max-height="200" max-width="200" />
      </div>
      <v-row no-gutters>
        <h4 class="col-12 title-download">
          {{ $t('honda.registration.after.download.title') }}
        </h4>
        <v-col class="body-download col-8 col-sm-12 column m-auto">
          <p class="normal-black text-center">
            {{ $t('honda.registration.after.download.description') }}
          </p>
        </v-col>
        <v-col class="body-download col-8 col-sm-12 column m-auto" v-if="!isAccountRegistered">
          <p
            class="email-readonly mb-0"
            :class="{
              pending: !isAccountRegistered,
              correct: isAccountRegistered,
            }"
          >
            {{ account.email.value }}
          </p>
        </v-col>
        <v-col class="body-download col-8 col-sm-12 column m-auto">
          <div class="box-warning text-left d-flex justify-start" v-if="!isAccountRegistered">
            <v-icon class="info-icon mr-3" medium>fas fa-exclamation-triangle</v-icon>
            <span v-html="$t('honda.registration.after.download.no_app_used')" />
          </div>
          <div class="box-success text-left d-flex justify-start" v-else>
            <v-icon class="info-icon mr-3" medium>fas fa-check-circle</v-icon>
            <span v-html="$t('honda.registration.after.download.all_done')" />
          </div>
        </v-col>
        <v-col id="check-lbl" class="body-download col-8 col-sm-12 column m-auto" v-if="!isAccountRegistered">
          <v-checkbox
            dense
            readonly
            v-model="isCheckNoSendEmail"
            class="normal-gray mt-0"
            @click="showDialogNoEmails"
            :label="$t('honda.registration.after.download.checkbox_no_send_mails')"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-stepper-content>

    <mapit-dialog :dialog="isOpenDialogNoEmail" @close="closeDialogNoEmails(null)">
      <template v-slot:header-icon>
        <v-icon class="text-secondary">fa fa-paper-plane</v-icon>
      </template>
      <template v-slot:header-text>
        <span>{{ $t('honda.registration.after.download.confirm_checkbox_popup.title') }}</span>
      </template>
      <template v-slot:body>
        <div class="dialog-body" v-html="$t('honda.registration.after.download.confirm_checkbox_popup.description')" />
      </template>
      <template v-slot:footer-center>
        <v-btn class="btn-gray" @click="closeDialogNoEmails(false)">{{ $t('buttons.no-cancel') }}</v-btn>
        <v-btn class="btn-primary" @click="closeDialogNoEmails(true)">{{ $t('buttons.yes-sure') }}</v-btn>
      </template>
    </mapit-dialog>

    <mapit-dialog :dialog="isOpenDialogSuccess" @close="closeDialogSuccess(isMailSenderSuccess)" :hideCloseButton="true">
      <template v-slot:header-icon>
        <v-icon class="text-secondary" v-if="isMailSenderSuccess">fa fa-paper-plane</v-icon>
        <v-icon class="text-orange" v-else>fa fa-exclamation-triangle</v-icon>
      </template>
      <template v-slot:header-text>
        <span>{{
            isMailSenderSuccess
              ? $t('honda.registration.after.download.sendmail_popup.title')
              : $t('honda.registration.after.download.sendmail_popup.title_fail')
          }}</span>
      </template>
      <template v-slot:body>
        <div
          class="dialog-body"
          v-html="
            isMailSenderSuccess
              ? $t('honda.registration.after.download.sendmail_popup.description')
              : $t('honda.registration.after.download.sendmail_popup.description_fail')
          "
        />
      </template>
      <template v-slot:footer-center>
        <v-btn class="btn-primary" :key="buttonKey" @click.once="closeDialogSuccess(isMailSenderSuccess)">{{
          $t('buttons.next')
        }}</v-btn>
      </template>
    </mapit-dialog>

    <footer-actions :step="step">
      <template v-slot:right>
        <v-btn
          class="btn-primary"
          :key="buttonKey"
          @click.once="nextStep()"
          :loading="loading"
          :disabled="loading"
          data-cy="btn-next-download-app"
        >
          {{ $t('buttons.next') }}
        </v-btn>
      </template>
    </footer-actions>
  </v-form>
</template>

<script>
import FooterActions from '../FooterActions'
import { cloneDeep } from 'lodash'
import MapitDialog from '@/components/dialog/MapitDialog'
import coreApi from '@/api/core.api'
import { mapGetters } from 'vuex'

export default {
  name: 'DownloadApp',
  components: { FooterActions, MapitDialog },
  props: ['step'],
  data() {
    return {
      isCheckNoSendEmail: false,
      isOpenDialogNoEmail: false,
      isOpenDialogSuccess: false,
      isMailSenderSuccess: false,
      loading: false,
      buttonKey: 1,
    }
  },
  computed: {
    ...mapGetters({
      registration: 'getRegistration',
    }),
    isAccountRegistered: function () {
      return this.account.id != null && this.account.email.value != ''
    },
    account() {
      return this.$store.getters.getAccount
    },
    getId() {
      return this.$store.getters.getId
    },
    services() {
      return this.$store.getters.getServices
    },
  },
  methods: {
    nextStep() {
      if (!this.isCheckNoSendEmail && !this.isAccountRegistered) {
        this.sendEmailDownloadApp()
      } else {
        this.checkNextStep()
      }
    },
    checkNextStep() {
      if (
        this.account.withoutEmail ||
        (!this.account.withoutEmail &&
          this.services &&
          (this.services['honda-plus'].selected || this.services['honda-plus-go'].selected))
      ) {
        this.$emit('nextStep')
      } else {
        this.$emit('nextStep', 2)
      }
      this.buttonKey++
    },
    closeDialogNoEmails(confirm) {
      if (confirm !== null) this.isCheckNoSendEmail = confirm
      this.isOpenDialogNoEmail = false
    },
    showDialogNoEmails() {
      this.isCheckNoSendEmail ? (this.isCheckNoSendEmail = false) : (this.isOpenDialogNoEmail = true)
    },
    closeDialogSuccess(next) {
      this.isOpenDialogSuccess = false
      if (next) {
        this.checkNextStep()
      }
    },
    showDialogSuccess() {
      this.isOpenDialogSuccess = !this.isOpenDialogSuccess
    },
    sendEmailDownloadApp() {
      if (!this.loading) {
        this.loading = true
        coreApi
          .sendEmailDownloadApp(this.registration)
          .then((res) => {
            this.isMailSenderSuccess = true
            this.showDialogSuccess()
          })
          .catch((e) => {
            this.isMailSenderSuccess = false
            this.showDialogSuccess()
            console.log('Error sending email: ', e)
          })
          .finally(() => {
            this.loading = false
            this.buttonKey++
          })
      }
    },
  },
}
</script>

<style lang="scss">
#check-lbl {
  label {
    &.v-label {
      font-size: 14px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.title-download {
  text-align: center;
  color: var(--v-primary-base);
  font-size: 24px !important;
  text-transform: uppercase;
  font-family: 'ProximaNovaExCnSbold', sans-serif;
}
.info-icon {
  color: var(--v-gray-lighten3);
}
.body-download {
  .normal {
    &-black {
      color: black;
      font-size: 14px !important;
      font: normal normal normal 14px/18px 'ProximaNovaRegular';
      letter-spacing: 0;
    }
    &-gray {
      color: var(--v-gray-darken1);
      font-size: 14px !important;
      font: normal normal normal 14px/18px 'ProximaNovaRegular';
    }
  }
  .email-readonly {
    color: var(--v-gray-darken4);
    font-size: 16px !important;
    font: normal normal normal 14px/18px 'ProximaNovaRegular';
    padding: 16px;
    background-color: #f2f2f2;
  }
  .box-warning {
    background-color: rgba(252, 164, 0, 0.12);
    color: var(--v-warning-base);
    font-family: 'ProximaNovaSemibold', sans-serif;
    font-size: 16px !important;
    padding: 14px 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    i {
      color: var(--v-warning-base);
    }
  }
  .box-success {
    background-color: rgba(0, 128, 62, 0.12);
    color: var(--v-secondary-base);
    font-family: 'ProximaNovaSemibold', sans-serif;
    font-size: 16px !important;
    padding: 14px 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    i {
      color: var(--v-secondary-base);
    }
  }
}
</style>
