<template>
  <th v-if="isHeader">
      {{title}}
  </th>
  <td class='column-500' v-else>
		<div class="col-md-6 col-sm-12 p-10 align-left" v-if="rowData">
			<span class="f-s-18 bold-900"><router-link class="color-gray-dark" :to="{name: 'Container', params: {containerId: rowData.container.id}}" >{{getContainerDetailParam(rowData.container, 'Model')}}</router-link></span>
			<span class="f-s-14">({{getContainerDetailParam(rowData.container, 'Plate')}})</span><br/>
			<span class="f-s-14">{{getContainerDetailParam(rowData.container, 'Vin')}}</span><br/>
			<span class="f-s-14">Km próxima revisión: <b>{{rowData.container.nextService}}</b></span>
			<span class="f-s-14"><br/>Sub Agente: {{getContainerDetailParam(rowData.container, 'SubAgent')}}</span>
		</div>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {

      rowData: {
        type: Object,
        default: () => {
          return {
              container:{
                id: '',
                detail:{
                  Model: '-',
                  Plate: '-',
                  Vin: '-',
                  SubAgent: 'No Informado'
                },
                nextService: ''
              },
          }
        }
      }
  },
  methods: {
    getContainerDetailParam(container, key){
      const detail = JSON.parse(container.detail);
      return detail[key];
    }
  },
  name: 'Container'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>

