<template>
  <card-wrapper
    class="dealer-bike-wrapper"
    :title="$t(`dealer-bike.${path}.title`)"
    :id="`dealer-bike-${path}`"
    :show-divider="!isFormPage"
    :hide-close-btn="!isFormPage"
    @openCloseCancelDialog="openCloseCancelDialog"
  >
    <router-view :key="$route.fullPath" />
    <dialog-exit-process
      :dialog="cancelDialog"
      @exit="exit"
      @openClose="openCloseCancelDialog"
      :title="$t(`dealer-bike.${path}.exit-popup-title`)"
      :description="$t(`dealer-bike.${path}.exit-popup-body`)"
    />
  </card-wrapper>
</template>

<script>
import DialogExitProcess from '@/components/dialog/DialogExitProcess'
import CardWrapper from "@/components/general/CardWrapper.vue";

export default {
  name: 'DealerBikes',
  components: {CardWrapper, DialogExitProcess },
  props: [],
  data() {
    return {
      cancelDialog: false,
    }
  },
  watch: {
    path: function (newVal, oldVal) {
      this.scrollUp()
    },
  },
  computed: {
    path() {
      return this.$route.meta.string || 'default'
    },
    isFormPage() {
      return !!this.$route.meta.canClose
    },
    omitDialog() {
      return !!this.$route.meta.omitDialog
    },
  },
  methods: {
    openCloseCancelDialog() {
      this.omitDialog?
        this.$router.push({ name: this.$route.meta.redirectCloseName || 'DealerBikesList' })
        : this.cancelDialog = !this.cancelDialog

    },
    exit() {
      this.openCloseCancelDialog()
      this.$router.push({ name: this.$route.meta.redirectCloseName || 'DealerBikesList' })
    },
    scrollUp() {
      this.$nextTick(() => {
        window.document.getElementById(`dealer-bike-${this.path}`).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      })
    },
  },
}
</script>

<style lang="scss" src="./dealer-bike-styles.scss" />
