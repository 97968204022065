<template>
  <v-app-bar
    class="app-bar"
    dense
    height="70"
  >
    <v-btn icon :class="`btn-appbar ${hideNavMenu ? 'hidden' : ''}`" @click="openCloseDrawerMenu" v-if="user"
          data-cy="btn-navigation-menu"
    >
        <v-icon dense color="white">fa-light fa-bars</v-icon>
    </v-btn>

    <v-toolbar-title :class="{'app-logo-unauthorized' : !user}">
      <img class="toolbar-logo click" src="/images/new-logo.svg" @click.prevent="goDashboard"/>
    </v-toolbar-title>

    <div class="my-spacer"/>

    <v-btn icon class="btn-appbar btn-appbar-right" to="/requests" v-if="user && permissions && permissions.requests.canSee">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon dense color="white"
                  v-bind="attrs"
                  v-on="on"
          >
            fa-light fa-message-lines
          </v-icon>
        </template>
        <span>{{ $t('menu.create-incidence') }}</span>
      </v-tooltip>
    </v-btn>

    <v-btn icon class="btn-appbar btn-appbar-right" to="/help" v-if="user">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon dense color="white"
                  v-bind="attrs"
                  v-on="on"
          >fa-light fa-circle-question
          </v-icon>
        </template>
        <span>{{ $t('menu.help') }}</span>
      </v-tooltip>
    </v-btn>

    <v-btn
      v-if="user"
      @click="showNewsSection"
      class="btn-appbar btn-appbar-right mr-2"
      icon
    >
      <v-badge color="yellow" dot :value="newNotifyLength > 0">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon dense color="white" v-bind="attrs" v-on="on">fa-light fa-bell</v-icon>
          </template>
          <span>{{ $t('menu.news') }}</span>
        </v-tooltip>
      </v-badge>
    </v-btn>

    <last-news
      v-if="user"
      :branch="branchLowerCase"
      :display="newsSection"
      @showNews="showNewsSection"
      @hideNews="hideNewsSection"
    />

    <v-btn icon class="btn-appbar btn-appbar-right user-menu mr-4" data-cy="btn-user-menu" @click="openUserDrawer" v-if="user">
      <v-icon dense color="white">fa-light fa-user</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
//@ts-ignore
import router from '@/router'
import { mapGetters, mapState } from "vuex";
import LastNews from './LastNews.vue'

export default {
  name: 'AppHeader',
  components: { LastNews },
  props: ['hideUserMenu', 'hideNavMenu'],
  data() {
    return {
      newsSection: false,
      newNotifyLength: 0,
    }
  },
  computed: {
    ...mapState(['permissions']),
    ...mapGetters({
      branch: 'getBranch',
      capabilities: 'getCapabilities'
    }),
    branchLowerCase() {
      return this.branch?.toLowerCase();
    },
    user() {
      return this.$store.getters.getUser
    },
    group() {
      return this.$store.getters.getGroup
    },
    getDrawerState() {
      return this.$store.getters.getDrawerState
    },
    getUserDrawerState() {
      return this.$store.getters.getUserDrawerState
    },
    branchPreferences() {
      return this.capabilities ?? this.$preferences(this.branch)
    }
  },
  methods: {
    openUserDrawer() {
      this.$store.dispatch('setUserDrawerState', !this.getUserDrawerState)
    },
    openCloseDrawerMenu() {
      this.$store.dispatch('setDrawerState', !this.getDrawerState)
    },
    goDashboard() {
      // router.go(-1);
      if (this.user) {
        if (this.user.role !== 99)
          router.push({name: 'Dashboard'})

      } else {
        router.push({name: 'Login'})
      }
    },
    showNewsSection() {
      this.newsSection = true
    },
    hideNewsSection() {
      this.newsSection = false
    },
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content:has(.notifications-popup) {
  top: 60px !important;
  left: unset !important;
  right: 100px !important;
  transform-origin: right top !important;
}
.app-bar {
  z-index: 3 !important;
  background-color: var(--v-primary-base) !important;

  .app-logo-unauthorized {
    margin: 0 20px;
  }
  .btn-appbar {
    text-decoration: none !important;
    color: white !important;
    padding: 0 !important;
    border-radius: 100px !important;
    height: 48px !important;
    width: 48px;
    .v-badge__badge {
      color: black !important;
    }

    &.user-menu i{
      padding: 20px !important;
      background-color: rgba(255, 255, 255, 0.3) !important;
      border-radius: 100px;
    }
  }

  .btn-appbar-right {
    & i.v-icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
  }

  .appbar-item {
    font-family: 'ProximaNovaExCnSReg', sans-serif;
    font-size: 22px;
    color: white;
    margin: 0 20px;
    &.appbar-link a{
      color: white !important;
      text-decoration: none !important;
      text-transform: uppercase;
    }
  }
  .toolbar-logo {
    display: block;
    height: 41px;
  }
  .my-spacer {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .appbar-menu-link {
    * {
      text-decoration: none !important;
    }
  }
}

.content-notify-body{
  width: 300px !important;
  height: 400px !important;
  overflow-y: auto;
  background-color: white !important;
}
</style>
