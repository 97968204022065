<template>
  <expansion-panel
    :hideContentMargins="hideContentMargins"
    :hideDivider="panelHideDivider"
    :hideIconHeader="hideIconHeader"
    :readonly="panelReadonly"
    :title="$t('offer-manager.create.step-dealer-notes.title')"
    id="dealer-annotations"
    key="expansion-panel-customer-notes"
  >
    <template v-slot:panel-content>
      <m-todo-list
        :buttonText="$t('leads.detail.follow-up.add')"
        :dateText="$t('leads.detail.follow-up.due-date')"
        :errorText="$t('leads.detail.follow-up.error')"
        :placeholder="$t('leads.detail.follow-up.placeholder')"
        :title="$t('leads.detail.follow-up.title')"
        @input="updateComments"
        v-model="commentLog"
      />
    </template>
  </expansion-panel>
</template>

<script>
import moment from 'moment'

import apiOffers from '@/api/offers.api'
import ExpansionPanel from '@/components/general/ExpansionPanel.vue'
import { mapDataToComments, mapDealerDataToComments } from '@/mappers/comments'

export default {
  name: 'DealerNotes',
  components: { ExpansionPanel },
  props: [
    'fromPage',
    'hideContentMargins',
    'hideIconHeader',
    'offerId',
    'panelHideDivider',
    'panelReadonly',
  ],
  data() {
    return {
      moment,
      commentLog: [],
    }
  },
  watch: {
    offerId: function (val) {
      this.commentLog = []
      this.loadComments(val)
    },
  },
  created() {
    this.loadComments(this.offerId)
  },
  methods: {
    async loadComments(offerId) {
      try {
        const { dealerId } = this.group
        const offerResponse = await apiOffers.getOfferById(offerId, dealerId)
        const commentsFromOffer = mapDataToComments(offerResponse.data?.commentLog)

        let commentsFromDealer = []
        const hasDealerComments = commentsFromOffer.some((comment) => !comment.dueDate)

        if (!hasDealerComments) {
          const dealerResponse = await apiOffers.getDealerCommentsByOfferId(offerId, dealerId)
          commentsFromDealer = mapDealerDataToComments(dealerResponse.data)
        }

        this.commentLog = [...commentsFromOffer, ...commentsFromDealer]
      } catch (error) {
        console.error(error)
      }
    },
    async updateComments(comments) {
      try {
        await apiOffers.updateOfferComments(
          this.offerId,
          { commentLog: comments },
          this.group.dealerId,
        )

        this.$gtm.trackEvent({
          category: 'Offers',
          action: 'CreateDealerAnnotationInOffer',
          label: this.fromPage,
        })
      } catch (error) {
        console.error(error)
      }
    },
  },
  computed: {
    group() {
      return this.$store.getters.getGroup
    },
  },
}
</script>
