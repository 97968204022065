import Amplify from 'aws-amplify'
import AWS from "aws-sdk"

Amplify.configure({
  region: 'eu-west-1',
  identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
  userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  mandatorySignIn: false,
})

AWS.config.update({
  region: 'eu-west-1',
  // endpoint: "http://localhost:8000"
})
