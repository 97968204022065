



















import { CoreApi } from "@/api/core.api"
import { mapGetters } from "@/utils/map-store"
import { RegistrationHelper } from "@/utils/registration-helper"
// @ts-ignore
import { MapitDevice } from "@mapit/core-dynamodb/dist/types"
import { defineComponent, PropType, Ref, ref, toRefs, watch } from "@vue/composition-api"
import { debounce } from "lodash"

interface ImeiValidation {
  imei: string,
  valid: boolean
}

export default defineComponent({
  name: "AvailableDeviceLookup",
  props: {
    value: {
      type: Object as PropType<MapitDevice>,
      required: false,
    },
    ignoreCheckRules: {
      type: Function as PropType<(dev?: MapitDevice) => boolean>,
      default: () => undefined
    },
    successMessageText: String,
    useRefModel: Boolean
  },
  emits: ['input', 'is-loading', 'search-error'],
  setup(props, { root, emit }) {

    const { value, ignoreCheckRules, successMessageText, useRefModel } = toRefs(props)
    const imei = ref('')
    const rawImei = ref('')
    const device = ref() as Ref<MapitDevice | undefined>
    const errorMessages = ref([] as string[])
    const successMessages = ref([] as string[])
    const loading = ref(false)

    const {
      imeiNeedsClearing,
    } = mapGetters(root.$store.getters)

    const debounceSearchDevice = debounce(searchDevice, 50)

    watch(() => value?.value, (val) => {
      device.value = JSON.parse(JSON.stringify(val))
    })

    watch(device, (val) => {
      if (val && val?.imei) {
        imei.value = val.imei
      }
    })

    watch(imeiNeedsClearing, (val) => {
      if (val) {
        imei.value = ''
        rawImei.value = ''
        root.$store.dispatch('imeiNeedsClearing', false)
      }
    })

    watch(errorMessages, (val) => {
      emit('is-valid', val && val.length === 0)
    })

    watch(rawImei, (val) => emit('imei-change', val))

    function onRawImeiChange(imei: string) {
      if (rawImei.value !== imei) {
        rawImei.value = imei
        errorMessages.value = []
        successMessages.value = []
      }
    }

    function checkAvailability() {
      if (ignoreCheckRules.value?.(device.value)) {
        emitInput(device.value)
        return
      }

      errorMessages.value = []
      successMessages.value = []

      const res = new RegistrationHelper().canDeviceBeInstalled(device.value)

      if (res.canBeInstalled) {
        if (successMessageText?.value) {
          successMessages.value = [successMessageText?.value]
        }
        emitInput(device.value)
        emit('is-valid', true)
      } else {
        errorMessages.value = [root.$t(`components.general.availability-device-lookup.prevent-install-reason.${res.reason}`).toString()]
        emit('is-valid', false)
      }
    }

    async function searchDevice() {
      if (imei.value) {
        loading.value = true
        emit('is-loading', true)
        try {
          const res = await new CoreApi().getDevicesByImei(imei.value)
          device.value = res?.data.data.length > 0 ? res?.data.data[0] as MapitDevice : undefined
          checkAvailability()
        } catch (e) {
          console.error("Problem finding device", e)
          emit('search-error', imei.value, e)
          emit('is-valid', false)
          errorMessages.value = [root.$t('components.general.availability-device-lookup.api-error-message').toString()]
        } finally {
          loading.value = false
          emit('is-loading', false)
        }
      }
    }

    function emitInput(device: MapitDevice | undefined) {
      if (device?.id && useRefModel.value) {
        emit('input', { id: device.id, imei: device.imei })
      } else {
        emit('input', device)
      }
    }

    function handleValidation({ valid, imei }: ImeiValidation) {
      if (valid && imei) {
        debounceSearchDevice()
      } else {
        emit('is-valid', false)
      }
    }

    return {
      imei,
      rawImei,
      device,
      errorMessages,
      successMessages,
      loading,
      onRawImeiChange,
      checkAvailability,
      emitInput,
      handleValidation
    }
  }
})
