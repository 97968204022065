














import {generateUUID} from "@/utils/utils";
import {computed, defineComponent, onMounted, ref} from "@vue/composition-api";

export default defineComponent({
  name: "MIframe",
  props: {
    src: {
      type: String,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    noCache: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, {refs}) {
    const loading = ref(true)

    const url = computed(() => {
      const url = new URL(props.src)
      if (props.noCache) url.searchParams.set('ts', Date.now().toString())

      return url.toString()
    })

    onMounted(() => {
      const frame = refs.frame as HTMLIFrameElement

      frame.onload = () => {
        loading.value = false
      }

      document.onresize = () => {
        frame.style.height = `${frame.contentWindow?.document.body.scrollHeight}px`
      }
    })

    return {
      loading,
      url,
      generateUUID
    }
  }
})

