<template>
  <div :key="refreshKey" id="mapit-input" :data-cy="uid ? `${uid}-wrapper` : ''">
    <v-select
      v-if="type === InputTypes.SELECT"
      :class="isReadonly ? 'readonly' : ''"
      v-model="dataValue"
      :error-messages="errorMessages"
      :success-messages="successMessages"
      :placeholder=" !dataValue ? placeholder : undefined "
      :rules="rules"
      :maxlength="maxlength"
      :ref="`mapitInput_${uid}`"
      :data-cy="uid"
      :disabled="disabled"
      :persistent-hint="!!hint"
      :hint="hint"
      :clearable="clearable"
      clear-icon="z"
      outlined
      :hide-details="isReadonly || hideDetails ? true : 'auto'"
      :items="listItems"
      :item-text="itemText || 'text'"
      :item-value="itemValue || 'value'"
      :return-object="returnObject"
      :attach="disableAttach ? false : true"
      :multiple="multiple || false"
    >
      <template v-slot:prepend-inner>
        <slot name="prepend-inner-icon" />
      </template>

      <template v-slot:selection="{ item, index }" v-if="multiple">
        <span v-if="index === 0">{{ item.text }}</span>
        <span
          v-if="index === 1"
          class="grey--text pl-2"
        >
          (+{{ value.length - 1 }})
        </span>
      </template>
    </v-select>

    <v-text-field
      v-else-if="type === InputTypes.PHONE"
      :class="isReadonly ? 'readonly' : ''"
      v-model="dataValue"
      :error-messages="errorMessages"
      :success-messages="successMessages"
      :placeholder="placeholder"
      :rules="rules"
      :maxlength="maxlength"
      :ref="`mapitInput_${uid}`"
      :data-cy="uid"
      :disabled="disabled"
      :persistent-hint="!!hint"
      :hint="hint"
      :clearable="clearable"
      clear-icon="z"
      outlined
      :hide-details="isReadonly || hideDetails ? true : 'auto'"
      @keypress="isPhoneNumber"
      autocomplete="off"
      type="tel"
    />

    <v-text-field
      v-else-if="type === InputTypes.PRICE"
      class="input-right"
      :class="isReadonly ? 'readonly' : ''"
      v-model="numberValue"
      :error-messages="errorMessages"
      :success-messages="successMessages"
      :placeholder="placeholder"
      :rules="rules"
      :maxlength="maxlength"
      :ref="`mapitInput_${uid}`"
      :data-cy="uid"
      :disabled="disabled"
      :persistent-hint="!!hint"
      :hint="hint"
      :clearable="clearable"
      clear-icon="z"
      outlined
      :readonly="isReadonly"
      :hide-details="isReadonly || hideDetails ? true : 'auto'"
      :append-icon="appendIcon ? appendIcon : '€'"
      v-mask="negativeValue ? negativePriceMask : defaultPriceMask"
      :key="refreshKey"
      @blur="fixDecimalss"
      autocomplete="off"
    >
      <template slot="append">
        <slot name="append-inner-icon" />
      </template>
    </v-text-field>

    <v-text-field
      v-else-if="type === InputTypes.PERCENTAGE"
      class="input-right"
      :class="isReadonly ? 'readonly' : ''"
      v-model="numberValue"
      :error-messages="errorMessages"
      :success-messages="successMessages"
      :placeholder="placeholder"
      :rules="rules"
      :maxlength="maxlength"
      :ref="`mapitInput_${uid}`"
      :data-cy="uid"
      :disabled="disabled"
      :persistent-hint="!!hint"
      :hint="hint"
      :clearable="clearable"
      clear-icon="z"
      outlined
      append-icon="%"
      :hide-details="isReadonly || hideDetails ? true : 'auto'"
      v-mask="negativeValue ? negativePercentageMask : defaultPercentageMask"
      :key="refreshKey"
      autocomplete="off"
    >
      <template slot="append">
        <slot name="append-inner-icon" />
      </template>
    </v-text-field>

    <v-radio-group
      v-else-if="type === InputTypes.RADIO_BUTTONS"
      v-model="dataValueRadio"
      row
      :disabled="disabled"
      :persistent-hint="!!hint"
      :hint="hint"
      :hide-details="isReadonly || hideDetails ? true : 'auto'"
      :error-messages="errorMessages"
      :success-messages="successMessages"
      :rules="rules"
      :ref="`mapitInput_${uid}`"
      :data-cy="uid"
      :class="this.align ? `align-${this.align}` : ''"
    >
      <v-radio
        v-for="item in listItems"
        :data-cy="`${uid}-${item.value}`"
        :key="item.value"
        :label="item.text"
        :value="item.value"
        :ripple="false"
      />
    </v-radio-group>

    <v-text-field
          v-else-if="type === InputTypes.TIME_PICKER"
          :key="refreshKey"
          class="append-icon-mapit"
          :ref="`mapitInput_${uid}`"
          :data-cy="uid"
          type="time"
          v-model="dataValue"
          :placeholder="placeholder"
          :disabled="disabled"
          @change="value => saveDobMenu(value, true)"
          append-icon="w"
          :rules="[
            ...(rules ? rules: []),
            date => !(!date || date === undefined || date === null || validator.isEmpty(date)) || $t('error.required')
          ]"
          :error-messages="errorMessages"
          :success-messages="successMessages"
          :persistent-hint="!!hint"
          :hint="hint"
          autocomplete="off"
          outlined
          :hide-details="isReadonly || hideDetails ? true : 'auto'"

        ></v-text-field>

    <v-combobox
      v-else-if="type === InputTypes.TEXT_FIELD_WITH_SUGGESTIONS"
      class="custom-combobox"
      :data-cy="uid"
      v-model="dataValue"
      @input.native="e => dataValue = e.target.value"
      :ref="`mapitInput_${uid}`"
      :placeholder="placeholder"
      color="secondary"
      :disabled="disabled"
      :items="listItems"
      :rules="rules"
      append-icon=""
      outlined
      :maxlength="maxlength"
      :hide-details="isReadonly || hideDetails ? true : 'auto'"
      attach
    />


    <v-checkbox
      v-else-if="type === InputTypes.CHECKBOX"
      :class="isReadonly ? 'readonly' : ''"
      v-model="dataValue"
      :ref="`mapitInput_${uid}`"
      :data-cy="uid"
      :disabled="disabled"
      outlined
      :rules="rules"
      :ripple="false"
      :hide-details="isReadonly || hideDetails ? true : 'auto'"
    >
      <template v-slot:label>
        <slot name="label" />
      </template>
    </v-checkbox>

    <v-text-field
      v-else
      :class="isReadonly ? 'readonly' : ''"
      v-model.trim="dataValue"
      :error-messages="errorMessages"
      :success-messages="successMessages"
      :placeholder="placeholder"
      :rules="rules"
      :maxlength="maxlength"
      :ref="`mapitInput_${uid}`"
      :data-cy="uid"
      :disabled="disabled"
      :persistent-hint="!!hint"
      :hint="hint"
      :clearable="clearable"
      clear-icon="z"
      :append-icon="appendIcon ? appendIcon : null"
      outlined
      :hide-details="isReadonly && !successMessages || hideDetails ? true : 'auto'"
      validate-on-blur
      autocomplete="off"
      @keypress="keypressPhone ? isPhoneNumber: () => {}"
      v-bind="$attrs"
    />

  </div>
</template>
<script>
import { InputTypes } from '@/utils/constants'
import { isNumberEvent, isNumberFloatEvent, isPhoneNumberEvent } from '@/helpers/validations'
import { defaultPriceMask, negativePriceMask, defaultPercentageMask, negativePercentageMask } from '@/helpers/utils'
import validator from "validator"
import moment from "moment"
import {mapGetters} from "vuex";

export default {
  name: 'MapitInput',
  props: [
    'uid',
    'value',
    'html',
    'placeholder',
    'rules',
    'disabled',
    'clearable',
    'maxlength',
    'successMessages',
    'errorMessages',
    'hint',
    'listItems',
    'type',
    'isReadonly',
    'appendIcon',
    'maxDate',
    'minDate',
    'returnObject',
    'forceFloat',
    'hideDetails',
    'itemText',
    'itemValue',
    'align',
    'negativeValue',
    'disableAttach',
    'keypressPhone',
    'multiple'
  ],
  data() {
    return {
      defaultPriceMask,
      negativePriceMask,
      defaultPercentageMask,
      negativePercentageMask,
      InputTypes,
      validator,
      dobMenu: false,
      refreshKey: 0,
      numberValue: null
    }
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
    }),
    dataValueRadio: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('changeValue', val)
        this.$emit('input', val)
      },
    },
    dateValue: {
      get() {
        return this.value
      },
      set(val) {
        if(this.validateDate(val)){
          this.$emit('changeValue', val)
          this.$emit('input', val)
        }
        if(!this.maxFourYearDigits(val)) {
          this.refreshKey++;
        }
      },
    },
    dataValue: {
      get() {
        return this.value
      },
      set(val) {
        this.validateInput()
        this.$emit('changeValue', val)
        this.$emit('input', val)
      },
    },
    lastFocus () {
      return this.$store.getters.getLastFocus?.id
    },
    lastFocusPos () {
      return this.$store.getters.getLastFocus.pos
    }
  },
  watch: {
    numberValue: function(val) {
      this.validateInput()
      if(val && val.replace('.','').replace('-','').replace(',','.')) {
        const value = parseFloat(val.replace('.','').replace('-','').replace(',','.'))
        this.$emit('validateForm')
        if(value.toFixed(2) !== parseFloat(this.value).toFixed(2)){
          this.$emit('changeValue', value)
          this.$emit('input', value)
          if(!this.isReadonly) this.$store.dispatch('setLastFocus', {
              id: `mapitInput_${this.uid}`,
              pos: this.$refs[`mapitInput_${this.uid}`].$el.getElementsByTagName('input')[0].selectionEnd +(value > 999 ? 1 : 0)
            })
        }
      }else if(val !== this.value ) {
        this.$emit('changeValue', 0.00)
        this.$emit('input', 0.00)
        this.refreshKey++
      }
    },
    value: {
      immediate: true,
      handler(val) {
        this.updateNumValueFromParent(val)
      }
    },
  },
  methods: {
    fixDecimalss(){
      if(this.value !== 0){
        this.numberValue = `${parseFloat(this.numberValue.replace('.','').replace('-','').replace(',','.')).toFixed(2)}`.replace('.',',')
        this.refreshKey++
      }
    },
    doSetCaretPosition(oField, iCaretPos){

     // IE Support
     if (document.selection) {

       // Set focus on the element
       oField.focus ();

       // Create empty selection range
       var oSel = document.selection.createRange ();

       // Move selection start and end to 0 position
       oSel.moveStart ('character', -oField.value.length);

       // Move selection start and end to desired position
       oSel.moveStart ('character', iCaretPos);
       oSel.moveEnd ('character', 0);
       oSel.select ();
     }

     // Firefox support
     else if (oField.selectionStart || oField.selectionStart == '0') {
       oField.selectionStart = iCaretPos;
       oField.selectionEnd = iCaretPos;
       oField.focus ();
     }
   },
    isNumber: isNumberEvent,
    isNumberFloat: isNumberFloatEvent,
    isPhoneNumber: isPhoneNumberEvent,
    maxFourYearDigits(value) {
      return moment(value, 'YYYY-MM-DD').isBefore(moment('9999-12-31', 'YYYY-MM-DD'))
    },
    dateRangeRule(value) {
      return ((!this.maxDate || this.maxDate && moment(value, 'YYYY-MM-DD').isBefore(moment(this.maxDate).add(1, 'days'))) && (!this.minDate || this.minDate && moment(value, 'YYYY-MM-DD').isAfter(moment(this.minDate).add(-1, 'days'))))
    },
    saveDobMenu(date, refresh) {
      if(!this.dateValue && date && validator.isDate(date)) {
        this.$emit('changeValue', date)
        this.$emit('input', date)
        this.$refs.dobMenu.save(date)
        this.$nextTick(() => {
          this.dateValue = date // Fix CalendarPicker not set correcly after select day in picker if data was empty
        })
      }else if(this.validateDate(date)){
        this.$refs.dobMenu.save(date)
      }
      if(refresh) this.refreshKey++;
    },
    validateDate(date) {
      return validator.isDate(date) && this.maxFourYearDigits(date) && this.dateRangeRule(date) && this.$refs[`mapitInput_${this.uid}`] && this.$refs[`mapitInput_${this.uid}`].validate()
    },
    validateInput(){
      return this.rules ? this.$refs[`mapitInput_${this.uid}`] && this.$refs[`mapitInput_${this.uid}`].validate() : true
    },
    updateNumValueFromParent(val) {
      const fVal = typeof val === 'string' ? parseFloat(val) : val
      if((this.type === InputTypes.PRICE || this.type === InputTypes.PERCENTAGE) && (!this.numberValue || this.numberValue.replace('.','').replace('-','').replace(',','.') !== `${fVal}`)){
        this.numberValue = fVal || fVal === 0 ? (`${fVal.toFixed(2)}`.replace('.',',').replace('-','')) : !this.isReadonly? null : '0,00'
        this.refreshKey++
        if(!this.isReadonly && this.lastFocus === `mapitInput_${this.uid}`) {
          this.$nextTick(() => {
            this.$refs[`mapitInput_${this.uid}`].$el.getElementsByTagName('input')[0].focus()
            this.doSetCaretPosition(this.$refs[`mapitInput_${this.uid}`].$el.getElementsByTagName('input')[0], this.lastFocusPos)
          })
        }
      }
    }
  },
  created() {
    if (this.type === InputTypes.RADIO_BUTTONS && this.value) this.dataValueRadio = this.value
    else if (this.value){
      if(this.type === InputTypes.PRICE || this.type === InputTypes.PERCENTAGE) {
        this.updateNumValueFromParent(this.value)
      }
      this.dataValue = this.value
    }
  },
}
</script>
<style lang="scss">

#mapit-input:not(.month-picker), .mapit-input:not(.month-picker){
  //DISABLED
  .v-input--is-disabled, .v-input--is-readonly {
    pointer-events: none;
    input,
    .v-select__selection, .v-select__selections {
      color: var(--v-gray-lighten4) !important;
      font: normal normal normal 15px/25px 'ProximaNovaSemibold';
      letter-spacing: 0px;
    }
    fieldset {
      border: 1px solid var(--v-gray-lighten2) !important;
      background-color: var(--v-gray-lighten1) !important;
    }
    ::placeholder {
    }
  }
  .v-input--is-readonly {
    pointer-events: none;
    .v-input__icon--clear {
      display: none;
    }
    ::placeholder {
      visibility: hidden !important;
    }
  }
}

//only for old inputs
#mapit-input {
  //icons default
  .fa-caret-down:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    text-transform: none;
    color: var(--v-gray-darken1) !important;
    content: '\55';
  }
  .fa-caret-up:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\58';
    text-transform: none;
    color: var(--v-gray-darken1) !important;
  }
  .fa-dot-circle:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\52';
    text-transform: none;
    color: #0075eb !important;
  }
  .fa-circle:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\50';
    text-transform: none;
    color: var(--v-gray-darken1) !important;
  }
  .fa-dot-circle:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\52';
    text-transform: none;
    color: #0075eb !important;
  }
  .fa-circle:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\50';
    text-transform: none;
    color: var(--v-gray-darken1) !important;
  }
  .fa-check-square:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\4f';
    text-transform: none;
    color: #0075eb !important;
  }
  .fa-square:before {
    font-size: 24px;
    font-family: 'mapitfont-v1' !important;
    content: '\4d';
    text-transform: none;
    color: var(--v-gray-darken1) !important;
  }
}
#mapit-input, .mapit-input {
  .v-input__slot {
    min-height: inherit !important;
  }

  .v-text-field__slot, .v-select__slot  {
    height: 40px;

    input {
      padding: 0;
    }
  }

  .v-text-field__details {
    margin-top: 8px;
  }

  fieldset {
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--v-gray-lighten3);
    padding-left: 16px;
    margin: 0 !important;
  }

  .v-progress-linear {
    border-radius: 0 0 4px 4px;
    height: 2px !important;
    left: 2px;
    top: calc(100% - 2px);
    width: calc(100% - 4px);
  }

  .v-select__selection--comma {
    margin: 0 4px 0 0 !important;
  }
  ::placeholder {
    font: normal normal normal 15px/25px 'ProximaNovaRegular';
    color: var(--v-gray-lighten4) !important;
  }
  //FOCUS STATE
  .v-input--is-focused {
    fieldset {
      border: 1px solid #0075eb !important;
      background-color: var(--v-gray-lighten1) !important;
    }
    input,
    .v-select__selection, .v-select__selections {
      color: var(--v-gray-darken4) !important;
      font: normal normal normal 15px/25px 'ProximaNovaRegular' !important;
      letter-spacing: 0px;
    }
  }
  //ACTIVE STATE (WITH VALUE)
  .v-input--is-label-active:not(.v-input--is-disabled):not(.v-input--is-readonly) {
    fieldset {
      border: 1px solid var(--v-gray-lighten3);
      background-color: #ecf3ff !important;
    }
    input,
    .v-select__selection, .v-select__selections {
      color: var(--v-gray-darken4) !important;
      font: normal normal normal 15px/25px 'ProximaNovaSemibold';
      letter-spacing: 0px;
    }
  }
  //NORMAL STATE
  .v-input--is-booted {
    input {
      color: var(--v-gray-darken4) !important;
      font: normal normal normal 15px/25px 'ProximaNovaRegular';
      letter-spacing: 0px;
    }
  }
  .v-input__slot, .v-select__slot{
    margin-bottom: 0;
  }
  //icons
  .v-input__prepend-inner, .v-input__append-inner {
    margin-top: 8px !important;
    z-index: 1;
  }
  .append-icon-mapit {
    .v-input__icon--append i, .v-input__icon--append button {
      font-family: 'mapitfont-v1' !important;
      font-size: 24px;
      text-transform: none;
      color: var(--v-gray-darken1) !important;
    }
  }
  .v-input__icon--prepend-inner i, .v-input__prepend-inner .icon-mapit {
    font-family: 'mapitfont-v1' !important;
    font-size: 24px;
    text-transform: none;
    color: var(--v-gray-darken1) !important;
    font-style: normal;
  }
  .v-input__icon--append i {
    font: normal normal normal 16px/26px 'ProximaNovaRegular';
    color: var(--v-gray-darken1) !important;
  }
  .v-input__icon--clear {
    .v-icon {
      font-family: 'mapitfont-v1' !important;
      text-transform: none;
      height: 40px;
      color: var(--v-gray-darken1) !important;
    }
  }
  //messages
  .error--text{
    fieldset {
      border-width: 1px;
    }
    &:not(.v-input--is-readonly) fieldset {
      border-color: var(--v-primary-base);
    }
    &.v-input--hide-details fieldset {
      border-color: var(--v-gray-lighten3) !important;
    }
  }
  .success--text {
    &:not(.v-input--is-readonly) fieldset {
      border: 1px solid var(--v-success-base) !important;
    }
  }
  .v-text-field__details {
    .v-messages__message {
      font: normal normal normal 14px/18px 'ProximaNovaRegular';
      letter-spacing: 0px;
      color: var(--v-gray-darken1);
    }
    .error--text {
      .v-messages__message {
        font: normal normal normal 14px/18px 'ProximaNovaRegular';
        letter-spacing: 0px;
        color: var(--v-primary-base);
      }
      fieldset {
        border: 1px solid var(--v-primary-base) !important;
      }
    }
    .success--text {
      .v-messages__message {
        font: normal normal normal 14px/18px 'ProximaNovaRegular';
        letter-spacing: 0px;
        color: var(--v-success-base);
      }
    }
  }
  .append-icon-mapit{
    .v-input__icon--append i, .v-input__icon--append button {
      font-family: 'mapitfont-v1' !important;
      font-style: normal;
      font-size: 24px;
      text-transform: none;
      height: 40px;
      color: var(--v-gray-darken1) !important;
    }
  }

  .v-input--selection-controls.v-input--checkbox{
    margin-top: 0;
    padding-top: 0;
  }
  .v-input--checkbox .v-label{
    color: var(--v-gray-darken4) !important;
    a {
      color: #0075eb !important;
      text-decoration: underline;
    }
  }
  .v-input--radio-group__input{
    justify-content: space-between;
    .v-label {
      color: var(--v-gray-darken1) !important;
    }
  }
  .align-left .v-input--radio-group__input {
    justify-content: start;
  }
  .align-right .v-input--radio-group__input {
    justify-content: end;
  }
  .v-input--selection-controls__ripple {
    &:hover {
      background-color: #0075eb !important;
    }
  }
  .v-input--radio-group {
    margin-top: 0;
  }

  //other
  .readonly {
    pointer-events: none;
  }
  .input-right {
    input {
      text-align: right !important;
    }
    ::placeholder {
      text-align: right;
    }
  }
}
.v-select--is-multi .v-list-item__action .v-simple-checkbox .v-icon, .v-select-list .v-list-item__action .v-simple-checkbox .v-icon{
  font-family: 'mapitfont-v1' !important;
  font-weight: normal !important;
  &.fa-check-square::before {
    font-size: 24px;
    content: '\4e'
  }
  &.fa-square::before {
    font-size: 24px;
    content: '\4d'
  }
}

</style>
